import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import paymentsService from './../../services/payments';

import PaymentsListSearch from './PaymentsListSearch';
import PaymentsListItem from './PaymentsListItem';
import List from './../commons/List';

class PaymentsList extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      payments: [],
      loading: false,
      reload: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = JSON.stringify(this.props.query) !== JSON.stringify(prevProps.query);
    if (reload) {
      this._isMounted && this.setState({ reload: !this.state.reload });
    }
  }

  load (limit, offset, searchQuery) {
    return paymentsService.getAll({...this.props.query, ...searchQuery, ...{limit, offset}}).then(([items]) => items);;
  }

  render() {
    const { payments, loading, reload } = this.state;

    return (
      <List
        title={this.props.title}
        reload={reload}
        load={(limit, offset, searchQuery) => this.load(limit, offset, searchQuery)}
        loaded={(items) => this._isMounted && this.setState({payments: items})}
        searchEnabled={this.props.searchEnabled}
        renderSearch={(onInitialize, searchQuery, onQueryChange) => (
          <PaymentsListSearch
            query={searchQuery}
            onQueryChange={onQueryChange}
            onInitialize={onInitialize}
            searching={loading}
            onError={this.props.onError}
            expanded={this.props.searchExpanded}
          />
        )}
        defaultSearchQuery={this.props.defaultSearchQuery}
        listOnlyOnSearch={this.props.listOnlyOnSearch}
        onSelect={this.props.onSelect}
        selectedItemsKeys={this.props.selectedItemsKeys}
        onError={this.props.onError}
        onSuccess={this.props.onSuccess}
        infiniteScrollProps={ {
          limit: this.props.limit || 25,
          scrollableTarget: this.props.scrollableTarget,
          items: payments,
          onLoading: (loading) => this._isMounted && this.setState({loading}),
          noResult: this.props.noResult,
        } }
        renderItem={ (payment, selectable) => (
          <PaymentsListItem
            payment={payment}
            onError={this.props.onError}
            onSuccess={this.props.onSuccess}
            right={this.props.listItemRight}
            disableExpand={selectable}
          />
        ) }
      />
    )
  }
}

const styles = theme => ({});

PaymentsList.propTypes = {
  limit: PropTypes.number,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  query: PropTypes.object,
  noResult: PropTypes.element,
  displayContact:PropTypes.bool,
  displayTimes:PropTypes.bool,
  scrollableTarget: PropTypes.string,
  selectedItemsKeys: PropTypes.array,

  searchEnabled: PropTypes.bool,
  searchExpanded: PropTypes.bool,
  listOnlyOnSearch: PropTypes.bool,
  defaultSearchQuery: PropTypes.object,

  listItemRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
};

PaymentsList.defaultProps = {
  searchEnabled: true,
};

export default withStyles(styles, { withTheme: true })(PaymentsList);
