/**
 * Composant permettant de parcourir les produits du profil.
 * En fonction des props passées, on peut ouvrir différentes éditions :
 * - Si une commande est passée en props, le composant permet de gérer les quantités de produits de celle-ci et de l'enregistrer.
 * - Sinon, si le profil authentifié peut passer commande au profil propriétaire du catalogue, on propose de créer un panier en vu de créer une commande, on utilise l'appStore pour cela.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import ProfileCatalogForOrder from './ProfileCatalogForOrder';
import ProfileCatalogForCart from './ProfileCatalogForCart';

class ProfileCatalog extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  render () {
    const { id_profile_provider, id_profile_customer, id_order, onError, onSuccess, scrollableTarget, onOrderItemsUpdated } = this.props;
    if (!id_profile_provider) {
      return null;
    }
    // Contexte d'une commande
    if (id_order) {
      return (
        <ProfileCatalogForOrder
          id_profile_provider={id_profile_provider}
          id_profile_customer={id_profile_customer}
          id_order={id_order}
          onError={onError}
          onSuccess={onSuccess}
          scrollableTarget={scrollableTarget}
          onOrderItemsUpdated={onOrderItemsUpdated}
        />
      );
    }
    // Par défaut
    return (
      <ProfileCatalogForCart
        id_profile_provider={id_profile_provider}
        onError={onError}
        onSuccess={onSuccess}
        scrollableTarget={scrollableTarget}
      />
    );
  }
}

const styles = theme => ({});

ProfileCatalog.propTypes = {
  id_profile_provider: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id_profile_customer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]), // nécessaire si id_order
  id_order: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]), // pour gérer les order items
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onOrderItemsUpdated: PropTypes.func,
  scrollableTarget:  PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(ProfileCatalog);
