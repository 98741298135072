import Push_API from './api/push';
import { store } from './store';
import { setPushGranted, setPushSubscribed } from './store/features/appSlice';

/****************************************************
 Web push notification support
****************************************************/

/**
 * Check if is Web push notification is available
 * NOTE : ce n'est pas parce que le context Web existe que nous ne sommes pas dans le contexte
 * d'une app iOS (étant donné que l'app iOS embarde notre PWA dans une webView)
 * @returns boolean
 */
export function isPushWebEnabled () {
  if (!('serviceWorker' in navigator)) {
    console.warn("Service Worker isn't supported on this browser");
    return false;
  }

  if (!('Notification' in window)) {
    console.warn("Notification isn't supported on this browser");
    return false;
  }

  if (!('PushManager' in window)) {
    console.warn("Push isn't supported on this browser");
    return false;
  }

  return true;
}

/**
 * Inscrit le service worker au service de notification push
 */
async function swPushSubscription () {
  const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration('/');
  if (!serviceWorkerRegistration) {
    console.warn("missed serviceWorker");
    return false;
  }
  if (!serviceWorkerRegistration.pushManager) {
    console.warn("missed serviceWorker's pushManager support");
    return false;
  }
  let subscription = await serviceWorkerRegistration.pushManager.getSubscription();
  if (!subscription) {
    const response = await Push_API.getKey();
    console.log("push api key : ", response.data);
    subscription = await serviceWorkerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: response.data
    });
  }
  console.log("subscription : ", subscription);
  return subscription;
}

/**
 * Lance le processus d'inscription au service de notification push web
 * en associant l'utilisateur authentifié le cas échéant
 */
export async function subscribeToPushWeb () {
  try {
    let subscription = false;
    if (Notification.permission === "granted") {
      // Service Worker Push subscription
      subscription = await swPushSubscription();
    } else {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        // Service Worker Push subscription
        subscription = await swPushSubscription();
      }
    }
    if (subscription) {
      // save on server
      await Push_API.subscribe({platform: 'web', data: {subscription: subscription.toJSON()}});
      store.dispatch(setPushGranted(true))
      store.dispatch(setPushSubscribed(true))
    }
  } catch (error) {
    console.warn("Erreur durant le processus d'inscription au service de notification push web : ", error);
  }
}

/**
 * Lance le processus de désinscription du service de notification push web
 * en désassociant l'utilisateur authentifié le cas échéant
 */
export async function unsubscribeToPushWeb () {
  try {
    const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration('/');
    if (!serviceWorkerRegistration) {
      console.warn("missed serviceWorker");
      return false;
    }
    if (!serviceWorkerRegistration.pushManager) {
      console.warn("missed serviceWorker's pushManager support");
      return false;
    }
    let subscription = await serviceWorkerRegistration.pushManager.getSubscription();
    if (subscription) {
      await Push_API.unsubscribe({platform: 'web', data: {subscription: subscription.toJSON()}});
      store.dispatch(setPushSubscribed(false))
    }
  } catch (error) {
    console.warn("Erreur durant le processus de désinscription du service de notification push web : ", error);
  }
}