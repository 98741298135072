import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  get (id, params) {
    return API.get('/orders/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAll (params) {
    return API.get('/orders/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  count (params) {
    return API.get('/orders/count', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  create (data) {
    return API.post('/orders/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  update (id, data) {
    return API.post('/orders/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  delete (id) {
    return API.delete('/orders/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  print (id) {
    return API.get('/orders/' + id + '/print')
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  setState (id, state, state_comment = '') {
    return API.post('/orders/' + id + '/state', {state, state_comment})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getOrderItems (id, params) {
    return API.get('/orders/' + id + '/items', {params})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  setOrderItems (id, order_items = []) {
    return API.post('/orders/' + id + '/items/bulk/', {order_items})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
