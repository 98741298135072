import Profiles_API from './api/profiles';
import utilsService from './utils';
import attachmentsService from './attachments';

/****************************************************
 Interface avec l'API relative aux profils
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
  TYPE_PRO: 'pro', // profil professionnel
	TYPE_PART: 'part', // profil particulier

  STATE_OPENED: 'opened', // profil ouvert
	STATE_HOLIDAYS: 'holidays', // profil en vacances
	STATE_CLOSED: 'closed', // profil fermé
	
	VISIBILITY_PUBLIC: 'public', // profil public (visible sur le réseau public / abonnement sans modération)
	VISIBILITY_PRIVATE: 'private', // profil privé (visible sur le réseau public (sauf catalogue, relations, etc. / abonnement soumis à modération))
	VISIBILITY_HIDDEN: 'hidden', // profil caché (invisible sur le réseau public (abonnement soumis à modération))

  CONTACT_ROLE_DEFAULT : 'default', // coordonnée à utiliser par défaut
	CONTACT_ROLE_BILLING : 'billing', // coordonnée à utiliser pour la facturation
	CONTACT_ROLE_SHIPPING : 'shipping', // coordonnée à utiliser pour l'expédition

	AUTOMATION_ORDER_STATE_DONE : 'order_state_done', // passe automatiquement une commande en statut 'terminé'
	AUTOMATION_ORDER_STATE_MISSED : 'order_state_missed', // passe automatiquement une commande en statut 'manquée'

	AUTOMATIC_BILLING_ON_ORDER_DONE : 'on_order_done', // facturation automatique à finalisation d'une commande
	AUTOMATIC_BILLING_AT_END_OF_MONTH : 'at_end_of_month', // facturation automatique en fin de mois

  BILLING_PAYMENT_DELAY_0 : 'payment_delay_0',
  BILLING_PAYMENT_DELAY_30 : 'payment_delay_30',
  BILLING_PAYMENT_DELAY_45 : 'payment_delay_45',
  BILLING_PAYMENT_DELAY_60 : 'payment_delay_60',
  BILLING_PAYMENT_DELAY_CUSTOM : 'payment_delay_custom',

  PRINTER_TYPE_NONE : "none", // aucune imprimante
	PRINTER_TYPE_STAR_CLOUDPRNT : "star_cloudprnt", // imprimante Star Micronics CloudPRNT

  /**************** profiles ************************************/
  /**
   * Compte les profils
   * @return Promise(data, notices) : nombre de profils
   */
  count (args) {
    return new Promise((resolve, reject) => {
      Profiles_API.count(args).then(({data, notices}) => {
        utilsService.debugLog('Nombre de profils : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les profils
   * @return Promise(data, notices) : les profils
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Profiles_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Profils récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un profil
   * @return Promise(data, notices) : l'profil
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Profiles_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Profil récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouvel profil
   * @return Promise(data, notices) : l'profil créé
   */
  create (data) {
    return new Promise((resolve, reject) => {
      Profiles_API.create(data).then(({data, notices}) => {
        utilsService.debugLog('Profil créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un profil
   * @return Promise(data, notices) : l'profil mis à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Profiles_API.update(id, data).then(({data, notices}) => {
        utilsService.debugLog('Profil mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Demande la suppression d'un profil
   * @return Promise(id, data, notices) : l'identifiant du profil
   */
  requestDeletion (id) {
    return new Promise((resolve, reject) => {
      Profiles_API.requestDeletion(id).then(({data, notices}) => {
        utilsService.debugLog('Demande de suppression de profil : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un profil
   * @return Promise(id, data, notices) : l'identifiant du profil supprimé
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      Profiles_API.delete(id).then(({data, notices}) => {
        utilsService.debugLog('Profil supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** Options ***********************************/
  /**
   * Récupère les options d'un profile
   * @return Promise(data, notices) : les options d'un profile
   */
  getOptions (id, args) {
    return new Promise((resolve, reject) => {
      Profiles_API.getOptions(id, args).then(({data, notices}) => {
        utilsService.debugLog('Options de profil récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** Contacts ***********************************/
  /**
   * Récupère les contacts d'un profile
   * @return Promise(data, notices) : les contacts d'un profile
   */
  getContacts (id, args) {
    return new Promise((resolve, reject) => {
      Profiles_API.getContacts(id, args).then(({data, notices}) => {
        utilsService.debugLog('Contacts de profil récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** products categories **********************/
  /**
   * Récupère une catégorie de produit du profile
   * @return Promise(data, notices) : la catégorie de produit créée
   */
  getProductCategory (id_profile, id) {
    return new Promise((resolve, reject) => {
      Profiles_API.getProductCategory(id_profile, id).then(({data, notices}) => {
        utilsService.debugLog('Catégorie de produit récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les catégories de produit du profile
   * @return Promise(data, notices) : la catégorie de produit créée
   */
  getAllProductCategories (id_profile, args) {
    return new Promise((resolve, reject) => {
      Profiles_API.getAllProductCategories(id_profile, args).then(({data, notices}) => {
        utilsService.debugLog('Catégories de produit récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Crée une catégorie de produit dans le profile
   * @return Promise(data, notices) : la catégorie de produit créée
   */
  createProductCategory (id_profile, data) {
    return new Promise((resolve, reject) => {
      Profiles_API.createProductCategory(id_profile, data).then(({data, notices}) => {
        utilsService.debugLog('Catégorie de produit créée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour une catégorie de produit dans le profile
   * @return Promise(data, notices) : la catégorie de produit mise à jour
   */
  updateProductCategory (id_profile, id, data) {
    return new Promise((resolve, reject) => {
      Profiles_API.updateProductCategory(id_profile, id, data).then(({data, notices}) => {
        utilsService.debugLog('Catégorie de produit mise à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime une catégorie de produit dans le profile
   * @return Promise(data, notices) : true if success
   */
  deleteProductCategory (id_profile, id) {
    return new Promise((resolve, reject) => {
      Profiles_API.deleteProductCategory(id_profile, id).then(({data, notices}) => {
        utilsService.debugLog('Catégorie de produit supprimée : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** products tags **********************/
  /**
   * Récupère un tag de produit du profil
   * @return Promise(data, notices) : la tag de produit créée
   */
  getProductTag (id_profile, id) {
    return new Promise((resolve, reject) => {
      Profiles_API.getProductTag(id_profile, id).then(({data, notices}) => {
        utilsService.debugLog('Tag de produit récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les tags de produit du profile
   * @return Promise(data, notices) : la tag de produit créée
   */
  getAllProductTags (id_profile, args) {
    return new Promise((resolve, reject) => {
      Profiles_API.getAllProductTags(id_profile, args).then(({data, notices}) => {
        utilsService.debugLog('Tags de produit récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Crée un tag de produit dans le profil
   * @return Promise(data, notices) : la tag de produit créée
   */
  createProductTag (id_profile, data) {
    return new Promise((resolve, reject) => {
      Profiles_API.createProductTag(id_profile, data).then(({data, notices}) => {
        utilsService.debugLog('Tag de produit créée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un tag de produit dans le profil
   * @return Promise(data, notices) : la tag de produit mise à jour
   */
  updateProductTag (id_profile, id, data) {
    return new Promise((resolve, reject) => {
      Profiles_API.updateProductTag(id_profile, id, data).then(({data, notices}) => {
        utilsService.debugLog('Tag de produit mise à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un tag de produit dans le profil
   * @return Promise(data, notices) : true if success
   */
  deleteProductTag (id_profile, id) {
    return new Promise((resolve, reject) => {
      Profiles_API.deleteProductTag(id_profile, id).then(({data, notices}) => {
        utilsService.debugLog('Tag de produit supprimée : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  /***************************** Extras ************************/

  getAvatar (profile) {
    const profileImage = attachmentsService.get_url(profile?.attachment, 'thumb');
    if (profileImage) {
      return { content: true, props: {src: profileImage} };
    } else {
      return { content: profile && profile.name ? utilsService.getInitials(profile.name) : "", props: {} };
    }
  },

  getSubInfo (profile) {
    let subInfo = '';
    if (profile?.id_business_sector && profile?.business_sector?.name) {
      subInfo = profile.business_sector.name;
    }
    if (profile?.address_cp && profile?.address_city) {
      subInfo += subInfo !== '' ? ' - ' : '';
      subInfo += profile.address_city + " (" + utilsService.getCpCode(profile.address_cp) + ")";
    }
    if (profile?.state === this.STATE_CLOSED) {
      subInfo += subInfo !== '' ? ' ' : '';
      subInfo += "(fermé)";
    } else if (profile?.state === this.STATE_HOLIDAYS) {
      subInfo += subInfo !== '' ? ' ' : '';
      subInfo += "(en congès)";
    }
    return subInfo;
  },

  getStateLabel (slug, capitalize = false) {
    switch (slug) {
      case this.STATE_OPENED: {
        return capitalize ? 'Ouvert' : 'ouvert';
      }
      case this.STATE_HOLIDAYS: {
        return capitalize ? 'En congès' : 'en congès';
      }
      case this.STATE_CLOSED: {
        return capitalize ? 'Fermé' : 'fermé';
      }
      default: {
        return '';
      }
    }
  },

  getVisibilityLabel (slug, capitalize = false) {
    switch (slug) {
      case this.VISIBILITY_PUBLIC: {
        return capitalize ? 'Public' : 'public';
      }
      case this.VISIBILITY_PRIVATE: {
        return capitalize ? 'Privé' : 'privé';
      }
      case this.VISIBILITY_HIDDEN: {
        return capitalize ? 'Caché' : 'caché';
      }
      default: {
        return '';
      }
    }
  },

  getTypeLabel (slug, capitalize = false, short = false) {
    switch (slug) {
      case this.TYPE_PRO: {
        return (capitalize ? (short ? 'Pro.' : 'Professionnel') : (short ? 'pro.' : 'professionnel'));
      }
      case this.TYPE_PART: {
        return (capitalize ? (short ? 'Part.' : 'Particulier') : (short ? 'part.' : 'particulier'));
      }
      default: {
        return slug;
      }
    }
  },

  countSubscribers (profile) {
    return profile?.count_subscribers_active || 0;
  },

  countSubscriptions (profile) {
    return profile?.count_subscriptions_active || 0;
  },

  hasSubscribers (profile) {
    return this.countSubscribers(profile) > 0 ? true : false;
  },

  hasSubscriptions (profile) {
    return this.countSubscriptions(profile) > 0 ? true : false;
  },
  
  /**
   * Renvoie si le profil est PROFESSIONNEL
   */
  isPro (profile) {
    return profile?.type === this.TYPE_PRO;
  },
  
  /**
   * Renvoie si le profil est PERSONNEL
   */
  isPart (profile) {
    return profile?.type === this.TYPE_PART;
  },
  
  /**
   * Renvoie si le profil est PUBLIC
   */
  isPublic (profile) {
    return profile?.visibility === this.VISIBILITY_PUBLIC;
  },
  
  /**
   * Renvoie si le profil est PRIVÉ
   */
  isPrivate (profile) {
    return profile?.visibility === this.VISIBILITY_PRIVATE;
  },
  
  /**
   * Renvoie si le profil est CACHÉ
   */
  isHidden (profile) {
    return profile?.visibility === this.VISIBILITY_HIDDEN;
  },
  
  /**
   * Renvoie si le profil est OUVERT
   */
  isOpened (profile) {
    return profile?.state === this.STATE_OPENED;
  },
  
  /**
   * Renvoie si le profil est FERMÉ
   */
  isClosed (profile) {
    return profile?.state === this.STATE_CLOSED;
  },
  
  /**
   * Renvoie si le profil est EN VACANCES
   */
  isInHolidays (profile) {
    return profile?.state === this.STATE_HOLIDAYS;
  },
  
  /**
   * Renvoie si le profil est suivi par le profil authentifié
   * NOTE : c'est l'API qui nous donne cette information
   */
  isSubscribed (profile) {
    return profile && profile.is_subscribed === true ? true : false;
  },
  
  /**
   * Renvoie si le profil est abonné au profil authentifié
   * NOTE : c'est l'API qui nous donne cette information
   */
  isSubscriber (profile) {
    return profile && profile.is_subscriber === true ? true : false;
  },

  /**
   * Renvoie si le profil a un abonnement actif
   */
  isPremium (profile) {
    return profile && profile.is_premium === true ? true : false;
  },

  /**
   * Renvoie si le profil est celui d'un agent commercial Bridger
   */
  isSalesAgent (profile) {
    return profile && profile.sales_agent === true ? true : false;
  },

  /**
   * Renvoie si le profil est ouvert aux particuliers
   */
  isBtoC (profile) {
    return profile && profile.btoc === true ? true : false;
  },

  /**
   * Renvoi le label du role du contact
   * @return string
   */
  getContactRoleLabel (role, capitalize = false) {
    switch (role) {
      case this.CONTACT_ROLE_DEFAULT: {
        return capitalize ? 'Adresse principale' : 'adresse principale';
      }
      case this.CONTACT_ROLE_BILLING: {
        return capitalize ? 'Adresse de facturation' : 'adresse de facturation';
      }
      case this.CONTACT_ROLE_SHIPPING: {
        return capitalize ? 'Adresse de livraison' : 'adresse de livraison';
      }
      default: {
        return '';
      }
    }
  },

  /**
   * Renvoi le label du role du contact
   * @return string
   */
  getBillingPaymentDelayLabel (billing_payment_delay, capitalize = false) {
    switch (billing_payment_delay) {
      case this.BILLING_PAYMENT_DELAY_0: {
        return capitalize ? 'À réception' : 'à réception';
      }
      case this.BILLING_PAYMENT_DELAY_30: {
        return '30 jours après réception';
      }
      case this.BILLING_PAYMENT_DELAY_45: {
        return '45 jours fin de mois';
      }
      case this.BILLING_PAYMENT_DELAY_60: {
        return '60 jours après émission de la facture';
      }
      case this.BILLING_PAYMENT_DELAY_CUSTOM: {
        return capitalize ? 'Autre délai (après émission de la facture)' : 'autre délai (après émission de la facture)';
      }
      default: {
        return '';
      }
    }
  }
};
export default exportedObject;
