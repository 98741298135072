import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from '../../services/profiles';
import productsService from '../../services/products';

import { Typography, Box } from '@mui/material';

class ProductPrice extends React.Component {

  render() {
    const { classes, price, price_ttc, format, tax_rate, volume_unit, format_label, appStore } = this.props;
    const is_pro = appStore?.authProfile?.type === profilesService.TYPE_PRO;

    if (is_pro && (!price || parseFloat(price) < 0)) {
      return null;
    }
    if (!is_pro && (!price_ttc || parseFloat(price_ttc) < 0)) {
      return null;
    }

    return (
        <Box className={classes.infos_price}>
          { is_pro && (
            <Typography className={classes.product_price} component="span" variant="body3"> { price }€{ tax_rate && parseInt(tax_rate) > 0 ? 'HT' : ''}</Typography>
          ) }
          { !is_pro && (
            <Typography className={classes.product_price} component="span" variant="body3"> { price_ttc }€{ tax_rate && parseInt(tax_rate) > 0 ? 'TTC' : ''}</Typography>
          ) }
          { format === productsService.META_FORMAT_UNIT && (
            <Typography component="span" variant="body3">/{format_label && format_label !== '' ? format_label : 'unité'}</Typography>
          ) }
          { (format === productsService.META_FORMAT_PORTION || format === productsService.META_FORMAT_BULK) && (
            <Typography component="span" variant="body3">/{volume_unit}</Typography>
          ) }
          { is_pro && parseFloat(tax_rate) > 0 && (
            <Typography component="span" variant="body3"> (TVA { tax_rate }%)</Typography>
          ) }
        </Box>
    )
  }
}

const styles = theme => ({
  product_price: {
    fontWeight: 600,
  },
});

ProductPrice.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price_ttc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
  tax_rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  volume_unit: PropTypes.string,
  format_label: PropTypes.string,
  hideFormatLabel: PropTypes.bool,
  is_pro: PropTypes.bool,
};

const mapStateToProps = state => ({
  appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ProductPrice));