import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  get (id, params) {
    return API.get('/payments/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAll (params) {
    return API.get('/payments/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getKey () {
    return API.get('/payments/key')
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  setupIntent (data) {
    return API.post('/payments/setupIntent/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  setupIntentSuccess (data) {
    return API.post('/payments/setupIntentSuccess/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
