import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  /**************** groups ************************************/
  get (id, params) {
    return API.get('/groups/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAll (params) {
    return API.get('/groups/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  create (data) {
    return API.post('/groups/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  update (id, data) {
    return API.post('/groups/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  delete (id, data) {
    return API.delete('/groups/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
