/**
 * Composant affichant un produit du profil
 * - Si une commande 'order' est passée en props, le composant permet
 * de gérer la quantité de celui-ci
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from './../../services/profiles';
import utilsService from './../../services/utils';

import BackdropLoading from './../commons/BackdropLoading';
import ConfirmDialog from './../commons/ConfirmDialog';
import DialogEditProductCategory from './../dialog/DialogEditProductCategory';
import ListItemContent from './../commons/ListItemContent';
import ListItemContentSummary1 from './../commons/ListItemContentSummary1';
import ListItemContentSummary2 from './../commons/ListItemContentSummary2';
import { CategoryIcon } from './../commons/Icons';

import Box from '@mui/material/Box';

class ProductCategory extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      openDialog_edit: false,
      openConfirm_delete: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openEdit() {
    this._isMounted && this.setState({openDialog_edit: true})
  }
  closeEdit() {
    this._isMounted && this.setState({openDialog_edit: false})
  }

  openDelete() {
    this._isMounted && this.setState({openConfirm_delete: true})
  }
  closeDelete() {
    this._isMounted && this.setState({openConfirm_delete: false})
  }

  delete () {
    const { id_profile, category } = this.props;
    this._isMounted && this.setState({loading: true});
    profilesService.deleteProductCategory(id_profile, category.ID).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      this.props.onDeleted(id);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error, this.state.is_new);
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
  }

  render () {
    const { id_profile, category } = this.props;
    return (
      <React.Fragment>
        <ListItemContent
          avatar={<CategoryIcon />}
          avatarProps={{variant: "rounded"}}
          right={this.renderRight()}
          summary={this.renderSummary()}
        />
        <BackdropLoading open={this.state.loading} />
        { this.state.openDialog_edit && (
          <DialogEditProductCategory
            open={this.state.openDialog_edit}
            id_profile={id_profile}
            category={category}
            onSaved={ (category, is_new) => {
              this.closeEdit();
              this.props.onSaved && this.props.onSaved(category, is_new);
            } }
            onError={this.props.onError}
            onSuccess={this.props.onSuccess}
            onClose={ () => this.closeEdit() }
          />
        ) }
        { this.state.openConfirm_delete && (
          <ConfirmDialog
            open={this.state.openConfirm_delete}
            message="Voulez-vous vraiment supprimer cette catégorie ? Cette action est irréversible."
            onConfirm={ () => {
              this.closeDelete();
              this.delete();
            } }
            onClose={ () => this.closeDelete() }
          />
        ) }
      </React.Fragment>
    );
  }

  renderSummary () {
    const { classes, category } = this.props;
    return (
      <Box className={classes.summary}>
        <ListItemContentSummary1>
          {utilsService.showDebugID(category)}{category.name}
        </ListItemContentSummary1>
        { (category && category.description) && (
          <ListItemContentSummary2>
            { utilsService.truncate(utilsService.stripHtmlTags(category.description), 12) }
          </ListItemContentSummary2>
        ) }
      </Box>
    );
  }

  renderRight () {
    const { right } = this.props;
    if (!right) {
      return null;
    }
    return right;
  }
}

const styles = theme => ({
  details: {},
  detail: {
    marginBottom: theme.spacing(0.5),
  },
  detail_with_start_icon: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  detail_icon: {
    marginRight: theme.spacing(1),
    color: '#555555',
    fontSize: 16,
  },
  detail_txt: {
  },
  details_sep: {
    margin: theme.spacing(0.5, 0),
    padding: 0,
    width: 80,
    height: 1,
    backgroundColor: theme.palette.bg.main,
    clear: 'both',
  },
});

ProductCategory.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  category: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onSaved: PropTypes.func,
  onDeleted: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(ProductCategory);
