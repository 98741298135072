import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  get (id, params) {
    return API.get('/invitations/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getByToken (token, params) {
    return API.get('/invitations/tk/' + token, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAll (id, params) {
    return API.get('/invitations/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  create (data) {
    return API.post('/invitations/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  update (id, data) {
    return API.post('/invitations/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  registerUser (token, data) {
    return API.post('/invitations/registerUser/' + token, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  accept (token, data = {}) {
    return API.post('/invitations/accept/' + token, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  request (data) {
    return API.post('/invitations/request/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  }
}

export default exportedObject;
