import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import ProfilesList from '../../profile/ProfilesList';
import FormFields from '../../commons/FormFields';
import FormField from '../../commons/FormField';
import { WorldIcon } from '../../commons/Icons';
import { Box, Button } from '@mui/material';

class EditNewOrderProfile extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      showNetwork: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount () {
    this._isMounted = true;
    const { order } = this.props;
    if (!order) {
      console.warn("commande manquante");
    }
    if (!order?.scenario) {
      console.warn("scénario  manquant");
    }
    if (order?.scenario === "provider" && !order?.id_profile_provider) {
      console.warn("fournisseur manquant");
    }
    if (order?.scenario === "customer" && !order?.id_profile_customer) {
      console.warn("client manquant");
    }
  }

  render () {
    const { onChange, onError, order, classes, scrollableTarget, enableNetwork } = this.props;
    const { showNetwork } = this.state;

    if (order?.scenario === "provider") {
      return (
        <FormFields>
          <FormField>
            <ProfilesList
              searchPlaceholder="Rechercher parmi mes abonnés"
              onSelect={(profile) => {
                onChange({...order, ...{id_profile_customer: profile?.ID}});
              }}
              onError={onError}
              query={{id_profile_subscribed_to: order.id_profile_provider}}
              selectedItemsKeys={[order.id_profile_customer]}
              scrollableTarget={scrollableTarget}
            />
          </FormField>
        </FormFields>
      );
    }

    if (order?.scenario === "customer") {
      if (enableNetwork && showNetwork) {
        return (
          <FormFields>
            <FormField>
              <ProfilesList
                searchPlaceholder="Rechercher dans le réseau Bridger "
                onSelect={(profile) => {
                  onChange({...order, ...{id_profile_provider: profile?.ID}});
                }}
                onError={onError}
                query={{premium: true}}
                selectedItemsKeys={[order.id_profile_provider]}
                scrollableTarget={scrollableTarget}
              />
            </FormField>
            <FormField>
              <Box className={classes.action}>
                <Button onClick={() => this._isMounted && this.setState({showNetwork: false})} startIcon={<WorldIcon />}>Mes abonnements</Button>
              </Box>
            </FormField>
          </FormFields>
        );
      }
      return (
        <FormFields>
          <FormField>
            <ProfilesList
              searchPlaceholder="Rechercher parmi mes abonnements"
              onSelect={(profile) => {
                onChange({...order, ...{id_profile_provider: profile?.ID}});
              }}
              onError={onError}
              query={{id_profile_subscribed_by: order.id_profile_customer, premium: true}}
              selectedItemsKeys={[order.id_profile_provider]}
              scrollableTarget={scrollableTarget}
            />
          </FormField>
          { enableNetwork && (
            <FormField>
              <Box className={classes.action}>
                <Button onClick={() => this._isMounted && this.setState({showNetwork: true})} startIcon={<WorldIcon />}>Rechercher dans le réseau Bridger</Button>
              </Box>
            </FormField>
          ) }
        </FormFields>
      );
    }

    return null;
  }
}

const styles = theme => ({
  action: {
    display: 'flex',
    justifyContent: 'center',
  },
});

EditNewOrderProfile.propTypes = {
  order: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onQuit: PropTypes.func,
  scrollableTarget: PropTypes.string,
  enableNetwork: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(EditNewOrderProfile);
