import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditInvoices from '../edit/EditInvoices';
import { DialogContent, DialogTitle } from '@mui/material';
import DialogBase from './DialogBase';

class DialogEditInvoices extends React.Component {

  render () {
    const { classes, open, onClose, id_invoice, ...others } = this.props;
    const scrollableTargetId = "dialog-edit-invoices";
    return (
      <DialogBase
        open={open}
        onClose={onClose}
      >
        <DialogTitle>Émettre des factures</DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}} id={scrollableTargetId}>
          <EditInvoices
            scrollableTarget={scrollableTargetId}
            id_invoice={id_invoice}
            {...others}
          />
        </DialogContent>
      </DialogBase>
    )
  }
}

const styles = theme => ({
  dialogContent: {
    padding: theme.spacing(0),
  },
});

DialogEditInvoices.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditInvoices);
