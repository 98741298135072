import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import authService from '../../services/auth';
import profilesService from '../../services/profiles';

import BackdropLoading from '../commons/BackdropLoading';
import EditControllers from '../commons/EditControllers';
import SelectProfiles from '../commons/SelectProfiles';
import Forbidden from '../commons/Forbidden';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';
import FormField from '../commons/FormField';
import SetProfileAutomations from '../commons/SetProfileAutomations';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { TextField } from '@mui/material';

class EditProfileProduction extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      preparation_time: 0,
      moderation: null,
      automations: [],
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
    const { id_profile, onError } = this.props;
    if (id_profile) {
      this._isMounted && this.setState({loading: true});
      profilesService.get(id_profile).then(([profile]) => {
        this._isMounted && this.setState({preparation_time: profile.preparation_time});
        return profilesService.getOptions(profile.ID);
      }).then(([options]) => {
        this._isMounted && this.setState({
          moderation: options?.moderation,
          automations: options?.automations && Array.isArray(options?.automations) ? options?.automations : [],
        });
      }).catch((e) => {
        onError(e);
      }).finally(() => {
        this._isMounted && this.setState({loading: false})
      });
    }
  }

  save (e) {
    e.preventDefault();
    const { id_profile } = this.props;
    const data = {
      preparation_time: this.state.preparation_time ? parseInt(this.state.preparation_time) : 0,
      options: {
        moderation: Array.isArray(this.state.moderation) ? this.state.moderation.map((profile) => profile.ID) : this.state.moderation,
        automations: this.state.automations,
      },
    }
    this._isMounted && this.setState({loading: true})
    profilesService.update(id_profile, data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération de l'item à jour
      return profilesService.get(id);
    }).then(([profile]) => {
      this.props.onSaved(profile);

      // Mise à jour du profil si c'est celui qui est authentifié
      return authService.maybeRefreshToken(profile.ID);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false})
    });
    return false;
  }

  render () {
    const { classes, id_profile, appStore } = this.props;

    if (!id_profile) {
      return <Forbidden message={<Typography variant="body1">Profil manquant.</Typography>} />;
    }

    if (!appStore?.auth?.id_user) {
      return <Forbidden />;
    }

    const moderation_type = !this.state.moderation ? 'none' : (Array.isArray(this.state.moderation) ? 'some' : this.state.moderation);

    return (
      <Box className={classes.container}>
        <BackdropLoading open={this.state.loading} />

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields className={classes.formFields}>

            <FormField className={classes.form_field}>
              <Typography variant="body2" component="h2" className={classes.form_field_title}>Général</Typography>
              <FormControl className={classes.form_control}>
                <TextField
                  id="preparation_time"
                  name="preparation_time"
                  label="Délai de préparation (h)"
                  type="number"
                  value={this.state && this.state.preparation_time ? this.state.preparation_time : ''}
                  onChange={ (e) => this._isMounted && this.setState({ preparation_time: e.target.value }) }
                  autoComplete="off"
                />
              </FormControl>
            </FormField>

            <FormField className={classes.form_field}>
              <Typography variant="body2" component="h2" className={classes.form_field_title}>Automatisation</Typography>
              <SetProfileAutomations value={this.state.automations} onChange={(automations) => this.setState({automations})} />
            </FormField>

            <FormField className={classes.form_field_last}>
              <Typography variant="body2" component="h2" className={classes.form_field_title}>Modération</Typography>
              <FormControl className={classes.form_control}>
                <InputLabel id="select-label-type">Type de modération</InputLabel>
                <Select
                  className={classes.select_field}
                  labelId="select-label-type"
                  label="Type de modération"
                  id="type"
                  value={ moderation_type }
                  onChange={ (e) => {
                    if (e.target.value === 'some') {
                      this._isMounted && this.setState({ moderation: [] })
                    } else {
                      this._isMounted && this.setState({ moderation: e.target.value });
                    }
                  } }
                >
                  <MenuItem value="none">Aucune modération</MenuItem>
                  <MenuItem value="all">Modérer toutes les commandes</MenuItem>
                  <MenuItem value="some">Modérer les commandes de certains abonnés</MenuItem>
                </Select>
              </FormControl>

              { Array.isArray(this.state.moderation) && (
                <SelectProfiles
                  title="Abonnés"
                  label="Aucun abonné"
                  value={ this.state.moderation }
                  query={ {id_profile_subscribed_to: id_profile} }
                  onChange={ (profiles) => {
                    this._isMounted && this.setState({moderation: profiles});
                  } }
                  onError={this.props.onError}
                />
              ) }

            </FormField>

          </FormFields>

          <EditControllers sticky>
            <Button type="submit" onClick={ (e) => this.save(e) } color="primary" variant="contained" className={classes.submit}>
              Enregistrer
            </Button>
          </EditControllers>

        </Form>

      </Box>
    )
  }

}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  form_field: {
    borderBottom: '1px solid ' + theme.palette.bg.light,
  },
  form_field_last: {},
  form_field_title: {
    fontWeight: 600,
    textDecoration: 'underline',
    marginBottom: theme.spacing(2),
  },
  form_control: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  submit: {
    width: '100%',
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
});

EditProfileProduction.propTypes = {
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const mapStateToProps = state => ({
  appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditProfileProduction));
