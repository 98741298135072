import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

class TextFieldLimited extends React.Component {

  render () {
    const { classes, value, onChange, limit, ...others } = this.props;
    return (
      <Box className={classes.root}>
        <TextField
          value={value}
          onChange={ (e) => onChange(e.target.value.slice(0, limit)) }
          {...others}
        />
        <Typography className={classes.info} component="span" variant="body3">{ value ? value.length : '0' }/{ limit }</Typography>
      </Box>
    );
  }
}

TextFieldLimited.propTypes = {
  onChange: PropTypes.func.isRequired,
  limit: PropTypes.number,
};

TextFieldLimited.defaultProps = {
  limit: 200,
};

const styles = theme => ({
  root: {
    position: 'relative',
  },
  info: {
    position: 'absolute',
    bottom: 0,
    right: 4,
    color: '#999999',
  },
});

export default withStyles(styles, { withTheme: true })(TextFieldLimited);
