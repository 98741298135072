import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditNewComplaint from '../edit/EditNewComplaint';
import Dialog from './Dialog';

class DialogEditNewComplaint extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onClose () {
    const { onClose } = this.props;
    onClose && onClose();
  }

  render () {
    const { classes, open, onClose, fullScreen, keepMounted, id, ...others } = this.props;
    return (
      <Dialog
        keepMounted={keepMounted}
        id={id}
        fullScreen={false}
        maxWidth="xs"
        fullWidth={true}
        open={open}
        onClose={() => this.onClose()}
        title="Signalement"
      >
        <EditNewComplaint
          scrollableTarget={id}
          fixedCtrl={fullScreen}
          {...others}
        />
      </Dialog>
    )
  }
}

const styles = theme => ({});

DialogEditNewComplaint.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditNewComplaint);
