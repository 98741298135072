import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withHOCComponent } from '../hoc/HOCComponent';
import authService from '../../services/auth';
import { isEmpty } from '../../services/utils';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CheckIcon } from '../commons/Icons';
import { CircularProgress } from '@mui/material';

class AuthUserLostPassword extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      success: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  lostPassword (e) {
    const { data, onSubmited } = this.props;
    e.preventDefault();
    if (isEmpty(data.login)) {
      this.props.hoc.showError('Email/Identifiant manquant');
      return;
    }
    this._isMounted && this.setState({loading: true});

    // TODO envoyer l'URL courante pour qu'après changement du mot de passe l'utilisateur soit rediriger au bon endroit

    authService.lostPassword(data.login).then((data) => {
      onSubmited && onSubmited(data);
      this._isMounted && this.setState({success: true});
    }).catch((error) => {
      this.props.hoc.showError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
    return false;
  }

  render () {
    const { classes, onChangeData, data, onChangeAction } = this.props;
    const { loading, success } = this.state;
    let submitEndIcon = null;
    if (loading) {
      submitEndIcon = <CircularProgress size={15} />
    } else if (success) {
      submitEndIcon = <CheckIcon />
    }
    return (
      <Box>

        <Typography className={classes.info_title} component="h2" variant="body2">Renseignez votre identifiant ou votre adresse email.</Typography>
        <Typography className={classes.info} component="div" variant="body2">En soumettant cette demande, vous allez recevoir un email vous permettant de réinitialiser votre mot de passe. Pensez à regarder dans vos spams si cet email n'apparait pas.</Typography>

        <form onSubmit={ (e) => this.lostPassword(e) } className={classes.form} noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  inputProps={{autoCapitalize: 'none'}}
                  required
                  id="login"
                  autoComplete="username"
                  label="Identifiant / Email"
                  className={classes.input}
                  value={data.login || ''}
                  onChange={ (e) => onChangeData({...data, ...{login: e.target.value}}) }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button disabled={loading || success} type="submit" className={classes.button} onClick={ (e) => this.lostPassword(e) } color="primary" variant="contained" endIcon={submitEndIcon}>
                Soumettre
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button size="small" className={classes.button} onClick={() => onChangeAction('login')}>
                retour
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }
}

const styles = theme => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
  info_title: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
  info: {
    marginBottom: theme.spacing(2),
  }
});

AuthUserLostPassword.propTypes = {
  onSubmited: PropTypes.func,
  onChangeAction: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onChangeData: PropTypes.func.isRequired,
};

export default withHOCComponent(withStyles(styles, { withTheme: true })(AuthUserLostPassword));