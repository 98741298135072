import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ordersService from '../../../services/orders'

import FormFields from '../../commons/FormFields';
import FormField from '../../commons/FormField';
import ToggleRadio from '../../commons/ToggleRadio';
import { FormControl, TextField, Typography } from '@mui/material';

class EditNewOrderType extends React.Component {

  render () {
    const { order, onChange } = this.props;

    return (
      <FormFields>
        <FormField>
          <FormControl>
            <ToggleRadio
              name="type"
              id="type"
              value={order.type || ''}
              onChange={(e) => onChange({...order, ...{type: e.target.value}}) }
              options={[
                {
                  value: ordersService.TYPE_STANDARD,
                  label: {
                    title: "Commande ponctuelle",
                    infos: "Commande unique non renouvelable"
                  }
                },
                {
                  value: ordersService.TYPE_RECURRENT,
                  label: {
                    title: "Commande récurrente",
                    infos: "Commande programmable renouvelable"
                  },
                  disabled: order.scenario === 'provider'
                }
              ]}
            />
          </FormControl>
        </FormField>

        { order?.type === ordersService.TYPE_RECURRENT && (
          <>
          <FormField>
            <FormControl sx={{width: '100%'}}>
              <TextField
                required
                fullWidth
                id="name"
                label="Nom de la récurrence"
                type="text"
                name="title"
                value={order.title || ''}
                onChange={ (e) => onChange({...order, ...{title: e.target.value}}) }
              />
            </FormControl>
          </FormField>
          <FormField>
            <Typography variant="body2" component="div">
            Les commandes récurrentes permettent de planifier une commande selon une certaine fréquence. Cette option optimise la création de commandes et minime les risques d'oublis. Sans modification de votre part, les futures occurrences hériteront des mêmes caractéristiques (excepté le montant).
            <br /><br />
            Il est possible de modifier une commande récurrente (fréquence, produits). Cette modification pourra s'appliquer ponctuellement ou à l'ensemble des futures occurrences. Il est également possible de suspendre une commande récurrente.
            <br /><br />
            Seule la prochaine occurrence émise apparaîtra dans votre tableau de commandes.
            </Typography>
          </FormField>
          </>
        ) }

      </FormFields>
    );
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(0,1),
  },
});

EditNewOrderType.propTypes = {
  order: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(EditNewOrderType);
