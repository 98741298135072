import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import productsService from '../../services/products';

import BackdropLoading from '../commons/BackdropLoading';
import EditControllers from '../commons/EditControllers';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';
import { SaveIcon } from '../commons/Icons';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SetProductClassification from '../commons/SetProductClassification';

class EditProductClassification extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      initialized: false,
      loading: false,
      data: {},
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
    const { id_profile, id_product } = this.props;
    let loaded_data = {};
    this._isMounted && this.setState({loading: true});
    // chargement du produit
    productsService.get(id_profile, id_product).then(([product]) => {
      loaded_data = {
        categories: product.categories ? product.categories.map((category) => category.ID) : [],
        tags: product.tags ? product.tags.map((tag) => tag.ID) : [],
      };
      this._isMounted && this.setState({data: loaded_data});
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false, initialized: true});
    });
  }

  save (e) {
    e.preventDefault();
    const { id_profile, id_product, onSaved } = this.props;
    const { data } = this.state;
    const parsed_data = {
      categories: data.categories ? data.categories.map((category) => category.ID) : [],
      tags: data.tags ? data.tags.map((tag) => tag.ID) : [],
    };
    this._isMounted && this.setState({loading: true});
    // mise à jour du produit
    productsService.update(id_profile, id_product, parsed_data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération du produit à jour
      return productsService.get(id_profile, id_product);
    }).then(([product]) => {
      onSaved && onSaved(product);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
    return false;
  }

  render () {
    const { loading, data, initialized } = this.state;
    const { classes, id_profile, id_product, onError} = this.props;
    if (!id_profile) {
      return (<Typography className={classes.error} component="p">Aucun profil de passé en paramètre.</Typography>)
    }
    if (!id_product) {
      return (<Typography className={classes.error} component="p">Aucun produit de passé en paramètre.</Typography>)
    }

    return (
      <Box className={classes.container}>
        <BackdropLoading open={loading} />

        { initialized && (

          <Form onSubmit={ (e) => this.save(e) } noValidate>

            <FormFields className={classes.formFields}>

              <SetProductClassification
                id_profile={id_profile}
                data={data}
                onChange={ (data) => {
                  this._isMounted && this.setState({data})
                } }
                onError={onError}
              />

            </FormFields>

            <EditControllers sticky>
              <Button type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={<SaveIcon />}>
                Enregistrer
              </Button>
            </EditControllers>

          </Form>

        ) }

      </Box>
    )
  }
}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  submit:{
    width: '100%',
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
});

EditProductClassification.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id_product: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditProductClassification);
