import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import productsService from '../../services/products';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import TextFieldLimited from './TextFieldLimited';
import SelectTaxRate from './SelectTaxRate';

class SetProductPrice extends React.Component {

  render () {
    const { classes, data, onChange } = this.props;

    const is_format_unit = data.format === productsService.META_FORMAT_UNIT;
    const is_format_portion = data.format === productsService.META_FORMAT_PORTION;
    const is_format_bulk = data.format === productsService.META_FORMAT_BULK;

    return (
      <Grid container rowSpacing={2} columnSpacing={2} className={classes.grid}>

        <Grid item xs={12}>
          <FormControl className={classes.form_control}>
            <InputLabel id="select-label-format">Prix unitaire</InputLabel>
            <Select
              className={classes.select_field}
              labelId="select-label-format"
              label="Prix unitaire"
              id="format"
              value={data?.format || ''}
              onChange={ (e) => onChange({...data, ...{format: e.target.value}}) }
            >
              <MenuItem value={productsService.META_FORMAT_UNIT}>{ productsService.getFormatLabel(productsService.META_FORMAT_UNIT) }</MenuItem>
              <MenuItem value={productsService.META_FORMAT_BULK}>{ productsService.getFormatLabel(productsService.META_FORMAT_BULK) }</MenuItem>
              <MenuItem value={productsService.META_FORMAT_PORTION}>{ productsService.getFormatLabel(productsService.META_FORMAT_PORTION) }</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        { (is_format_unit || is_format_portion) && (
          <Grid item xs={12}>
            <FormControl className={classes.form_control}>
              <TextFieldLimited
                id="format_label"
                label={is_format_portion ? "Type de portion" : "Conditionnement"}
                type="text"
                className={classes.input}
                value={data?.format_label || ''}
                onChange={ (format_label) => onChange({...data, ...{format_label}}) }
                helperText={is_format_portion ? "Décrivez avec précision le type de portion. Exemple : demi-melon, part de tarte, demi-tome... (optionnel)" : "Décrivez avec précision le conditionnement du produit. Exemple : carton de 6, pot de 250 g, bouteille de 75 cl, barquette de 12... (Laisser vide si le produit n'est pas conditionné. Exemple : Salade à l'unité.)"}
                limit={20}
              />
            </FormControl>
          </Grid>
        ) }

        <Grid item xs={6}>
          <FormControl className={classes.form_control}>
            <TextField
              id="price"
              label="Prix HT"
              type="number"
              className={classes.input}
              value={data?.price || ''}
              onChange={ (e) => onChange({...data, ...{price: e.target.value}}) }
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
            />
          </FormControl>
        </Grid>
        
        <Grid item xs={6}>
          <SelectTaxRate
            formControlProps={{className: classes.form_control}}
            value={data?.tax_rate || '0'}
            onChange={ (e) => onChange({...data, ...{tax_rate: e.target.value}}) }
          />
        </Grid>

        { (is_format_bulk || is_format_portion) && (
          <>
            <Grid item xs={12}>
              <FormControl className={classes.form_control}>
                <InputLabel id="select-label-vat">Unité de volume *</InputLabel>
                <Select
                  required
                  className={classes.select_field}
                  labelId="select-label-vat"
                  label="Unité de volume *"
                  id="volume_unit"
                  value={data?.volume_unit || ''}
                  onChange={ (e) => onChange({...data, ...{volume_unit: e.target.value}}) }
                >
                  <MenuItem value="kg">kg (kilogramme)</MenuItem>
                  <MenuItem value="g">g (gramme)</MenuItem>
                  <MenuItem value="mg">mg (milligramme)</MenuItem>
                  <MenuItem value="l">l (litre)</MenuItem>
                  <MenuItem value="cl">cl (centilitre)</MenuItem>
                  <MenuItem value="ml">ml (millilitre)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </>
        ) }

        { is_format_portion && (
          <Grid item xs={12}>
            <FormControl className={classes.form_control}>
              <TextField
                required
                id="volume_estimation"
                label="Estimation du volume de la portion"
                type="number"
                className={classes.input}
                value={data?.volume_estimation || ''}
                onChange={ (e) => onChange({...data, ...{volume_estimation: e.target.value}}) }
                InputProps={{
                  startAdornment: <InputAdornment position="start">{data?.volume_unit}</InputAdornment>,
                }}
                helperText="Permet d'estimer le prix d'une commande lorsque les volumes nets ne sont pas renseigné"
              />
            </FormControl>
          </Grid>
        ) }

      </Grid>
    )
  }
}

const styles = theme => ({
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
});

SetProductPrice.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
};

SetProductPrice.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetProductPrice);
