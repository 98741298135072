import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import ProductsList from '../../product/ProductsList';

class EditNewOrderStepCatalog extends React.Component {

  render () {
    const { order, orderItems, onChange, onError, scrollableTarget } = this.props;
    
    return (
      <ProductsList
        id_profile_provider={order.id_profile_provider}
        id_profile_customer={order.id_profile_customer}
        hideTitle={true}
        onError={onError}
        order_items={orderItems}
        onOrderItemsChange={ (orderItems) => {
          onChange(orderItems)
        }}
        scrollableTarget={scrollableTarget}
        searchExpanded={true}
      />
    );
  }
}

const styles = theme => ({});

EditNewOrderStepCatalog.propTypes = {
  order: PropTypes.object.isRequired,
  orderItems: PropTypes.array.isRequired,
  onError: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  scrollableTarget: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(EditNewOrderStepCatalog);
