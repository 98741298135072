import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import contactsService from '../../services/contacts';
import { isEmpty } from '../../services/utils';

import { Box, Typography, ListItemIcon, ListItemText, Divider, MenuItem } from '@mui/material';
import IconMenu from '../commons/IconMenu';
import { DeleteIcon, EditIcon, MoreVertIcon } from '../commons/Icons';
import ConfirmDialog from '../commons/ConfirmDialog';
import DialogEditContact from '../dialog/DialogEditContact';
import BackdropLoading from '../commons/BackdropLoading';

class Contact extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      openDialog_edit: false,
      openConfirm_delete: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  delete () {
    const { contact } = this.props;
    this._isMounted && this.setState({ loading: true });
    contactsService.delete(contact.id_profile, contact.ID).then(([deleted, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      this.props.onDeleted && this.props.onDeleted(contact.ID);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError && this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  render () {
    const { classes, contact, withActions } = this.props;
    const { loading } = this.state;
    let address_part = [];
    if (!isEmpty(contact.address_1)) {
      address_part.push(contact.address_1);
    }
    if (!isEmpty(contact.address_2)) {
      address_part.push(contact.address_2);
    }
    if (!isEmpty(contact.address_cp)) {
      if (!isEmpty(address_part)) {
        address_part.push(" - ");
      }
      address_part.push(contact.address_cp);
    }
    if (!isEmpty(contact.address_city)) {
      address_part.push(contact.address_city);
    }
    if (!isEmpty(contact.address_country)) {
      if (!isEmpty(address_part)) {
        address_part.push(" - ");
      }
      address_part.push(contact.address_country);
    }
    return (
      <Box className={classes.root}>
        { loading && (
          <BackdropLoading open={loading} />
        ) }
        <Box className={classes.content}>
          <Typography className={classes.title} component="div" variant="body1">{contact.address_name}</Typography>
          { !isEmpty(address_part) && (
            <Typography className={classes.address} component="div" variant="body2">{address_part.join(' ')}</Typography>
          ) }
          { contact.role === contactsService.ROLE_DEFAULT && (
              <Typography className={classes.role} component="div" variant="body3">Coordonnées principales du profil</Typography>
          ) }
          { contact.role === contactsService.ROLE_BILLING && (
              <Typography className={classes.role} component="div" variant="body3">Coordonnées de facturation du profil</Typography>
          ) }
          { contact.role === contactsService.ROLE_SHIPPING && (
              <Typography className={classes.role} component="div" variant="body3">Coordonnées livraison du profil</Typography>
          ) }
        </Box>
        { withActions && (
          <>
            <Box className={classes.actions}>
              <IconMenu icon={<MoreVertIcon fontSize="small" />} iconColor="secondary" render={ () => {
                let items = [];

                items.push(<MenuItem key={1} onClick={ (e) => this._isMounted && this.setState({openDialog_edit: true}) }>
                  <ListItemIcon><EditIcon /></ListItemIcon>
                  <ListItemText disableTypography={true} primary="Modifier" />
                </MenuItem>);

                if (contact.role !== contactsService.ROLE_DEFAULT) {

                  items.push(<Divider key={2} />);

                  items.push(<MenuItem key={3} onClick={ (e) => this._isMounted && this.setState({openConfirm_delete: true}) }>
                    <ListItemIcon><DeleteIcon /></ListItemIcon>
                    <ListItemText disableTypography={true} primary="Supprimer" />
                  </MenuItem>);

                }

                return items;
              } } />
            </Box>

            { this.state.openDialog_edit && (
              <DialogEditContact
                open={this.state.openDialog_edit}
                id_profile={contact.id_profile}
                id_contact={contact.ID}
                onSaved={ (contact, isNew) => {
                  this._isMounted && this.setState({openDialog_edit: false});
                  this.props.onSaved && this.props.onSaved(contact, isNew);
                } }
                onError={ (e) => this.props.onError && this.props.onError(e) }
                onClose={ () => this._isMounted && this.setState({openDialog_edit: false}) }
              />
            ) }

            { this.state.openConfirm_delete && (
              <ConfirmDialog
                fullWidth={true}
                maxWidth="xs"
                open={this.state.openConfirm_delete}
                title="Supprimer ce contact"
                message="Confirmez-vous la suppression de ce contact ? Cette action est irréversible."
                onConfirm={ () => {
                  this._isMounted && this.setState({openConfirm_delete: false});
                  this.delete();
                } }
                onClose={ () => this._isMounted && this.setState({openConfirm_delete: false}) }
              />
            ) }
          </>
        ) }
      </Box>
    );
  }
}

const styles = theme => ({
  root: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '40px',
  },
  title: {
    fontWeight: 600,
  },
  address: {},
  role: {
    color: "#999999",
  }
});

Contact.propTypes = {
  contact: PropTypes.object.isRequired,
  withActions: PropTypes.bool,
  onSaved: PropTypes.func,
  onDeleted: PropTypes.func,
  onError: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(Contact);
