import React from 'react';
import { withStyles } from '@mui/styles';

import FormField from './../commons/FormField';

// Documentation : https://stripe.com/docs/js/element/other_element
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const COMMONS_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CARD_NUMBER_ELEMENT_OPTIONS = {
  showIcon: true
};

class CardSection extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>

        <FormField>
          <label className={classes.label}>
            <CardNumberElement options={{...COMMONS_ELEMENT_OPTIONS, ...CARD_NUMBER_ELEMENT_OPTIONS}} />
          </label>
        </FormField>

        <Box>

          <Grid container spacing={2}>

            <Grid item xs={6}>

              <FormField>
                <label className={classes.label}>
                  <CardExpiryElement options={COMMONS_ELEMENT_OPTIONS} />
                </label>
              </FormField>

            </Grid>

            <Grid item xs={6}>

              <FormField>
                <label className={classes.label}>
                  <CardCvcElement options={COMMONS_ELEMENT_OPTIONS} />
                </label>
              </FormField>

            </Grid>

          </Grid>

        </Box>

      </React.Fragment>
    )
  }
}

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(CardSection);
