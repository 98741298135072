import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import authService from '../../services/auth';
import profilesService from '../../services/profiles';
import utilsService from '../../services/utils';
import { isEmpty } from '../../services/utils';

import BackdropLoading from './../commons/BackdropLoading';
import SetProfileState from '../commons/SetProfileState';
import SelectAttachment from './../commons/SelectAttachment';
import EditControllers from './../commons/EditControllers';
import Form from './../commons/Form';
import FormFields from './../commons/FormFields';
import FormField from './../commons/FormField';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SetProfileName from '../commons/SetProfileName';
import SetProfileDescription from '../commons/SetProfileDescription';
import { Typography } from '@mui/material';

class EditProfile extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      data: {
        type: '',
        title: '',
        state: '',
        attachment: null,
      },
    }
  }

  componentDidMount () {
    this._isMounted = true;
    const { id_profile } = this.props;
    if (id_profile) {
      this.load();
    } else {
      console.warn("id_profile manquant - EditProfil ne sert qu'à modifier un profil existant");
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
    const { id_profile, onError } = this.props;
    if (id_profile) {
      this._isMounted && this.setState({loading: true});
      profilesService.get(id_profile).then(([profile]) => {
        this.setState({data: {...this.state.data, ...{
          type: profile.type,
          name: profile.name,
          description: profile.description,
          state: profile.state,
          attachment: profile.id_attachment,
        }}});
      }).catch((e) => {
        onError(e);
      }).finally(() => {
        this._isMounted && this.setState({loading: false})
      });
    }
  }

  validate () {
    const { data } = this.state;
    let valid = true;
    if (isEmpty(data.name)) {
      valid = false;
      this.props.onError("Nom manquant");
    }
    return valid;
  }

  save (e) {
    e.preventDefault();
    const { id_profile } = this.props;
    const { data } = this.state;
    if (!this.validate()) {
      return;
    }
    const parsed_data = {
      name: data.name,
      description: data.description,
      state: data.state,
      id_attachment: utilsService.isObject(data.attachment) ? data.attachment.ID : data.attachment
    }
    this._isMounted && this.setState({loading: true})
    if (id_profile) {
      profilesService.update(id_profile, parsed_data).then(([id, notices]) => {
        notices && notices.length > 0 && this.props.onError(notices);
        // récupération de l'item à jour
        return profilesService.get(id_profile);
      }).then(([profile]) => {
        this.props.onSaved(profile);

        // Mise à jour du profil si c'est celui qui est authentifié
        return authService.maybeRefreshToken(profile.ID);
      }).catch((error) => {
        console.error('error : ', error);
        this.props.onError(error);
      }).finally(() => {
        this._isMounted && this.setState({loading: false})
      });
    }
    return false;
  }

  render () {
    const { classes, id_profile, label_submit } = this.props;
    const { data, loading } = this.state;

    return (
      <Box className={classes.container}>
        <BackdropLoading open={loading} />

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields className={classes.formFields}>

            <FormField>
              <SelectAttachment
                className={{root: clsx(classes.form_control, classes.form_control_select)}}
                id_profile={ id_profile }
                onChange={ (attachment) => this._isMounted && this.setState({data: {...this.state.data, ...{attachment}}}) }
                onError={ this.props.onError }
                value={ data.attachment }
                query={ {} }
                title="Image du profil"
              />
            </FormField>

            <SetProfileName
              data={data}
              onChange={(data) => this._isMounted && this.setState({data})}
            />

            <SetProfileDescription
              data={data}
              onChange={(data) => this._isMounted && this.setState({data})}
            />

            { data.type === profilesService.TYPE_PRO && (
              <>
                <Typography variant="h6" component="div">Disponibilité du profil</Typography>
                <SetProfileState
                  data={data}
                  onChange={(data) => this._isMounted && this.setState({data})}
                />
              </>
            ) }

          </FormFields>

          <EditControllers sticky>
            <Button type="submit" onClick={ (e) => this.save(e) } color="primary" variant="contained" className={classes.submit}>
              { label_submit || "Enregistrer" }
            </Button>
          </EditControllers>

        </Form>

      </Box>
    )
  }

}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  form_control: {
    width: '100%',
  },
  form_control_select: {
  },
  submit: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  info_text: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
});

EditProfile.propTypes = {
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label_submit: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(EditProfile);
