/**
 * Composant affichant les champs de recherche
 * des produits du profil
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import cloneDeep from'lodash.clonedeep';

import Search from './../commons/Search';
import SelectProductCategory from './../commons/SelectProductCategory';
import SelectProductTags from './../commons/SelectProductTags';

import Box from '@mui/material/Box';

class ProductsListSearch extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.initializedFields = [];
    this.state = {
      category: null,
      tags: [],
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onInitialize (field) {
    const { enableTags } = this.props;
    if (!this.initializedFields.includes(field)) {
      this.initializedFields.push(field);
    }
    if (
      this.initializedFields.includes('category') &&
      (!enableTags || this.initializedFields.includes('tags')) &&
      this.initializedFields.includes('search')
    ) {
      this.props.onInitialize && this.props.onInitialize();
    }
  }

  onCategoryInitialized () {
    this.onInitialize('category');
  }

  onCategoryChange (category) {
    this._isMounted && this.setState({category});

    let query = cloneDeep(this.props.query);
    if (!category) {
      delete query.categories;
    } else {
      query.categories = [category.ID];
    }
    this.props.onQueryChange && this.props.onQueryChange(query);
  }

  onTagsInitialized () {
    this.onInitialize('tags');
  }

  onTagsChange (tags) {
    this._isMounted && this.setState({tags});

    let query = cloneDeep(this.props.query);
    if (!tags || tags.length < 1) {
      delete query.tags;
    } else {
      query.tags = tags.map((tag) => tag.ID);
    }
    this.props.onQueryChange && this.props.onQueryChange(query);
  }

  render () {
    const { classes, ...others } = this.props;

    return (
      <Box displayPrint="none">
        <Search
          { ...{...others, ...{
            searching: this.props.searching,
            renderAdvanced: this.renderAdvanced,
            onInitialize: () => this.onInitialize('search')
          }} }
        />
      </Box>
    )
  }

  renderAdvanced = () => {
    const { classes, id_profile, enableTags } = this.props;
    const { category, tags } = this.state;

    return (
      <Box className={classes.search_advanced}>

        <Box className={classes.search_advanced_box}>
          <SelectProductCategory
            title="Choissez une catégorie"
            id_profile={id_profile}
            fullwidth
            className={ {form_control: classes.form_control} }
            label="Catégories"
            value={category}
            onChange={ (category) => this.onCategoryChange(category) }
            onInitialized={ () => this.onCategoryInitialized() }
            onError={this.props.onError}
          />
        </Box>

        { enableTags && (
          <Box className={classes.search_advanced_box}>
            <SelectProductTags
              title="Choissez des tags"
              id_profile={id_profile}
              fullwidth
              className={ {form_control: classes.form_control} }
              label="Tous"
              value={tags}
              onChange={ (tags) => this.onTagsChange(tags) }
              onInitialized={ () => this.onTagsInitialized() }
              onError={this.props.onError}
            />
          </Box>
        ) }

      </Box>
    );
  }
}

const styles = theme => ({
  search_advanced: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
});

ProductsListSearch.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  query: PropTypes.object,
  onQueryChange: PropTypes.func,
  onError: PropTypes.func.isRequired,
  enableTags: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(ProductsListSearch);
