import { BillIcon } from '../components/commons/Icons';
import { store } from './store';
import Invoices_API from './api/invoices';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux factures
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
  STATE_ISSUED: 'issued', // la facture est émise
  STATE_PAYED: 'payed', // la facture est payée
 	STATE_UNPAYED: 'unpayed', // la facture est impayée
 	STATE_CANCELED: 'canceled', // la facture est annulée

  TYPE_CLASSIC: 'classic', // facture classique
  TYPE_CREDIT_NOTE: 'credit_note', // facture d'avoir

  /**************** invoices ************************************/
  /**
   * Récupère les factures du serveur
   * @return Promise(data, notices) : les factures
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Invoices_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Factures récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Compte les factures du serveur
   * @return Promise(data, notices) : les commandes
   */
  count (args) {
    return new Promise((resolve, reject) => {
      Invoices_API.count(args).then(({data, notices}) => {
        utilsService.debugLog('Factures comptées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère une facture du serveur
   * @return Promise(data, notices) : la facture
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Invoices_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Facture récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Télécharge des factures
   * @return Promise(data, notices) : le ZIP
   */
  downloadAll (params) {
    return new Promise((resolve, reject) => {
      Invoices_API.downloadAll(params).then((response) => {
        // console.log('Documents téléchargé - response : ', response);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Télécharge une facture
   * @return Promise(data, notices) : la facture
   */
  download (id, params) {
    return new Promise((resolve, reject) => {
      Invoices_API.download(id, params).then((response) => {
        // console.log('Documents téléchargé - response : ', response);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  getBase64 (id, params = {}) {
    return new Promise((resolve, reject) => {
      Invoices_API.getBase64(id, params).then((response) => {
        // console.log('Documents téléchargé - response : ', response);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé une nouvel facture pour une commande
   * @return Promise(data, notices) : l'identifiant de la facture
   */
  createForOrder (id_order) {
    return new Promise((resolve, reject) => {
      Invoices_API.createForOrder(id_order).then(({data, notices}) => {
        utilsService.debugLog('Facture créée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé une nouvel facture pour un paiement
   * @return Promise(data, notices) : l'identifiant de la facture
   */
  createForPayment (id_payment) {
    return new Promise((resolve, reject) => {
      Invoices_API.createForPayment(id_payment).then(({data, notices}) => {
        utilsService.debugLog('Facture créée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé des nouvelles factures pour des commandes
   * @return Promise(data, notices) : les identifiants des factures
   */
  createForOrders (data) {
    return new Promise((resolve, reject) => {
      Invoices_API.createForOrders(data).then(({data, notices}) => {
        utilsService.debugLog('Factures créées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour le statut d'une facture
   * @return Promise(data, notices) : l'identifiant de la facture mise à jour
   */
  setState (id, state, state_comment = '') {
    return new Promise((resolve, reject) => {
      Invoices_API.setState(id, state, state_comment).then(({data, notices}) => {
        utilsService.debugLog('Statut de facture mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un facture
   * @return Promise(id, data, notices) : l'identifiant de la facture supprimée
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      Invoices_API.delete(id).then(({data, notices}) => {
        utilsService.debugLog('Facture supprimée : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /***************************** Extras ************************/
  /**
   * Renvoi si le profil authentifié est le vendeur de la facture
   * @param {object} order 
   */
  isAuthSeller (order) {
    const storeState = store.getState();
    const id_auth_profile = storeState?.app?.authProfile.ID;
    if (id_auth_profile && order?.id_profile_seller && parseInt(id_auth_profile) === parseInt(order?.id_profile_seller)) {
      return true;
    }
    return false;
  },
  /**
   * Renvoi si le profil authentifié est l'acheteur de la facture
   * @param {object} order 
   */
  isAuthBuyer (order) {
    const storeState = store.getState();
    const id_auth_profile = storeState?.app?.authProfile.ID;
    if (id_auth_profile && order?.id_profile_buyer && parseInt(id_auth_profile) === parseInt(order?.id_profile_buyer)) {
      return true;
    }
    return false;
  },

  /**
   * Renvoie les permissions de l'utilisateur connecté sur la facture
   * @param {object} invoice 
   */
  getPermissions (invoice) {
    const isAuthSeller = this.isAuthSeller(invoice);
    const isAuthBuyer = this.isAuthBuyer(invoice);
    let permissions = {
      is_auth_seller: isAuthSeller, // indique si le profil authentifié est le vendeur
      is_auth_buyer: isAuthBuyer, // indique si le profil authentifié est l'acheteur
      can_make_issued: false,
      can_make_payed: false,
      can_make_unpayed: false,
      can_make_canceled: false,
      can_download: false,
    };

    if (!invoice) {
      console.warn("getPermissions - facture manquante");
    }

    if (isAuthSeller) {
      // --
      // -- c'est le vendeur qui est connecté
      // --
      permissions.can_make_issued = false;
      permissions.can_make_payed = !this.isStateCanceled(invoice) && !this.isStatePayed(invoice);
      permissions.can_make_unpayed = !this.isStateCanceled(invoice) && !this.isStateUnpayed(invoice);
      permissions.can_make_canceled = !this.isStateCanceled(invoice);
      permissions.can_download = true;
    } else if (isAuthBuyer) {
      // --
      // -- c'est l'acheteur qui est connecté
      // --
      permissions.can_download = true;
    }
    return permissions;
  },
  /**
   * Affiche le libellé du type de la facture
   * @return string
   */
  getTypeLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.TYPE_CLASSIC: {
        return 'facture';
      }
      case this.TYPE_CREDIT_NOTE: {
        return 'facture d\'avoir';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Affiche le libellé du type de la facture
   * @return string
   */
  getInvoiceTypeLabel (invoice) {
    if (!invoice) {
      return 'aucun';
    }
    return this.getTypeLabel(invoice.type);
  },
  /**
   * Affiche le libellé du statut de la facture
   * @return string
   */
  getStateLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.STATE_ISSUED: {
        return 'émise';
      }
      case this.STATE_PAYED: {
        return 'payée';
      }
      case this.STATE_UNPAYED: {
        return 'impayée';
      }
      case this.STATE_CANCELED: {
        return 'annulée';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Affiche le libellé du statut de la facture
   * @return string
   */
  getInvoiceStateLabel (invoice) {
    if (!invoice) {
      return 'aucun';
    }
    return this.getStateLabel(invoice.state);
  },
  /**
   * Renvoi le slug de la couleur du statut de la facture
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * NOTE : la couleur peur être null dans certains cas.
   * @return string
   */
  getStateColor (slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.STATE_ISSUED: {
        return 'invoice_state_issued';
      }
      case this.STATE_PAYED: {
        return 'invoice_state_payed';
      }
      case this.STATE_UNPAYED: {
        return 'invoice_state_unpayed';
      }
      case this.STATE_CANCELED: {
        return 'invoice_state_canceled';
      }
      default: {
        return null;
      }
    }
  },
  /**
   * Renvoi le slug de la couleur du statut de la facture
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * @return string
   */
  getInvoiceStateColor (invoice) {
    if (!invoice) {
      return null;
    }
    return this.getStateColor(invoice.state);
  },
  /**
   * Indique si la facture est en statut 'issued'
   * @return boolean
   */
  isStateIssued(invoice) {
    if (invoice && !invoice.state) {
      console.warn("isStateIssued - la facture' n'a pas de statut");
    }
    return invoice && invoice.state && invoice.state === this.STATE_ISSUED ? true : false;
  },
  /**
   * Indique si la facture est en statut 'payed'
   * @return boolean
   */
  isStatePayed(invoice) {
    if (invoice && !invoice.state) {
      console.warn("isStatePayed - la facture' n'a pas de statut");
    }
    return invoice && invoice.state && invoice.state === this.STATE_PAYED ? true : false;
  },
  /**
   * Indique si la facture est en statut 'unpayed'
   * @return boolean
   */
  isStateUnpayed(invoice) {
    if (invoice && !invoice.state) {
      console.warn("isStateUnpayed - la facture' n'a pas de statut");
    }
    return invoice && invoice.state && invoice.state === this.STATE_UNPAYED ? true : false;
  },
  /**
   * Indique si la facture est en statut 'canceled'
   * @return boolean
   */
  isStateCanceled(invoice) {
    if (invoice && !invoice.state) {
      console.warn("isStateCanceled - la facture' n'a pas de statut");
    }
    return invoice && invoice.state && invoice.state === this.STATE_CANCELED ? true : false;
  },
  /**
   * Renvoie l'avatar de la facture
   */
  getAvatar (invoice) {
    return { content: <BillIcon />, props: { variant: "rounded" } };
  },

};
export default exportedObject;
