import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import SubscriptionPlan from '../subscriptionPlan/SubscriptionPlan';
import BackdropLoading from '../commons/BackdropLoading';
import DialogEditSubscription from '../dialog/DialogEditSubscription';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

class SubscriptionViewHeader extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      changeDialog_opened: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { classes, className } = this.props;
    const { loading } = this.state;
    return (
      <Box className={clsx(classes.root, className && className.root)}>
        <BackdropLoading open={loading} />
        { this.renderContent() }
        { this.renderDialogs() }
      </Box>
    );
  }

  renderContent () {
    const { classes, subscription } = this.props;
    if (!subscription) {
      return null;
    }

    return (
      <Box className={classes.content}>
        <SubscriptionPlan center plan={subscription.plan} />
        <Box className={classes.actions}>
          <Button color="quaternary" className={clsx(classes.action, classes.action_change)} onClick={() => this._isMounted && this.setState({ changeDialog_opened: true })}>Changer de plan</Button>
        </Box>
      </Box>
    );

  }

  renderDialogs () {
    const { subscription } = this.props;
    const { changeDialog_opened } = this.state;
    if (!subscription && !changeDialog_opened) {
      return null;
    }
    return (
      <DialogEditSubscription
        open={changeDialog_opened}
        subscription={subscription}
        onSaved={ (savedOrder) => {
          this._isMounted && this.setState({changeDialog_opened: false});
          this.props.onUpdated && this.props.onUpdated(savedOrder);
        } }
        onError={this.props.onError}
        onClose={ () => this._isMounted && this.setState({changeDialog_opened: false}) }
      />
    );
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
  },
  actions: {
    marginTop: theme.spacing(2),
  },
  action: {
    width: '100%',
  }
});

SubscriptionViewHeader.propTypes = {
  subscription: PropTypes.object.isRequired,
  onUpdated: PropTypes.func,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(SubscriptionViewHeader);
