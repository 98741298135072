import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withHOCComponent } from '../hoc/HOCComponent';
import invitationsService from '../../services/invitations';

import Box from '@mui/material/Box';
import AuthUserLogin from './AuthUserLogin';
import AuthUserRegister from './AuthUserRegister';
import AuthUserLostPassword from './AuthUserLostPassword';

class AuthUser extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      data: {
        login: '',
        password: '',
        firstname: '',
        lastname: '',
        terms_of_service: false,
      },
      action: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    const { invitation, data } = this.props;
    if (invitation) {
      const action = invitation.type === invitationsService.TYPE_SPONSORED_REGISTER ? 'register' : 'login';
      this._isMounted && this.setState({action, data: {...data, ...{login: invitation.email}}});
    } else {
      this._isMounted && this.setState({action: 'login'});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { classes, invitation, invitationToken, onAuthenticated } = this.props;
    const { action, data } = this.state;
    if (!action) {
      return null;
    }
    return (
      <Box className={classes.root}>
        { action === 'login' && (
          <AuthUserLogin
            invitation={invitation}
            invitationToken={invitationToken}
            onAuthenticated={() => onAuthenticated && onAuthenticated()}
            onChangeAction={(action) => this._isMounted && this.setState({action})}
            data={data}
            onChangeData={(data) => this._isMounted && this.setState({data})}
          />
        ) }
        { action === 'register' && (
          <AuthUserRegister
            invitation={invitation}
            invitationToken={invitationToken}
            onAuthenticated={() => onAuthenticated && onAuthenticated()}
            onChangeAction={(action) => this._isMounted && this.setState({action})}
            data={data}
            onChangeData={(data) => this._isMounted && this.setState({data})}
          />
        ) }
        { action === 'lostpassword' && (
          <AuthUserLostPassword
            onChangeAction={(action) => this._isMounted && this.setState({action})}
            data={data}
            onChangeData={(data) => this._isMounted && this.setState({data})}
          />
        ) }
      </Box>
    );
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2)
  },
});

AuthUser.propTypes = {
  onAuthenticated: PropTypes.func,
  onRedirect: PropTypes.func,
  invitation: PropTypes.object, // si l'authentification est faite dans le contexte d'une invitation
  invitationToken: PropTypes.string, // si l'authentification est faite dans le contexte d'une invitation
};

export default withHOCComponent(withStyles(styles, { withTheme: true })(AuthUser));