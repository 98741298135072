import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from './../../services/profiles';

import BackdropLoading from './../commons/BackdropLoading';
import EditControllers from './../commons/EditControllers';
import Form from './../commons/Form';
import FormFields from './../commons/FormFields';
import FormField from './../commons/FormField';
import TextFieldLimited from './../commons/TextFieldLimited';
import { DeleteIcon, SaveIcon } from './../commons/Icons';
import ConfirmDialog from '../commons/ConfirmDialog';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

class EditProductTag extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      is_new: true,
      name: '',
      description: '',
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showLoading() {
    this._isMounted && this.setState({loading: true})
  }

  hideLoading() {
    this._isMounted && this.setState({loading: false})
  }

  load () {
    const { tag } = this.props;
    if (typeof tag === 'object' && tag !== null && tag.ID) {
      // le tag est passé en props
      this._isMounted && this.setState({
        name: tag.name || '',
        description: tag.description || '',
        is_new: false
      });
    } else {
      // c'est un nouveau tag
      this._isMounted && this.setState({ is_new: true });
    }
  }

  save (e) {
    e.preventDefault();
    const { id_profile, tag } = this.props;
    const data = {
      name: this.state.name,
      description: this.state.description,
    }
    if (this.state.is_new) {
      this.showLoading();
      profilesService.createProductTag(id_profile, data).then(([id, notices]) => {
        notices && notices.length > 0 && this.props.onError(notices);
        // récupération de l'item à jour
        return profilesService.getProductTag(id_profile, id);
      }).then(([tag]) => {
        this.props.onSaved(tag, this.state.is_new);
      }).catch((error) => {
        console.error('error : ', error);
        this.props.onError(error, this.state.is_new);
      }).finally(() => {
        this.hideLoading();
      });
    } else {
      this.showLoading();
      profilesService.updateProductTag(id_profile, tag.ID, data).then(([id, notices]) => {
        notices && notices.length > 0 && this.props.onError(notices);
        // récupération de l'item à jour
        return profilesService.getProductTag(id_profile, id);
      }).then(([tag]) => {
        this.props.onSaved(tag, this.state.is_new);
      }).catch((error) => {
        console.error('error : ', error);
        this.props.onError(error, this.state.is_new);
      }).finally(() => {
        this.hideLoading();
      });
    }
    return false;
  }

  confirm_delete () {
    this._isMounted && this.setState({openConfirm_delete: true})
  }

  delete () {
    const { id_profile, tag } = this.props;
    this.showLoading();
    profilesService.deleteProductTag(id_profile, tag.ID).then(([deleted, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      this.props.onDeleted && this.props.onDeleted(tag.ID);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this.hideLoading();
    });
  }

  render () {
    const { classes, id_profile, fixedCtrl, enableDescription } = this.props;
    if (!id_profile) {
      return (
        <Typography className={classes.error} component="p">Aucun profil de passé en paramètre.</Typography>
      )
    }
    return (
      <Box className={classes.container}>
        <BackdropLoading open={this.state.loading} />

        { this.state.openConfirm_delete && (
          <ConfirmDialog
            open={this.state.openConfirm_delete}
            message="Voulez-vous vraiment supprimer ce tag ? Cette action est irréversible."
            onConfirm={ () => {
              this._isMounted && this.setState({openConfirm_delete: false});
              this.delete();
            } }
            onClose={ () => this._isMounted && this.setState({openConfirm_delete: false}) }
          />
        ) }

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields>

            <FormField>
              <FormControl className={classes.form_control}>
                <TextField
                  required
                  id="name"
                  name="tag-name"
                  label="Nom"
                  type="text"
                  className={classes.input}
                  value={this.state.name}
                  onChange={ (e) => this._isMounted && this.setState({name: e.target.value}) }
                  autoFocus
                />
              </FormControl>
            </FormField>

            { enableDescription && (
              <FormField>
                <FormControl className={classes.form_control}>
                  <TextFieldLimited
                    id="description"
                    label="Description"
                    type="text"
                    className={classes.input}
                    value={this.state.description}
                    onChange={ (description) => this._isMounted && this.setState({description}) }
                    multiline
                    rows={4}
                    limit={100}
                  />
                </FormControl>
              </FormField>
            ) }

            <EditControllers fixed={fixedCtrl}>
              { !this.state.is_new && (
                <Button type="button" onClick={ (e) => this.confirm_delete(e) } color="primary" variant="outlined" className={classes.delete} startIcon={<DeleteIcon />}>
                  Supprimer
                </Button>
              ) }
              <Button type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={<SaveIcon />}>
                Enregistrer
              </Button>
            </EditControllers>

          </FormFields>

        </Form>

      </Box>
    )
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submit:{
    width: '100%',
  },
  delete: {
    width: '100%',
  },
});

EditProductTag.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  tag: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onDeleted: PropTypes.func,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
  enableDescription: PropTypes.bool,
};

EditProductTag.defaultProps = {
  fixedCtrl: false,
  enableDescription: false,
};

export default withStyles(styles, { withTheme: true })(EditProductTag);
