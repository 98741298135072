import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import productsService from './../../services/products';

import ProductsListItem from './ProductsListItem';
import ProductsListSearch from './ProductsListSearch';
import List from './../commons/List';

import Box from '@mui/material/Box';

/**
 * Ce composant représente une liste de produits
 * Il peut prendre en props : des items de commande dont il faut gérer le nombre d'occurence des produits de la liste
 */
class ProductsList extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      products: [],
      loading: false,
      reload: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = JSON.stringify(this.props.query) !== JSON.stringify(prevProps.query);
    if (reload) {
      this._isMounted && this.setState({ reload: !this.state.reload });
    }
  }

  load (limit, offset, searchQuery) {
    return productsService.getAll(this.props.id_profile_provider, {...this.props.query, ...{id_profile_for: this.props.id_profile_customer}, ...searchQuery, ...{limit, offset}}).then(([items]) => items);;
  }

  onProductSaved (savedProduct, is_new) {
    if (is_new) {
      this._isMounted && this.setState({products: [...[savedProduct], ...this.state.products]});
    } else {
      this._isMounted && this.setState(prevState => ({
        products: prevState.products.map((product) => {
          if (product.ID === savedProduct.ID) {
            return savedProduct;
          }
          return product;
        })
      }));
    }
  }

  onProductDeleted (id_deletedProduct) {
    this._isMounted && this.setState(prevState => ({
      products: prevState.products.filter((product) => {
        return product.ID !== id_deletedProduct;
      })
    }))
  }

  render() {
    const { classes, id_profile_provider, order_items } = this.props;
    const { products, loading, reload } = this.state;

    return (
      <Box className={classes.content}>

        <List
          title={this.props.title}
          reload={reload}
          load={(limit, offset, searchQuery) => this.load(limit, offset, searchQuery)}
          loaded={(items) => this._isMounted && this.setState({products: items})}
          searchEnabled={this.props.searchEnabled}
          renderSearch={(onInitialize, searchQuery, onQueryChange) => (
            <ProductsListSearch
              id_profile={ id_profile_provider }
              query={searchQuery}
              onQueryChange={onQueryChange}
              onInitialize={onInitialize}
              searching={loading}
              onError={this.props.onError}
              expanded={this.props.searchExpanded}
            />
          )}
          defaultSearchQuery={this.props.defaultSearchQuery}
          listOnlyOnSearch={this.props.listOnlyOnSearch}
          onSelect={this.props.onSelect}
          selectedItemsKeys={this.props.selectedItemsKeys}
          onError={this.props.onError}
          onSuccess={this.props.onSuccess}
          infiniteScrollProps={ {
            limit: this.props.limit || 25,
            scrollableTarget: this.props.scrollableTarget,
            items: products,
            onLoading: (loading) => this._isMounted && this.setState({loading}),
            noResult: this.props.noResult,
          } }
          renderItem={ (product, selectable) => (
            <ProductsListItem
              scrollableTarget={this.props.scrollableTarget}
              id_profile_provider={ id_profile_provider }
              order_items={order_items}
              product={product}
              onSaved={ (product, is_new) => this.onProductSaved(product, is_new) }
              onDeleted={ (id_product) => this.onProductDeleted(id_product) }
              onError={this.props.onError}
              onSuccess={this.props.onSuccess}
              onOrderItemsChange={this.props.onOrderItemsChange}
              right={this.props.listItemRight}
              disableExpand={selectable}
            />
          ) }
        />

      </Box>
    )
  }
}

const styles = theme => ({
  content: {
  },
  title: {
    padding: theme.spacing(1, 2),
  },
  actions: {
    padding: theme.spacing(2),
  },
});

ProductsList.propTypes = {
  id_profile_provider: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id_profile_customer: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  order_items: PropTypes.array,
  limit: PropTypes.number,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  query: PropTypes.object,
  onOrderItemsChange: PropTypes.func,
  noResult: PropTypes.element,
  scrollableTarget: PropTypes.string,
  selectedItemsKeys: PropTypes.array,

  searchEnabled: PropTypes.bool,
  searchExpanded: PropTypes.bool,
  listOnlyOnSearch: PropTypes.bool,
  defaultSearchQuery: PropTypes.object,

  listItemRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
};

ProductsList.defaultProps = {
  searchEnabled: true,
};

export default withStyles(styles, { withTheme: true })(ProductsList);
