import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from './../../services/profiles';

import { TagIcon } from './../commons/Icons';

import SelectAsyncMultiple from './SelectAsyncMultiple';
import ProductTagsList from './../product/ProductTagsList';

/**
 * Sélecteur d'utilisateur
 * Ce composant est asynchrone, il charge les valeurs à sélectionner depuis une API
 * Pour savoir quand le composant est initialisé, il faut écouter props.valueSelected
 */
class SelectProductTags extends React.Component {

  render () {
    const { classes, id_profile, ...other } = this.props;
    return (
      <SelectAsyncMultiple
        { ...{...other, ...{
          title: this.props.title || "Tags",
          renderSelect: this.renderSelect,
          label: this.props.label || "Ajouter",
          label_icon: this.props.label_icon || <TagIcon />,
          renderSelected: (selected) => selected.name,
        }} }
        load={ (query, arrayOfKeys, value) => profilesService.getAllProductTags(id_profile, {...query, include: arrayOfKeys}) }
      />
    );
  }

  renderSelect = (onSelect, selectedItemsKeys, selected) => {
    const { id_profile } = this.props;
    return (
      <ProductTagsList
        id_profile={id_profile}
        onSelect={onSelect}
        onError={this.props.onError}
        query={this.props.query}
        selectedItemsKeys={selectedItemsKeys}
        canCreate={true}
        selectAfterCreate={true}
      />
    )
  }
}

const styles = theme => ({});

SelectProductTags.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectProductTags);
