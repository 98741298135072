import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import utilsService, { isEmpty } from '../../services/utils';
import deliveryModesService from '../../services/delivery-modes';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

class DeliveryMode extends React.Component {

  render () {
    const { classes, delivery_mode } = this.props;

    return (
      <Box className={classes.root}>
        <Typography className={classes.title} component="div" variant="body1">{delivery_mode.name}</Typography>
        { !isEmpty(delivery_mode.description) && (
          <Typography className={classes.info} component="div" variant="body3">{delivery_mode.description}</Typography>
        ) }
        { this.render_price () }
        { this.render_free_price () }
        { this.render_delivery_time () }
        { this.render_contact () }
      </Box>
    );
  }

  render_price () {
    const { delivery_mode } = this.props;
    let price = "Gratuit";
    if (!isEmpty(delivery_mode.price) && parseFloat(delivery_mode.price) > 0) {
      if (delivery_mode.tax_rule === deliveryModesService.TAX_RULE_FIXED_RATE) {
        if (!isEmpty(delivery_mode.tax_rate) && parseFloat(delivery_mode.tax_rate) > 0) {
          price = utilsService.prettyPrice(delivery_mode.price, '€', "HT (TVA "+delivery_mode.tax_rate+"%)");
        } else {
          price = utilsService.prettyPrice(delivery_mode.price, '€', " (pas de TVA)");
        }
      } else {
        price = utilsService.prettyPrice(delivery_mode.price, '€', "HT (TVA forfaitaire)");
      }
    }
    return (
      <Box>
        <Typography component="div" variant="body3">Tarif : {price}</Typography>
      </Box>
    );
  }

  render_free_price () {
    const { delivery_mode } = this.props;
    if (!delivery_mode) return null;
    if (!isEmpty(delivery_mode.free_price) && parseFloat(delivery_mode.free_price) > 0) {
      return (
        <Box>
          <Typography component="div" variant="body3">Frais de livraison offert dès {utilsService.prettyPrice(delivery_mode.free_price, '€', "HT")} d'achat</Typography>
        </Box>
      );
    }
    return null;
  }

  render_delivery_time () {
    const { delivery_mode } = this.props;
    if (!delivery_mode) return null;
    return (
      <>
        { !isEmpty(delivery_mode.estimated_delivery?.time) && (
          <Box>
            <Typography component="div" variant="body3">
              { delivery_mode.type === deliveryModesService.TYPE_PICKUP ? "Délai moyen de mise à disponibilité de votre commande (hors week-end et congés) : " : "Délai moyen de livraison (hors week-end et congés) : " }
              <Typography component="span" variant="body3" sx={{fontWeight: 'bold'}}>{ parseInt(delivery_mode.estimated_delivery?.time) < 1 ? 'aucun' : (parseInt(delivery_mode.estimated_delivery?.time) + 'h')}</Typography>
            </Typography>
          </Box>
        ) }
      </>
    );
  }

  render_contact () {
    const { classes, delivery_mode } = this.props;
    let address = [];
    if (delivery_mode.type === deliveryModesService.TYPE_PICKUP) {
      if (!isEmpty(delivery_mode.address_name)) {
        address.push(delivery_mode.address_name);
      }
      if (!isEmpty(delivery_mode.address_1)) {
        address.push(delivery_mode.address_1);
      }
      if (!isEmpty(delivery_mode.address_2)) {
        address.push(delivery_mode.address_2);
      }
      if (!isEmpty(delivery_mode.address_3)) {
        address.push(delivery_mode.address_3);
      }
      if (!isEmpty(delivery_mode.address_cp)) {
        address.push(delivery_mode.address_cp);
      }
      if (!isEmpty(delivery_mode.address_city)) {
        address.push(delivery_mode.address_city);
      }
      if (!isEmpty(delivery_mode.address_country)) {
        address.push(delivery_mode.address_country);
      }
      if (!isEmpty(address)) {
        return (
          <Box className={classes.info_price}>
            <Typography className={classes.info_free_price} component="div" variant="body3">Lieu de retrait : {address.join(' ')}</Typography>
          </Box>
        );
      }
    }
    return null;
  }
}

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: theme.spacing(1,0),
  },
  root_center: {
    textAlign: 'center',
  },
  title: {
    fontWeight: 600,
  },
});

DeliveryMode.propTypes = {
  delivery_mode: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DeliveryMode);
