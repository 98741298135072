import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from '../../services/utils';
import { subscribeToPush, unsubscribeToPush } from '../../services/push';

class PushNotificationsController extends React.Component {

  componentDidUpdate(prevProps) {
    // IMPORTANT : contrôle du changement de l'utilisateur authentifié.
    // SCENARIO : l'utilisateur se connecte une première fois, on ne fait rien d'automatique, il choisi de s'inscrire au service de notification push (cf. HomeNotification).
    // Plus tard il se déconnecte, alors désactive supprimer son inscription car on ne doit plus lui en envoyer.
    // Plus tard il se reconnecte, alors là on on le réinscrit sans rien lui demander car il en avait déjà fait la demande.
    // S'il veut annuler son inscription, il doit utiliser les paramètres de son appareil (notre API saura gérer...)
    if (JSON.stringify(this.props.appStore?.auth?.id_user) !== JSON.stringify(prevProps.appStore.auth?.id_user)) {
      if (!isEmpty(this.props.appStore?.auth?.id_user)) {
        if (this.props.appStore.push_granted) {
          subscribeToPush();
        }
      } else {
        unsubscribeToPush();
      }
    }
    return;
  }

  render () {
    return (
      <>{this.props.children}</>
    );
  }
}

const mapStateToProps = state => ({
  appStore: state.app,
});

export default connect(mapStateToProps)(PushNotificationsController);