import SubscriptionPlans_API from './api/subscription-plans';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux plans d'abonnements
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
  STATE_ACTIVE: 'active', // l'abonnement est actif
 	STATE_CLOSED: 'closed', // l'abonnement est fermé
 	STATE_PRIVATE: 'private', // l'abonnement est privé

  /**************** subscription plans ************************************/
  /**
   * Récupère les plan d'abonnements du serveur
   * @return Promise(data, notices) : les abonnements
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      SubscriptionPlans_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Plans d\'abonnements récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un plan d'abonnement du serveur
   * @return Promise(data, notices) : le plan d'abonnement
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      SubscriptionPlans_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('plan d\'abonnement récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Crée un nouveau plan d'abonnement
   * @return Promise(data, notices) : l'identifiant du plan d'abonnement
   */
  create (data) {
    return new Promise((resolve, reject) => {
      SubscriptionPlans_API.create(data).then(({data, notices}) => {
        utilsService.debugLog('Plan d\'abonnement créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un plan d'abonnement
   * @return Promise(data, notices) : l'identifiant du plan d'abonnement
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      SubscriptionPlans_API.update(id, data).then(({data, notices}) => {
        utilsService.debugLog('Plan d\'abonnement mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un plan d'abonnement
   * @return Promise(id, data, notices) : l'identifiant du plan d'abonnement supprimé
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      SubscriptionPlans_API.delete(id).then(({data, notices}) => {
        utilsService.debugLog('Plan d\'abonnement supprimée : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  /***************************** Extras ************************/

  /**
   * Affiche le libellé du statut du plan d'abonnement
   * @return string
   */
  getStateLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.STATE_ACTIVE: {
        return 'actif';
      }
      case this.STATE_CLOSED: {
        return 'fermé';
      }
      case this.STATE_PRIVATE: {
        return 'privé';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Affiche le libellé du statut du plan d'abonnement
   * @return string
   */
  getSubscriptionPlanStateLabel (subscriptionPlan) {
    if (!subscriptionPlan) {
      return 'aucun';
    }
    return this.getStateLabel(subscriptionPlan.state);
  },
  /**
   * Indique si le plan d'abonnement est en statut 'active'
   * @return boolean
   */
  isStateActive(subscriptionPlan) {
    if (subscriptionPlan && !subscriptionPlan.state) {
      console.warn("isStateActive - l'abonnement' n'a pas de statut");
    }
    return subscriptionPlan && subscriptionPlan.state && subscriptionPlan.state === this.STATE_ACTIVE ? true : false;
  },
  /**
   * Indique si le plan d'abonnement est en statut 'closed'
   * @return boolean
   */
  isStateClosed(subscriptionPlan) {
    if (subscriptionPlan && !subscriptionPlan.state) {
      console.warn("isStateClosed - l'abonnement' n'a pas de statut");
    }
    return subscriptionPlan && subscriptionPlan.state && subscriptionPlan.state === this.STATE_CLOSED ? true : false;
  },
  /**
   * Indique si le plan d'abonnement est en statut 'private'
   * @return boolean
   */
  isStatePrivate(subscriptionPlan) {
    if (subscriptionPlan && !subscriptionPlan.state) {
      console.warn("isStatePrivate - l'abonnement' n'a pas de statut");
    }
    return subscriptionPlan && subscriptionPlan.state && subscriptionPlan.state === this.STATE_PRIVATE ? true : false;
  },

  /**
   * Indique si le plan d'abonnement est gratuit
   * @return boolean
   */
  isFree(subscriptionPlan) {
    return subscriptionPlan && (!subscriptionPlan.price || subscriptionPlan.price === 0) ? true : false;
  },
};
export default exportedObject;
