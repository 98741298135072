import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import authService from '../../services/auth';
import profilesService from '../../services/profiles';

import BackdropLoading from '../commons/BackdropLoading';
import AlertDialog from '../commons/AlertDialog';
import UserProfilesList from '../user/UserProfilesList';

import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { ChevronRightIcon, PlusIcon, UserIcon, UsersIcon } from '../commons/Icons';
import ProfilesList from '../profile/ProfilesList';
import { withHOCComponent } from '../hoc/HOCComponent';
import EditNewProfile from '../edit/editNewProfile/EditNewProfile';
import EditControllers from '../commons/EditControllers';

class AuthProfileSwitch extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      firstLoad: true,
      createProfile: false,
      displayList: true,
      allProfiles: false,
      alertProfileType_opened: false,
      alertProfileType_message: '',
    };
    /**
     * NOTE : createProfile et displayList ne doivent pas être affiché en même temps, cela permet que si l'utilisateur
     * quitte le tunel de création de profil (notamment après que le profil ait été créé dans l'API), la liste s'actualise et affiche ce dernier.
     */
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setProfile (id_profile) {
    const { onEnd } = this.props;
    this.setState({loading: true});
    authService.setProfile(id_profile).then(() => {
      // rien à faire, le profil est automatiquement fixé dans le store
      onEnd && onEnd();
    }).catch((e) => {
      this.props.hoc.showError(e);
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
  }

  render () {
    const { classes, className, appStore, selectDefault, disableTypePart, onEnd } = this.props;
    const { loading, createProfile, displayList, allProfiles, alertProfileType_opened, alertProfileType_message, firstLoad } = this.state;
    const isAdmin = appStore?.auth?.is_admin;
    return (
      <Box className={clsx(classes.root, className && className.root)}>
        { loading && (
          <BackdropLoading open={true} />
        ) }
        { alertProfileType_opened && (
          <AlertDialog
            message={alertProfileType_message}
            open={alertProfileType_opened}
            onClose={ () => this._isMounted && this.setState({alertProfileType_opened: false, alertProfileType_message: ''}) }
          />
        ) }
        { createProfile && (
          <EditNewProfile
            onSaved={ (profile) => {
              this._isMounted && this.setState({createProfile: false, displayList: true});
              this.setProfile(profile?.ID);
            }}
            onCancel={() => {
              this._isMounted && this.setState({createProfile: false, displayList: true});
            }}
            onError={this.props.hoc.showError}
            onClose={ () => this._isMounted && this.setState({createProfile: false, displayList: true}) }
            disableTypePart={disableTypePart}
          />
        ) }
        { displayList && (
          <Box>
            <Typography variant="body1" component="h2" className={classes.title}>Choisissez votre profil</Typography>
            <Box>
              { !allProfiles && (
                <UserProfilesList
                  onError={this.props.hoc.showError}
                  onSelect={(profile) => {
                    if (disableTypePart && profile?.type === profilesService.TYPE_PART) {
                      this.setState({alertProfileType_opened: true, alertProfileType_message: 'Vous ne pouvez pas utiliser un profil personnel'});
                    } else {
                      this.setProfile(profile?.ID);
                    }
                  }}
                  onLoaded={(profiles) => {
                    if (profiles) {
                      if (profiles.length < 1 && firstLoad) {
                        // Aucun profil, on ouvre la popup de création
                        this._isMounted && this.setState({createProfile: true, displayList: false, firstLoad: false})
                      } else if (selectDefault) {
                        // Sélection par défaut du profil
                        if (profiles.length === 1) {
                          // Il n'y a qu'un seul profil, on le sélectionne
                          this.setProfile(profiles[0]?.ID);
                        }
                        // On peut imaginer sélectionner le dernier profil utilisé, pour cela il faudrait soit stocker cette info côté API, soit côté LocalStorage...
                      }
                    }
                  }}
                  id_user={appStore?.auth?.id_user}
                  right={<ChevronRightIcon />}
                />
              ) }
              { allProfiles && (
                <ProfilesList
                  onError={this.props.hoc.showError}
                  onSelect={(profile) => this.setProfile(profile?.ID)}
                />
              ) }
              <EditControllers sticky direction="column" className={{root: classes.editCtrl}}>
                { isAdmin && (
                  <>
                    { allProfiles && ( 
                      <Button className={classes.button} variant="outlined" startIcon={<UserIcon />} onClick={() => this._isMounted && this.setState({allProfiles: false})}>Les miens</Button>
                    ) }
                    { !allProfiles && ( 
                      <Button className={classes.button} variant="outlined" startIcon={<UsersIcon />} onClick={() => this._isMounted && this.setState({allProfiles: true})}>Tous</Button>
                    ) }
                  </>
                ) }
                <Button className={classes.button} startIcon={<PlusIcon />} variant="outlined" onClick={ (e) => {
                    e.preventDefault();
                    this._isMounted && this.setState({createProfile: true, displayList: false});
                    return false;
                  } }>Nouveau profil</Button>
                <Button className={classes.button} onClick={ (e) => {
                    e.preventDefault();
                    authService.logOut();
                    onEnd && onEnd();
                    return false;
                  } }>Déconnexion</Button>
              </EditControllers>
            </Box>
          </Box>
        ) }
      </Box>
    );
  }
}

const styles = theme => ({
  root: {},
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  button: {
    width: '100%',
  },
  editCtrl: {
    borderTop: 'none !important',
  },
});

const mapStateToProps = state => ({
  appStore: state.app,
});

AuthProfileSwitch.propTypes = {
  selectDefault: PropTypes.bool,
  disableTypePart: PropTypes.bool,
  onEnd: PropTypes.func
};

export default withHOCComponent(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(AuthProfileSwitch)));