import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ordersService from '../../services/orders';

import FormControl from '@mui/material/FormControl';
import FormField from './FormField';
import SelectOrderDate from './SelectOrderDate';
import { Box, FormControlLabel, FormHelperText, Switch, Typography } from '@mui/material';
import SelectDeliveryMode from './SelectDeliveryMode';

class SetOrderDelivery extends React.Component {

  render () {
    const { classes, data, onChange, onError } = this.props;

    return (
      <>
        <FormField>
          <FormControl fullWidth>
            <SelectDeliveryMode
              value={data.id_delivery_mode}
              onChange={({id_delivery_mode}) => onChange({...data, ...{id_delivery_mode}}) }
              onError={onError}
              id_profile={data.id_profile_provider}
            />
          </FormControl>
        </FormField>

        { data.id_delivery_mode && (
          <Box className={classes.adds}>
            <FormField>
              <SelectOrderDate
                value={{date_delivery: data.date_delivery, recurrence_day: data.recurrence_day, recurrence_time: data.recurrence_time}}
                onChange={ (value) => onChange({...data, ...{date_delivery: value.date_delivery, recurrence_day: value.recurrence_day, recurrence_time: value.recurrence_time}}) }
                id_profile={data.id_profile_provider}
                id_delivery_mode={data.id_delivery_mode}
                order_type={data.type}
                onError={this.props.onError}
              />
            </FormField>

            { data.type === ordersService.TYPE_RECURRENT && (
              <FormField>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={data.auto_emit === '1'}
                        onChange={ (e) => onChange({...data, ...{auto_emit: e.target.checked ? '1' : '0'}}) }
                        inputProps={{ 'id': 'auto_emit', 'aria-label': 'Automatisation' }}
                      />
                    }
                    label={
                      <Typography component="div" variant="body2">Automatisation</Typography>
                    }
                  />
                  <FormHelperText>Chaque nouvelle commande sera automatiquement. Dans le cas contraire, chaque occurrence sera archivée dans les brouillons jusqu'à validation manuelle.</FormHelperText>
                </FormControl>
              </FormField>
            ) }
          </Box>
        ) }
      </>
    )
  }
}

const styles = theme => ({
  loading: {},
  error: {},
  adds: {
    padding: theme.spacing(2,1,1,1),
    borderTop: '1px solid ' + theme.palette.bg.light,
  }
});

SetOrderDelivery.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
  onError: PropTypes.func.isRequired,
};

SetOrderDelivery.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetOrderDelivery);
