import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import utilsService from './../../services/utils';

import WarningText from './WarningText';

// import DatePicker from '@mui/lab/DatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

class SelectOrderDateDelivery extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {};
    this.date_min = new Date();
    this.date_min.setDate(this.date_min.getDate() - 1); // on limite à aujourd'hui les dates de livraison ;) - ça allège le DatePicker
    this.date_max = new Date();
    this.date_max.setDate(this.date_max.getDate() + 365); // on limite à 1an les dates de livraison ;) - ça allège le DatePicker
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  onTimeChange (time) {
    let date = this.getDate();
    if (!date) {
      this.props.onChange(date, '');
    }
    let timeHours = 0;
    let timeMinutes = 0;
    if (time) {
      const timeParts = time.split(':');
      if (timeParts && timeParts.length === 2) {
        timeHours = timeParts[0];
        timeMinutes = timeParts[1];
      }
    }
    date.setHours(timeHours);
    date.setMinutes(timeMinutes);
    this.props.onChange(date, date.toISOString());
  }

  getDate () {
    const { value } = this.props;
    if (!value) {
      return null;
    }
    return utilsService.dateStringToDate(value);
  }

  getDateDay () {
    let date = this.getDate();
    if (!date) {
      return null;
    }
    return date.getDay();
  }

  getDateDaySlug () {
    let day = this.getDateDay();
    if (!day && day !== 0) {
      return null;
    }
    return utilsService.getDaySlugFromIndex(day);
  }

  getTime () {
    let date = this.getDate();
    if (!date) {
      return null;
    }
    return utilsService.getHours2Digits(date.getHours()) + ":" + utilsService.getMinutes2Digits(date.getMinutes());
  }

  render () {
    const { classes, daysTimes, value } = this.props;
    const times = daysTimes[this.getDateDaySlug()];

    let selected_time = this.getTime();
    if (times && !times.includes(selected_time)) {
      selected_time = '';
    }

    return (
      <React.Fragment>
        <MobileDatePicker
          disableCloseOnSelect={false}
          disablePast
          cancelText="Annuler"
          clearText="Effacer"
          okText="Valider"
          label={this.props.label_date || "Date"}
          value={value}
          onChange={ (date) => this.props.onChange(date, date.toISOString()) }
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => <TextField className={classes.date_picker} {...params} />}
          minDate={this.date_min}
          maxDate={this.date_max}
          shouldDisableDate={(date) => {
            // On vérifie que le jour comporte bien des horaires
            if (daysTimes[utilsService.getDaySlugFromIndex(date.getDay())]) {
              return false;
            }
            // Sinon on le désactive
            return true;
          }}
        />
        { !times && (
          <WarningText>Aucun horaire disponible</WarningText>
        ) }
        { times && (
          <FormControl className={classes.action_select}>
            <InputLabel id="select-time">Heure</InputLabel>
            <Select
              className={classes.select_field}
              labelId="select-time"
              label="Heure"
              id="day"
              value={ selected_time }
              onChange={ (e) => this.onTimeChange(e.target.value) }
            >
              { times.map((time) => {
                return <MenuItem key={time} value={time}>{ time }</MenuItem>
              }) }
            </Select>
          </FormControl>
        ) }
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  action_select: {
    display: 'block',
    width: '100%',
  },
  select_field: {
    width: '100%',
  },
  date_picker: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
});

SelectOrderDateDelivery.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  daysTimes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectOrderDateDelivery);
