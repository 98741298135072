import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormControl from '@mui/material/FormControl';
import FormField from './FormField';
import ToggleRadio from './ToggleRadio';

class SetProfileBtoC extends React.Component {

  render () {
    const { value, onChange } = this.props;

    return (
      <FormField>
        <FormControl>
          <ToggleRadio
            name="btoc"
            id="btoc"
            value={(value === null || value === undefined ? "none" : (value === true ? "btoc" : "btob"))}
            onChange={(e) => onChange(e.target.value === "btoc")}
            options={[
              {
                value: "btob",
                label: {
                  title: "BtoB",
                  infos: "Votre réseau sera exclusivement professionnel. Seuls les professionnels pourront s’abonner à votre profil, accéder à votre catalogue et passer commande de vos produits.",
                }
              },
              {
                value: "btoc",
                label: {
                  title: "BtoB et BtoC",
                  infos: "Votre réseau sera mixte. Les professionnels et particuliers pourront s’abonner à votre profil, accéder à votre catalogue et passer commande de vos produits.",
                }
              }
            ]}
          />
        </FormControl>
      </FormField>
    )
  }
}

const styles = theme => ({});

SetProfileBtoC.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
};

SetProfileBtoC.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetProfileBtoC);