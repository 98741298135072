import React from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

class Form extends React.Component {

  render () {
    const { classes, className, children, ...others } = this.props;
    return (
        <form className={clsx(classes.root, className)} {...others}>
          { children }
        </form>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
  },
});

export default withStyles(styles, { withTheme: true })(Form);
