import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { setReloadPaymentMethods } from '../../services/store/features/appSlice';
import clsx from 'clsx';
import paymentMethodsService from '../../services/payment-methods';

import PaymentCard from '../commons/PaymentCard';
import ConfirmDialog from '../commons/ConfirmDialog';
import EditPaymentMethod from '../edit/EditPaymentMethod';
import { RenewIcon, EditIcon } from '../commons/Icons';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { CircularProgress } from '@mui/material';
import Message from '../commons/Message';

class PaymentMethod extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      error: false,
      paymentMethod: null,
      openConfirm_delete: false,
      updating: false,
      alertMultiple: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.id_profile) !== JSON.stringify(prevProps.id_profile)) {
      this.load();
    } else if (JSON.stringify(this.props.appStore.reloadPaymentMethods) !== JSON.stringify(prevProps.appStore.reloadPaymentMethods)) {
      this.load();
    }
  }

  load () {
    const { id_profile, onLoaded } = this.props;
    if (id_profile) {
      if (this.state.updating) {
        this.props.onUpdating && this.props.onUpdating(false);
      }
      this._isMounted && this.setState({ loading: true, error: false, alertMultiple: false, updating: false });
      paymentMethodsService.getAll({id_profile}).then(([paymentMethods]) => {
        const paymentMethod = paymentMethods && paymentMethods.length > 0 ? paymentMethods[0] : null;
        const alertMultiple = paymentMethods && paymentMethods.length > 1 ? true : false;
        this._isMounted && this.setState({paymentMethod, alertMultiple});
        onLoaded && onLoaded(paymentMethod, alertMultiple);
      }).catch((e) => {
        this.props.onError(e);
        this._isMounted && this.setState({ error: true });
      }).finally(() => {
        this._isMounted && this.setState({ loading: false });
      });
    } else {
      this._isMounted && this.setState({paymentMethod: null});
      onLoaded && onLoaded(null, false);
    }
  }

  update () {
    this.props.onUpdating && this.props.onUpdating(true);
    this._isMounted && this.setState({ updating: true });
  }

  cancelUpdate () {
    this.props.onUpdating && this.props.onUpdating(false);
    this._isMounted && this.setState({ updating: false });
  }

  delete () {
    this._isMounted && this.setState({ openConfirm_delete: true });
  }

  deleteConfirm () {
    const { setReloadPaymentMethods } = this.props;
    const { paymentMethod } = this.state;
    this._isMounted && this.setState({ loading: true, error: false, openConfirm_delete: false });
    paymentMethodsService.delete(paymentMethod.ID).then(() => {
      this.props.onDeleted && this.props.onDeleted();
      // on notifie l'app de mettre à jour les moyens de paiement (y compris le composant présent...)
      setReloadPaymentMethods();
    }).catch((e) => {
      this.props.onError(e);
      this._isMounted && this.setState({ error: true });
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  render () {
    const { classes, className } = this.props;
    return (
      <Box className={clsx(classes.root, className && className.root)}>
        <Typography className={classes.title} component="div" variant="h5">Moyen de paiement</Typography>
        { this.renderContent() }
        { this.renderDialogs() }
      </Box>
    );
  }

  renderContent () {
    const { classes, id_profile, canDelete, onUpdated, onError, editSubmitLabel, editSubmitIcon, setReloadPaymentMethods } = this.props;
    const { paymentMethod, updating, loading, error, alertMultiple } = this.state;
    if (error) {
      return (
        <Box className={classes.error}>
          <h4>Erreur de chargement</h4>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    }
    if (loading) {
      return (
        <Box className={classes.loading}>
          <CircularProgress color="secondary" />
        </Box>
      );
    }
    if (!paymentMethod || updating) {
      return (
        <Box className={classes.content}>
          <Box className={classes.edit}>
            <EditPaymentMethod
              disableGutters
              id_profile={id_profile}
              onUpdated={() => {
                onUpdated && onUpdated();
                // on notifie l'app de mettre à jour les moyens de paiement (y compris le composant présent...)
                setReloadPaymentMethods();
              }}
              onError={onError}
              submitLabel={editSubmitLabel}
              submitIcon={editSubmitIcon}
            />
          </Box>
          { updating && (
            <Box className={classes.actions}>
              <Button className={clsx(classes.action, classes.action_cancel)} onClick={() => this.cancelUpdate()}>Annuler</Button>
            </Box>
          ) }
        </Box>
      )
    }
    return (
      <Box className={classes.content}>

        { alertMultiple && (
          <Message level="warning">
            <Typography component="div" variant="body3">Il semble qu'il y ait un problème, votre profil a plusieurs moyens de paiement, cela ne devrait pas être le cas. Merci de contacter l'assistance.</Typography>
          </Message>
        ) }

        <PaymentCard>
          <Box className={classes.card_content}>
            <Box className={classes.card_content_lines}>
              <Typography className={clsx(classes.card_content_line, classes.card_content_brand)} component="div" variant="body3">{ paymentMethod.brand ? paymentMethod.brand.toUpperCase() : '' }</Typography>
              <Typography className={clsx(classes.card_content_line, classes.card_content_digits)} component="div" variant="body2">{ paymentMethod.digits }</Typography>
              <Typography className={clsx(classes.card_content_line, classes.card_content_expire)} component="div" variant="body3">Expire { paymentMethod.expire }</Typography>
            </Box>
            <Box className={classes.update}>
              <IconButton className={classes.btn_update} aria-label="Modifier le moyen de paiement" onClick={() => this.update()}>
                <EditIcon />
              </IconButton>
            </Box>
          </Box>
        </PaymentCard>
        { canDelete && (
          <Box className={classes.actions}>
            <Button className={clsx(classes.action, classes.action_remove)} onClick={() => this.delete()}>Résilier</Button>
          </Box>
        ) }
      </Box>
    );
  }

  renderDialogs () {
    return (
      <>
        { this.state.openConfirm_delete && (
          <ConfirmDialog
            open={this.state.openConfirm_delete}
            title="Vous souhaitez rélisier votre moyen de paiement ?"
            message="Si vous avez un abonnement actif, il ne pourra pas être renouvelé et sera fermé à échéance."
            onConfirm={ () => this.deleteConfirm() }
            onClose={ () => this._isMounted && this.setState({ openConfirm_delete: false }) }
            cancelLabel="Annuler"
            confirmLabel="Résilier"
          />
        ) }
      </>
    );
  }
}

const styles = theme => ({
  loading: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  error: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  content: {},
  title: {
    textAlign: 'center',
  },
  card_content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  card_content_lines: {
    width: '100%',
  },
  update: {
    marginLeft: theme.spacing(1),
  },
  card_content_line: {
    color: theme.palette.secondary.main,
    backgroundColor: '#ffffff',
    borderRadius: '3px',
    padding: theme.spacing(0.5, 2),
    marginBottom: theme.spacing(1),
  },
  card_content_brand: {
    fontWeight: '600',
  },
  card_content_expire: {
    marginBottom: 0,
  },
  actions: {},
  action: {
    width: '100%',
  },
});

PaymentMethod.propTypes = {
  id_profile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onLoaded: PropTypes.func,
  onUpdating: PropTypes.func,
  onUpdated: PropTypes.func,
  onDeleted: PropTypes.func,
  onError: PropTypes.func.isRequired,
  canDelete: PropTypes.bool,
  editSubmitLabel: PropTypes.string,
  editSubmitIcon: PropTypes.element,
};

const mapStateToProps = state => ({
  appStore: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setReloadPaymentMethods: () => dispatch(setReloadPaymentMethods()),
  }
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(PaymentMethod));