import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from '../../services/profiles';

import FormControl from '@mui/material/FormControl';
import FormField from './FormField';
import ToggleRadio from './ToggleRadio';

class SetProfileState extends React.Component {

  render () {
    const { data, onChange } = this.props;

    return (
      <FormField>
        <FormControl>
          <ToggleRadio
            name="type"
            id="type"
            value={data.state}
            onChange={(e) => onChange({...data, ...{state: e.target.value}})}
            options={[
              {
                value: profilesService.STATE_OPENED,
                label: {
                  title: "Ouvert",
                  infos: "Votre profil est ouvert et hérite des paramêtres de confidentialité",
                }
              },
              {
                value: profilesService.STATE_HOLIDAYS,
                label: {
                  title: "En congés",
                  infos: "Hérite des paramêtres de confidentialité. Le catalogue est visible, les commandes sont fermées.",
                }
              },
              {
                value: profilesService.STATE_CLOSED,
                label: {
                  title: "Fermé",
                  infos: "L’intégralité des informations du profil sont privées, vous seul(e) pouvez les consulter. Le profil n’apparait pas dans les recherches, et les abonnements sont fermés. Si ce profil est Premium, le catalogue n’est pas visible, et les commandes sont fermées.",
                }
              }
            ]}
          />
        </FormControl>
      </FormField>
    )
  }
}

const styles = theme => ({});

SetProfileState.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
};

SetProfileState.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetProfileState);
