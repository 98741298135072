import React from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import Stack from '@mui/material/Stack';

class FormFields extends React.Component {

  render () {
    const { classes, className, children, ...others } = this.props;
    return (
        <Stack spacing={2} className={clsx(classes.root, className)} {...others}>
          { children }
        </Stack>
    );
  }
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
});

export default withStyles(styles, { withTheme: true })(FormFields);
