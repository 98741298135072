import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class SelectTaxRate extends React.Component {

  render () {
    const { classes, formControlProps, selectProps, value, onChange } = this.props;
    return (
      <FormControl {...formControlProps}>
        <InputLabel id="select-label-vat">Taux TVA</InputLabel>
        <Select
          className={classes.select_field}
          labelId="select-label-vat"
          label="Taux TVA"
          id="tax_rate"
          value={parseFloat(value || '0')}
          onChange={onChange}
          {...selectProps}
        >
          <MenuItem value={20}>20% taux normal</MenuItem>
          <MenuItem value={10}>10% taux intermédiaire</MenuItem>
          <MenuItem value={5.5}>5,5% taux réduit</MenuItem>
          <MenuItem value={2.1}>2,1% taux particulier</MenuItem>
          <MenuItem value={0}>pas de TVA applicable</MenuItem>
        </Select>
      </FormControl>
    )
  }
}

const styles = theme => ({});

SelectTaxRate.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  formControlProps: PropTypes.object,
  selectProps: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(SelectTaxRate);
