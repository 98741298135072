import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ordersService from '../../services/orders';
import {isEmpty} from '../../services/utils';

import WarningText from './WarningText';

import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

class SelectOrderRecurrences extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.days = [
      { value: ordersService.RECURRENCE_MONDAY, label: "Chaque lundi" },
      { value: ordersService.RECURRENCE_TUESDAY, label: "Chaque mardi" },
      { value: ordersService.RECURRENCE_WEDNESDAY, label: "Chaque mercredi" },
      { value: ordersService.RECURRENCE_THURSDAY, label: "Chaque jeudi" },
      { value: ordersService.RECURRENCE_FRIDAY, label: "Chaque vendredi" },
      { value: ordersService.RECURRENCE_SATURDAY, label: "Chaque samedi" },
      { value: ordersService.RECURRENCE_SUNDAY, label: "Chaque dimanche" },
    ];
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onDayChange (recurrence_day) {
    const { daysTimes } = this.props;
    // Lorsque le jour de récurrence change, on sélectionne par défaut le premier horaire de disponible
    this.props.onChange({recurrence_day, recurrence_time: daysTimes[recurrence_day] && daysTimes[recurrence_day].length > 0 ? daysTimes[recurrence_day][0] : null});
  }

  onTimeChange (recurrence_time) {
    const { value } = this.props;
    // Lorsque le jour de récurrence change, on sélectionne par défaut le premier horaire de disponible
    this.props.onChange({...value, ...{recurrence_time}});
  }

  render () {
    const { classes, daysTimes, value } = this.props;

    return (
      <Box className={classes.content}>
        { isEmpty(daysTimes) && (
          <WarningText>Aucun jour de disponible</WarningText>
        ) }
        { !isEmpty(daysTimes) && (
          <Box>
            <FormControl className={classes.days} fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Fréquence</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={value?.recurrence_day || ''}
                label="Fréquence"
                onChange={(e) => this.onDayChange(e.target.value)}
              >
                { this.days.map((day, index) => {
                  if (daysTimes[day.value] && daysTimes[day.value].length > 0) {
                    return (<MenuItem key={index} value={day.value}>{day.label}</MenuItem>)
                  }
                  return null
                }) }
              </Select>
            </FormControl>
            { value?.recurrence_day && (
              <FormControl className={classes.times} fullWidth>
                <InputLabel id="select-time">Heure</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={value?.recurrence_time || ''}
                  label="Heure"
                  onChange={(e) => this.onTimeChange(e.target.value)}
                >
                  { daysTimes[value.recurrence_day] && daysTimes[value.recurrence_day].length > 0 && daysTimes[value.recurrence_day].map((time, index) => (
                    <MenuItem key={index} value={time}>{time}</MenuItem>
                  )) }
                </Select>
              </FormControl>
            ) }
          </Box>
        ) }
      </Box>
    )
  }
}

const styles = theme => ({
  content: {
    position: 'relative',
  },
  label: {
    display: 'inline-block',
    margin: theme.spacing(1, 0),
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
  },
  days: {
    margin: theme.spacing(0, 0, 2, 0),
  }
});

SelectOrderRecurrences.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  daysTimes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectOrderRecurrences);
