import React from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import Box from '@mui/material/Box';

class PaymentCard extends React.Component {

  render() {
    const { classes, className, children } = this.props;
    return (
    <Box className={clsx(classes.root, className && className.root)}>
        <Box className={clsx(classes.stripe, className && className.stripe)} />
        <Box className={clsx(classes.content, className && className.content)}>
          { children }
        </Box>
      </Box>
    )
  }
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 'auto'),
    backgroundColor: '#f7f7f7',
    borderRadius: '10px',
    position: 'relative',
    width: '100%',
  },
  stripe: {
    position: 'absolute',
    top: '15px',
    left: 0,
    right: 0,
    height: '30px',
    width: '100%',
    backgroundColor: '#f2f2f2',
  },
  content: {
    position: 'relative',
    padding: theme.spacing(6, 2, 2, 2),
  },
});

export default withStyles(styles, { withTheme: true })(PaymentCard);
