/**
 * Composant permettant de parcourir les produits du profil et de composer un panier en vue d'une commande
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setCart } from '../../services/store/features/appSlice';
import { withStyles } from '@mui/styles';
import { withHOCComponent } from '../hoc/HOCComponent';
import { isEmpty } from '../../services/utils';

import ProductsList from '../product/ProductsList';

import Box from '@mui/material/Box';

class ProfileCatalogForCart extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { classes, id_profile_provider, setCart, appStore } = this.props;
    if (!id_profile_provider) {
      return null;
    }

    const order_items = appStore.carts && !isEmpty(appStore.carts[id_profile_provider]) ? appStore.carts[id_profile_provider] : [];

    return (
      <Box className={classes.content}>
        <ProductsList
          id_profile_provider={id_profile_provider}
          hideTitle={true}
          onError={this.props.onError}
          onSuccess={this.props.onSuccess}
          order_items={order_items}
          onOrderItemsChange={ (order_items) => {
            this._isMounted && setCart({id_profile_provider, order_items});
          }}
          scrollableTarget={this.props.scrollableTarget}
          searchExpanded={true}
        />
      </Box>
    )
  }
}

const styles = theme => ({
  content: {
    paddingBottom: theme.spacing(10),
  },
});

ProfileCatalogForCart.propTypes = {
  id_profile_provider: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  scrollableTarget:  PropTypes.string,
};

const mapStateToProps = state => ({
  appStore: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCart: (value) => dispatch(setCart(value)),
  }
};

export default withHOCComponent(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ProfileCatalogForCart)));
