import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import contactsService from '../../services/contacts';

import BackdropLoading from '../commons/BackdropLoading';
import EditControllers from '../commons/EditControllers';
import SetContact from '../commons/SetContact';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';
import { SaveIcon } from '../commons/Icons';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

class EditContact extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      contact: {},
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.id_contact) !== JSON.stringify(prevProps.id_contact)){
      this.load()
    }
  }

  load () {
    const { id_profile, id_contact } = this.props;
    if (id_profile && id_contact) {
      this._isMounted && this.setState({ loading: true, error: false });
      contactsService.get(id_profile, id_contact).then(([contact]) => {
        this._isMounted && this.setState({ contact });
      }).catch((e) => {
        this.props.onError(e);
        this._isMounted && this.setState({ error: true });
      }).finally(() => {
        this._isMounted && this.setState({ loading: false });
      });
    }
  }

  save (e) {
    const { id_profile, id_contact } = this.props;
    e.preventDefault();
    this._isMounted && this.setState({ loading: true });
    this.saveProcess().then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération de l'item à jour
      return contactsService.get(id_profile, id);
    }).then(([contact]) => {
      this.setState({contact});
      this.props.onSaved(contact, !id_contact);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
    return false;
  }

  async saveProcess () {
    const { id_profile, id_contact } = this.props;
    const { contact } = this.state;
    if (id_contact) {
      return contactsService.update(id_profile, id_contact, contact);
    }
    return contactsService.create(id_profile, contact);
  }

  render () {
    const { loading, contact } = this.state;
    const { classes } = this.props;

    return (
      <Box className={classes.root}>
        <BackdropLoading open={loading} />

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields className={classes.formFields}>

            <SetContact
              value={contact}
              onChange={ (contact) => this._isMounted && this.setState({contact}) }
            />

          </FormFields>

          <EditControllers sticky>
            <Button type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={<SaveIcon />}>
              Enregistrer
            </Button>
          </EditControllers>

        </Form>

      </Box>
    )
  }
}

const styles = theme => ({
  root: {},
  submit:{
    width: '100%',
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
});

EditContact.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id_contact: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]), // contact à modifier le cas échéant
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditContact);
