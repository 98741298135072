import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ordersService from '../../services/orders';
import orderItemsService from '../../services/order-items';
import productsService from '../../services/products';
import utilsService, { isEmpty } from '../../services/utils';

import imagePlaceholder from './../../static/img/placeholder.png';

import ListItemContent from './../commons/ListItemContent';
import DialogEditOrderItem from './../dialog/DialogEditOrderItem';

import Box from '@mui/material/Box';
import { AlertIcon } from '../commons/Icons';
import ProductPrice from '../product/ProductPrice';
import { Typography } from '@mui/material';

class OrderItemsListItem extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      openDialog_edit: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { order_item, permissions } = this.props;

    const isDone = order_item.state === orderItemsService.STATE_DONE;
    const hasPrivateNote = order_item && order_item.note_int && order_item.note_int !== '';
    let badge = (isDone || hasPrivateNote) && permissions && permissions.is_auth_provider;
    let badgeProps = null;
    if (badge && isDone) {
      badgeProps = {variant: "dot", color: 'order_item_state_done'};
    } else if (badge && hasPrivateNote) {
      badgeProps = {variant: "dot", color: 'order_item_state_alert'};
    }
    const avatar = orderItemsService.getAvatar(order_item, imagePlaceholder);

    return (
      <React.Fragment>
        <ListItemContent
          avatar={avatar.content}
          avatarProps={avatar.props}
          badge={badge}
          badgeProps={badge && badgeProps}
          summary={this.renderSummary()}
          right={permissions && permissions.can_update ? null : null }
          gutterLeft={false}
          gutterRight={false}
          onClick={() => this._isMounted && permissions && permissions.can_store && this.setState({openDialog_edit: true})}
        />
        { this.renderDialogs() }
      </React.Fragment>
    )
  }

  renderSummary () {
    const { classes, order_item, permissions } = this.props;
    const is_bulk = order_item.product_format === productsService.META_FORMAT_BULK;
    const is_portion = order_item.product_format === productsService.META_FORMAT_PORTION;
    const is_order_validated = order_item?.order?.state === ordersService.STATE_VALIDATED;
    const is_order_processing = order_item?.order?.state === ordersService.STATE_PROCESSING;

    let item_summary = [];
    if (is_bulk) {
      item_summary.push(productsService.getFormatLabel(order_item.product_format, order_item.product_format_label));
    }
    if (is_portion) {
      item_summary.push(productsService.getFormatLabel(order_item.product_format, order_item.product_format_label) + " (volume estimé : " + order_item.product_volume_estimation + order_item.product_volume_unit+")");
    }

    return (
      <>
        <Box className={classes.summary}>
          <Box className={classes.summary_left}>
            <Typography variant="body2" component="div" className={classes.item_name}>
              {utilsService.showDebugID(order_item)}{ order_item.product_name }
            </Typography>
            { item_summary && !isEmpty(item_summary) && (
              <Typography variant="body3" component="div" className={classes.item_summary}>
                { item_summary.join(' - ') }
              </Typography>
            ) }
            { order_item.batch_number && order_item.batch_number !== '' && (
              <Typography variant="body3" component="div" className={classes.item_batch_number}>N° lot : {order_item.batch_number}</Typography>
            ) }
            { order_item.expiration_date && order_item.expiration_date !== '' && (
              <Typography variant="body3" component="div" className={classes.item_expiration_date}>DLC : {order_item.expiration_date}</Typography>
            ) }
            <ProductPrice
              price={order_item?.product_price}
              format={order_item?.product_format}
              tax_rate={order_item?.product_tax_rate}
              volume_unit={order_item?.product_volume_unit}
              format_label={order_item?.product_format_label}
            />
          </Box>
          <Box className={classes.summary_right}>
            <Typography variant="body2" component="div" className={classes.item_qty}>
              { is_bulk && (
                <span className={classes.item_volume}>
                  {order_item.volume}{order_item.product_volume_unit + " x "}
                </span>
              ) }
              {order_item.qty}
            </Typography>
            { order_item.volume_net && parseFloat(order_item.volume_net) > 0 && (
              <Typography variant="body3" component="div" className={classes.item_volume_net}>Net {order_item.volume_net}{order_item.product_volume_unit}</Typography>
            ) }
            { parseFloat(order_item.price) > 0 && (
              <Typography variant="body3" component="div" className={classes.item_price}>{ utilsService.prettyPrice(order_item.price, '€', 'HT') }</Typography>
            ) }
            { permissions && permissions.can_store && (is_order_validated || is_order_processing) && (is_bulk || is_portion) && (!order_item.volume_net || parseFloat(order_item.volume_net) === 0) && (
              <Typography variant="body3" component="div" className={classes.item_volume_net_alert}>Volume net<AlertIcon fontSize="small"/></Typography>
            ) }
          </Box>
        </Box>
      </>
    )
  }

  renderDialogs () {
    const { order_item, permissions } = this.props;
    return (
      <React.Fragment>
        { this.state.openDialog_edit && order_item && (
          <DialogEditOrderItem
            order_item={order_item}
            open={this.state.openDialog_edit}
            permissions={permissions}
            onChanged={ (order_item) => {
              this._isMounted && this.setState({openDialog_edit: false})
              this.props.onChanged(order_item);
            } }
            onDeleted={ (order_item) => {
              this._isMounted && this.setState({openDialog_edit: false})
              this.props.onDeleted(order_item);
            } }
            onError={ this.props.onError }
            onClose={ () => this._isMounted && this.setState({openDialog_edit: false}) }
          />
        ) }
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(0.5),
  },
  summary_left: {
    maxWidth: '70%',
    width: '100%',
    // background: '#FD45A7',
  },
  summary_right: {
    maxWidth: '30%',
    width: '100%',
    // background: '#88DD76',
  },
  item_qty: {
    fontWeight: 600,
    textAlign: 'right',
  },
  item_volume_net: {
    textAlign: 'right',
    fontWeight: 600,
  },
  item_volume_net_alert: {
    color: theme.palette.error.main,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(0.5),
  },
  item_price: {
    textAlign: 'right',
  },
});

OrderItemsListItem.propTypes = {
  order_item: PropTypes.object,
  onChanged: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(OrderItemsListItem);
