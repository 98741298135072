import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import groupsService from '../../services/groups';

import GroupsListSearch from './GroupsListSearch';
import GroupsListItem from './GroupsListItem';
import List from '../commons/List';

import DialogEditGroup from './../dialog/DialogEditGroup';
import { PlusIcon } from './../commons/Icons';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

class GroupsList extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      groups: [],
      loading: false,
      reload: false,
      openDialog_edit: false,
      openDialog_edit_item: null,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = JSON.stringify(this.props.query) !== JSON.stringify(prevProps.query);
    if (reload) {
      this._isMounted && this.setState({ reload: !this.state.reload });
    }
  }

  load (limit, offset, searchQuery) {
    return groupsService.getAll({...this.props.query, ...searchQuery, ...{limit, offset}}).then(([items]) => items);;
  }

  onGroupSaved (savedGroup) {
    // s'il n'est pas déjà dans la liste on l'ajoute, sinon on l'update
    let updated = false;
    this._isMounted && this.setState(prevState => ({
      groups: prevState.groups.map((group) => {
        if (group.ID === savedGroup.ID) {
          updated = true;
          return savedGroup;
        }
        return group;
      })
    }));
    if (!updated) {
      this._isMounted && this.setState({groups: [...[savedGroup], ...this.state.groups]});
    }
  }

  onGroupDeleted (id_deletedGroup) {
    this._isMounted && this.setState(prevState => ({
      groups: prevState.groups.filter((group) => {
        return parseInt(group.ID) !== parseInt(id_deletedGroup);
      })
    }))
  }

  openEdit (item = null) {
    this._isMounted && this.setState({ openDialog_edit_item: item, openDialog_edit: true });
  }

  closeEdit () {
    this._isMounted && this.setState({ openDialog_edit_item: null, openDialog_edit: false });
  }

  editOnSelect = (item) => {
    this.openEdit(item);
  }

  render() {
    const { classes } = this.props;
    const { groups, loading, reload } = this.state;

    const onSelect = this.props.onSelect || (this.props.canUpdate && this.editOnSelect);

    return (

      <Box className={classes.content}>

        { (this.props.canCreate || this.props.canUpdate) && (
          <React.Fragment>
            { this.state.openDialog_edit && (
              <DialogEditGroup
                open={ this.state.openDialog_edit }
                group={ this.state.openDialog_edit_item }
                preset={ {id_profile: this.props.query?.id_profile} }
                onSaved={ (group) => {
                  this.onGroupSaved(group);
                  this.closeEdit();
                } }
                onDeleted={ (id) => {
                  this.onGroupDeleted(id);
                  this.closeEdit();
                } }
                onError={ this.props.onError }
                onClose={ () => this.closeEdit() }
              />
            ) }
            { this.props.canCreate && (
              <Box className={classes.actions}>
                <Button variant="outlined" color="primary" className={classes.button} startIcon={<PlusIcon />} onClick={ () => this.openEdit() }>Groupe</Button>
              </Box>
            ) }
          </React.Fragment>
        ) }

        <List
          title={this.props.title}
          reload={reload}
          load={(limit, offset, searchQuery) => this.load(limit, offset, searchQuery)}
          loaded={(items) => this._isMounted && this.setState({groups: items})}
          searchEnabled={this.props.searchEnabled}
          renderSearch={(onInitialize, searchQuery, onQueryChange) => (
            <GroupsListSearch
              query={searchQuery}
              onQueryChange={onQueryChange}
              onInitialize={onInitialize}
              searching={loading}
              onError={this.props.onError}
              expanded={this.props.searchExpanded}
            />
          )}
          defaultSearchQuery={this.props.defaultSearchQuery}
          listOnlyOnSearch={this.props.listOnlyOnSearch}
          onSelect={onSelect}
          selectedItemsKeys={this.props.selectedItemsKeys}
          onError={this.props.onError}
          onSuccess={this.props.onSuccess}
          infiniteScrollProps={ {
            limit: this.props.limit || 25,
            scrollableTarget: this.props.scrollableTarget,
            items: groups,
            onLoading: (loading) => this._isMounted && this.setState({loading}),
            noResult: this.props.noResult,
          } }
          renderItem={ (group, selectable) => (
            <GroupsListItem
              group={group}
              onSaved={ (group, is_new) => this.onGroupSaved(group, is_new) }
              onDeleted={ (id) => this.onGroupDeleted(id) }
              onError={this.props.onError}
              onSuccess={this.props.onSuccess}
              right={this.props.listItemRight}
              disableExpand={selectable}
            />
          ) }
        />
      </Box>
    )
  }
}

const styles = theme => ({
  content: {
  },
  title: {
    padding: theme.spacing(1, 2),
  },
  actions: {
    padding: theme.spacing(2),
  },
  button: {
    width: '100%',
  }
});

GroupsList.propTypes = {
  limit: PropTypes.number,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  query: PropTypes.object,
  noResult: PropTypes.element,
  displayContact:PropTypes.bool,
  scrollableTarget: PropTypes.string,
  selectedItemsKeys: PropTypes.array,

  searchEnabled: PropTypes.bool,
  searchExpanded: PropTypes.bool,
  listOnlyOnSearch: PropTypes.bool,
  defaultSearchQuery: PropTypes.object,

  canCreate: PropTypes.bool,
  canUpdate: PropTypes.bool,

  listItemRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
};

GroupsList.defaultProps = {
  searchEnabled: true,
};

export default withStyles(styles, { withTheme: true })(GroupsList);
