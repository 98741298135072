import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import utilsService, { isEmpty } from './../../services/utils';

import DialogBase from '../dialog/DialogBase';
import SelectAsyncChip from './SelectAsyncChip';
import EditControllers from './EditControllers';
import { RenewIcon, PlusIcon, CheckIcon } from './../commons/Icons';

import { CircularProgress, Box, Typography, Button, DialogContent, DialogTitle } from '@mui/material';

/**
 * Sélecteur Asynchrone, permet de gérer une sélection d'élement à partir d'une source asynchrone
 */
class SelectAsyncMultiple extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this._isInitialized = false;
    this.state = {
      loading: false,
      error: false,
      open: false,
      alreadyOpened: false,
      valueDefered: [],
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  componentDidUpdate (prevProps) {
    // On met à jour le composant si la query a changé
    if (JSON.stringify(this.props.query) !== JSON.stringify(prevProps.query)){
      this.load()
    }
    // On met à jour le composant si les valeurs à sélectionner ont changé
    else if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) {
      this.load()
    }
  }

  load = () => {
    const { query, value, itemKey } = this.props;

    // si aucune valeur, on initialise
    if (!value || !Array.isArray(value) || value.length < 1) {
      this.maybeInitiliaze();
      return;
    }

    // si les valeurs sont déjà des objets, on initialise
    const arrayObjs = value.filter((val) => utilsService.isObject(val));
    const isArrayOfObject = arrayObjs.length === value.length;
    if (isArrayOfObject) {
      this.maybeInitiliaze();
      return;
    }

    // si ce sont des IDs de passés en props, on tente de le charger depuis l'API
    const arrayOfKeys = value.filter((val) => !isNaN(val));
    this._isMounted && this.setState({ loading: true, error: false });
    this.props.load && this.props.load(query, arrayOfKeys, value).then(([items]) => {
      if (!items || items.length !== value.length) {
        console.warn("Attention, certains éléments initialement sélectionnés n'ont pas pu être récupérés car ils ne sont plus existants ou vous n'avez pas les droits nécéssaires");
      }
      let selectedItems = [];
      if (items && Array.isArray(items) && items.length > 0) {
        for (const item of items) {
          if (value.filter((val) => val.toString() === item[itemKey].toString()).length > 0) {
            selectedItems.push(item);
          }
        }
      }
      // On propage
      this.props.onChange && this.props.onChange(selectedItems);
      this.maybeInitiliaze();

    }).catch((e) => {
      this.props.onError(e);
      this.props.onChange && this.props.onChange(null);
      this.maybeInitiliaze();
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  onSelect = (item) => {
    const { valueDefered } = this.state;
    const { itemKey } = this.props;
    let newValue = [...valueDefered];

    if (Array.isArray(item)) {
      // si le nouvel item est un tableau on écrase tout
      newValue = item;
    } else if (utilsService.isObject(item)) {
      // sinon c'est que c'est un item unique
      const found = newValue && newValue && newValue.filter((selected_item) => selected_item[itemKey] === item[itemKey]);
      if (found.length > 0) {
        // si le nouvel item est déjà dans la liste, on le retire
        newValue = newValue.filter((existing) => existing[itemKey] !== item[itemKey]);
      } else {
        // sinon on l'ajoute
        newValue.push(item);
      }
    }
    // on met à jour la sélection temporaire
    this.setState({ valueDefered: newValue });
  }

  validateSelection () {
    const { valueDefered } = this.state;

    // On propage
    this.props.onChange && this.props.onChange(valueDefered);
    this.maybeInitiliaze();

    // On ferme le sélecteur
    this.close();

  }

  onRemove = (item) => {
    const { itemKey, value } = this.props;

    // On propage
    this.props.onChange && this.props.onChange(value.filter((val) => val[itemKey] !== item[itemKey]));
  }

  maybeInitiliaze = () => {
    if (!this._isInitialized) {
      this.props.onInitialized && this.props.onInitialized();
      this._isInitialized = true;
    }
  }

  open = () => {
    this._isMounted && this.setState({ alreadyOpened: true, open: true, valueDefered: [...this.props.value] });
  }

  close = () => {
    this._isMounted && this.setState({ open: false });
  }

  render () {
    const { classes, value, itemKey, title, className, scrollableTarget } = this.props;
    const { loading, error, valueDefered } = this.state;
    return (
      <Box className={clsx(classes.root, className && className.root)}>
        { this.state.alreadyOpened && (
          <DialogBase
            open={this.state.open}
            onClose={() => this.close()}
          >
            { !isEmpty(title) && (
              <DialogTitle>{title}</DialogTitle>
            ) }
            <DialogContent classes={{root: classes.dialogContent}} id={scrollableTarget}>
              { this.props.renderSelect && this.props.renderSelect(this.onSelect, valueDefered ? valueDefered.map((item) => item[itemKey]) : [], valueDefered || []) }
              <EditControllers sticky>
                <Button className={classes.done} onClick={() => this.validateSelection()} color="primary" variant="contained" startIcon={<CheckIcon />}>Terminer</Button>
              </EditControllers>
            </DialogContent>
          </DialogBase>
        ) }
        { this.props.renderField && this.props.renderField(this.open, this.load, value, loading, error) }
        { !this.props.renderField && (
          <React.Fragment>
            <fieldset className={classes.fieldset}>
              { this.props.title && (<legend className={classes.title}>{this.props.title}</legend>) }
              { this.renderField() }
            </fieldset>
            { this.props.helperText && (
              <Typography className={classes.helperText}>{ this.props.helperText }</Typography>
            ) }
          </React.Fragment>
        ) }
      </Box>
    );
  }

  renderField() {
    const { value, itemKey } = this.props;
    const { loading, error } = this.state;

    if (loading) {
      return (
        <SelectAsyncChip
          icon={<CircularProgress size={20} color="secondary" />}
        />
      );
    } else if (error) {
      return (
        <SelectAsyncChip
          icon={<RenewIcon />}
          label={ this.props.label_error || "Erreur de chargement" }
          onClick={() => this.open() }
        />
      );
    } else if (value && value.length > 0) {
      return (
        <React.Fragment>
          { value.map((item) => {
            if (!utilsService.isObject(item)) {
              return null;
            }
            if (!item[itemKey]) {
              console.warn("List item must have '"+[itemKey]+"' key.");
            }
            return (
              <SelectAsyncChip
                key={item[itemKey]}
                icon={this.props.label_icon}
                label={this.props.renderSelected && this.props.renderSelected(item)}
                onDelete={() =>  this.onRemove(item) }
                variant="outlined"
              />
            )
          }) }
          <SelectAsyncChip
            icon={<PlusIcon />}
            label={ this.props.label || "Ajouter" }
            onClick={() => this.open() }
          />
        </React.Fragment>
      );
    }
    return (
      <SelectAsyncChip
        icon={<PlusIcon />}
        label={ this.props.label || "Ajouter" }
        onClick={() => this.open() }
      />
    );
  }
}

const styles = theme => ({
  title: {
    padding: theme.spacing(0, 0.5),
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  helperText: {
    display: 'block',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  done: {
    width: '100%'
  },
  dialogContent:{
    padding: theme.spacing(0),
  },
});

SelectAsyncMultiple.propTypes = {
  title: PropTypes.string,
  renderSelect: PropTypes.func.isRequired, // affiche la liste des éléments à sélectionner dans une Dialog
  renderField: PropTypes.func, // Surcharge le rendu du composant
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired, // La sélection est mise à jour
  onError: PropTypes.func.isRequired,
  query: PropTypes.object,
  disabled: PropTypes.bool,
  itemKey: PropTypes.string,
  scrollableTarget: PropTypes.string, // used by dialog selection
};

SelectAsyncMultiple.defaultProps = {
  itemKey: 'ID',
};

export default withStyles(styles, { withTheme: true })(SelectAsyncMultiple);
