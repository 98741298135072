import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormFields from '../commons/FormFields';
import FormField from '../commons/FormField';
import SelectAttachment from '../commons/SelectAttachment';

class EditNewProductAttachment extends React.Component {

  render () {
    const { id_profile, data, onChange, onError } = this.props;

    return (
      <FormFields>
        <FormField>
          <SelectAttachment
            id_profile={ id_profile }
            onError={ onError }
            value={ data.attachment }
            onChange={ (attachment) => onChange({...data, ...{attachment}}) }
            query={ {} }
            title="Image du produit"
          />
        </FormField>
      </FormFields>
    );
  }
}

const styles = theme => ({});

EditNewProductAttachment.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
  onError: PropTypes.func.isRequired,
  data: PropTypes.object,
};

EditNewProductAttachment.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(EditNewProductAttachment);
