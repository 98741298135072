import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appReducer from './features/appSlice';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  blacklist: [
    /**
     * Ici on liste les stores qui ne doivent pas
     * êtres persistants ou ceux qui ont ces attributs
     * qui ne doivent pas être persistants
     */
    'app',
  ]
};

const appPersistConfig = {
  key: 'app',
  storage: storage,
  blacklist: [
    /**
     * Ici on liste les attributs du store 'app' qui ne doivent
     * pas être persistants.
     */
    'online',
    'current_order',
    'notifications_count',
    'webpush_notifications_supported',
    'webpush_notifications_enabled',
    'webpush_notifications_opened_ids',
    'shortAction',
  ]
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  // other: otherReducer, // not persistent storeSlice
})

export const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: getDefaultMiddleware({
    serializableCheck: false, // disable serializable action type
  }),
})
export const persistor = persistStore(store)
