import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormFields from '../../commons/FormFields';
import SetProfileType from '../../commons/SetProfileType';

class EditNewProfileType extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { data, onChange, disableTypePart, disableTypePartMessage } = this.props;

    return (
      <FormFields>
        <SetProfileType
          data={data}
          onChange={(data) => onChange(data) }
          disableTypePart={disableTypePart}
          disableTypePartMessage={disableTypePartMessage}
        />
      </FormFields>
    );
  }
}

const styles = theme => ({});

EditNewProfileType.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.object,
  disableTypePart: PropTypes.bool,
};

EditNewProfileType.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(EditNewProfileType);
