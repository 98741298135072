import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withHOCComponent } from '../hoc/HOCComponent';
import invitationsService from '../../services/invitations';
import profilesService from '../../services/profiles';
import clsx from 'clsx';

import BackdropLoading from '../commons/BackdropLoading';
import EditControllers from '../commons/EditControllers';
import Forbidden from '../commons/Forbidden';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';
import FormField from '../commons/FormField';
import TextFieldLimited from '../commons/TextFieldLimited';
import { PaperPlaneIcon, InfoIcon, CheckIcon } from '../commons/Icons';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

class EditInvitationSubscribe extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      email: '',
      message: '',
    }
  }

  componentDidMount () {
    const { appStore } = this.props;
    this._isMounted = true;
    this._isMounted && this.setState({message: 'Bonjour, rejoignez-moi sur la plateforme Bridger.store pour passer et suivre vos commandes en toute simplicité. Merci, ' + appStore?.authProfile?.name + '.'});
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  invite (e) {
    e.preventDefault();
    if (!this.state.email || this.state.email === '') {
      this.props.hoc.showError("Email manquant");
      return;
    }
    const data = {
      email: this.state.email,
      type: invitationsService.TYPE_SUBSCRIBE,
      message: this.state.message,
    }
    this._isMounted && this.setState({loading: true});
    invitationsService.create(data).then(() => {
      this.props.hoc.showSuccess("Invitation envoyée");
      if (this.props.onSaved) {
        this.props.onSaved(data);
      }
    }).catch((e) => {
      this.props.onError && this.props.onError(e);
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
    return false;
  }

  render () {
    const { classes, appStore } = this.props;
    if (!appStore?.auth?.id_user || !appStore?.authProfile?.ID) {
      return <Forbidden />;
    }
    const is_btoc = profilesService.isBtoC(appStore?.authProfile);

    return (
      <Box className={classes.container}>
        <BackdropLoading open={this.state.loading} />

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields className={classes.fields}>

            <FormField>
              <Box className={classes.info}>
                <Typography className={classes.info_title} component="p" variant="body1">Invitez qui vous voulez à vous rejoindre sur le réseau Bridger.store</Typography>
              </Box>
            </FormField>

            <FormField>
              <Box className={classes.info}>
                <Box className={classes.info_icon}>
                  <CheckIcon color="primary" />
                </Box>
                <Typography className={classes.info_text} component="p" variant="body2">À l'issue de la création de son compte, le nouveau profil sera invité à s'abonner au votre et pourra vous passer commande.</Typography>
              </Box>
            </FormField>

            { !is_btoc && (
              <FormField>
                <Box className={classes.info}>
                  <Box className={classes.info_icon}>
                    <InfoIcon color="primary" />
                  </Box>
                  <Typography className={classes.info_text} component="p" variant="body2">Vous ne traitez qu'avec des professionnels, si vous souhaitez traiter aussi avec des particuliers vous devez modifier les paramètres de votre profil.</Typography>
                </Box>
              </FormField>
            ) }

            <FormField>
              <FormControl className={clsx(classes.form_control, classes.form_control_email)}>
                <TextField
                  required
                  type="email"
                  className={classes.text_field}
                  label="Email"
                  value={this.state.email}
                  id="email"
                  onChange={ (e) => this._isMounted && this.setState({ email: e.target.value }) }
                />
              </FormControl>
            </FormField>

            <FormField>
              <FormControl className={clsx(classes.form_control, classes.form_control_email)}>
                <TextFieldLimited
                  type="message"
                  className={classes.text_field}
                  label="Message"
                  value={this.state.message}
                  onChange={ (message) => this._isMounted && this.setState({ message }) }
                  multiline
                  rows={4}
                  limit={200}
                />
              </FormControl>
              <FormHelperText>Ce message sera intégré au mail d'invitation, il est optionel.</FormHelperText>
            </FormField>

          </FormFields>

          <EditControllers sticky>
            <Button type="submit" className={classes.submit} onClick={ (e) => this.invite(e) } color="primary" variant="contained" startIcon={<PaperPlaneIcon />}>
              Envoyer l'invitation
            </Button>
          </EditControllers>

        </Form>
      </Box>
    )
  }
}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  fields: {
    padding: theme.spacing(0, 2),
  },
  form_control: {
    width: '100%',
  },
  text_field: {
    width: '100%'
  },
  select_field: {
    width: '100%'
  },
  info: {
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
  },
  info_icon: {
    marginRight: theme.spacing(1),
  },
  info_title: {
    width: '100%',
    fontWeight: 500,
    textDecoration: 'underline',
  },
  info_text: {
  },
  submit:{
    width: '100%',
  }
});

EditInvitationSubscribe.propTypes = {
  onSaved: PropTypes.func,
  onError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	appStore: state.app,
});

export default withHOCComponent(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditInvitationSubscribe)));
