import Attachments_API from './api/attachments';
import utilsService from './utils';

const exportedObject = {

  /**************** attachments ************************************/

  /**
   * Récupère un fichier du serveur
   * @return Promise(data, notices) : le fichier
   */
  get (id_profile, id) {
    return new Promise((resolve, reject) => {
      Attachments_API.get(id_profile, id).then(({data, notices}) => {
        utilsService.debugLog('Fichier récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les fichiers du serveur
   * @return Promise(data, notices) : les fichiers
   */
  getAll (id_profile, args) {
    return new Promise((resolve, reject) => {
      Attachments_API.getAll(id_profile, args).then(({data, notices}) => {
        utilsService.debugLog('Fichiers récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouveau fichier
   * @return Promise(data, notices) : le fichier créé
   */
  upload (id_profile, formData, onUploadProgress) {
    return new Promise((resolve, reject) => {
      Attachments_API.upload(id_profile, formData, onUploadProgress).then(({data, notices}) => {
        utilsService.debugLog('Fichier uploadé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un fichier
   * @return Promise(data, notices) : le fichier mis à jour
   */
  update (id_profile, id, data) {
    return new Promise((resolve, reject) => {
      Attachments_API.update(id_profile, id, data).then(({data, notices}) => {
        utilsService.debugLog('Fichier mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un fichier
   * @return Promise(id, data, notices) : l'identifiant du fichier supprimé
   */
  delete (id_profile, id) {
    return new Promise((resolve, reject) => {
      Attachments_API.delete(id_profile, id).then(({data, notices}) => {
        utilsService.debugLog('Fichier supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  /**************** extras ************************************/

  /**
   * Renvoi l'URL de l'attachment en fonction d'une taille
   * @param {object} attachment 
   * @param {string} size default 'thumb'
   * @param {string} placeholder default null
   */
  get_url (attachment, size = 'thumb', placeholder = null) {
    return attachment && attachment.base_url && attachment.thumbs && attachment.thumbs[size] ? attachment.base_url + attachment.thumbs[size].filename : placeholder
  },
};
export default exportedObject;
