/**
 * Composant utilisé dans les vues d'édition pour afficher les controllers en bas du formulaire
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

class EditControllers extends React.Component {

  render () {
    const { classes, className, fixed, sticky, children, direction, spacing } = this.props;
    if (fixed) {
      return (
        <AppBar color="inherit" position="fixed" className={clsx(classes.bar, className && className.root)}>
          <Toolbar className={classes.tool}>
            <Container maxWidth="sm" className={classes.container}>
              <Stack direction={direction ?? 'row'} spacing={spacing ?? 1} className={classes.stack}>
                { children }
              </Stack>
            </Container>
          </Toolbar>
        </AppBar>
      )
    }
    if (sticky) {
      return (
        <Stack direction={direction ?? 'row'} spacing={spacing ?? 1} className={clsx(classes.stackSticky, className && className.root)}>
          { children }
        </Stack>
      )
    }
    return (
      <Stack direction={direction ?? 'row'} spacing={spacing ?? 1} className={clsx(classes.stack, className && className.root)}>
        { children }
      </Stack>
    )
  }
}

const styles = theme => ({
  bar: {
    zIndex: theme.zIndex.drawer + 1,
    top: 'auto',
    bottom: 0,
    borderTop: '1px solid ' + theme.palette.secondary.main,
  },
  tool: {
    padding: 0,
  },
  container: {
    display: 'flex',
  },
  stack: {
    width: '100%',
  },
  stackSticky: {
    margin: theme.spacing(2,0,0,0),
    backgroundColor: '#ffffff',
    padding: theme.spacing(1, 2),
    borderTop: '1px solid ' + theme.palette.bg.light,
    position: 'sticky',
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
  }
});

EditControllers.propTypes = {
  fixed: PropTypes.bool,
};

EditControllers.defaultProps = {
  fixed: false,
};

export default withStyles(styles, { withTheme: true })(EditControllers);
