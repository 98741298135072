/**
 * Composant affichant un produit du profil
 * - Si une commande 'order' est passée en props, le composant permet de gérer la quantité de celui-ci
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import utilsService from './../../services/utils';
import productsService from './../../services/products';
import authService from './../../services/auth';
import orderItemsService from './../../services/order-items';
import imagePlaceholder from './../../static/img/placeholder.png';
import { isEmpty } from '../../services/utils';

import BackdropLoading from './../commons/BackdropLoading';
import DialogProduct from './../dialog/DialogProduct';
import DialogEditProduct from './../dialog/DialogEditProduct';
import DialogEditProductVisibility from './../dialog/DialogEditProductVisibility';
import SetOrderItemQty from './../commons/SetOrderItemQty';
import ConfirmDialog from './../commons/ConfirmDialog';
import IconMenu from './../commons/IconMenu';
import ListItemContent from './../commons/ListItemContent';
import ListItemContentSummary1 from './../commons/ListItemContentSummary1';
import ListItemContentSummary3 from './../commons/ListItemContentSummary3';
import { MoreVertIcon, EditIcon, DeleteIcon, BoxIcon, EuroIcon, EyeIcon, TagIcon } from './../commons/Icons';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Divider } from '@mui/material';
import DialogEditProductPrice from '../dialog/DialogEditProductPrice';
import DialogEditProductStock from '../dialog/DialogEditProductStock';
import DialogEditProductClassification from '../dialog/DialogEditProductClassification';
import ProductsListItemPrice from './ProductsListItemPrice';

class ProductsListItem extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      openDialog_view: false,
      openDialog_edit: false,
      openDialog_editClassification: false,
      openDialog_editPrice: false,
      openDialog_editStock: false,
      openDialog_editVisibility: false,
      openConfirm_delete: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openEdit() {
    this._isMounted && this.setState({openDialog_edit: true})
  }
  closeEdit() {
    this._isMounted && this.setState({openDialog_edit: false})
  }
  openView() {
    this._isMounted && this.setState({openDialog_view: true})
  }
  closeView() {
    this._isMounted && this.setState({openDialog_view: false})
  }
  openEditClassification() {
    this._isMounted && this.setState({openDialog_editClassification: true})
  }
  closeEditClassification() {
    this._isMounted && this.setState({openDialog_editClassification: false})
  }
  openEditPrice() {
    this._isMounted && this.setState({openDialog_editPrice: true})
  }
  closeEditPrice() {
    this._isMounted && this.setState({openDialog_editPrice: false})
  }
  openEditStock() {
    this._isMounted && this.setState({openDialog_editStock: true})
  }
  closeEditStock() {
    this._isMounted && this.setState({openDialog_editStock: false})
  }
  openEditVisibility() {
    this._isMounted && this.setState({openDialog_editVisibility: true})
  }
  closeEditVisibility() {
    this._isMounted && this.setState({openDialog_editVisibility: false})
  }
  openDelete() {
    this._isMounted && this.setState({openConfirm_delete: true})
  }
  closeDelete() {
    this._isMounted && this.setState({openConfirm_delete: false})
  }

  delete () {
    const { id_profile_provider, product } = this.props;
    this._isMounted && this.setState({loading: true});
    productsService.delete(id_profile_provider, product.ID).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      this.props.onDeleted(id);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
  }

  render () {
    const { classes, id_profile_provider, product, order_items } = this.props;

    const productAvatar = productsService.getAvatar(product, imagePlaceholder);
    const productAvatarProps = {...productAvatar.props, ...{className: classes.avatar, onClick: () => this.openView()}};

    return (
      <React.Fragment>
        <ListItemContent
          avatar={productAvatar.content}
          avatarProps={productAvatarProps}
          right={this.renderRight()}
          summary={this.renderSummary()}
          gutterRight={false}
        />
        <BackdropLoading open={this.state.loading} />
        { this.state.openDialog_view && (
          <DialogProduct
            open={this.state.openDialog_view}
            id_profile={id_profile_provider}
            product={product}
            enableShortOrder={isEmpty(order_items)}
            onError={this.props.onError}
            onSuccess={this.props.onSuccess}
            onClose={ () => this.closeView() }
          />
        ) }
        { this.state.openDialog_edit && (
          <DialogEditProduct
            open={this.state.openDialog_edit}
            id_profile={id_profile_provider}
            product={product}
            onSaved={ (product) => {
              this.closeEdit();
              this.props.onSaved && this.props.onSaved(product);
            } }
            onError={this.props.onError}
            onSuccess={this.props.onSuccess}
            onClose={ () => this.closeEdit() }
          />
        ) }
        { this.state.openDialog_editClassification && (
          <DialogEditProductClassification
            open={this.state.openDialog_editClassification}
            id_profile={id_profile_provider}
            id_product={product.ID}
            onSaved={ (product) => {
              this.closeEditClassification();
              this.props.onSaved && this.props.onSaved(product);
            } }
            onError={this.props.onError}
            onSuccess={this.props.onSuccess}
            onClose={ () => this.closeEditClassification() }
          />
        ) }
        { this.state.openDialog_editPrice && (
          <DialogEditProductPrice
            open={this.state.openDialog_editPrice}
            id_profile={id_profile_provider}
            product={product}
            onSaved={ (product) => {
              this.closeEditPrice();
              this.props.onSaved && this.props.onSaved(product);
            } }
            onError={this.props.onError}
            onSuccess={this.props.onSuccess}
            onClose={ () => this.closeEditPrice() }
          />
        ) }
        { this.state.openDialog_editStock && (
          <DialogEditProductStock
            open={this.state.openDialog_editStock}
            id_profile={id_profile_provider}
            product={product}
            onSaved={ (product) => {
              this.closeEditStock();
              this.props.onSaved && this.props.onSaved(product);
            } }
            onError={this.props.onError}
            onSuccess={this.props.onSuccess}
            onClose={ () => this.closeEditStock() }
          />
        ) }
        { this.state.openDialog_editVisibility && (
          <DialogEditProductVisibility
            open={this.state.openDialog_editVisibility}
            id_profile={id_profile_provider}
            id_product={product.ID}
            onSaved={ (product) => {
              this.closeEditVisibility();
              this.props.onSaved && this.props.onSaved(product);
            } }
            onError={this.props.onError}
            onSuccess={this.props.onSuccess}
            onClose={ () => this.closeEditVisibility() }
          />
        ) }
        { this.state.openConfirm_delete && (
          <ConfirmDialog
            fullWidth={true}
            maxWidth="xs"
            open={this.state.openConfirm_delete}
            title="Supprimer un produit"
            message="Confirmez-vous la suppression de ce produit ? Cette action est irréversible."
            onConfirm={ () => {
              this.closeDelete();
              this.delete();
            } }
            onClose={ () => this.closeDelete() }
          />
        ) }
      </React.Fragment>
    );
  }

  renderSummary () {
    const { classes, product, order_items } = this.props;

    const is_format_portion = product.format === productsService.META_FORMAT_PORTION;
    const is_format_bulk = product.format === productsService.META_FORMAT_BULK;

    let summary_second = [];
    if (is_format_bulk) {
      summary_second.push(productsService.getFormatLabel(product.format, product.format_label));
    }
    if (is_format_portion) {
      summary_second.push(productsService.getFormatLabel(product.format, product.format_label) + " (volume estimé : " + product.volume_estimation + product.volume_unit+")");
    }
    if (product.visibility === productsService.VISIBILITY_PRIVATE) {
      summary_second.push('distribution exclusive');
    } else if (product.visibility === productsService.VISIBILITY_SCOPED) {
      summary_second.push('distribution sélective');
    }
    if (product?.state !== productsService.META_STATE_AVAILABLE) {
      summary_second.push('indisponible');
    }

    return (
      <Box className={classes.summary}>
        <Box className={classes.summary_product} onClick={() => this.openView()}>
          <ListItemContentSummary1>{utilsService.showDebugID(product)}{product.name}</ListItemContentSummary1>
          { (product.description) && (
            <ListItemContentSummary3>
              { utilsService.truncate(utilsService.stripHtmlTags(product.description), 12) }
            </ListItemContentSummary3>
          ) }
          { summary_second && !isEmpty(summary_second) && (
            <ListItemContentSummary3 className={classes.summary_second}>
              { summary_second.join(' - ') }
            </ListItemContentSummary3>
          ) }
          <ProductsListItemPrice product={product} />
        </Box>
        { order_items && (
          <Box className={classes.summary_order_items}>
            <SetOrderItemQty
              order_item={orderItemsService.getForProduct(order_items, product)}
              onChange={(order_item) => {
                const updatedOrderItems = orderItemsService.getUpdatedOrderItems(order_items, order_item);
                this.props.onOrderItemsChange && this.props.onOrderItemsChange(updatedOrderItems);
              }}
            />
          </Box>
        ) }
      </Box>
    );
  }

  renderRight () {
    const { id_profile_provider, right } = this.props;
    if (!right) {
      return null;
    }
    if (right === 'actions') {
      if (!authService.isLoggedInProfile(id_profile_provider)) {
        return null;
      }
      return (
        <IconMenu icon={<MoreVertIcon fontSize="small" />} iconColor="secondary" render={ () => {
          let items = [];

          items.push(<MenuItem key={1} onClick={(e) => {
            this.openEdit();
          }}>
            <ListItemIcon><EditIcon /></ListItemIcon>
            <ListItemText disableTypography={true} primary="Caractéristiques" />
          </MenuItem>);

          items.push(<MenuItem key={2} onClick={(e) => {
            this.openEditStock();
          }}>
            <ListItemIcon><BoxIcon /></ListItemIcon>
            <ListItemText disableTypography={true} primary="Stock et disponibilité" />
          </MenuItem>);

          items.push(<MenuItem key={4} onClick={(e) => {
            this.openEditPrice();
          }}>
            <ListItemIcon><EuroIcon /></ListItemIcon>
            <ListItemText disableTypography={true} primary="Tarif" />
          </MenuItem>);

          items.push(<MenuItem key={5} onClick={(e) => {
            this.openEditVisibility();
          }}>
            <ListItemIcon><EyeIcon /></ListItemIcon>
            <ListItemText disableTypography={true} primary="Distribution" />
          </MenuItem>);

          items.push(<MenuItem key={3} onClick={(e) => {
            this.openEditClassification();
          }}>
            <ListItemIcon><TagIcon /></ListItemIcon>
            <ListItemText disableTypography={true} primary="Catégories" />
          </MenuItem>);

          items.push(<Divider key={6} />);

          items.push(<MenuItem key={7} onClick={(e) => {
            this.openDelete();
          }}>
            <ListItemIcon><DeleteIcon /></ListItemIcon>
            <ListItemText disableTypography={true} primary="Supprimer" />
          </MenuItem>);

          return items;
        } } />
      )
    } else {
      return right;
    }
  }
}

const styles = theme => ({
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  },
  summary_product: {
    width: '100%',
  },
  summary_order_items: {
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  avatar:{
    width: theme.spacing(8),
    height: theme.spacing(8),
    fontSize: '1rem',
    fontWeight: 400,
    borderRadius: '10px',
    [theme.breakpoints.down('mobile')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  },
});

ProductsListItem.propTypes = {
  id_profile_provider: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  order_items: PropTypes.array,
  product: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onSaved: PropTypes.func,
  onDeleted: PropTypes.func,
  onOrderItemsChange: PropTypes.func,
  displayMenu: PropTypes.bool,
  scrollableTarget: PropTypes.string,
};

ProductsListItem.defaultProps = {
  displayMenu: true,
};

export default withStyles(styles, { withTheme: true })(ProductsListItem);
