import { CollectBagIcon, ShippingIcon } from '../components/commons/Icons';
import DeliveryModes_API from './api/delivery-modes';
import utilsService from './utils';

const exportedObject = {
  /**
   * Constantes
   */
  TYPE_SHIPPING: 'shipping', // livraison
  TYPE_PICKUP: 'pickup', // retrait en magasin

  TAX_RULE_FLAT_RATE: 'flat-rate', // calcul forfaitaire de la taxe (en fonction des taux des modes de livraison de la commande)
  TAX_RULE_FIXED_RATE: 'fixed-rate', // calcul de la taxe en fonction d'un taux spécifique
  
  VISIBILITY_PUBLIC: 'public', // public (visible par tout le monde)
  VISIBILITY_SCOPED: 'scoped', // ciblé (visible uniquement par les profils ciblés)

  /**************** deliveryModes ************************************/
  /**
   * Compte les modes de livraison du serveur
   * @return Promise(data, notices) : les modes de livraison
   */
  count (id_profile, args) {
    return new Promise((resolve, reject) => {
      DeliveryModes_API.count(id_profile, args).then(({data, notices}) => {
        utilsService.debugLog('Modes de livraison comptés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les modes de livraison du serveur
   * @return Promise(data, notices) : les modes de livraison
   */
  getAll (id_profile, args) {
    return new Promise((resolve, reject) => {
      DeliveryModes_API.getAll(id_profile, args).then(({data, notices}) => {
        utilsService.debugLog('Modes de livraison récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un mode de livraison du serveur
   * @return Promise(data, notices) : le mode de livraison
   */
  get (id_profile, id) {
    return new Promise((resolve, reject) => {
      DeliveryModes_API.get(id_profile, id).then(({data, notices}) => {
        utilsService.debugLog('Mode de livraison récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouveau mode de livraison
   * @return Promise(data, notices) : le mode de livraison créé
   */
  create (id_profile, data) {
    return new Promise((resolve, reject) => {
      DeliveryModes_API.create(id_profile, data).then(({data, notices}) => {
        utilsService.debugLog('Mode de livraison créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un mode de livraison
   * @return Promise(data) : le mode de livraison mis à jour
   */
  update (id_profile, id, data) {
    return new Promise((resolve, reject) => {
      DeliveryModes_API.update(id_profile, id, data).then(({data, notices}) => {
        utilsService.debugLog('Mode de livraison mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un mode de livraison
   * @return Promise(id, data, notices) : l'identifiant du mode de livraison supprimé
   */
  delete (id_profile, id) {
    return new Promise((resolve, reject) => {
      DeliveryModes_API.delete(id_profile, id).then(({data, notices}) => {
        utilsService.debugLog('Mode de livraison supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** deliveryMode scopes ************************************/
  /**
   * Récupère les restrictions d'un mode de livraison du serveur
   * @return Promise(data, notices) : les restrictions du mode de livraison
   */
  getScopes (id_profile, id_deliveryMode, args = {}) {
    return new Promise((resolve, reject) => {
      DeliveryModes_API.getScopes(id_profile, id_deliveryMode, args).then(({data, notices}) => {
        utilsService.debugLog('Restrictions de mode de livraison récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour les restrictions de mode de livraison
   * @return Promise(data, notices) : les rsetrictions de mode de livraison créés
   */
  setScopes (id_profile, id_deliveryMode, data = {}) {
    return new Promise((resolve, reject) => {
      DeliveryModes_API.setScopes(id_profile, id_deliveryMode, data).then(({data, notices}) => {
        utilsService.debugLog('Restrictions de mode de livraison créés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** extras ************************************/
  getAvatar (deliveryMode) {
    if (deliveryMode?.type === this.TYPE_PICKUP) {
      return { content: <CollectBagIcon />, props: {variant: "rounded"} };
    } else {
      return { content: <ShippingIcon />, props: {variant: "rounded"} };
    }
  },
  getVisibilityLabel (slug, capitalize = false) {
    switch (slug) {
      case this.VISIBILITY_PUBLIC: {
        return capitalize ? 'Public' : 'public';
      }
      case this.VISIBILITY_SCOPED: {
        return capitalize ? 'Ciblé' : 'ciblé';
      }
      default: {
        return '';
      }
    }
  },
  getTypeLabel (slug, capitalize = false) {
    switch (slug) {
      case this.TYPE_SHIPPING: {
        return (capitalize ? 'Livraison' : 'livraison');
      }
      case this.TYPE_PICKUP: {
        return (capitalize ? 'Retrait' : 'retrait');
      }
      default: {
        return slug;
      }
    }
  },
  getTaxeRuleLabel (slug, capitalize = false) {
    switch (slug) {
      case this.TAX_RULE_FLAT_RATE: {
        return (capitalize ? 'Taux forfaitaire' : 'taux forfaitaire');
      }
      case this.TAX_RULE_FIXED_RATE: {
        return (capitalize ? 'Taux spécifique' : 'taux spécifique');
      }
      default: {
        return slug;
      }
    }
  },
};
export default exportedObject;
