import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import usersService from './../../services/users';

import ConfirmDialog from './../commons/ConfirmDialog';
import BackdropLoading from './../commons/BackdropLoading';
import SetContact from './../commons/SetContact';
import EditControllers from './../commons/EditControllers';
import Form from './../commons/Form';
import FormFields from './../commons/FormFields';
import FormField from './../commons/FormField';
import { SaveIcon } from './../commons/Icons';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

class EditUser extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      openConfirm_email: false,
      email: '',
      firstname: '',
      lastname: '',
      contact: {},
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showLoading() {
    this._isMounted && this.setState({loading: true})
  }

  hideLoading() {
    this._isMounted && this.setState({loading: false})
  }

  load () {
    const { user } = this.props;
    if (typeof user === 'object' && user !== null && user.ID) {
      // l'utilisateur est passé en props
      this._isMounted && this.setState({
        email: user.email || '',
        firstname: user.firstname || '',
        lastname: user.lastname || '',
        contact: user.contact || { address_country: 'France' },
      });
    } else {
      console.warn("Pas de création d'utilisateur, il faut passer par une invitation");
    }
  }

  pre_save (e) {
    e.preventDefault();
    const { user } = this.props;
    if (this.state.email !== user.email) {
      this._isMounted && this.setState({openConfirm_email: true});
    } else {
      this.save();
    }
    return false;
  }

  validate () {
    let valid = true;
    if (!this.state.email || this.state.email === '') {
      valid = false;
      this.props.onError("Email manquant");
    }
    if (!this.state.firstname || this.state.firstname === '') {
      valid = false;
      this.props.onError("Prénom manquant");
    }
    if (!this.state.lastname || this.state.lastname === '') {
      valid = false;
      this.props.onError("Nom manquant");
    }
    if (!this.state.contact) {
      valid = false;
      this.props.onError("Informations de contact manquants");
    } else {
      if (!this.state.contact.tel || this.state.contact.tel === '') {
        valid = false;
        this.props.onError("Téléphone manquant");
      }
    }
    return valid;
  }

  save () {
    const { user } = this.props;

    if (!this.validate()) {
      return;
    }

    const data = {
      email: this.state.email,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      contact: this.state.contact,
    }
    this.showLoading();
    usersService.update(user.ID, data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération de l'item à jour
      return usersService.get(id);
    }).then(([user]) => {
      this.props.onSaved(user);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this.hideLoading();
    });
  }

  render () {
    const { classes, user, fixedCtrl } = this.props;
    if (!user || !user.ID) {
      return (
        <Typography className={classes.error} component="p">Pour créer un nouvel utilisateur, vous devez l'inviter.</Typography>
      )
    }
    return (
      <Box className={classes.container}>
        <BackdropLoading open={this.state.loading} />

        <Form onSubmit={ (e) => this.pre_save(e) } noValidate>

          <FormFields>

            <FormField>
              <FormControl className={classes.form_control}>
                <TextField
                  required
                  id="email"
                  label="Email"
                  type="email"
                  className={classes.input}
                  value={this.state.email}
                  onChange={ (e) => this._isMounted && this.setState({email: e.target.value}) }
                  autoComplete="email username"
                />
              </FormControl>
            </FormField>

            <FormField>
              <FormControl className={classes.form_control}>
                <TextField
                  required
                  id="firstname"
                  label="Prénom"
                  type="text"
                  className={classes.input}
                  value={this.state.firstname}
                  onChange={ (e) => this._isMounted && this.setState({firstname: e.target.value}) }
                  autoComplete="given-name"
                />
              </FormControl>
            </FormField>

            <FormField>
              <FormControl className={classes.form_control}>
                <TextField
                  required
                  id="lastname"
                  label="Nom"
                  type="text"
                  className={classes.input}
                  value={this.state.lastname}
                  onChange={ (e) => this._isMounted && this.setState({lastname: e.target.value}) }
                  autoComplete="family-name"
                />
              </FormControl>
            </FormField>

            <FormField>
              <SetContact
                tel_required={true}
                address_name_required={false}
                address_1_required={false}
                address_2_required={false}
                address_3_required={false}
                address_country_required={false}
                address_cp_required={false}
                address_city_required={false}
                value={this.state.contact}
                onChange={ (value) => this._isMounted && this.setState({contact: value}) }
              />
            </FormField>

            <EditControllers fixed={fixedCtrl}>
              <Button type="submit" className={classes.submit} onClick={ (e) => this.pre_save(e) } color="primary" variant="contained" startIcon={<SaveIcon />}>
                Enregistrer
              </Button>
            </EditControllers>

          </FormFields>

          <ConfirmDialog
            open={this.state.openConfirm_email}
            title="Changement d'email"
            message="Confirmez-vous le changement d'email de ce compte ?"
            onConfirm={ () => {
                this._isMounted && this.setState({openConfirm_email: false});
                this.save();
              }
            }
            onClose={ () => this._isMounted && this.setState({ openConfirm_email: false }) }
          />

        </Form>

      </Box>
    )
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submit:{
    width: '100%',
  }
});

EditUser.propTypes = {
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  fixedCtrl: PropTypes.bool,
};

EditUser.defaultProps = {
  fixedCtrl: false,
};

const mapStateToProps = state => ({
	appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditUser));
