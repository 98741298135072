import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormFields from '../../commons/FormFields';
import SetOrderContacts from '../../commons/SetOrderContacts';

class EditNewOrderContacts extends React.Component {

  render () {
    const { order, onChange, onError } = this.props;

    return (
      <FormFields>
        <SetOrderContacts
          order={order}
          onChange={(order) => onChange(order)}
          onError={onError}
        />
      </FormFields>
    );
  }
}

const styles = theme => ({});

EditNewOrderContacts.propTypes = {
  onChange: PropTypes.func,
  order: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditNewOrderContacts);
