import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class SelectTimeHours extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.items = [
      { value: 0, label: '00' },
      { value: 1, label: '01' },
      { value: 2, label: '02' },
      { value: 3, label: '03' },
      { value: 4, label: '04' },
      { value: 5, label: '05' },
      { value: 6, label: '06' },
      { value: 7, label: '07' },
      { value: 8, label: '08' },
      { value: 9, label: '09' },
      { value: 10, label: '10' },
      { value: 11, label: '11' },
      { value: 12, label: '12' },
      { value: 13, label: '13' },
      { value: 14, label: '14' },
      { value: 15, label: '15' },
      { value: 16, label: '16' },
      { value: 17, label: '17' },
      { value: 18, label: '18' },
      { value: 19, label: '19' },
      { value: 20, label: '20' },
      { value: 21, label: '21' },
      { value: 22, label: '22' },
      { value: 23, label: '23' },
    ];
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getLabel (value) {
    const items = this.items.filter((item) => item.value === value);
    return (items && items.length > 0) ? items[0].label : 'Erreur de récupération du label';
  }

  render () {
    const { classes, className, label, value } = this.props;
    const form_control = className ? className.form_control : null;
    return (
      <FormControl className={clsx(form_control, classes.form_control)} disabled={this.props.disabled}>
        <InputLabel id="select-hours">{ label || 'Heure' }</InputLabel>
        <Select
          className={classes.select_field}
          labelId="select-hours"
          label={label || 'Heure'}
          id="select-hours"
          value={value || 0}
          onChange={ (e) => this.props.onChange && this.props.onChange(e.target.value, this.getLabel(e.target.value)) }
        >
        { this.items.map((item) => {
          return item.component || (
            <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
          )
        }) }
        </Select>
      </FormControl>
    )
  }
}

const styles = theme => ({
  form_control: {
    position: 'relative',
  },
});

SelectTimeHours.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(SelectTimeHours);
