import Push_API from './api/push';
import { store } from './store';
import { setPushGranted, setPushSubscribed } from './store/features/appSlice';
import { isEmpty } from './utils';

/****************************************************
 iOS push notification support
****************************************************/

/**
 * Check if is iOS push notification is available
 * @returns boolean
 */
export function isPushIOSEnabled () {
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['push-subscribe']) {
    return true;
  }
  return false;
}

/**
 * Lance le processus d'inscription au service de notification push iOS
 * en associant l'utilisateur authentifié le cas échéant : demande l'autorisation si nécessaire et revoit le token dans tous les cas (sauf erreur)
 * => iOS app respond in window.event['push-subscription']
 * @returns void
 */
export function subscribeToPushIOS () {
  window.webkit.messageHandlers['push-subscribe'].postMessage('push-subscribe');
  // processus asynchrone, la suite dans handle_pushSubscription()
}

/**
 * Lance le processus de désinscription de service de notification push iOS
 * en désassociant l'utilisateur authentifié le cas échéant : revoit le token dans tous les cas (sauf erreur)
 * => iOS app respond in window.event['push-unsubscription']
 * @returns void
 */
export function unsubscribeToPushIOS () {
  window.webkit.messageHandlers['push-unsubscribe'].postMessage('push-unsubscribe');
}

/**
 * Ecoute une Notification Push reçue en tâche de fond
 * @returns void
 */
function handle_pushNotificationRemote (event) {
  if (event && event.detail){
    // window.alert("handle_pushNotificationRemote : " + JSON.stringify(event.detail));
  }
};

/**
 * Ecoute une Notification Push reçue alors que l'utilisateur est déjà sur l'application
 * @returns void
 */
function handle_pushNotificationPresent (event) {
  if (event && event.detail){
    // window.alert("handle_pushNotificationPresent : " + JSON.stringify(event.detail));
  }
};

/**
 * Ecoute une Notification Push depuis laquelle l'utilisateur accède à l'application
 * @returns void
 */
function handle_pushNotification (event) {
  // window.alert("handle_pushNotification - event.detail : " + JSON.stringify(event.detail));
  if (!isEmpty(event?.detail?.url)){
    // window.alert("handle_pushNotification - event.detail.url : " + JSON.stringify(event.detail.url));
    // ask app to navigate to specific URL
    const customNavigateEvent = new CustomEvent('app-navigate-to', {detail: event.detail.url});
    window.dispatchEvent(customNavigateEvent);
  } else {
    // window.alert("handle_pushNotification - unable to navigate : " + event?.detail?.url);
  }
};

/**
 * Suite du processus d'inscription au service de notification push iOS
 * en associant l'utilisateur authentifié le cas échéant
 * @returns void
 */
async function handle_pushSubscription (event) {
  try {
    if (event && event.detail){
      const token = event.detail;
      // window.alert("handle_pushSubscription : " + token);

      await Push_API.subscribe({platform: 'ios', data: {token}});
      store.dispatch(setPushGranted(true));
      store.dispatch(setPushSubscribed(true));
    }
  } catch (error) {
    window.alert("Erreur durant le processus d'inscription au service de notification push iOS : ", JSON.stringify(error));
  }
};

/**
 * Suite du processus de désinscription du service de notification push iOS
 * en désassociant l'utilisateur authentifié le cas échéant
 * @returns void
 */
async function handle_pushUnsubscription (event) {
  try {
    if (event && event.detail){
      const token = event.detail;
      // window.alert("handle_pushUnsubscription : " + token);

      await Push_API.unsubscribe({platform: 'ios', data: {token}});
      store.dispatch(setPushSubscribed(false));
    }
  } catch (error) {
    window.alert("Erreur durant le processus de désinscription du service de notification push iOS : ", JSON.stringify(error));
  }
};

/**
 * Surveille l'erreur d'inscription de l'appareil au service de notification push iOS
 * @returns void
 */
function handle_pushSubscriptionFailed (event) {
  if (event && event.detail){
    window.alert("handle_pushRegistrationFailed : " + JSON.stringify(event.detail));
  }
};

/**
 * Surveille l'erreur de désinscription de l'appareil du service de notification push iOS
 * @returns void
 */
function handle_pushUnsubscriptionFailed (event) {
  if (event && event.detail){
    window.alert("handle_pushRegistrationFailed : " + JSON.stringify(event.detail));
  }
};

/**
 * Surveille les évènements de notification push provenant de l'app iOS
 * @returns void
 */
export function surveyPushIOS () {
  window.addEventListener('push-notification-remote', handle_pushNotificationRemote);
  window.addEventListener('push-notification-present', handle_pushNotificationPresent);
  window.addEventListener('push-notification', handle_pushNotification);
  window.addEventListener('push-subscription', handle_pushSubscription);
  window.addEventListener('push-subscription-failed', handle_pushSubscriptionFailed);
  window.addEventListener('push-unsubscription', handle_pushUnsubscription);
  window.addEventListener('push-unsubscription-failed', handle_pushUnsubscriptionFailed);
}

/**
 * Arrête de surveiller les évènements de notification push provenant de l'app iOS
 * @returns void
 */
export function unsurveyPushIOS () {
  window.removeEventListener('push-notification-remote', handle_pushNotificationRemote);
  window.removeEventListener('push-notification-present', handle_pushNotificationPresent);
  window.removeEventListener('push-notification', handle_pushNotification);
  window.removeEventListener('push-subscription', handle_pushSubscription);
  window.removeEventListener('push-subscription-failed', handle_pushSubscriptionFailed);
  window.removeEventListener('push-unsubscription', handle_pushUnsubscription);
  window.removeEventListener('push-unsubscription-failed', handle_pushUnsubscriptionFailed);
}