import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import productsService from './../../services/products';

import SetOrderItemQtyInputPopup from './SetOrderItemQtyInputPopup';
import { PlusIcon, MinusIcon, TrashIcon, CloseIcon } from './../commons/Icons';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

class SetOrderItemQty extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      enableInput_qty: false,
      enableInput_volume: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  enableInput_qty () {
    this._isMounted && this.setState({enableInput_qty: true});
  }

  disableInput_qty () {
    this._isMounted && this.setState({enableInput_qty: false});
  }

  enableInput_volume () {
    this._isMounted && this.setState({enableInput_volume: true});
  }

  disableInput_volume () {
    this._isMounted && this.setState({enableInput_volume: false});
  }

  onChange (order_item_input) {
    this.props.onChange({...this.props.order_item, ...order_item_input});
  }

  render () {
    const { classes, order_item } = this.props;
    let render_content = null;
    const is_bulk = order_item.product_format === productsService.META_FORMAT_BULK;
    if (is_bulk) {
      render_content = this.render_volume();
    } else {
      render_content = this.render_unit();
    }
    return (
      <Box className={classes.container}>
        { this.state.enableInput_qty && (
          <SetOrderItemQtyInputPopup
            value={parseInt(order_item.qty)}
            onChange={ (qty) => {
              this.props.onChange({...order_item, ...{qty: parseInt(qty)}})
              this.disableInput_qty();
            } }
            onCancel={ () => this.disableInput_qty() }
          />
        ) }
        { this.state.enableInput_volume && (
          <SetOrderItemQtyInputPopup
            title={'Volume'}
            value={ parseFloat(order_item.volume) }
            endAdornment={<InputAdornment position="end">{ order_item.product_volume_unit }</InputAdornment>}
            onChange={ (volume) => {
              this.props.onChange({...order_item, ...{volume: parseFloat(volume)}})
              this.disableInput_volume();
            } }
            onCancel={ () => this.disableInput_volume() }
          />
        ) }
        { render_content }
      </Box>
    );
  }

  render_unit () {
    const { classes, order_item } = this.props;
    return (
      <Stack spacing={1} direction="row" className={classes.container_unit}>
        { order_item.qty > 0 && (
          <IconButton
            color="secondary"
            className={clsx(classes.button, classes.iconButton, classes.iconButton_remove)}
            aria-controls="remove"
            onClick={ () => this.props.onChange({ ...order_item, ...{qty: 0, volume: 0} }) }
            size="small">
            <TrashIcon className={clsx(classes.icon, classes.icon_remove)} />
          </IconButton>
        ) }
        { this.render_qty() }
      </Stack>
    )
  }

  render_volume () {
    const { classes, order_item, stackDirection } = this.props;
    return (
      <Stack spacing={1} direction={stackDirection || "column"} className={classes.container_volume}>
        <Stack spacing={1} direction="row" className={classes.volume_val}>
          { order_item.volume > 0 && (
            <IconButton
              color="secondary"
              className={clsx(classes.button, classes.iconButton, classes.iconButton_remove)}
              aria-controls="remove"
              onClick={ () => this.props.onChange({ ...order_item, ...{qty: 0, volume: 0} }) }
              size="small">
              <TrashIcon className={clsx(classes.icon)} />
            </IconButton>
          )}
          <Button size="small" variant="outlined" color="secondary" className={clsx(classes.button, classes.button_input)} aria-label="volume" onClick={() => this.enableInput_volume()}>
            <Typography component="span" className={classes.volume} variant="body2">{ order_item.volume } { order_item.product_volume_unit }</Typography>
          </Button>
        </Stack>

        { order_item.volume > 0 && (
          <Stack spacing={1} direction="row" className={classes.volume_qty}>
            <IconButton
              color="secondary"
              className={clsx(classes.button, classes.iconButton, classes.iconButton_x)}
              size="small">
              <CloseIcon className={clsx(classes.icon)} />
            </IconButton>
            { this.render_qty() }
          </Stack>
        ) }
      </Stack>
    )
  }

  render_qty () {
    const { classes, order_item } = this.props;
    return (
      <React.Fragment>
        <Button size="small" variant="outlined" color="secondary" className={clsx(classes.button, classes.button_input)} aria-label="decrement" onClick={() => this.enableInput_qty()}>
          <Typography component="span" className={classes.qty} variant="body2">{ order_item.qty }</Typography>
        </Button>

        <IconButton
          color="secondary"
          className={clsx(classes.button, classes.iconButton, classes.iconButton_decrement)}
          aria-controls="decrement"
          onClick={ () => this.props.onChange({ ...order_item, ...{qty: parseInt(order_item.qty) > 0 ? (parseInt(order_item.qty) - 1) : 0} }) }
          size="small">
          <MinusIcon className={clsx(classes.icon)} />
        </IconButton>

        <IconButton
          color="secondary"
          className={clsx(classes.button, classes.iconButton, classes.iconButton_increment)}
          aria-controls="increment"
          onClick={ () => this.props.onChange({ ...order_item, ...{qty: (parseInt(order_item.qty) + 1)} }) }
          size="small">
          <PlusIcon className={clsx(classes.icon)} />
        </IconButton>
      </React.Fragment>
    )
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: theme.spacing(1),
  },
  container_unit: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  container_volume: {
    alignItems: 'flex-end',
  },
  volume_val: {
    alignItems: 'center',
  },
  volume_qty: {
    alignItems: 'center',
  },
  qty: {
    width: 30,
  },
  volume: {
    width: '110px',
  },
  volume_unit: {
    margin: theme.spacing(0, 0, 0, 0.4),
  },
  buttons: {
    alignItems: 'center',
  },
  iconButton: {
    padding: theme.spacing(0.3),
  },
  iconButton_remove: {
  },
  iconButton_x: {
  },
  button_input: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minWidth: 24,
    borderRadius: 0,
  },
  button_remove: {
  },
  text: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  icon: {
    fontSize: 16,
  },
});

SetOrderItemQty.propTypes = {
  order_item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  stackDirection: PropTypes.string
};

export default withStyles(styles, { withTheme: true })(SetOrderItemQty);
