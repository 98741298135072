import React from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';

class ListItemContentSummary1 extends React.Component {

  render() {
    const { classes, children, className, ...others } = this.props;
    const innerProps = { className: clsx(classes.content, className), component: "h3", variant: "body1" };
    return (
      <Typography {...{...innerProps, ...others}}>{children}</Typography>
    )
  }
}

const styles = theme => ({
  content: {},
});

export default withStyles(styles, { withTheme: true })(ListItemContentSummary1);
