import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import OrderMessages from '../../order/OrderMessages';

class EditNewOrderStepResumePrice extends React.Component {

  render () {
    const { order, permissions, onError } = this.props;
    return (
      <OrderMessages id_order={order.ID} permissions={permissions} onError={(e) => onError(e)} />
    );
  }
}

const styles = theme => ({});

EditNewOrderStepResumePrice.propTypes = {
  order: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditNewOrderStepResumePrice);
