import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

class Forbidden extends React.Component {

  render () {
    const { classes, message, children } = this.props;
    return (
      <Box className={classes.content}>
        <Box>
          {message || <Typography variant="body1">Vous n'avez pas les droits nécessaires.</Typography> }
        </Box>
        {children}
      </Box>
    );
  }
}

const styles = theme => ({
  content: {
    padding: theme.spacing(2, 0),
  }
});

Forbidden.propTypes = {
  message: PropTypes.element,
};

export default withStyles(styles, { withTheme: true })(Forbidden);
