import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import authService from '../../services/auth';
import profilesService from '../../services/profiles';
import { isEmpty } from '../../services/utils';

import BackdropLoading from '../commons/BackdropLoading';
import EditControllers from '../commons/EditControllers';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { RenewIcon } from '../commons/Icons';
import { IconButton } from '@mui/material';
import SetProfileOptionsLegal from '../commons/SetProfileOptionsLegal';
import SetProfileBtoC from '../commons/SetProfileBtoC';
import SetBusinessSector from '../commons/SetBusinessSector';

class EditProfilePro extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      error: false,
      data: {
        business_sector: null,
        btoc: null,
        legal: {}
      },
    }
  }

  componentDidMount () {
    this._isMounted = true;
    const { id_profile } = this.props;
    if (id_profile) {
      this.load();
    } else {
      console.warn("id_profile manquant - EditProfilePro ne sert qu'à modifier un profil existant");
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
    const { id_profile, onError } = this.props;
    if (id_profile) {
      this._isMounted && this.setState({loading: true, error: false});
      profilesService.get(id_profile).then(([profile]) => {
        this._isMounted && this.setState({data: {...this.state.data, ...{ business_sector: profile.business_sector?.name || '', btoc: profile.btoc || false }}});
      }).catch((e) => {
        this._isMounted && this.setState({error: true});
        onError(e);
      }).finally(() => {
        this._isMounted && this.setState({loading: false});
      });
    }
  }

  validate () {
    const { data } = this.state;
    let valid = true;
    if (isEmpty(data.business_sector)) {
      valid = false;
      this.props.onError("Secteur d'activité manquant");
    }
    if (isEmpty(data?.legal?.name)) {
      valid = false;
      this.props.onError("Raison sociale manquante");
    }
    if (isEmpty(data?.legal?.siret)) {
      valid = false;
      this.props.onError("SIRET manquant");
    }
    if (isEmpty(data?.legal?.type)) {
      valid = false;
      this.props.onError("Forme juridique manquante");
    }
    return valid;
  }

  save (e) {
    const { id_profile } = this.props;
    const { data } = this.state;
    e.preventDefault();
    if (!this.validate()) {
      return;
    }
    const parsed_data = {
      business_sector: data.business_sector,
      btoc: data.btoc || false,
      options: {
        legal: data.legal,
      },
    }
    this._isMounted && this.setState({loading: true})
    profilesService.update(id_profile, parsed_data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      return profilesService.get(id);
    }).then(([profile]) => {
      this.props.onSaved(profile);

      // Mise à jour du profil si c'est celui qui est authentifié
      return authService.maybeRefreshToken(profile.ID);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false})
    });
    return false;
  }

  render () {
    const { classes, id_profile } = this.props;
    const { loading, error } = this.state;

    if (error) {
      return (
        <Box className={classes.container}>
          <Typography variant="h4">Erreur de chargement</Typography>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    }

    return (
      <Box className={classes.container}>
        <BackdropLoading open={loading} />

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields className={classes.formFields}>

            <SetBusinessSector
              value={this.state.data?.business_sector || ''}
              onChange={(business_sector) => this._isMounted && this.setState({data: {...this.state.data, ...{business_sector}}}) }
            />

            <SetProfileBtoC
              value={this.state.data.btoc}
              onChange={(btoc) => this._isMounted && this.setState({data: {...this.state.data, ...{btoc}}}) }
            />

            <SetProfileOptionsLegal
              id_profile={id_profile}
              value={this.state.data?.legal || {}}
              onChange={ (legal) => this._isMounted && this.setState({data: {...this.state.data, ...{legal}}}) }
            />

          </FormFields>

          <EditControllers sticky>
            <Button type="submit" onClick={ (e) => this.save(e) } color="primary" variant="contained" className={classes.submit}>Enregistrer</Button>
          </EditControllers>

        </Form>

      </Box>
    )
  }

}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  submit: {
    width: '100%',
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
});

EditProfilePro.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditProfilePro);
