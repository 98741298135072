import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import utilsService, { isEmpty } from './../../services/utils';

import DialogBase from '../dialog/DialogBase';
import SelectAsyncChip from './SelectAsyncChip';
import { RenewIcon, ArrowDropDownIcon } from './../commons/Icons';

import { CircularProgress, Box, Typography, DialogContent, DialogTitle } from '@mui/material';

/**
 * Sélecteur Asynchrone, permet de gérer une sélection d'élement à partir d'une source asynchrone
 */
class SelectAsync extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this._isInitialized = false;
    this.state = {
      loading: false,
      error: false,
      open: false,
      alreadyOpened: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  componentDidUpdate (prevProps) {
    // On met à jour le composant si la query a changé
    if (JSON.stringify(this.props.query) !== JSON.stringify(prevProps.query)){
      this.load()
    }
    // On met à jour le composant si la valeur à sélectionner a changé
    else if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) {
      this.load()
    }
  }

  load = () => {
    const { query, value } = this.props;

    // si aucune valeur ou si c'est déjà un objet, on initialise
    if (!value || isNaN(value) || utilsService.isObject(value)) {
      this.maybeInitiliaze();
      return;
    }

    // si ce n'est pas un objet mais un ID de passé en props, on tente de le charger depuis l'API
    this._isMounted && this.setState({ loading: true, error: false });
    this.props.load && this.props.load(query, value).then(([item]) => {
      this.onSelect(item);
    }).catch((e) => {
      this.props.onError(e);
      this.onSelect(null);
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  onSelect = (item) => {

    this.props.onChange && this.props.onChange(item);
    this.close();

    this.maybeInitiliaze();
  }

  maybeInitiliaze = () => {
    if (!this._isInitialized) {
      this.props.onInitialized && this.props.onInitialized();
      this._isInitialized = true;
    }
  }

  open = () => {
    this._isMounted && this.setState({ alreadyOpened: true, open: true });
  }

  close = () => {
    this._isMounted && this.setState({ open: false });
  }

  render () {
    const { classes, value, itemKey, title, className, scrollableTarget } = this.props;
    const { loading, error } = this.state;
    return (
      <Box className={clsx(classes.root, className && className.root)}>
        { this.state.alreadyOpened && (
          <DialogBase
            open={this.state.open}
            onClose={() => this.close()}
          >
            { !isEmpty(title) && (
              <DialogTitle>{title}</DialogTitle>
            ) }
            <DialogContent classes={{root: classes.dialogContent}} id={scrollableTarget}>
              { this.props.renderSelect && this.props.renderSelect(this.onSelect, value ? value[itemKey] : null, value) }
            </DialogContent>
          </DialogBase>
        ) }
        { this.props.renderField && this.props.renderField(this.open, this.load, value, loading, error) }
        { !this.props.renderField && (
          <>
            <fieldset className={classes.fieldset}>
              { value && this.props.label && (<legend className={classes.legend}>{this.props.label}</legend>) }
              { this.renderField() }
            </fieldset>
            { this.props.helperText && (
              <Typography className={classes.helperText}>{ this.props.helperText }</Typography>
            ) }
          </>
        ) }
      </Box>
    );
  }

  renderField() {
    const { value } = this.props;
    const { loading, error } = this.state;

    if (loading) {
      return (
        <SelectAsyncChip
          icon={<CircularProgress size={20} color="secondary" />}
        />
      );
    } else if (error) {
      return (
        <SelectAsyncChip
          icon={<RenewIcon />}
          label={ this.props.label_error || "Erreur de chargement" }
          onClick={() => this.open() }
        />
      );
    } else if (value) {
      return (
        <SelectAsyncChip
          icon={this.props.label_icon}
          label={this.props.renderSelected && this.props.renderSelected(value)}
          onClick={() => this.open() }
          onDelete={() =>  this.props.onChange && this.props.onChange(null, null) }
          variant="outlined"
        />
      );
    }
    return (
      <SelectAsyncChip
        icon={this.props.label_icon}
        label={ this.props.label || "Choisir" }
        iconDelete={<ArrowDropDownIcon />}
        onClick={() => this.open() }
        onDelete={() => this.open() }
      />
    );
  }
}

const styles = theme => ({
  helperText: {
    display: 'block',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
  },
  fieldset: {
    position: 'relative',
    padding: '6px 12px 2px 12px',
    minWidth: '0',
  },
  legend: {
    padding: theme.spacing(0, 0.5),
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.75rem',
    position: 'absolute',
    background: '#fff',
    top: '-9px',
  },
});

SelectAsync.propTypes = {
  title: PropTypes.string,
  renderSelect: PropTypes.func.isRequired, // affiche la liste des éléments à sélectionner dans une Dialog
  renderField: PropTypes.func, // Surcharge le rendu du composant
  value: PropTypes.oneOfType([
    PropTypes.number, // 12
    PropTypes.string, // '12'
    PropTypes.object
  ]),
  onChange: PropTypes.func.isRequired, // La sélection est mise à jour
  onInitialized: PropTypes.func, // Permet de savoir quand le sélecteur a terminé de charger la sélection depuis l'API
  onError: PropTypes.func.isRequired,
  query: PropTypes.object,
  disabled: PropTypes.bool,
  itemKey: PropTypes.string,
  scrollableTarget: PropTypes.string, // used by dialog selection
};

SelectAsync.defaultProps = {
  itemKey: 'ID',
};

export default withStyles(styles, { withTheme: true })(SelectAsync);
