import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import authService from './../../services/auth';
import profilesService from '../../services/profiles';
import { isEmpty } from '../../services/utils';

import BackdropLoading from '../commons/BackdropLoading';
import EditControllers from '../commons/EditControllers';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { RenewIcon } from '../commons/Icons';
import { IconButton } from '@mui/material';
import SetProfileVisibility from '../commons/SetProfileVisibility';

class EditProfileVisibility extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      error: false,
      data: {
        visibility: ''
      },
    }
  }

  componentDidMount () {
    this._isMounted = true;
    const { id_profile } = this.props;
    if (id_profile) {
      this.load();
    } else {
      console.warn("id_profile manquant - EditProfil ne sert qu'à modifier un profil existant");
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
    const { id_profile, onError } = this.props;
    if (id_profile) {
      this._isMounted && this.setState({loading: true, error: false});
      profilesService.get(id_profile).then(([profile]) => {
        this._isMounted && this.setState({data: { visibility: profile.visibility, type: profile.type }});
      }).catch((e) => {
        this._isMounted && this.setState({error: true});
        onError(e);
      }).finally(() => {
        this._isMounted && this.setState({loading: false})
      });
    }
  }

  validate () {
    const { data } = this.state;
    let valid = true;
    if (isEmpty(data.visibility)) {
      valid = false;
      this.props.onError("Visiblité manquante");
    }
    return valid;
  }

  save (e) {
    const { id_profile } = this.props;
    const { data } = this.state;
    e.preventDefault();
    if (!this.validate()) {
      return;
    }
    const parsed_data = {
      visibility: data.visibility,
    }
    this._isMounted && this.setState({loading: true})
    profilesService.update(id_profile, parsed_data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      return profilesService.get(id);
    }).then(([profile]) => {
      this.props.onSaved(profile);

      // Mise à jour du profil si c'est celui qui est authentifié
      return authService.maybeRefreshToken(profile.ID);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false})
    });
    return false;
  }

  render () {
    const { classes } = this.props;
    const { loading, error, data } = this.state;

    if (error) {
      return (
        <Box className={classes.container}>
          <Typography variant="h4">Erreur de chargement</Typography>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    }

    return (
      <Box className={classes.container}>
        <BackdropLoading open={loading} />

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields className={classes.formFields}>
            
            <SetProfileVisibility
              data={data}
              onChange={(data) => this.setState({data})}
            />

          </FormFields>

          <EditControllers sticky>
            <Button disabled={loading} type="submit" onClick={ (e) => this.save(e) } color="primary" variant="contained" className={classes.submit}>Enregistrer</Button>
          </EditControllers>

        </Form>

      </Box>
    )
  }

}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  submit: {
    width: '100%',
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
});

EditProfileVisibility.propTypes = {
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default withStyles(styles, { withTheme: true })(EditProfileVisibility);
