import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import deliveryModesService from '../../services/delivery-modes';
import utilsService from '../../services/utils';
import { isEmpty } from '../../services/utils';

import Box from '@mui/material/Box';
import EditControllers from '../commons/EditControllers';
import { Button, Typography } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon, SaveIcon } from '../commons/Icons';
import BackdropLoading from '../commons/BackdropLoading';
import EditNewDeliveryModeGeneral from './EditNewDeliveryModeGeneral';
import EditNewDeliveryModePrice from './EditNewDeliveryModePrice';
import EditNewDeliveryModeTimes from './EditNewDeliveryModeTimes';
import EditNewDeliveryModeVisibility from './EditNewDeliveryModeVisibility';

class EditNewDeliveryMode extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      deliveryMode: null, // une fois créé
      loading: false,
      step: 'general',
      data: {...{
        name: '',
        description: '',
        type: deliveryModesService.TYPE_SHIPPING,
        visibility: deliveryModesService.VISIBILITY_PUBLIC,
        price: '',
        tax_rule: deliveryModesService.TAX_RULE_FLAT_RATE,
        tax_rate: '20',
        free_price: '',
        times: {},
        required_time: 0,
        address_name: '',
        address_1: '',
        address_2: '',
        address_3: '',
        address_cp: '',
        address_city: '',
        address_country: '',
        scopes: {
          profiles: [],
          groups: [],
        }
      }, ...this.props.preset},
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  save (e) {
    const { data } = this.state;
    const { id_profile, onError, onSaved, onEnd } = this.props;
    e.preventDefault();
    const parsed_data = {
      name: data?.name,
      description: data?.description,
      type: data?.type,
      visibility: data?.visibility,
      price: data?.price,
      tax_rule: data?.tax_rule,
      tax_rate: data?.tax_rule === deliveryModesService.TAX_RULE_FIXED_RATE ? data?.tax_rate : null,
      free_price: data?.free_price,
      times: !isEmpty(data?.times) ? data?.times : null,
      required_time: data?.required_time,
      address_name: data?.address_name,
      address_1: data?.address_1,
      address_2: data?.address_2,
      address_3: data?.address_3,
      address_cp: data?.address_cp,
      address_city: data?.address_city,
      address_country: data?.address_country,
    }
    this._isMounted && this.setState({loading: true});
    deliveryModesService.create(id_profile, parsed_data).then(([id, notices]) => {
      notices && notices.length > 0 && onError(notices);
      // récupération de l'item à jour
      return deliveryModesService.get(id_profile, id);
    }).then(([deliveryMode]) => {

      // on propage au parent que le produit est créé
      onSaved && onSaved(deliveryMode);

      // on enregistre maintenant les portées de visibilité
      return this.saveScopes(deliveryMode);

    }).then(() => {

      // on propage au parent que c'est terminé
      onEnd && onEnd();
      
    }).catch((error) => {
      onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false})
    });
    return false;
  }

  saveScopes (deliveryMode) {
    const { id_profile } = this.props;
    const { data } = this.state;
    const parsed_data = {
      profiles: data.scopes.profiles && data.scopes.profiles.map((profile) => utilsService.isObject(profile) ? profile.ID : profile),
      groups: data.scopes.groups && data.scopes.groups.map((group) => utilsService.isObject(group) ? group.ID : group),
    }
    if (deliveryMode.visibility === deliveryModesService.VISIBILITY_SCOPED) {
      return deliveryModesService.setScopes(id_profile, deliveryMode.ID, parsed_data);
    } else {
      return new Promise((resolve) => resolve());
    }
  }

  isStepCompleted_general () {
    const { data } = this.state;
    if (isEmpty(data.name)) {
      return false;
    }
    return true;
  }

  nextStep () {
    const { onStepChange } = this.props;
    const { step, } = this.state;
    let newStep = null;
    if (step === 'general') {
      newStep = 'price';
    } else if (step === 'price') {
      newStep = 'times';
    } else if (step === 'times') {
      newStep = 'visibility';
    }
    this.setState({step: newStep});
    onStepChange && onStepChange(newStep);
  }

  prevStep () {
    const { onStepChange } = this.props;
    const { step } = this.state;
    let newStep = null;
    if (step === 'price') {
      newStep = 'general';
    } else if (step === 'times') {
      newStep = 'price';
    } else if (step === 'visibility') {
      newStep = 'times';
    }
    this.setState({step: newStep});
    onStepChange && onStepChange(newStep);
  }

  render () {
    const { classes } = this.props;

    return (
      <Box className={classes.container}>
        { this.render_step() }
      </Box>
    );
  }

  render_step () {
    const { classes, onError, id_profile } = this.props;
    const { data, step, loading } = this.state;

    return (
      <Box className={classes.root}>

          <BackdropLoading open={loading} />

          { step === 'general' && (
            <Box className={classes.step}>
              <Box className={classes.step_title}>
                <Typography variant="h6" component="div">Caractéristiques</Typography>
              </Box>
              <Box className={classes.step_content}>
                <EditNewDeliveryModeGeneral
                  data={data}
                  onChange={(data) => {
                    this.setState({data});
                  }}
                  onError={onError}
                />
              </Box>
              <EditControllers sticky>
                <Button disabled={!this.isStepCompleted_general()} className={classes.btn_next} onClick={ () => this.nextStep() } color="primary" variant="contained" endIcon={ <ArrowRightIcon /> }>
                  Suivant
                </Button>
              </EditControllers>
            </Box>
          ) }

          { step === 'price' && (
            <Box className={classes.step}>
              <Box className={classes.step_title}>
                <Typography variant="h6" component="div">Tarif</Typography>
              </Box>
              <Box className={classes.step_content}>
                <EditNewDeliveryModePrice
                  data={data}
                  onChange={(data) => {
                    this.setState({data});
                  }}
                  onError={onError}
                />
              </Box>
              <EditControllers sticky>
                <Button className={classes.btn_prev} onClick={ () => this.prevStep() } color="primary" variant="outlined" startIcon={ <ArrowLeftIcon /> }></Button>
                <Button className={classes.btn_next} onClick={ () => this.nextStep() } color="primary" variant="contained" endIcon={ <ArrowRightIcon /> }>
                  Suivant
                </Button>
              </EditControllers>
            </Box>
          ) }

          { step === 'times' && (
            <Box className={classes.step}>
              <Box className={classes.step_title}>
                <Typography variant="h6" component="div">Horaires</Typography>
              </Box>
              <Box className={classes.step_content}>
                <EditNewDeliveryModeTimes
                  data={data}
                  onChange={(data) => {
                    this.setState({data});
                  }}
                  onError={onError}
                />
              </Box>
              <EditControllers sticky>
                <Button className={classes.btn_prev} onClick={ () => this.prevStep() } color="primary" variant="outlined" startIcon={ <ArrowLeftIcon /> }></Button>
                <Button className={classes.btn_next} onClick={ () => this.nextStep() } color="primary" variant="contained" endIcon={ <ArrowRightIcon /> }>
                  Suivant
                </Button>
              </EditControllers>
            </Box>
          ) }

          { step === 'visibility' && (
            <Box className={classes.step}>
              <Box className={classes.step_title}>
                <Typography variant="h6" component="div">Disponibilité</Typography>
              </Box>
              <Box className={classes.step_content}>
                <EditNewDeliveryModeVisibility
                  id_profile={id_profile}
                  data={data}
                  onChange={(data) => {
                    this.setState({data});
                  }}
                  onError={onError}
                />
              </Box>
              <EditControllers sticky>
                <Button className={classes.btn_prev} onClick={ () => this.prevStep() } color="primary" variant="outlined" startIcon={ <ArrowLeftIcon /> }></Button>
                <Button className={classes.btn_next} onClick={ (e) => this.save(e) } color="primary" variant="contained" endIcon={ <SaveIcon /> }>
                  Enregistrer
                </Button>
              </EditControllers>
            </Box>
          ) }

        </Box>
    );
  }
}

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1),
  },
  btn_next: {
    width: '100%',
  },
  btn_prev: {},
  step: {},
  step_title: {
    padding: theme.spacing(0, 2, 1, 2),
    textDecoration: 'underline',
  },
  step_content: {
    padding: theme.spacing(0, 2),
  },
});

EditNewDeliveryMode.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onSaved: PropTypes.func,
  onEnd: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  preset: PropTypes.object,
  onStepChange: PropTypes.func,
};

EditNewDeliveryMode.defaultProps = {
  preset: {}
};

export default withStyles(styles, { withTheme: true })(EditNewDeliveryMode);
