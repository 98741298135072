import React from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import { InfoIcon } from './../commons/Icons';

import Box from '@mui/material/Box';

class InfoText extends React.Component {

  render() {
    const { classes, children, variant } = this.props;
    return (
      <Box className={clsx(classes.content, this.props.classContent, {[classes.content_box]: variant === 'box'})}>
        <InfoIcon className={clsx(classes.icon, this.props.classIcon)} fontSize="small" />
        <Box>
          {children}
        </Box>
      </Box>
    )
  }
}

const styles = theme => ({
  content: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  content_box: {
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(0),
    backgroundColor: '#f9f9f9',
  },
  icon: {
    marginRight: theme.spacing(1),
  }
});

export default withStyles(styles, { withTheme: true })(InfoText);
