import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import productsService from '../../services/products';
import utilsService from '../../services/utils';
import { isEmpty } from '../../services/utils';

import Box from '@mui/material/Box';
import EditControllers from '../commons/EditControllers';
import { Button, Typography } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon, SaveIcon } from '../commons/Icons';
import BackdropLoading from '../commons/BackdropLoading';
import EditNewProductVisibility from './EditNewProductVisibility';
import EditNewProductGeneral from './EditNewProductGeneral';
import EditNewProductClassification from './EditNewProductClassification';
import EditNewProductAttachment from './EditNewProductAttachment';
import EditNewProductPrice from './EditNewProductPrice';

class EditNewProduct extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      product: null, // une fois créé
      loading: false,
      step: 'general',
      data: {...{
        name: '',
        type: productsService.TYPE_SIMPLE,
        visibility: productsService.VISIBILITY_PUBLIC,
        state: productsService.META_STATE_AVAILABLE,
        description: '',
        format: productsService.META_FORMAT_UNIT,
        volume_unit: 'kg',
        volume_estimation: '',
        format_label: '',
        price: 0,
        tax_rate: 0,
        sku: '',
        attachment: null,
        categories: [],
        tags: [],
        scopes: {
          profiles: [],
          groups: [],
        }
      }, ...this.props.preset},
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  save (e) {
    const { data } = this.state;
    const { id_profile, onError, onSaved, onEnd } = this.props;
    e.preventDefault();
    const parsed_data = {
      type: data.type,
      name: data.name,
      visibility: data.visibility,
      state: data.state,
      description: data.description,
      format: data.format,
      volume_unit: data.volume_unit,
      volume_estimation: data.volume_estimation,
      format_label: data.format_label,
      price: data.price,
      tax_rate: data.tax_rate,
      sku: data.sku,
      id_attachment: utilsService.isObject(data.attachment) ? data.attachment.ID : data.attachment,
      categories: data.categories && data.categories.map((category) => utilsService.isObject(category) ? category.ID : category),
      tags: data.tags && data.tags.map((tag) => utilsService.isObject(tag) ? tag.ID : tag),
    }
    this._isMounted && this.setState({loading: true});
    productsService.create(id_profile, parsed_data).then(([id, notices]) => {
      notices && notices.length > 0 && onError(notices);
      // récupération de l'item à jour
      return productsService.get(id_profile, id);
    }).then(([product]) => {

      // on propage au parent que le produit est créé
      onSaved && onSaved(product);

      // on enregistre maintenant les portées de visibilité
      return this.saveScopes(product);

    }).then(() => {

      // on propage au parent que c'est terminé
      onEnd && onEnd();
      
    }).catch((error) => {
      onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false})
    });
    return false;
  }

  saveScopes (product) {
    const { id_profile } = this.props;
    const { data } = this.state;
    const parsed_data = {
      profiles: data.scopes.profiles && data.scopes.profiles.map((profile) => utilsService.isObject(profile) ? profile.ID : profile),
      groups: data.scopes.groups && data.scopes.groups.map((group) => utilsService.isObject(group) ? group.ID : group),
    }
    if (product.visibility === productsService.VISIBILITY_SCOPED) {
      return productsService.setScopes(id_profile, product.ID, parsed_data);
    } else {
      return new Promise((resolve) => resolve());
    }
  }

  isStepCompleted_general () {
    const { data } = this.state;
    if (isEmpty(data.name)) {
      return false;
    }
    return true;
  }

  nextStep () {
    const { onStepChange } = this.props;
    const { step, } = this.state;
    let newStep = null;
    if (step === 'general') {
      newStep = 'price';
    } else if (step === 'price') {
      newStep = 'attachment';
    } else if (step === 'attachment') {
      newStep = 'classification';
    } else if (step === 'classification') {
      newStep = 'visibility';
    }
    this.setState({step: newStep});
    onStepChange && onStepChange(newStep);
  }

  prevStep () {
    const { onStepChange } = this.props;
    const { step } = this.state;
    let newStep = null;
    if (step === 'price') {
      newStep = 'general';
    } else if (step === 'attachment') {
      newStep = 'price';
    } else if (step === 'classification') {
      newStep = 'attachment';
    } else if (step === 'visibility') {
      newStep = 'classification';
    }
    this.setState({step: newStep});
    onStepChange && onStepChange(newStep);
  }

  render () {
    const { classes } = this.props;

    return (
      <Box className={classes.container}>
        { this.render_step() }
      </Box>
    );
  }

  render_step () {
    const { classes, onError, id_profile } = this.props;
    const { data, step, loading } = this.state;

    return (
      <Box>

          <BackdropLoading open={loading} />

          { step === 'general' && (
            <Box className={classes.step}>
              <Box className={classes.step_title}>
                <Typography variant="h6" component="div">Caractéristiques</Typography>
              </Box>
              <Box className={classes.step_content}>
                <EditNewProductGeneral
                  data={data}
                  onChange={(data) => {
                    this.setState({data});
                  }}
                  onError={onError}
                />
              </Box>
              <EditControllers sticky>
                <Button disabled={!this.isStepCompleted_general()} className={classes.btn_next} onClick={ () => this.nextStep() } color="primary" variant="contained" endIcon={ <ArrowRightIcon /> }>
                  Suivant
                </Button>
              </EditControllers>
            </Box>
          ) }

          { step === 'price' && (
            <Box className={classes.step}>
              <Box className={classes.step_title}>
                <Typography variant="h6" component="div">Tarif du produit</Typography>
              </Box>
              <Box className={classes.step_content}>
                <EditNewProductPrice
                  id_profile={id_profile}
                  data={data}
                  onChange={(data) => {
                    this.setState({data});
                  }}
                  onError={onError}
                />
              </Box>
              <EditControllers sticky>
                <Button className={classes.btn_prev} onClick={ () => this.prevStep() } color="primary" variant="outlined" startIcon={ <ArrowLeftIcon /> }></Button>
                <Button className={classes.btn_next} onClick={ () => this.nextStep() } color="primary" variant="contained" endIcon={ <ArrowRightIcon /> }>
                  Suivant
                </Button>
              </EditControllers>
            </Box>
          ) }

          { step === 'attachment' && (
            <Box className={classes.step}>
              <Box className={classes.step_title}>
                <Typography variant="h6" component="div">Image du produit</Typography>
              </Box>
              <Box className={classes.step_content}>
                <EditNewProductAttachment
                  id_profile={id_profile}
                  data={data}
                  onChange={(data) => {
                    this.setState({data});
                  }}
                  onError={onError}
                />
              </Box>
              <EditControllers sticky>
                <Button className={classes.btn_prev} onClick={ () => this.prevStep() } color="primary" variant="outlined" startIcon={ <ArrowLeftIcon /> }></Button>
                <Button className={classes.btn_next} onClick={ () => this.nextStep() } color="primary" variant="contained" endIcon={ <ArrowRightIcon /> }>
                  Suivant
                </Button>
              </EditControllers>
            </Box>
          ) }

          { step === 'classification' && (
            <Box className={classes.step}>
              <Box className={classes.step_title}>
                <Typography variant="h6" component="div">Catégorisation du produit</Typography>
              </Box>
              <Box className={classes.step_content}>
                <EditNewProductClassification
                  id_profile={id_profile}
                  data={data}
                  onChange={(data) => {
                    this.setState({data});
                  }}
                  onError={onError}
                />
              </Box>
              <EditControllers sticky>
                <Button className={classes.btn_prev} onClick={ () => this.prevStep() } color="primary" variant="outlined" startIcon={ <ArrowLeftIcon /> }></Button>
                <Button className={classes.btn_next} onClick={ () => this.nextStep() } color="primary" variant="contained" endIcon={ <ArrowRightIcon /> }>
                  Suivant
                </Button>
              </EditControllers>
            </Box>
          ) }

          { step === 'visibility' && (
            <Box className={classes.step}>
              <Box className={classes.step_title}>
                <Typography variant="h6" component="div">Distribution du produit</Typography>
              </Box>
              <Box className={classes.step_content}>
                <EditNewProductVisibility
                  id_profile={id_profile}
                  data={data}
                  onChange={(data) => {
                    this.setState({data});
                  }}
                  onError={onError}
                />
              </Box>
              <EditControllers sticky>
                <Button className={classes.btn_prev} onClick={ () => this.prevStep() } color="primary" variant="outlined" startIcon={ <ArrowLeftIcon /> }></Button>
                <Button className={classes.btn_next} onClick={ (e) => this.save(e) } color="primary" variant="contained" endIcon={ <SaveIcon /> }>
                  Enregistrer
                </Button>
              </EditControllers>
            </Box>
          ) }

        </Box>
    );
  }
}

const styles = theme => ({
  btn_next: {
    width: '100%',
  },
  btn_prev: {},
  step: {
  },
  step_title: {
    padding: theme.spacing(0, 2),
    textDecoration: 'underline',
  },
  step_content: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 2),
  },
});

EditNewProduct.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onSaved: PropTypes.func,
  onEnd: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  preset: PropTypes.object,
  onStepChange: PropTypes.func,
};

EditNewProduct.defaultProps = {
  preset: {}
};

export default withStyles(styles, { withTheme: true })(EditNewProduct);
