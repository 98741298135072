import Notifications_API from './api/notifications';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux notifications
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
	STATE_SCHEDULED: 'scheduled', // la notification est programmée
  STATE_UNREAD: 'unread', // la notification est non-lue
  STATE_READ: 'read', // la notification est lue
	STATE_ERROR :'error', // la notification est en erreur

	TOPIC_GENERAL : 'general',
	TOPIC_ORDER : 'order',
  TOPIC_MODERATION : 'moderation',
  TOPIC_INVITATION : 'invitation',
	TOPIC_ACCOUNT : 'account',
	TOPIC_SUBSCRIPTION : 'subscription',
	TOPIC_NETWORK : 'network',

	LEVEL_HIGH : 'high',
	LEVEL_NORMAL : 'normal',
	LEVEL_LOW : 'low',

	WAY_EMAIL : 'email',
	WAY_PUSH : 'push',
	WAY_SMS : 'sms',

  /**************** notifications ************************************/

  /**
   * Récupère les notifications du serveur
   * @return Promise(data, notices) : les notifications
   */
  count (args) {
    return new Promise((resolve, reject) => {
      Notifications_API.count(args).then(({data, notices}) => {
        utilsService.debugLog('Notifications comptées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les notifications du serveur
   * @return Promise(data, notices) : les notifications
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Notifications_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Notifications récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère une notification du serveur
   * @return Promise(data, notices) : la notification
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Notifications_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Notification récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé une nouvelle notification
   * @return Promise(data, notices) : la notification créée
   */
  create (data) {
    return new Promise((resolve, reject) => {
      Notifications_API.create(data).then(({data, notices}) => {
        utilsService.debugLog('Notification créée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour une notification
   * @return Promise(data, notices) : la notification mise à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Notifications_API.update(id, data).then(({data, notices}) => {
        utilsService.debugLog('Notification mise à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime une notification
   * @return Promise(id, data, notices) : l'identifiant de la notification supprimée
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      Notifications_API.delete(id).then(({data, notices}) => {
        utilsService.debugLog('Notification supprimée : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  /***************************** Extras ************************/

  /**
   * Affiche le libellé du statut
   * @return string
   */
  getStateLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.STATE_SCHEDULED: {
        return 'programmée';
      }
      case this.STATE_UNREAD: {
        return 'non lue';
      }
      case this.STATE_READ: {
        return 'lue';
      }
      case this.STATE_ERROR: {
        return 'en erreur';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Affiche le libellé du statut d'une notification
   * @return string
   */
  getNotificationStateLabel (notification) {
    if (!notification) {
      return 'aucun';
    }
    return this.getStateLabel(notification.state);
  },
  /**
   * Affiche le libellé du niveau
   * @return string
   */
  getLevelLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.LEVEL_NORMAL: {
        return 'normal';
      }
      case this.LEVEL_HIGH: {
        return 'élevé';
      }
      case this.LEVEL_LOW: {
        return 'bas';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Affiche le libellé du niveau d'une notification
   * @return string
   */
  getNotificationLevelLabel (notification) {
    if (!notification) {
      return 'aucun';
    }
    return this.getLevelLabel(notification.level);
  },
  getTopics () {
    return [
      this.TOPIC_GENERAL,
      this.TOPIC_ACCOUNT,
      this.TOPIC_ORDER,
      this.TOPIC_MODERATION,
      this.TOPIC_INVITATION,
      this.TOPIC_SUBSCRIPTION,
      this.TOPIC_NETWORK,
    ];
  },
  getWays () {
    return [
      this.WAY_EMAIL,
      this.WAY_PUSH,
      // this.WAY_SMS,
    ];
  },
  /**
   * Affiche le libellé du topic
   * @return string
   */
  getTopicLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.TOPIC_GENERAL: {
        return 'général';
      }
      case this.TOPIC_ACCOUNT: {
        return 'compte';
      }
      case this.TOPIC_ORDER: {
        return 'commande';
      }
      case this.TOPIC_MODERATION: {
        return 'moderation';
      }
      case this.TOPIC_INVITATION: {
        return 'invitation';
      }
      case this.TOPIC_SUBSCRIPTION: {
        return 'abonnement';
      }
      case this.TOPIC_NETWORK: {
        return 'réseau';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Affiche le libellé du niveau d'une notification
   * @return string
   */
  getNotificationTopicLabel (notification) {
    if (!notification) {
      return 'aucun';
    }
    return this.getTopicLabel(notification.topic);
  },
  /**
   * Affiche le libellé du moyen de notification
   * @return string
   */
  getWayLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.WAY_EMAIL: {
        return 'e-mail';
      }
      case this.WAY_PUSH: {
        return 'push';
      }
      case this.WAY_SMS: {
        return 'sms';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Renvoi le slug de la couleur du statut de la notification
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * NOTE : la couleur peur être null dans certains cas.
   * @return string
   */
  getStateColor (slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.STATE_SCHEDULED: {
        return null;
      }
      case this.STATE_UNREAD: {
        return 'notification_state_unread';
      }
      case this.STATE_READ: {
        return null;
      }
      case this.STATE_ERROR: {
        return 'notification_state_error';
      }
      default: {
        return null;
      }
    }
  },
  /**
   * Renvoi le slug de la couleur du statut de la notification
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * @return string
   */
  getNotificationStateColor (notification) {
    if (!notification) {
      return null;
    }
    return this.getStateColor(notification.state);
  },
};
export default exportedObject;
