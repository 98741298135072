import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import usersService from './../../services/users';

import EditControllers from './../commons/EditControllers';
import SetNotificationsPreferences from './../commons/SetNotificationsPreferences';
import Form from './../commons/Form';
import FormFields from './../commons/FormFields';
import { RenewIcon } from './../commons/Icons';
import { SaveIcon } from './../commons/Icons';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

class EditUserPreferences extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      error: false,
      notifications: {},
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showLoading() {
    this._isMounted && this.setState({loading: true})
  }

  hideLoading() {
    this._isMounted && this.setState({loading: false})
  }

  load () {
    const { user } = this.props;
    let user_id = user;
    if (typeof user === 'object' && user !== null && user.ID) {
      user_id = user.ID;
    }
    if (user_id) {
      this.showLoading();
      // load user
      usersService.get(user_id).then(([user]) => {
        this._isMounted && this.setState({error: false});
        this.init(user);
      }).catch(() => {
        this._isMounted && this.setState({ error: true });
      }).finally(() => {
        this.hideLoading();
      });
    }
  }

  init (user) {
    let newState = {};
    if (user.preferences) {
      if (user.preferences.notifications) {
        newState.notifications = user.preferences.notifications;
      }
    }
    this._isMounted && this.setState(newState);
  }

  save (e) {
    e.preventDefault();
    const { user } = this.props;
    let user_id = user;
    if (typeof user === 'object' && user !== null && user.ID) {
      user_id = user.ID;
    }
    const data = {
      preferences: {
        notifications: this.state.notifications,
      }
    }
    this.showLoading();
    usersService.update(user_id, data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération de l'item à jour
      return usersService.get(id);
    }).then(([user]) => {
      this.init(user);
      if (this.props.onSaved) {
        this.props.onSaved(user);
      }
    }).catch((error) => {
      if (this.props.onError) {
        this.props.onError(error);
      }
    }).finally(() => {
      this.hideLoading();
    });
    return false;
  }

  render () {
    const { classes, fixedCtrl } = this.props;

    if (this.state.loading) {
      return (
        <Box className={classes.loading}>
          <CircularProgress color="secondary" />
        </Box>
      )
    } else if (this.state.error) {
      return (
        <Box className={classes.loading}>
          <h4>Erreur de chargement</h4>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    } else {
      return (
        <Box className={classes.container}>

          <Form onSubmit={ (e) => this.save(e) } noValidate>

            <FormFields>

              <fieldset className={classes.group}>
                <legend>Notifications</legend>
                <SetNotificationsPreferences value={this.state.notifications} onChange={(notifications) => this.setState({notifications})} />
              </fieldset>

              <EditControllers fixed={fixedCtrl}>
                <Button type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={<SaveIcon />}>
                  Enregistrer
                </Button>
              </EditControllers>

            </FormFields>

          </Form>
        </Box>
      );
    }
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  loading: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(6),
  },
  group: {
    margin: theme.spacing(2, 0),
  },
  next_date_delivery: {
    display: 'flex',
    alignItems: 'center',
  },
  next_date_delivery_time_label: {
    margin: theme.spacing(0, 2),
  },
  submit:{
    width: '100%',
  }
});

EditUserPreferences.propTypes = {
  onSaved: PropTypes.func,
  onError: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  fixedCtrl: PropTypes.bool,
};

EditUserPreferences.defaultProps = {
  fixedCtrl: false,
};

export default withStyles(styles, { withTheme: true })(EditUserPreferences);
