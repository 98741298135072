import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {
  get (id) {
    return API.get('/profile-rels/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAll (params) {
    return API.get('/profile-rels/', {params})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  count (params) {
    return API.get('/profile-rels/count', {params})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  create (data) {
    return API.post('/profile-rels/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  update (id, data) {
    return API.post('/profile-rels/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  delete (id) {
    return API.delete('/profile-rels/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  setState (id, state) {
    return API.post('/profile-rels/' + id + '/state', {state})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
