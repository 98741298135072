import React from 'react';
import { withStyles } from '@mui/styles';
import groupsService from '../../services/groups';

import { StoreIcon } from './Icons';

import SelectAsync from './SelectAsync';
import GroupsList from '../group/GroupsList';

/**
 * Sélecteur de groupe de profils
 * Ce composant est asynchrone, il charge les valeurs à sélectionner depuis une API
 * Pour savoir quand le composant est initialisé, il faut écouter props.onInitialized
 */
class SelectGroup extends React.Component {

  render () {
    const { classes, ...other } = this.props;

    return (
      <SelectAsync
        { ...{...other, ...{
          renderSelect: this.renderSelect,
          label: this.props.label || "Groupe",
          label_icon: this.props.label_icon || <StoreIcon />,
          renderSelected: (selected) => selected.name,
        }} }
        load={(query, value) => groupsService.get(value)}
      />
    );
  }

  renderSelect = (onSelect, selectedItemKey, selected) => {
    return (
      <GroupsList
        onSelect={onSelect}
        onError={this.props.onError}
        query={this.props.query}
        canCreate={this.props.canCreate}
        selectedItemsKeys={[selectedItemKey]}
      />
    )
  }
}

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(SelectGroup);
