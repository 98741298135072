import Subscriptions_API from './api/subscriptions';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux abonnements
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
  STATE_INITIAL: 'initial', // l'abonnement est en attente
  STATE_ACTIVE: 'active', // l'abonnement est actif
 	STATE_CLOSED: 'closed', // l'abonnement est fermé
 	STATE_ERROR: 'error', // l'abonnement est en erreur

  /**************** subscriptions ************************************/
  /**
   * Récupère les abonnements du serveur
   * @return Promise(data, notices) : les abonnements
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Subscriptions_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Abonnements récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un abonnement du serveur
   * @return Promise(data, notices) : l'abonnement
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Subscriptions_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Abonnement récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouvel abonnement
   * @return Promise(data, notices) : l'identifiant de l'abonnement
   */
  create (data) {
    return new Promise((resolve, reject) => {
      Subscriptions_API.create(data).then(({data, notices}) => {
        utilsService.debugLog('Abonnement créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un abonnement
   * @return Promise(data, notices) : l'identifiant de l'abonnement
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Subscriptions_API.update(id, data).then(({data, notices}) => {
        utilsService.debugLog('Abonnement mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un abonnement
   * @return Promise(id, data, notices) : l'identifiant de l'abonnement supprimé
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      Subscriptions_API.delete(id).then(({data, notices}) => {
        utilsService.debugLog('Abonnement supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Active l'abonnement d'un profil
   * @return Promise(data, notices) : l'identifiant de l'abonnement
   */
  activate (id) {
    return new Promise((resolve, reject) => {
      Subscriptions_API.activate(id).then(({data, notices}) => {
        utilsService.debugLog('Abonnement activé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  /***************************** Extras ************************/

  /**
   * Affiche le libellé du statut de l'abonnement
   * @return string
   */
  getStateLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.STATE_INITIAL: {
        return 'en attente';
      }
      case this.STATE_ACTIVE: {
        return 'actif';
      }
      case this.STATE_CLOSED: {
        return 'fermé';
      }
      case this.STATE_ERROR: {
        return 'en erreur';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Affiche le libellé du statut de l'abonnement
   * @return string
   */
  getSubscriptionStateLabel (subscription) {
    if (!subscription) {
      return 'aucun';
    }
    return this.getStateLabel(subscription.state);
  },
  /**
   * Renvoi le slug de la couleur du statut de l'abonnement
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * NOTE : la couleur peur être null dans certains cas.
   * @return string
   */
  getStateColor (slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.STATE_ACTIVE: {
        return 'subscription_state_active';
      }
      case this.STATE_INITIAL: {
        return 'subscription_state_initial';
      }
      case this.STATE_CLOSED: {
        return 'subscription_state_closed';
      }
      case this.STATE_ERROR: {
        return 'subscription_state_error';
      }
      default: {
        return null;
      }
    }
  },
  /**
   * Renvoi le slug de la couleur du statut de l'abonnement
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * @return string
   */
  getSubscriptionStateColor (subscription) {
    if (!subscription) {
      return null;
    }
    return this.getStateColor(subscription.state);
  },
  /**
   * Indique si l'abonnement est en statut 'active'
   * @return boolean
   */
  isStateActive(subscription) {
    if (subscription && !subscription.state) {
      console.warn("isStateActive - l'abonnement' n'a pas de statut");
    }
    return subscription && subscription.state && subscription.state === this.STATE_ACTIVE ? true : false;
  },
  /**
   * Indique si l'abonnement est en statut 'initial'
   * @return boolean
   */
  isStateInitial(subscription) {
    if (subscription && !subscription.state) {
      console.warn("isStateInitial - l'abonnement' n'a pas de statut");
    }
    return subscription && subscription.state && subscription.state === this.STATE_INITIAL ? true : false;
  },
  /**
   * Indique si l'abonnement est en statut 'closed'
   * @return boolean
   */
  isStateClosed(subscription) {
    if (subscription && !subscription.state) {
      console.warn("isStateClosed - l'abonnement' n'a pas de statut");
    }
    return subscription && subscription.state && subscription.state === this.STATE_CLOSED ? true : false;
  },
  /**
   * Indique si l'abonnement est en statut 'error'
   * @return boolean
   */
  isStateError(subscription) {
    if (subscription && !subscription.state) {
      console.warn("isStateError - l'abonnement' n'a pas de statut");
    }
    return subscription && subscription.state && subscription.state === this.STATE_ERROR ? true : false;
  },

};
export default exportedObject;
