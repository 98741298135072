import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormFields from '../commons/FormFields';
import SetProductClassification from '../commons/SetProductClassification';

class EditNewProductClassification extends React.Component {

  render () {
    const { data, onChange, onError, id_profile } = this.props;

    return (
      <FormFields>

        <SetProductClassification
          id_profile={id_profile}
          data={data}
          onChange={onChange}
          onError={onError}
        />

      </FormFields>
    );
  }
}

const styles = theme => ({});

EditNewProductClassification.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.object,
};

EditNewProductClassification.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(EditNewProductClassification);
