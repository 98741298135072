import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import deliveryModesService from '../../services/delivery-modes';
import utilsService, { isEmpty } from '../../services/utils';

import DeliveryMode from '../deliveryMode/DeliveryMode';
import { PencilIcon, RenewIcon } from './Icons';
import ToggleRadio from './ToggleRadio';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * Sélecteur de mode de livraison
 * Ce composant est asynchrone, il charge les valeurs à sélectionner depuis une API
 * Pour savoir quand le composant est initialisé, il faut écouter props.onInitialized
 */
class SelectDeliveryMode extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this._isInitialized = false;
    this.state = {
      loading: false,
      error: false,
      deliveryModes: [],
      showSelected: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.id_profile) !== JSON.stringify(prevProps.id_profile)){
      this.load();
      return;
    }
    if (JSON.stringify(this.props.reload) !== JSON.stringify(prevProps.reload)){
      this.load();
      return;
    }
  }

  load () {
    const { query, id_profile, value } = this.props;
    if (id_profile) {
      this._isMounted && this.setState({ loading: true, error: false });
      deliveryModesService.getAll(id_profile, query).then(([deliveryModes]) => {
        this._isMounted && this.setState({ deliveryModes }, () => {
          // si une valeur est déjà sélectionnée et qu'elle correspond à un des items chargés, on affiche le résumé
          if (!isEmpty(value) && !isEmpty(this.getDeliveryMode(value))) {
            this._isMounted && this.setState({ showSelected: true });
          }
        });
        if (deliveryModes && deliveryModes.length === 1) {
          // on sélectionne le seul delivery_mode par défaut
          // this.props.onChange && this.props.onChange(deliveryModes[0]?.ID);
        }
      }).catch((e) => {
        this.props.onError(e);
        this._isMounted && this.setState({ error: true });
      }).finally(() => {
        this._isMounted && this.setState({ loading: false });
      });
    }
  }

  getDeliveryMode (value) {
    const { deliveryModes } = this.state;
    const id_delivery_mode = value ? (utilsService.isObject(value) ? value.ID : value) : null;
    let delivery_mode = null;
    if (deliveryModes && deliveryModes.length > 0) {
      deliveryModes.forEach(item => {
        if (parseInt(item.ID) === parseInt(id_delivery_mode)) {
          delivery_mode = item;
        }
      });
    }
    return delivery_mode;
  }

  getValue () {
    const { value } = this.props;
    return value ? (utilsService.isObject(value) ? value.ID : value) : null;
  }

  render () {
    const { classes, className, id_profile } = this.props;
    if (!id_profile) {
      return (<Box>Aucun profil</Box>);
    }
    return (
      <Box className={clsx(classes.root, className && className.root)}>
        { this.renderContent() }
      </Box>
    );
  }

  renderContent() {
    const { classes } = this.props;
    const { loading, error, showSelected, deliveryModes } = this.state;
    if (loading) {
      return (
        <Box className={classes.loading}>
          <CircularProgress color="secondary" />
        </Box>
      );
    } else if (error) {
      return (
        <Box className={classes.error}>
          <h4>Erreur de chargement</h4>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    } else if (isEmpty(deliveryModes)) {
      <Box className={classes.error}>
        <h4>Aucun mode de livraison de disponible</h4>
      </Box>
    }
    return (
      <>
        {!showSelected && this.renderSelect()}
        {showSelected && this.renderSelected()}
      </>
    );
  }

  renderSelect () {
    const { classes } = this.props;
    const { deliveryModes } = this.state;
    const options = deliveryModes && deliveryModes.length > 0 ? deliveryModes.map((deliveryMode) => ({
      label: {
        el: <DeliveryMode delivery_mode={deliveryMode} />
      },
      value: deliveryMode.ID
    })) : [];
    return (
      <Box>
        <ToggleRadio
          value={this.getValue()}
          onChange={(e) => {
            this.props.onChange && this.props.onChange({id_delivery_mode: e.target.value, delivery_mode: this.getDeliveryMode(e.target.value)});
            this._isMounted && this.setState({showSelected: true});
          }}
          options={options}
          className={{radioFormControl: classes.radioFormControl, radioFormControlLabel: classes.radioFormControlLabel}}
        />
      </Box>
    );
  }

  renderSelected () {
    const { classes, value } = this.props;
    const selectedDeliveryMode = this.getDeliveryMode(value);
    if (selectedDeliveryMode) {
      return (
        <Box className={classes.selected}>
          <DeliveryMode delivery_mode={selectedDeliveryMode} />
          <Box className={classes.action}>
            <IconButton size="small" color="secondary" onClick={() => {
              this._isMounted && this.setState({showSelected: false});
              this.props.onChange && this.props.onChange({id_delivery_mode: null, delivery_mode: null});
            }}>
              <PencilIcon />
            </IconButton>
          </Box>
        </Box>
      );
    }
    return null;
  }
}

const styles = theme => ({
  loading: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  error: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  radioFormControl: {
    alignItems: 'center',
    margin: theme.spacing(0, 0, 1, 0),
  },
  radioFormControlLabel: {
    margin: 0,
  },
  selected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(0.5),
    padding: theme.spacing(0,0,0,1),
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

SelectDeliveryMode.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  query: PropTypes.object,
  id_profile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectDeliveryMode);
