import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from '../../services/profiles';

import FormControl from '@mui/material/FormControl';
import FormField from './FormField';
import ToggleRadio from './ToggleRadio';

class SetProfileVisibility extends React.Component {

  render () {
    const { data, onChange } = this.props;

    let infos_public = "Profil visible sur le réseau. Tous les utilisateurs peuvent consulter les informations et le catalogue de ce profil, et s'y abonner.";
    let infos_private = "Profil visible sur le réseau. Seuls les membres du réseau de ce profil peuvent consulter ses informations et son catalogue. Les abonnements à ce profil sont soumis à approbation.";
    if (data?.type === profilesService.TYPE_PART) {
      infos_public = "Profil visible sur le réseau.";
      infos_private = "Profil visible seulement de ses abonnements et ses abonnés.";
    }

    return (
      <FormField>
        <FormControl>
          <ToggleRadio
            name="type"
            id="type"
            value={data?.visibility || ''}
            onChange={(e) => onChange({...data, ...{visibility: e.target.value}})}
            options={[
              {
                value: profilesService.VISIBILITY_PUBLIC,
                label: {
                  title: "Profil public",
                  infos: infos_public,
                }
              },
              {
                value: profilesService.VISIBILITY_PRIVATE,
                label: {
                  title: "Profil privé",
                  infos: infos_private,
                }
              },
            ]}
          />
        </FormControl>
      </FormField>
    )
  }
}

const styles = theme => ({});

SetProfileVisibility.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
};

SetProfileVisibility.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetProfileVisibility);