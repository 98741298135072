import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ordersService from '../../../services/orders';

import { Box, Typography } from '@mui/material';
import BackdropLoading from '../../commons/BackdropLoading';
import EditNewOrderStepResumeOrderItems from './EditNewOrderStepResumeOrderItems';
import EditNewOrderStepResumeInfos from './EditNewOrderStepResumeInfos';
import EditNewOrderStepResumePrice from './EditNewOrderStepResumePrice';
import EditNewOrderStepResumeMessages from './EditNewOrderStepResumeMessages';

class EditNewOrderStepResume extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      laoding: false,
      permissions: {},
    };
  }

  componentDidMount () {
    this._isMounted = true;
    const { order } = this.props;
    order && this.setState({permissions : ordersService.getOrderPermissions(order)});
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onOrderItemsUpdated () {
    const { order, onChange, onError } = this.props;
    this._isMounted && this.setState({ loading: true });
    ordersService.get(order.ID).then(([order]) => {
      onChange(order);
    }).catch((e) => {
      onError(e);
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  render () {
    const { order, classes, onError } = this.props;
    const { permissions, loading } = this.state;
    if (!order || !permissions) {
      return null;
    }
    return (
      <Box className={classes.root}>
        { loading && (<BackdropLoading open={loading} />) }
        <Box className={classes.part}>
          { !ordersService.isRecurrent(order) && (
            <Typography className={classes.title} component="div" variant="h5">Résumé de votre commande</Typography>
          ) }
          { ordersService.isRecurrent(order) && (
            <Typography className={classes.title} component="div" variant="h5">Résumé de votre modèle de récurrence</Typography>
          ) }
          { ordersService.isStandard(order) && (
            <Typography className={classes.subtitle} component="div" variant="body3">{ order.reference }</Typography>
          ) }
        </Box>
        <Box className={classes.sep}></Box>
        <Box className={classes.part}>
          <EditNewOrderStepResumeInfos order={order} permissions={permissions} />
        </Box>
        <Box className={classes.sep}></Box>
        <Box className={classes.part}>
          <EditNewOrderStepResumePrice order={order} permissions={permissions} />
        </Box>
        <Box className={classes.sep}></Box>
        <Box className={classes.part}>
          <EditNewOrderStepResumeOrderItems order={order} permissions={permissions} onChange={(order) => this.props.onChange(order)} onError={(e) => onError(e)} />
        </Box>
        <Box className={classes.sep}></Box>
        <Box className={classes.part}>
          <EditNewOrderStepResumeMessages order={order} permissions={permissions} onError={(e) => onError(e)} />
        </Box>
      </Box>
    );
  }
}

const styles = theme => ({
  root: {},
  part: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
  sep: {
    width: '100%',
    height: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    fontWeight: 600,
  },
  subtitle: {
    textAlign: 'center',
  }
});

EditNewOrderStepResume.propTypes = {
  onChange: PropTypes.func,
  order: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditNewOrderStepResume);
