import Users_API from './api/users';
import utilsService from './utils';
import attachmentsService from './attachments';

/****************************************************
 Interface avec l'API relative aux utilisateurs
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
  PROFILE_USER_ROLE__CUSTOMER: 'customer',
  PROFILE_USER_ROLE__MANAGER: 'manager',
  /**
   * Récupère un utilisateur du serveur
   * @return Promise(data, notices) : l'utilisateur
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Users_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Utilisateur récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les utilisateurs du serveur
   * @return Promise(data, notices) : les utilisateurs
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Users_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Utilisateurs récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un utilisateur
   * @return Promise(data, notices) : l'utilisateur mis à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Users_API.update(id, data).then(({data, notices}) => {
        utilsService.debugLog('Utilisateur mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Active un utilisateur
   * @return Promise(data, notices) : boolean
   */
  activate (token, data = {}) {
    return new Promise((resolve, reject) => {
      Users_API.activate(token, data).then(({data, notices}) => {
        utilsService.debugLog('Activation de l\'utilisateur : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /***************************** Extras ************************/

  getAvatar (user) {
    const userImage = attachmentsService.get_url(user?.attachment, 'thumb');
    if (userImage) {
      return { content: true, props: {src: userImage} };
    } else {
      return { content: this.getInitials(user), props: {} };
    }
  },

  isAdmin (user) {
    return user?.is_admin;
  },

  /**
   * Renvoie les initiales de l'utilisateur
   */
  getInitials (user) {
    let initials = '';
    if (user && user.display_name) {
      initials = utilsService.getInitials(user.display_name);
    }
    return initials;
  },
};
export default exportedObject;
