import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from './../../services/profiles';

import SelectAsync from './SelectAsync';
import ProductCategoriesList from './../product/ProductCategoriesList';
import { CategoryIcon } from './../commons/Icons';

/**
 * Sélecteur d'utilisateur
 * Ce composant est asynchrone, il charge les valeurs à sélectionner depuis une API
 * Pour savoir quand le composant est initialisé, il faut écouter props.onInitialized
 */
class SelectProductCategory extends React.Component {

  render () {
    const { classes, id_profile, ...other } = this.props;
    return (
      <SelectAsync
        { ...{...other, ...{
          renderSelect: this.renderSelect,
          label: this.props.label || "Catégorie",
          label_icon: this.props.label_icon || <CategoryIcon />,
          renderSelected: (selected) => selected.name,
        }} }
        load={(query, value) => profilesService.getProductCategory(id_profile, value)}
      />
    );
  }

  renderSelect = (onSelect, selectedItemKey, selected) => {
    const { id_profile } = this.props;
    return (
      <ProductCategoriesList
        id_profile={id_profile}
        onSelect={onSelect}
        onError={this.props.onError}
        query={this.props.query}
        selectedItemsKeys={[selectedItemKey]}
      />
    )
  }
}

const styles = theme => ({});

SelectProductCategory.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectProductCategory);
