import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from '../../services/profiles';

import List from '../commons/List';
import { Typography } from '@mui/material';
import ProfilesListItem from '../profile/ProfilesListItem';

class UserProfilesList extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      profiles: [],
      loading: false,
      reload: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async load (limit, offset, searchQuery) {
    return profilesService.getAll({...{id_user_managed_profiles: this.props.id_user}, ...searchQuery, ...{limit, offset}}).then(([items]) => items);
  }

  render() {
    const { profiles, reload } = this.state;

    return (
      <List
        reload={reload}
        load={(limit, offset, searchQuery) => this.load(limit, offset, searchQuery)}
        loaded={(items) => {
          this._isMounted && this.setState({profiles: items});
          this.props.onLoaded && this.props.onLoaded(items);
        }}
        searchEnabled={false}
        defaultSearchQuery={this.props.defaultSearchQuery}
        listOnlyOnSearch={this.props.listOnlyOnSearch}
        onSelect={this.props.onSelect}
        selectedItemsKeys={this.props.selectedItemsKeys}
        onError={this.props.onError}
        infiniteScrollProps={ {
          limit: this.props.limit || 25,
          scrollableTarget: this.props.scrollableTarget,
          items: profiles,
          onLoading: (loading) => this._isMounted && this.setState({loading}),
          noResult: <Typography>Aucun profil</Typography>,
        } }
        renderItem={ (profile) => (
          <ProfilesListItem
            profile={profile}
            onError={this.props.onError}
            right={this.props.right}
          />
        ) }
      />
    )
  }
}

const styles = theme => ({});

UserProfilesList.propTypes = {
  id_user: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,

  limit: PropTypes.number,
  onLoaded: PropTypes.func,
  onSelect: PropTypes.func,
  onError: PropTypes.func.isRequired,
  scrollableTarget: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(UserProfilesList);
