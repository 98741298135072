import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormField from './FormField';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import TextFieldLimited from './TextFieldLimited';

class SetProductGeneral extends React.Component {

  render () {
    const { classes, data, onChange } = this.props;

    return (
      <>
        <FormField>
          <FormControl className={classes.form_control}>
            <TextField
              required
              id="name"
              label="Nom"
              type="text"
              name="name"
              className={classes.input}
              value={data?.name || ''}
              onChange={ (e) => onChange({...data, ...{name: e.target.value}}) }
              autoFocus
            />
          </FormControl>
        </FormField>

        <FormField>
          <FormControl className={classes.form_control}>
            <TextFieldLimited
              id="description"
              label="Description"
              type="text"
              className={classes.input}
              value={data?.description || ''}
              onChange={ (description) => onChange({...data, ...{description}}) }
              multiline
              rows={4}
              helperText="Décrivez les principales caractéristique du produit (spécificités, origine, terroir, distinctions, arômes, matériaux, savoir-faire...). Exemple : Vin biodynamique certifié, vendangé à la main, cépage syrah, origine Languedoc..."
            />
          </FormControl>
        </FormField>
      </>
    )
  }
}

const styles = theme => ({
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
});

SetProductGeneral.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
};

SetProductGeneral.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetProductGeneral);
