import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from './../../services/profiles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

class SetProfileAutomations extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {};
    this.availableAutomations = [
      {
        slug: profilesService.AUTOMATION_ORDER_STATE_DONE,
        label: 'Commande "terminée"',
        description: 'Si la date optimale de retrait ou de livraison d\'une commande “validée” arrive à échéance, le statut de la commande est automatiquement définit comme "terminée".',
      },
      {
        slug: profilesService.AUTOMATION_ORDER_STATE_MISSED,
        label: 'Commande "manquée"',
        description: 'Si la date optimale de retrait ou de livraison d\'une commande “en attente” arrive à échéance, le statut de la commande est automatiquement définit comme "manquée".',
      },
    ];
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  change (automation, checked) {
    const { value } = this.props;
    if (checked) {
      this.props.onChange && this.props.onChange([...value, automation]);
    } else {
      this.props.onChange && this.props.onChange(value.filter((existingAutomation) => existingAutomation !== automation));
    }
  }

  render () {
    const { classes, value } = this.props;

    return (
      <Box className={classes.content}>
        { this.availableAutomations.map((automation) => {
          return (
            <Box className={classes.automation_wrapper} key={automation.slug}>
              <FormControlLabel
                className={classes.automation}
                control={
                  <Switch
                    checked={value.includes(automation.slug)}
                    onChange={ (e) => this.change(automation.slug, e.target.checked) }
                    inputProps={{ 'id': automation.slug, 'aria-label': automation.label }}
                  />
                }
                label={
                  <Typography component="div" variant="body2" className={classes.automation_label}>{ automation.label }</Typography>
                }
              />
              <FormHelperText className={classes.automation_description}>{ automation.description }</FormHelperText>
            </Box>
          )
        }) }
      </Box>
    );
  }
}

const styles = theme => ({
  content: {},
  automation_wrapper: {
    marginBottom: theme.spacing(2),
  },
  automation: {},
  automation_label: {
    color: '#555555',
  },
  automation_description: {
    marginLeft: theme.spacing(4.7),
  }
});

SetProfileAutomations.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};


export default withStyles(styles, { withTheme: true })(SetProfileAutomations);
