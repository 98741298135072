import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import deliveryModesService from '../../services/delivery-modes';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import SelectTaxRate from './SelectTaxRate';

class SetDeliveryModePrice extends React.Component {

  render () {
    const { classes, data, onChange } = this.props;

    return (
      <Grid container rowSpacing={2} columnSpacing={2} className={classes.grid}>

        <Grid item xs={12}>
          <FormControl className={classes.form_control}>
            <TextField
              id="price"
              name="price"
              label="Prix HT"
              type="number"
              className={classes.input}
              value={data?.price || ''}
              onChange={ (e) => onChange({...data, ...{price: e.target.value}}) }
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl className={classes.form_control}>
            <InputLabel id="select-tax-rule-label">Calcul de la TVA</InputLabel>
            <Select
              required
              labelId="select-tax-rule-label"
              label="Calcul de la TVA"
              id="tax_rule"
              value={data?.tax_rule || ''}
              onChange={ (e) => onChange({...data, ...{tax_rule: e.target.value}}) }
            >
                <MenuItem value={deliveryModesService.TAX_RULE_FLAT_RATE}>{deliveryModesService.getTaxeRuleLabel(deliveryModesService.TAX_RULE_FLAT_RATE)}</MenuItem>
                <MenuItem value={deliveryModesService.TAX_RULE_FIXED_RATE}>{deliveryModesService.getTaxeRuleLabel(deliveryModesService.TAX_RULE_FIXED_RATE)}</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        { data?.tax_rule === deliveryModesService.TAX_RULE_FIXED_RATE && (
          <Grid item xs={12}>
            <SelectTaxRate
              formControlProps={{className: classes.form_control}}
              value={data?.tax_rate || '0'}
              onChange={ (e) => onChange({...data, ...{tax_rate: e.target.value}}) }
            />
          </Grid>
        ) }
        
        <Grid item xs={12}>
          <FormControl className={classes.form_control}>
            <TextField
              id="free_price"
              name="free_price"
              label="Franco HT"
              type="number"
              className={classes.input}
              value={data?.free_price || ''}
              onChange={ (e) => onChange({...data, ...{free_price: e.target.value}}) }
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
              }}
            />
          </FormControl>
        </Grid>

      </Grid>
    )
  }
}

const styles = theme => ({
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
});

SetDeliveryModePrice.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
};

SetDeliveryModePrice.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetDeliveryModePrice);
