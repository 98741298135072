import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import paymentsService from '../../services/payments';
import invoicesService from '../../services/invoices';
import utilsService from '../../services/utils';

import { AlertIcon, CheckIcon, DownloadIcon } from '../commons/Icons';

import { Button, CircularProgress } from '@mui/material';

class PaymentInvoiceButton extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      error: false,
      success: false,
      downloadUrl: null,
      downloading: false,
      fileName: 'fichier',
    };
    this.downloadRef = React.createRef();
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
  }

  process () {
    const { id_payment } = this.props;
    // récupération du paiement
    this._isMounted && this.setState({loading: true});
    paymentsService.get(id_payment).then(([item]) => {
      if (item.id_invoice) {
        return this.download(item.id_invoice);
      }
      return this.create(id_payment);
    }).catch(() => {
      this._isMounted && this.setState({error: true});
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
  }

  /**
   * Crée la facture du paiement
   * @param {int} id_payment 
   * @returns Promise
   */
  async create (id_payment) {
    return invoicesService.createForPayment(id_payment).then(([id_invoice]) => {
      return this.download(id_invoice);
    });
  }

  /**
   * Télécharge la facture du paiement
   * @param {int} id_invoice 
   * @returns 
   */
  async download (id_invoice) {
    return invoicesService.download(id_invoice).then((response) => {
      const blob = new Blob([response.data], {type: response.headers['content-type']})
      const downloadUrl = URL.createObjectURL(blob);
      const fileName = utilsService.getDownloadResponseHeaderFileName(response);
      this._isMounted && this.setState({downloadUrl, fileName, success: true}, () => {
        // On attend un court instant afin que React ai rendu/mis à jour l'élément pointé par ref
        setTimeout(() => {
          this.downloadRef?.current?.click();
        }, 500);
        utilsService.debugLog("Fichier téléchargé : ", response);
      });
      return id_invoice;
    });
  }

  render () {
    const { loading, error, success, downloadUrl, fileName } = this.state;
    let startIcon = <DownloadIcon />;
    if (loading) {
      startIcon = <CircularProgress size={20} />;
    } else if (error) {
      startIcon = <AlertIcon />;
    } else if (success) {
      startIcon = <CheckIcon />;
    }
    return (
      <>
        <a href={downloadUrl} download={fileName} style={{visibility: 'hidden', height: 0, display: 'inline-block', fontSize: '0px'}} ref={this.downloadRef}>Télécharger le fichier</a>
        <Button
          startIcon={startIcon}
          disabled={loading}
          onClick={() => this.process()}
        >
          Facture
        </Button>
      </>
    );
  }
}

const styles = theme => ({
  loading: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  error: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(4),
  },
});

PaymentInvoiceButton.propTypes = {
  id_payment: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default withStyles(styles, { withTheme: true })(PaymentInvoiceButton);
