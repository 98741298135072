import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormFields from '../commons/FormFields';
import SetProductGeneral from '../commons/SetProductGeneral';

class EditNewProductGeneral extends React.Component {

  render () {
    const { data, onChange } = this.props;

    return (
      <FormFields>

        <SetProductGeneral
          data={data}
          onChange={(data) => onChange(data)}
        />

      </FormFields>
    );
  }
}

const styles = theme => ({});

EditNewProductGeneral.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.object,
};

EditNewProductGeneral.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(EditNewProductGeneral);
