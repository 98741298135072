import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import profilesService from './../../services/profiles';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

class SetProfilePrint extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {};
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { classes, value, className } = this.props;
    const classes_content = className ? className.content : null;
    const classes_form_control = className ? className.form_control : null;

    return (

      <Grid container spacing={2} className={clsx(classes_content, classes.content)}>

        <Grid item xs={12}>
          <FormControl className={clsx(classes_form_control, classes.form_control)}>
            <InputLabel id="select-label-type">Type</InputLabel>
            <Select
              className={classes.select_field}
              labelId="select-label-type"
              label="Type"
              id="type"
              value={value && value.type ? value.type : profilesService.PRINTER_TYPE_NONE}
              onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{type: e.target.value}}) }
            >
              <MenuItem value={profilesService.PRINTER_TYPE_NONE}>Aucune</MenuItem>
              <MenuItem value={profilesService.PRINTER_TYPE_STAR_CLOUDPRNT}>Star CloudPRNT</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        { value.type === profilesService.PRINTER_TYPE_STAR_CLOUDPRNT && (

          <React.Fragment>

            <Grid item xs={12}>
              <FormControl className={clsx(classes_form_control, classes.form_control)}>
                <TextField
                  id="star_device_group_path"
                  label="Star device group path"
                  type="text"
                  value={value && value.star_device_group_path ? value.star_device_group_path : ''}
                  onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{star_device_group_path: e.target.value}}) }
                  autoComplete="off"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl className={clsx(classes_form_control, classes.form_control)}>
                <TextField
                  id="star_device_id"
                  label="Star device ID"
                  type="text"
                  value={value && value.star_device_id ? value.star_device_id : ''}
                  onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{star_device_id: e.target.value}}) }
                  autoComplete="off"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl className={clsx(classes_form_control, classes.form_control)}>
                <TextField
                  id="star_api_key"
                  label="Star api key"
                  type="text"
                  value={value && value.star_api_key ? value.star_api_key : ''}
                  onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{star_api_key: e.target.value}}) }
                  autoComplete="off"
                />
              </FormControl>
            </Grid>

          </React.Fragment>

        ) }

      </Grid>
    );
  }
}

const styles = theme => ({
  content: {},
  form_control: {
    width: '100%',
  },
});

SetProfilePrint.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  hide_tel: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(SetProfilePrint);
