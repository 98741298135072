import { MailIcon, OrderIcon, ShippingIcon } from '../components/commons/Icons';
import Contacts_API from './api/contacts';
import utilsService from './utils';

const exportedObject = {
  /**
   * Constantes
   */
  ROLE_DEFAULT : 'default', // coordonnée à utiliser par défaut
	ROLE_BILLING : 'billing', // coordonnée à utiliser pour la facturation
	ROLE_SHIPPING : 'shipping', // coordonnée à utiliser pour l'expédition

  /**************** contacts ************************************/
  /**
   * Compte les contacts du serveur
   * @return Promise(data, notices) : les contacts
   */
  count (id_profile, args) {
    return new Promise((resolve, reject) => {
      Contacts_API.count(id_profile, args).then(({data, notices}) => {
        utilsService.debugLog('Contacts comptés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les contacts du serveur
   * @return Promise(data, notices) : les contacts
   */
  getAll (id_profile, args) {
    return new Promise((resolve, reject) => {
      Contacts_API.getAll(id_profile, args).then(({data, notices}) => {
        utilsService.debugLog('Contacts récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un contact du serveur
   * @return Promise(data, notices) : le contact
   */
  get (id_profile, id) {
    return new Promise((resolve, reject) => {
      Contacts_API.get(id_profile, id).then(({data, notices}) => {
        utilsService.debugLog('Contact récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouveau contact
   * @return Promise(data, notices) : le contact créé
   */
  create (id_profile, data) {
    return new Promise((resolve, reject) => {
      Contacts_API.create(id_profile, data).then(({data, notices}) => {
        utilsService.debugLog('Contact créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un contact
   * @return Promise(data) : le contact mis à jour
   */
  update (id_profile, id, data) {
    return new Promise((resolve, reject) => {
      Contacts_API.update(id_profile, id, data).then(({data, notices}) => {
        utilsService.debugLog('Contact mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un contact
   * @return Promise(id, data, notices) : l'identifiant du contact supprimé
   */
  delete (id_profile, id) {
    return new Promise((resolve, reject) => {
      Contacts_API.delete(id_profile, id).then(({data, notices}) => {
        utilsService.debugLog('Contact supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** extras ************************************/
  getAvatar (contact) {
    if (contact?.role === this.ROLE_BILLING) {
      return { content: <OrderIcon />, props: {variant: "rounded"} };
    } if (contact?.role === this.ROLE_SHIPPING) {
      return { content: <ShippingIcon />, props: {variant: "rounded"} };
    } else {
      return { content: <MailIcon />, props: {variant: "rounded"} };
    }
  },
  getRoleLabel (slug, capitalize = false) {
    switch (slug) {
      case this.ROLE_DEFAULT: {
        return (capitalize ? 'Défaut' : 'défaut');
      }
      case this.ROLE_BILLING: {
        return (capitalize ? 'Facturation' : 'facturation');
      }
      case this.ROLE_SHIPPING: {
        return (capitalize ? 'Livraison' : 'livraison');
      }
      default: {
        return slug;
      }
    }
  },
};
export default exportedObject;
