import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormFields from '../commons/FormFields';
import SetDeliveryModeRequiredTime from '../commons/SetDeliveryModeRequiredTime';
import SetDeliveryModeTimes from '../commons/SetDeliveryModeTimes';

class EditNewDeliveryModePrice extends React.Component {

  render () {
    const { data, onChange, onError } = this.props;
    return (
      <FormFields>
        <SetDeliveryModeRequiredTime
          data={data}
          onChange={onChange}
          onError={onError}
        />
        <SetDeliveryModeTimes
          data={data}
          onChange={onChange}
          onError={onError}
        />
      </FormFields>
    );
  }
}

const styles = theme => ({});

EditNewDeliveryModePrice.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.object,
};

EditNewDeliveryModePrice.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(EditNewDeliveryModePrice);
