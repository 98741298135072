import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ordersService from './../../services/orders';
import deliveryModesService from './../../services/delivery-modes';
import utilsService from './../../services/utils';
import { isEmpty } from './../../services/utils';

import SelectOrderDateDelivery from './SelectOrderDateDelivery';
import SelectOrderDateRecurrence from './SelectOrderDateRecurrence';
import { AlertIcon, RenewIcon } from './../commons/Icons';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';

class SelectOrderDate extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      error: false,
      custom_date_delivery_enabled: false,
      estimated_date_delivery: null,
      delivery_type: null,
      delivery_available_times: null,
      delivery_mode_required_time: null,
      daysTimes_pickup: null,
      daysTimes_shipping: null,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  componentDidUpdate (prevProps) {
    // On met à jour le composant si le type de livraison a changé
    if (JSON.stringify(this.props.id_delivery_mode) !== JSON.stringify(prevProps.id_delivery_mode)){
      this.load();
    }
  }

  load () {
    const { id_profile, id_delivery_mode, value } = this.props;
    if (!id_profile || !id_delivery_mode) {
      return;
    }

    // date de livraison spécifique
    if (isEmpty(value?.date_delivery)) {
      this._isMounted && this.setState({ custom_date_delivery_enabled: false });
    } else {
      this._isMounted && this.setState({ custom_date_delivery_enabled: true });
    }

    // on charge le mode de livraison
    this._isMounted && this.setState({loading: true, error: false});
    deliveryModesService.get(id_profile, id_delivery_mode).then(([delivery_mode]) => {
      const delivery_type = delivery_mode?.type;
      const estimated_date_delivery = delivery_mode?.estimated_delivery?.date;
      const delivery_available_times = delivery_mode?.times && !Array.isArray(delivery_mode.times) ? delivery_mode.times : {};
      const profile_preparation_time = delivery_mode?.profile?.preparation_time ? parseInt(delivery_mode?.profile?.preparation_time) : 0;
      const delivery_mode_required_time = delivery_mode?.required_time ? parseInt(delivery_mode.required_time) : 0;
      const delivery_time = parseInt(profile_preparation_time + delivery_mode_required_time);

      // Si props.value contient des horaires qui ne sont plus disponibles, on les retire afin de garder des valeurs
      // cohérentes avec les horaires disponibles du profile.
      this.checkForNotFoundValues(delivery_available_times);

      this._isMounted && this.setState({ delivery_type, delivery_available_times, delivery_time, estimated_date_delivery });

    }).catch((error) => {
      console.error('error : ', error);
      this._isMounted && this.setState({error: true});
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
  }

  /**
   * Pour une commande récurrente, on parcours props.value.recurrence et on retire la recurrence
   * qui ne corresponderait pas aux créneaux disponibles du mode de livraison. Ainsi on garde des valeurs cohérentes,
   * notamment dans le cas d'une modification de commande récurrente alors que les horaires du mode de livraison ont changés.
   */
  checkForNotFoundValues (delivery_available_times) {
    const { order_type, value } = this.props;
    if (order_type === ordersService.TYPE_RECURRENT) {
      if (value && value.recurrence) {
        if (!value?.recurrence?.day || !delivery_available_times[value?.recurrence?.day]) {
          this.onChangeRecurrence({recurrence_day: '', recurrence_time: ''});
        } else if (!value?.recurrence?.time || !delivery_available_times[value?.recurrence?.day].includes(value?.recurrence?.time)) {
          this.onChangeRecurrence({recurrence_day: value?.recurrence?.day, recurrence_time: ''});
        }
      }
    }
  }

  /**
   * Renvoi la prochaine date de livraison (en fonction du type de livraison) de disponible pour le fournisseur
   * @param {string|number} delivery_time 
   * @param {object} delivery_available_times 
   * @param {date} next_data
   */
  getNextAvailableDeliveryDate (delivery_time, delivery_available_times, next_date = null, depth = 0) {
    if (!delivery_available_times || isEmpty(delivery_available_times)) {
      return null;
    }
    // Au bout d'une semaine, c'est que le fournisseur n'a aucun jour d'ouverture
    if (depth > 168) { // boucle sur 1 semaine (7jr * 24h)
      return null;
    }
    if (!next_date) {
      next_date = new Date();
      next_date.setHours(next_date.getHours() + (delivery_time ? parseInt(delivery_time) : 0) + 1); // le + 1 car l'heure courante est toujours entamée
      next_date.setMinutes(0);
    }
    const next_date_day = utilsService.getDaySlugFromIndex(next_date.getDay());
    const dayTimes = delivery_available_times[next_date_day];
    if (!dayTimes) {
      // Ce jour n'est pas disponible pour le fournisseur
      next_date.setDate(next_date.getDate() + 1);
      next_date.setHours(0); // à chaque nouveau jour, on repart à 0h
      return this.getNextAvailableDeliveryDate(delivery_time, delivery_available_times, next_date, depth + 1);
    }
    const next_date_time = utilsService.getHours2Digits(next_date.getHours()) + ':' + utilsService.getMinutes2Digits(next_date.getMinutes());
    if (!dayTimes.includes(next_date_time)) {
      // Cet horaire n'est pas disponible pour le fournisseur
      next_date.setHours(next_date.getHours() + 1);
      return this.getNextAvailableDeliveryDate(delivery_time, delivery_available_times, next_date, depth + 1);
    }
    return next_date;
  }

  onChangeRecurrence (recurrence) {
    this.props.onChange({...recurrence, date_delivery: this.props.value.date_delivery});
  }

  onChangeDateDelivery (date, date_iso) {
    console.log("onChangeDateDelivery : ", date);
    this.props.onChange({recurrence: this.props.value.recurrence, date_delivery: date_iso});
  }

  render () {
    const { classes, id_profile, id_delivery_mode, order_type, value } = this.props;
    const { loading, error, delivery_type, estimated_date_delivery, delivery_available_times } = this.state;

    if (!id_profile) {
      return (
        <Box>Aucun profil</Box>
      );
    }
    if (!id_delivery_mode) {
      return (
        <Box>Aucun mode de livraison</Box>
      );
    }

    if (loading) {
      return (
        <Box className={classes.loading}>
          <CircularProgress color="secondary" />
        </Box>
      );
    } else if (error) {
      return (
        <Box className={classes.error}>
          <h4>Erreur de chargement</h4>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    }

    if (order_type === ordersService.TYPE_STANDARD) {
      return (
        <Box className={classes.content}>
          
          <Box className={classes.date_delivery}>
            { delivery_type === deliveryModesService.TYPE_PICKUP && (
              <Typography component="div" variant="body2">Date de retrait estimée*</Typography>
            ) }
            { delivery_type !== deliveryModesService.TYPE_PICKUP && (
              <Typography component="div" variant="body2">Date de livraison estimée*</Typography>
            ) }
            { value.date_delivery && (
              <Typography component="div" variant="body1" sx={{fontWeight: '600', textDecoration: 'underline'}}>{utilsService.dateStringToLocalString(value.date_delivery, false)}</Typography>
            ) }
            { !value.date_delivery && (
              <Typography component="div" variant="body1" sx={{fontWeight: '600', textDecoration: 'underline'}}>{estimated_date_delivery ? utilsService.dateStringToLocalString(estimated_date_delivery, false) : 'aucune'}</Typography>
            ) }
            <Typography component="div" variant="body3" sx={{marginTop: 1}}>* La disponibilité de votre commande vous sera confirmée par notification.</Typography>
          </Box>

          { delivery_available_times && !isEmpty(delivery_available_times) && (
            <Box className={classes.custom_date_delivery}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      id="custom-date-delivery-enabled"
                      name="custom-date-delivery-enabled"
                      checked={ this.state.custom_date_delivery_enabled }
                      onChange={ () => {
                        this._isMounted && this.setState({custom_date_delivery_enabled: !this.state.custom_date_delivery_enabled});
                        if (!this.state.custom_date_delivery_enabled === false) {
                          this.onChangeDateDelivery(null, null);
                        }
                      } }
                    />
                  }
                  label="Choisir une autre date"
                />
              </FormGroup>
              <Box className={classes.custom_date_delivery_field}>
                { this.state.custom_date_delivery_enabled && (
                  <SelectOrderDateDelivery
                    daysTimes={delivery_available_times}
                    onChange={(date, date_iso) => this.onChangeDateDelivery(date, date_iso)}
                    value={value.date_delivery || (estimated_date_delivery || '')}
                  />
                ) }
              </Box>
            </Box>
          ) }
        </Box>
      );
    } else if (order_type === ordersService.TYPE_RECURRENT) {
      if (!delivery_available_times || isEmpty(delivery_available_times)) {
        return (
          <Box className={classes.no_recurrent_times}><AlertIcon /> Aucun horaire de disponible</Box>
        );
      }
      return (
        <Box className={classes.content}>
          <SelectOrderDateRecurrence
            daysTimes={delivery_available_times}
            onChange={(value) => this.onChangeRecurrence(value)}
            value={{
              recurrence_day: value.recurrence_day || '',
              recurrence_time: value.recurrence_time || ''
            }}
          />
        </Box>
      );
    }
    return null;
  }
}

const styles = theme => ({
  content: {},
  loading: {
    flexGrow: 1,
    textAlign: 'center',
  },
  error: {
    flexGrow: 1,
    textAlign: 'center',
  },
  custom_date_delivery: {
    marginTop: theme.spacing(2),
  },
  custom_date_delivery_field: {
    marginTop: theme.spacing(2),
  },
  no_recurrent_times: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 0),
  }
});

SelectOrderDate.propTypes = {
  id_profile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id_delivery_mode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  order_type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectOrderDate);
