import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { loadStripe } from '@stripe/stripe-js';
import paymentsService from './../../services/payments';

import { Elements } from '@stripe/react-stripe-js';

import EditCardForm from './../stripe/EditCardForm';
import Forbidden from './../commons/Forbidden';
import { RenewIcon } from './../commons/Icons';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';

class EditPaymentMethod extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      error: false,
      stripePromise: null,
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
    this._isMounted && this.setState({ loading: true, error: false });
    // Load Stripe public key (from our server)
    paymentsService.getKey().then(([stripeKey]) => {
      this._isMounted && this.setState({ stripePromise: loadStripe(stripeKey) });
    }).catch((e) => {
      this.props.onError(e);
      this._isMounted && this.setState({ error: true });
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  render () {
    const { classes, appStore, disableGutters } = this.props;
    const { loading, error, stripePromise } = this.state;

    if (!appStore?.auth?.id_user) {
      return <Forbidden />;
    }

    if (loading) {
      return (
        <Box className={classes.loading}>
          <CircularProgress color="secondary" />
        </Box>
      );
    }

    if (error) {
      return (
        <Box className={classes.error}>
          <h4>Erreur de chargement</h4>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    }

    if (!stripePromise) {
      return null;
    }

    return (
      <Box className={clsx(classes.container, disableGutters ? undefined : classes.containerGutters)}>
        { this.renderStripe() }
      </Box>
    )
  }

  renderStripe () {
    const { id_profile } = this.props;
    const { stripePromise } = this.state;

    return (
      <Elements stripe={stripePromise}>
        <EditCardForm
          id_profile={id_profile}
          onCompleted={this.props.onUpdated}
          onError={this.props.onError}
          submitLabel={this.props.submitLabel}
          submitIcon={this.props.submitIcon}
        />
      </Elements>
    );
  }
}

const styles = theme => ({
  container: {},
  containerGutters: {
    padding: theme.spacing(2),
  },
  loading: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  error: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
});

EditPaymentMethod.propTypes = {
  id_profile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onUpdated: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  submitIcon: PropTypes.element,
};

const mapStateToProps = state => ({
	appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditPaymentMethod));
