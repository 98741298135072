import React from 'react';
import { withStyles } from '@mui/styles';
import paymentsService from '../../services/payments';
import { isEmpty } from '../../services/utils';

import PaymentCard from './../commons/PaymentCard';
import CardSection from './CardSection';
import Form from './../commons/Form';
import FormFields from './../commons/FormFields';
import FormField from './../commons/FormField';
import EditControllers from './../commons/EditControllers';
import BackdropLoading from './../commons/BackdropLoading';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

// https://stripe.com/docs/payments/accept-card-payments?platform=web&ui=elements&html-or-react=react
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { CreditCardIcon } from '../commons/Icons';

class EditCardForm extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      error: false,
      terms_of_sales: false,
      card_holder_name: '',
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit (e) {
    e.preventDefault();

    if (this.state.loading) {
      // Make sure to disable form submission until component has loaded.
      return;
    }

    const {stripe, elements} = this.props;
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // CGV
    if (!this.state.terms_of_sales) {
      this.props.onError && this.props.onError('Vous devez accepter les conditions générales de vente');
      return;
    }

		// Porteur de la carte
    if (!this.state.card_holder_name || this.state.card_holder_name === '') {
      this.props.onError && this.props.onError('Veuillez renseigner le nom du porteur de la carte');
      return;
    }

    this._isMounted && this.setState({loading: true});

    // -- préparation du moyen de paiement
    this.setupIntent().then((setupData) => {

      // -- création du moyen de paiement chez Stripe
			return this.confirmCardSetup(setupData.clientSecret);

		}).then((result) => {

			if (result.error) {
				throw result.error;
			} else {

        // -- enregistrement du moyen de paiement chez nous
        return this.setupIntentSuccess(result.setupIntent);

      }
		}).then((data) => {
			if (data.error) {
				throw data.error;
			} else {

        // -- carte enregistrée
				this.props.onCompleted && this.props.onCompleted(data);

			}
		}).catch((error) => {
      console.error(error);
      this.props.onError && this.props.onError(error);
		}).finally(() => {
			this._isMounted && this.setState({loading: false});
		});
  };

  /**
	 * Prépare l'intention de paiement
	 * @return Promise
	 */
	setupIntent () {
    const { id_profile } = this.props;
    if (!id_profile) {
      return new Promise((resolve, reject) => {
				throw new Error('Il manque l\'identifiant d\'abonnement');
			});
    }
    return paymentsService.setupIntent({ id_profile }).then(([setupData]) => setupData);
	};

  /**
   * Crée le moyen de paiement Stripe en checkant la validité des champs (avec validation 3DSecure si besoin)
	 * @return Promise
   */
  confirmCardSetup (clientSecret) {
    const {stripe, elements} = this.props;
    if (!stripe || !elements) {
      return new Promise((resolve, reject) => {
				throw new Error('Stripe semble ne pas être chargé');
			});
    }

		const payment_method = {
      /**
       * Just pass the CardNumber Element in card. As long as they were all created from the same instance of the Elements object,
       * the confirmCardPayment function will pull the relevant information from all of the mounted Elements
       * to get the expiry/CVC too and it will just work.
       */
      card: elements.getElement('cardNumber'),
			billing_details: {
				name: this.state.card_holder_name,
			}
		};
    return stripe.confirmCardSetup(clientSecret, { payment_method });
  }

  /**
	 * Le setupIntent Stripe est valide, on enregistre le nouveau moyen de paiement
	 * @return Promise
	 */
	setupIntentSuccess (setupIntent) {
    const { id_profile } = this.props;
    if (!id_profile) {
      return new Promise((resolve, reject) => {
				throw new Error('Il manque l\'identifiant d\'abonnement');
			});
    }
    return paymentsService.setupIntentSuccess({ id_profile, paymentMethodId: setupIntent.payment_method });
	};

  render() {
    const { classes } = this.props;
    const disabled = !this.props.stripe || this.state.loading || !this.state.terms_of_sales || isEmpty(this.state.card_holder_name) ? true : false;
    return (
      <Form onSubmit={(e) => this.handleSubmit(e)} noValidate>

        <BackdropLoading open={this.state.loading} />

        <PaymentCard>

          <FormFields className={classes.form_fields}>

            <CardSection />

            <FormField>
              <FormControl className={classes.form_control}>
                <TextField
                  required
                  size="small"
                  className={classes.input}
                  id="card_holder_name"
                  placeholder="Porteur de la carte"
                  type="text"
                  value={this.state.card_holder_name}
                  onChange={ (e) => this._isMounted && this.setState({card_holder_name: e.target.value}) }
                />
              </FormControl>
            </FormField>

            <FormField>
              <FormControlLabel
                control={
                  <Switch
                    required
                    checked={this.state.terms_of_sales}
                    onChange={ (e) => this._isMounted && this.setState({terms_of_sales: e.target.checked}) }
                    inputProps={{ 'id': 'accept-legal', 'aria-label': "acceptation conditions générales de vente" }}
                  />
                }
                label={
                  <Typography component="div" variant="body2">J'accepte les <a href={process.env.REACT_APP_SITE_URL_CGV} target="_blank" rel="noreferrer">Conditions générales de vente</a> du site Web {process.env.REACT_APP_MAIN_BASE_URL}</Typography>
                }
              />
            </FormField>

            <EditControllers>
              <Button className={classes.submit} type="submit" disabled={disabled} onClick={(e) => this.handleSubmit(e)} color="primary" variant="contained" startIcon={(this.props.submitIcon || <CreditCardIcon />)}>
                {this.props.submitLabel || "Enregistrer" }
              </Button>
            </EditControllers>

          </FormFields>

        </PaymentCard>

      </Form>
    );
  }
}

const styles = theme => ({
  form_fields: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
    border: 'none',
    backgroundColor: '#ffffff',
  },
  submit: {
    width: '100%',
  },
});

const InjectedEditCardForm = (props) => {
  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <EditCardForm
          classes={props.classes}
          submitLabel={props.submitLabel}
          submitIcon={props.submitIcon}
          stripe={stripe}
          elements={elements}
          id_profile={props.id_profile}
          onCompleted={props.onCompleted}
          onError={props.onError}
        />
      )}
    </ElementsConsumer>
  )
}

export default withStyles(styles, { withTheme: true })(InjectedEditCardForm);
