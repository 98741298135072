import Products_API from './api/products';
import attachmentsService from './attachments';
import utilsService from './utils';

const exportedObject = {
  /**
   * Constantes
   */
  TYPE_SIMPLE: 'simple', // produit simple

  META_STATE_AVAILABLE: 'available', // le produit est disponible à la commande
	META_STATE_UNAVAILABLE: 'unavailable', // le produit est indisponible à la commande

  VISIBILITY_PUBLIC: 'public', // produit public (visible par tout le monde)
	VISIBILITY_PRIVATE: 'private', // produit privé (visible uniquement par les abonnés)
	VISIBILITY_SCOPED: 'scoped', // produit ciblé (visible uniquement par les profils ciblés)

	META_FORMAT_UNIT: 'unit', // le produit vendu à l'unité
  META_FORMAT_BULK: 'bulk', // le produit vendu au vrac
  META_FORMAT_PORTION: 'portion', // le produit vendu à la portion

  /**************** products ************************************/
  /**
   * Compte les produits du serveur
   * @return Promise(data, notices) : nombre de produits
   */
  count (id_profile, args) {
    return new Promise((resolve, reject) => {
      Products_API.count(id_profile, args).then(({data, notices}) => {
        utilsService.debugLog('Nombre de produits : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les produits du serveur
   * @return Promise(data, notices) : les produits
   */
  getAll (id_profile, args) {
    return new Promise((resolve, reject) => {
      Products_API.getAll(id_profile, args).then(({data, notices}) => {
        utilsService.debugLog('Produits récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un produit du serveur
   * @return Promise(data, notices) : le produit
   */
  get (id_profile, id) {
    return new Promise((resolve, reject) => {
      Products_API.get(id_profile, id).then(({data, notices}) => {
        utilsService.debugLog('Produit récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouveau produit
   * @return Promise(data, notices) : le produit créé
   */
  create (id_profile, data) {
    return new Promise((resolve, reject) => {
      Products_API.create(id_profile, data).then(({data, notices}) => {
        utilsService.debugLog('Produit créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un produit
   * @return Promise(data) : le produit mis à jour
   */
  update (id_profile, id, data) {
    return new Promise((resolve, reject) => {
      Products_API.update(id_profile, id, data).then(({data, notices}) => {
        utilsService.debugLog('Produit mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un produit
   * @return Promise(id, data, notices) : l'identifiant du produit supprimé
   */
  delete (id_profile, id) {
    return new Promise((resolve, reject) => {
      Products_API.delete(id_profile, id).then(({data, notices}) => {
        utilsService.debugLog('Produit supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** product scopes ************************************/
  /**
   * Récupère les restrictions d'un produit du serveur
   * @return Promise(data, notices) : les restrictions du produit
   */
  getScopes (id_profile, id_product, args = {}) {
    return new Promise((resolve, reject) => {
      Products_API.getScopes(id_profile, id_product, args).then(({data, notices}) => {
        utilsService.debugLog('Restrictions de produit récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour les restrictions de produit
   * @return Promise(data, notices) : les rsetrictions de produit créés
   */
  setScopes (id_profile, id_product, data = {}) {
    return new Promise((resolve, reject) => {
      Products_API.setScopes(id_profile, id_product, data).then(({data, notices}) => {
        utilsService.debugLog('Restrictions de produit créés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** extras ************************************/
  /**
   * Récupère la structure des produits d'un profil
   * @return Promise(data, notices) : la structure
   */
  getStructure (id_profile) {
    return new Promise((resolve, reject) => {
      Products_API.getStructure(id_profile).then(({data, notices}) => {
        utilsService.debugLog('Structure récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Recherche des produits
   * @return Promise(data, notices) : une liste de produits
   */
  search (id_profile, args) {
    return new Promise((resolve, reject) => {
      Products_API.search(id_profile, args).then(({data, notices}) => {
        utilsService.debugLog('Produits trouvés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** extras ************************************/
  getAvatar (product, defaultImage = null) {
    const productImage = attachmentsService.get_url(product?.attachment, 'thumb', defaultImage);
    if (productImage) {
      return { content: true, props: {variant: "rounded", src: productImage} };
    } else {
      return { content: false, props: {} };
    }
  },
  /**
   * Renvoi le label du format de produit
   * @return string
   */
  getFormatLabel (format, format_label = '') {
    switch (format) {
      case this.META_FORMAT_UNIT: {
        if (format_label && format_label !== '') {
          return 'À l\'unité (' + format_label + ')';
        }
        return 'À l\'unité';
      }
      case this.META_FORMAT_BULK: {
        return 'Au vrac';
      }case this.META_FORMAT_PORTION: {
        if (format_label && format_label !== '') {
          return 'À la portion (' + format_label + ')';
        }
        return 'À la portion';
      }
      default: {
        return '';
      }
    }
  },
};
export default exportedObject;
