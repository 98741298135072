import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import subscriptionsService from './../../services/subscriptions';
import utilsService from '../../services/utils';

import SubscriptionView from './../subscription/SubscriptionView';
import { RenewIcon } from './../commons/Icons';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import EditSubscription from '../edit/EditSubscription';
import { Typography } from '@mui/material';
import Message from '../commons/Message';

class Subscription extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      error: false,
      alertMultiple: false,
      subscription: null,
      initialized: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
    const { id_subscription, id_profile } = this.props;

    if (id_subscription) {
      this._isMounted && this.setState({ loading: true, error: false });
      subscriptionsService.get(id_subscription).then(([subscription]) => {
        this._isMounted && this.setState({ subscription: subscription });
      }).catch((e) => {
        this.props.onError(e);
        this._isMounted && this.setState({ error: true });
      }).finally(() => {
        this._isMounted && this.setState({ loading: false, initialized: true });
      });
    } else if (id_profile) {
      this._isMounted && this.setState({ loading: true, error: false });
      subscriptionsService.getAll({id_profile: id_profile}).then(([subscriptions]) => {
        if (subscriptions && subscriptions.length > 0) {
          this._isMounted && this.setState({ subscription: subscriptions[0], alertMultiple: subscriptions.length > 1 ? true : false });
        }
      }).catch((e) => {
        this.props.onError(e);
        this._isMounted && this.setState({ error: true });
      }).finally(() => {
        this._isMounted && this.setState({ loading: false, initialized: true });
      });
    } else {
      console.warn("Manque id_subscription ou id_profile !");
    }
  }

  render () {
    const { classes, onUpdated, onError, onSuccess, id_profile } = this.props;
    const { initialized, loading, error, subscription, alertMultiple } = this.state;
    if (loading) {
      return (
        <Box className={classes.loading}>
          <CircularProgress color="secondary" />
        </Box>
      );
    }
    if (!initialized) {
      return null;
    }
    if (error) {
      return (
        <Box className={classes.error}>
          <h4>Erreur de chargement</h4>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    }
    if (subscription) {
      // si l'abonnement est créé on propose le récap de l'abonnement
      let renewAlert = false;
      if (subscription?.attempts && parseInt(subscription.attempts) > 0) {
        renewAlert = "Échec du renouvellement de votre abonnement Premium. Veuillez vérifier ou mettre à jour vos coordonnées bancaires. Une nouvelle tentative de prélèvement sera faite le " + utilsService.dateStringToLocalString(subscription?.attempts_next_date, true, false) + ". Votre abonnement reste actif.";
        if (parseInt(subscription.attempts) >= subscription.attempts_limit) {
          renewAlert = "Échec du renouvellement de votre abonnement Premium. Votre abonnement va être fermé d'ici peu.";
        }
      }
      return (
        <Box>
          { alertMultiple && (
            <Message level="warning" className={{root: classes.message_root}}>
              <Typography component="div" variant="body3">Il semble qu'il y ait un problème, votre profil a plusieurs abonnements, cela ne devrait pas être le cas. Merci de contacter l'assistance.</Typography>
            </Message>
          ) }
          { renewAlert !== false && (
            <Message level="warning" className={{root: classes.message_root}}>
              <Typography component="div" variant="body3">{renewAlert}</Typography>
            </Message>
          ) }
          <SubscriptionView
            subscription={subscription}
            onUpdated={ (subscription) => {
              this._isMounted && this.setState({ subscription });
              onUpdated && onUpdated(subscription);
            } }
            onError={ onError }
            onSuccess={ onSuccess }
          />
        </Box>
      )
    }
    // si l'abonnement n'est pas créé on propose le formulaire de création
    return (
      <Box className={classes.editWrapper}>
        <EditSubscription
          onSaved={ (subscription) => {
            this._isMounted && this.setState({ subscription });
            onUpdated && onUpdated(subscription);
          } }
          onError={ onError }
          id_profile={ id_profile }
        />
      </Box>
    )
  }
}

const styles = theme => ({
  loading: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  error: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  message_root: {
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
  },
  editWrapper: {
    padding: theme.spacing(1, 0),
    backgroundColor: '#fff',
  },
});

Subscription.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  id_subscription: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onUpdated: PropTypes.func,
  onDeleted: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(Subscription);
