import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import productsService from '../../services/products';

import FormControl from '@mui/material/FormControl';
import SelectProfiles from './SelectProfiles';
import SelectGroups from './SelectGroups';
import FormField from './FormField';
import ToggleRadio from './ToggleRadio';

class SetProductVisibility extends React.Component {

  render () {
    const { data, onChange, onError, id_profile } = this.props;

    return (
      <>
        <FormField>
          <FormControl>
            <ToggleRadio
              name="visibility"
              id="visibility"
              value={data?.visibility || ''}
              onChange={ (e) => onChange({...data, ...{...{visibility: e.target.value}}}) }
              options={[
                {
                  value: productsService.VISIBILITY_PUBLIC,
                  label: {
                    title: "Intensive",
                    infos: "Produit visible pour tous les profils, mais disponible à la commande uniquement pour vos abonnés",
                  }
                },
                {
                  value: productsService.VISIBILITY_PRIVATE,
                  label: {
                    title: "Exclusive",
                    infos: "Produit visible et disponible à la commande uniquement pour vos abonnés.",
                  }
                },
                {
                  value: productsService.VISIBILITY_SCOPED,
                  label: {
                    title: "Sélective",
                    infos: "Produit visible et disponible à la commande uniquement pour une sélection parmi vos abonnés.",
                  }
                }
              ]}
            />
          </FormControl>
        </FormField>

        { data.visibility === productsService.VISIBILITY_SCOPED && (
          <>
            <FormField>
              <SelectProfiles
                title="Abonnés"
                label="Ajouter un profil"
                value={data?.scopes?.profiles || []}
                onChange={ (profiles) => onChange({...data, ...{scopes: {...data.scopes, ...{profiles}}}}) }
                onError={onError}
                query={ {id_profile_subscribed_to: id_profile} }
              />
            </FormField>

            <FormField>
              <SelectGroups
                title="Groupes"
                label="Ajouter un groupe"
                value={data?.scopes?.groups || []}
                onChange={ (groups) => onChange({...data, ...{scopes: {...data.scopes, ...{groups}}}}) }
                onError={onError}
                query={ {id_profile: id_profile} }
                canCreate={true}
              />
            </FormField>
          </>
        ) }
      </>
    )
  }
}

const styles = theme => ({
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  radioFormControl: {
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  radioFormControlLabel: {
    marginTop: theme.spacing(1),
    cursor: 'pointer',
  },
});

SetProductVisibility.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.object,
};

SetProductVisibility.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetProductVisibility);
