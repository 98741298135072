import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import profilesService from '../../services/profiles';

import TextFieldLimited from './TextFieldLimited';
import FormField from './FormField';
import { FormControl, FormControlLabel, FormGroup, TextField, Select, MenuItem, InputLabel, Typography, Switch } from '@mui/material';

class SetProfileOptionsBilling extends React.Component {

render () {
  const { classes, value, className } = this.props;
  const classes_form_control = className ? className.form_control : null;

  return (

    <>

      <FormField>
        <FormControl className={clsx(classes_form_control, classes.form_control)}>
          <InputLabel id="automatic_billing">Facturation automatique</InputLabel>
          <Select
            className={classes.select_field}
            labelId="automatic_billing"
            label="Facturation automatique"
            id="automatic_billing"
            value={value?.automatic_billing || 'none'}
            onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{automatic_billing: e.target.value}}) }
          >
            <MenuItem key="1" value="none">Désactivée</MenuItem>
            <MenuItem key="2" value={profilesService.AUTOMATIC_BILLING_ON_ORDER_DONE}>À la finalisation de la commande</MenuItem>
            <MenuItem key="3" value={profilesService.AUTOMATIC_BILLING_AT_END_OF_MONTH}>En fin de mois</MenuItem>
          </Select>
        </FormControl>

        { (!value?.automatic_billing || value?.automatic_billing === 'none') && (
          <Typography variant="body3" component="div" className={classes.infosBillingItem}>
            Vous émettez manuellement vos factures pour chacune de vos commandes finalisées.
          </Typography>
        ) }

        { value?.automatic_billing === profilesService.AUTOMATIC_BILLING_ON_ORDER_DONE && (
          <Typography variant="body3" component="div" className={classes.infosBillingItem}>
            Une facture est émise dès qu'une commande est finalisée.
          </Typography>
        ) }

        { value?.automatic_billing === profilesService.AUTOMATIC_BILLING_AT_END_OF_MONTH && (
          <Typography variant="body3" component="div" className={classes.infosBillingItem}>
            Les factures de vos commandes finalisées sont émises chaque fin de mois.
          </Typography>
        ) }

      </FormField>

      <FormField>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                id="billing_emailing"
                name="billing_emailing"
                checked={ value?.billing_emailing || false }
                onChange={ () => {
                  this.props.onChange && this.props.onChange({...value, ...{billing_emailing: !(value?.billing_emailing || false)}});
                } }
              />
            }
            label="Envoyer vos factures par mail directement depuis Bridger"
          />
        </FormGroup>
      </FormField>

      <FormField>
        <FormControl className={clsx(classes_form_control, classes.form_control)}>
          <InputLabel id="billing_payment_delay">Délai pour le paiement*</InputLabel>
          <Select
            className={classes.select_field}
            labelId="billing_payment_delay"
            label="Délai pour le paiement*"
            id="billing_payment_delay"
            value={value?.billing_payment_delay || ''}
            onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{billing_payment_delay: e.target.value}}) }
          >
            <MenuItem key="1" value={profilesService.BILLING_PAYMENT_DELAY_0}>{profilesService.getBillingPaymentDelayLabel(profilesService.BILLING_PAYMENT_DELAY_0)}</MenuItem>
            <MenuItem key="2" value={profilesService.BILLING_PAYMENT_DELAY_30}>{profilesService.getBillingPaymentDelayLabel(profilesService.BILLING_PAYMENT_DELAY_30)}</MenuItem>
            <MenuItem key="3" value={profilesService.BILLING_PAYMENT_DELAY_45}>{profilesService.getBillingPaymentDelayLabel(profilesService.BILLING_PAYMENT_DELAY_45)}</MenuItem>
            <MenuItem key="4" value={profilesService.BILLING_PAYMENT_DELAY_60}>{profilesService.getBillingPaymentDelayLabel(profilesService.BILLING_PAYMENT_DELAY_60)}</MenuItem>
            <MenuItem key="5" value={profilesService.BILLING_PAYMENT_DELAY_CUSTOM}>{profilesService.getBillingPaymentDelayLabel(profilesService.BILLING_PAYMENT_DELAY_CUSTOM)}</MenuItem>
          </Select>
        </FormControl>
      </FormField>

      { value?.billing_payment_delay === profilesService.BILLING_PAYMENT_DELAY_CUSTOM && (
        <FormField>
          <FormControl className={clsx(classes_form_control, classes.form_control)}>
            <TextField
              id="billing_payment_delay_value"
              name="billing_payment_delay_value"
              label="Délai de paiement (nombre de jours)*"
              type="number"
              className={classes.input}
              value={value?.billing_payment_delay_value || ''}
              onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{billing_payment_delay_value: e.target.value}}) }
            />
          </FormControl>
        </FormField>
      ) }

      <FormField>
        <Typography variant="body2" component="div" className={classes.title}>RIB pour le paiement</Typography>
      </FormField>

      <FormField>
        <FormControl className={clsx(classes_form_control, classes.form_control)}>
          <TextField
            id="iban"
            label="IBAN"
            type="text"
            className={classes.input}
            value={value && value.iban ? value.iban : ''}
            onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{iban: e.target.value}}) }
            helperText="Apparaît comme coordonnée bancaire en bas de vos factures pour le paiement"
          />
        </FormControl>
      </FormField>

      <FormField>
        <FormControl className={clsx(classes_form_control, classes.form_control)}>
          <TextField
            id="bic"
            label="BIC"
            type="text"
            className={classes.input}
            value={value && value.bic ? value.bic : ''}
            onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{bic: e.target.value}}) }
            helperText="Apparaît comme coordonnée bancaire en bas de vos factures pour le paiement"
          />
        </FormControl>
      </FormField>

      <FormField>
        <Typography variant="body2" component="div" className={classes.title}>Mentions légales</Typography>
      </FormField>

      <FormField>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                id="billing_aga_member"
                name="billing_aga_member"
                checked={ value?.billing_aga_member || false }
                onChange={ () => {
                  this.props.onChange && this.props.onChange({...value, ...{billing_aga_member: !(value?.billing_aga_member || false)}});
                } }
              />
            }
            label="Membre d'un centre de gestion ou d'une association agréée"
          />
        </FormGroup>
      </FormField>

      <FormField>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                id="billing_vat_franchise"
                name="billing_vat_franchise"
                checked={ value?.billing_vat_franchise || false }
                onChange={ () => {
                  this.props.onChange && this.props.onChange({...value, ...{billing_vat_franchise: !(value?.billing_vat_franchise || false)}});
                } }
              />
            }
            label="Bénéficiaire de la franchise en base de TVA"
          />
        </FormGroup>
      </FormField>

      <FormField>
        <FormControl className={clsx(classes_form_control, classes.form_control)}>
          <TextFieldLimited
            id="info"
            label="Mentions légales supplémentaires"
            type="text"
            className={classes.textarea}
            value={value && value.info ? value.info : ''}
            onChange={ (info) => this.props.onChange && this.props.onChange({...value, ...{info}}) }
            multiline
            rows={4}
            limit={400}
          />
        </FormControl>
      </FormField>
    </>
  );
}
}

const styles = theme => ({
  content: {},
  form_control: {
    width: '100%',
  },
  textarea: {
    width: '100%',
  },
  title: {
    fontWeight: 600,
  },
  infosBillingItem: {
    margin: theme.spacing(0.5,0,0,0),
    padding: theme.spacing(0,1),
    color: theme.palette.secondary.main,
  }
});

SetProfileOptionsBilling.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SetProfileOptionsBilling);
