import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

class ConfirmDialog extends React.Component {

  render () {
    const { open, message, children, fullWidth, maxWidth } = this.props;
    return (
      <Dialog open={open} onClose={this.props.onClose} aria-labelledby="form-dialog-title" fullWidth={fullWidth} maxWidth={maxWidth || 'xs'}>
        { !this.props.hideTitle && (
          <DialogTitle id="form-dialog-title">{ this.props.title || "Confirmation" }</DialogTitle>
        ) }
        <DialogContent>
          { message && (
            <DialogContentText>
              {message}
            </DialogContentText>
          ) }
          { children }
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel ? this.props.onCancel : this.props.onClose}>
            { this.props.cancelLabel || 'Non' }
          </Button>
          <Button onClick={ () => this.props.onConfirm() } color="primary" variant="contained">
            { this.props.confirmLabel || 'Oui' }
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = theme => ({});

ConfirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  hideTitle: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(ConfirmDialog);
