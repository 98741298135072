import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import groupsService from '../../services/groups';
import groupItemsService from '../../services/group-items';

import BackdropLoading from '../commons/BackdropLoading';
import EditControllers from '../commons/EditControllers';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';
import FormField from '../commons/FormField';
import SelectProfiles from '../commons/SelectProfiles';
import ConfirmDialog from '../commons/ConfirmDialog';
import { DeleteIcon, SaveIcon } from './../commons/Icons';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Forbidden from '../commons/Forbidden';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { isEmpty } from '../../services/utils';

class EditGroup extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      name: '',
      target: groupsService.TARGET_PRO_SUBSCRIBERS,
      profiles: [],
      is_new: true,
      openConfirm_delete: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showLoading() {
    this._isMounted && this.setState({loading: true})
  }

  hideLoading() {
    this._isMounted && this.setState({loading: false})
  }

  load () {
    const { group, preset } = this.props;
    if (typeof group === 'object' && group !== null && group.ID) {
      this._isMounted && this.setState({
        name: group.name || '',
        target: group.target || '',
        is_new: false,
      });
      this.load_items();
    } else if (preset) {
      this._isMounted && this.setState({
        name: preset.name || this.state.name,
        target: preset.target || this.state.target,
        is_new: true,
      });
    }
  }

  load_items () {
    const { group } = this.props;
    if (group && group.ID && isEmpty(group.target)) {
      this.showLoading();
      groupItemsService.getAll({id_group: group.ID}).then(([items, notices]) => {
        notices && notices.length > 0 && this.props.onError(notices);
        if (items && items.length > 0) {
          this._isMounted && this.setState({
            profiles: items.map((item) => item.id_profile)
          });
        }
      }).catch((error) => {
        console.error('error : ', error);
        this.props.onError(error);
      }).finally(() => {
        this.hideLoading();
      });
    }
  }

  validate () {
    let valid = true;
    if (!this.state.name || this.state.name === '') {
      valid = false;
      this.props.onError("Nom manquant");
    }
    return valid;
  }

  save (e) {
    e.preventDefault();
    const { group, appStore } = this.props;

    if (!this.validate()) {
      return false;
    }

    const data = {
      name: this.state.name,
      target: this.state.target,
    }

    this.showLoading();
    if (this.state.is_new) {
      groupsService.create({...data, ...{ id_profile: appStore?.authProfile?.ID }}).then(([id, notices]) => {
        notices && notices.length > 0 && this.props.onError(notices);
        // récupération de l'item créé
        return groupsService.get(id);
      }).then(([group]) => {
        return this.save_items(group);
      }).catch((error) => {
        console.error('error : ', error);
        this.props.onError(error);
      }).finally(() => {
        this.hideLoading();
      });
    } else {
      groupsService.update(group.ID, data).then(([id, notices]) => {
        notices && notices.length > 0 && this.props.onError(notices);
        // récupération de l'item à jour
        return groupsService.get(id);
      }).then(([group]) => {
        return this.save_items(group);
      }).catch((error) => {
        console.error('error : ', error);
        this.props.onError(error);
      }).finally(() => {
        this.hideLoading();
      });
    }
    return false;
  }

  async save_items (group) {
    // NOTE : on n'enregistre les items que si le groupe n'a pas de cible dynamique
    if (isEmpty(group.target)) {
      const group_items = this.state.profiles ? this.state.profiles.map((profile) => { 
        return { id_profile: (profile && profile.ID ? profile.ID : profile) };
      }) : [];
      return groupItemsService.set(group.ID, group_items).then(([ids]) => {
        // onfixe le nombre de group_items (ça évite de refaire un appel API, cette information n'ayant pas besoin d'une forte intégrité)
        this.props.onSaved({...group, ...{nb_group_items: ids?.length || 0}}, this.state.is_new);
      });
    } else {
      this.props.onSaved(group, this.state.is_new);
    }
  }

  confirm_delete () {
    this._isMounted && this.setState({openConfirm_delete: true})
  }

  delete () {
    const { group } = this.props;
    this._isMounted && this.setState({loading: true});
    groupsService.delete(group.ID).then(([deleted, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      this.props.onDeleted && this.props.onDeleted(group.ID);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
  }

  render () {
    const { classes, appStore } = this.props;

    if (!appStore?.authProfile?.ID) {
      return <Forbidden />;
    }

    return (
      <Box className={classes.container}>
        <BackdropLoading open={this.state.loading} />

        { this.state.openConfirm_delete && (
          <ConfirmDialog
            open={this.state.openConfirm_delete}
            title="Supprimer un groupe"
            message={"Voulez-vous vraiment supprimer le groupe '"+this.state.name+"' ? Cette action est irréversible."}
            onConfirm={ () => {
              this._isMounted && this.setState({openConfirm_delete: false});
              this.delete();
            } }
            onClose={ () => this._isMounted && this.setState({openConfirm_delete: false}) }
          />
        ) }

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields className={classes.formFields}>

            <FormField>
              <FormControl className={classes.form_control}>
                <TextField
                  required
                  id="name"
                  name="group-name"
                  label="Nom du groupe"
                  type="text"
                  className={classes.input}
                  value={this.state.name}
                  onChange={(e) => this._isMounted && this.setState({name: e.target.value})}
                />
              </FormControl>
            </FormField>

            <FormField>
              <FormControl className={classes.form_control}>
                <FormLabel>Profils ciblés</FormLabel>
                <RadioGroup
                  name="target"
                  id="target"
                  value={this.state.target || ''}
                  onChange={(e) => this._isMounted && this.setState({target: e.target.value})}
                >
                  <FormControlLabel value={groupsService.TARGET_PRO_SUBSCRIBERS} control={<Radio />} label="Mes abonnés professionnels" />
                  <FormControlLabel value={groupsService.TARGET_PART_SUBSCRIBERS} control={<Radio />} label="Mes abonnés particuliers" />
                  <FormControlLabel value="" control={<Radio />} label="Sélection parmi mes abonnés" />
                </RadioGroup>
              </FormControl>
            </FormField>

            { isEmpty(this.state.target) && (
              <FormField>
                <SelectProfiles
                  title="Profils sélectionnés"
                  label="Ajouter"
                  query={{id_profile_subscribed_to: appStore?.authProfile?.ID}}
                  value={this.state.profiles}
                  onChange={(profiles) => this._isMounted && this.setState({profiles})}
                  onError={this.props.onError}
                />
              </FormField>
            ) }

          </FormFields>

          <EditControllers sticky>
            { !this.state.is_new && (
              <Button type="button" onClick={ (e) => this.confirm_delete(e) } color="primary" variant="outlined" className={classes.delete} startIcon={<DeleteIcon />}>
                Supprimer
              </Button>
            ) }
            <Button type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={<SaveIcon />}>
              Enregistrer
            </Button>
          </EditControllers>

        </Form>

      </Box>
    )
  }

}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
  form_control: {
    width: '100%',
  },
  form_control_select: {
  },
  submit: {
    width: '100%',
  },
  delete: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  info_text: {
    margin: theme.spacing(0, 0, 2, 0),
  }
});

EditGroup.propTypes = {
  onSaved: PropTypes.func.isRequired,
  onDeleted: PropTypes.func,
  onError: PropTypes.func.isRequired,
  id_profile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  group: PropTypes.object,
  preset: PropTypes.object,
};

const mapStateToProps = state => ({
	appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditGroup));
