import React from 'react';
import clsx from 'clsx';
import Icon from '@mui/material/Icon';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

export function AlertPlainIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-alert-plain', props.className)}} } /> }
export function AlertIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-alert', props.className)}} } /> }
export function ArchiveIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-archive', props.className)}} } /> }
export function ArrowBottomIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-arrow-bottom', props.className)}} } /> }
export function ArrowLeftIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-arrow-left', props.className)}} } /> }
export function ArrowRightIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-arrow-right', props.className)}} } /> }
export function ArrowTopIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-arrow-top', props.className)}} } /> }
export function ArrowDropDownIcon (props) { return <ArrowDropDown {...props} /> }
export function ArrowDropUpIcon (props) { return <ArrowDropUp {...props} /> }
export function AttachIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-attach', props.className)}} } /> }
export function BagIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-bag', props.className)}} } /> }
export function BellIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-bell', props.className)}} } /> }
export function BillIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-bill', props.className)}} } /> }
export function BoxIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-box', props.className)}} } /> }
export function BridgerIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-bridger', 'icon-large', props.className)}} } /> }
export function CategoryIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-category', props.className)}} } /> }
export function ChartIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-chart', props.className)}} } /> }
export function CheckIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-check-1', props.className)}} } /> }
export function Check2Icon (props) { return <Icon { ...{...props, ...{className: clsx('icon-check-2', props.className)}} } /> }
export function Check3Icon (props) { return <Icon { ...{...props, ...{className: clsx('icon-check-3', props.className)}} } /> }
export function CheckPlainIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-check-plain', props.className)}} } /> }
export function ChevronBottomIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-chevron-bottom', props.className)}} } /> }
export function ChevronLeftIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-chevron-left', props.className)}} } /> }
export function ChevronRightIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-chevron-right', props.className)}} } /> }
export function ChevronTopIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-chevron-top', props.className)}} } /> }
export function CloseIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-close', props.className)}} } /> }
export function CoffeeIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-coffee', props.className)}} } /> }
export function CollectBagIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-collect-bag', props.className)}} } /> }
export function CollectBoxIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-collect-box', props.className)}} } /> }
export function ContentCopyIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-content-copy', props.className)}} } /> }
export function CreditCardIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-credit-card', props.className)}} } /> }
export function CustomerIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-customer', props.className)}} } /> }
export function DeletePlainIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-delete-plain', props.className)}} } /> }
export function DeleteIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-delete', props.className)}} } /> }
export function DownloadIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-download', props.className)}} } /> }
export function EditIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-edit', props.className)}} } /> }
export function EuroIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-euro', props.className)}} } /> }
export function EyeHideIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-eye-hide', props.className)}} } /> }
export function EyeIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-eye', props.className)}} } /> }
export function FiltersX2Icon (props) { return <Icon { ...{...props, ...{className: clsx('icon-filters-x2', props.className)}} } /> }
export function FiltersIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-filters-x3', props.className)}} } /> }
export function FolderDeleteIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-folder-delete', props.className)}} } /> }
export function FolderIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-folder', props.className)}} } /> }
export function HelpIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-help', props.className)}} } /> }
export function HomeGearIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-home-gears', props.className)}} } /> }
export function HomeShopIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-home-shop', props.className)}} } /> }
export function HomeOrdersIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-home-orders', props.className)}} } /> }
export function HomeIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-home', props.className)}} } /> }
export function InboxIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-inbox', props.className)}} } /> }
export function InfoPlainIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-info-plain', props.className)}} } /> }
export function InfoIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-info', props.className)}} } /> }
export function LibraryIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-library', props.className)}} } /> }
export function LocationIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-location', props.className)}} } /> }
export function LockIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-lock', props.className)}} } /> }
export function LoginIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-login', props.className)}} } /> }
export function LogoutIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-logout', props.className)}} } /> }
export function MailIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-mail', props.className)}} } /> }
export function MinusIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-minus', props.className)}} } /> }
export function MoreHoriIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-more-horizontal', props.className)}} } /> }
export function MoreVertIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-more-vertical', props.className)}} } /> }
export function OrderIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-order', props.className)}} } /> }
export function OrderInIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-order-in', props.className)}} } /> }
export function OrderOutIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-order-out', props.className)}} } /> }
export function OrderProgressIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-order-progress', props.className)}} } /> }
export function OrderRecurringIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-order-recurring', props.className)}} } /> }
export function PaperPlaneIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-paper-plane', props.className)}} } /> }
export function PDFIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-pdf', props.className)}} } /> }
export function PencilIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-pencil', props.className)}} } /> }
export function PhoneIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-phone', props.className)}} } /> }
export function PictureIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-picture', props.className)}} } /> }
export function PlusIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-plus', props.className)}} } /> }
export function PrintIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-printer', props.className)}} } /> }
export function RefreshIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-refresh-1', props.className)}} } /> }
export function RenewIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-refresh-2', props.className)}} } /> }
export function SaveIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-save', props.className)}} } /> }
export function SearchIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-search', props.className)}} } /> }
export function SettingsIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-settings', props.className)}} } /> }
export function ShareIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-share', props.className)}} } /> }
export function ShipIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-ship', props.className)}} } /> }
export function ShippingIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-shipping', props.className)}} } /> }
export function StarIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-star', props.className)}} } /> }
export function StarPlainIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-star-plain', props.className)}} } /> }
export function StoreIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-store', props.className)}} } /> }
export function SwapIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-swap', props.className)}} } /> }
export function TagIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-tag', props.className)}} } /> }
export function TrafficConeIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-traffic-cone', props.className)}} } /> }
export function TrashIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-trash', props.className)}} } /> }
export function UnavailableIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-unavailable', props.className)}} } /> }
export function UserIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-user', props.className)}} } /> }
export function UserAddIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-user-add', props.className)}} } /> }
export function UserDeleteIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-user-delete', props.className)}} } /> }
export function UsersIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-users', props.className)}} } /> }
export function WorldIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-world', props.className)}} } /> }
export function WoodkitIcon (props) { return <Icon { ...{...props, ...{className: clsx('icon-woodkit', props.className)}} } /> }
