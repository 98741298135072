import React from 'react';
import { connect } from 'react-redux';
import { setNotificationsCount } from './../../services/store/features/appSlice';
import notificationsService from './../../services/notifications';

/**
 * Manage in-app notifications - call API to get notification count
 */
class AppNotificationsController extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.firstLoad = true;
    this.surveyInterval = null;
  }

  componentDidMount () {
    this._isMounted = true;
    this.startSurvey();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.stopSurvey();
  }

  startSurvey () {
    this.load();
    this.surveyInterval = setInterval(() => {
      this.load();
    }, process.env.REACT_APP_NOTIFICATIONS_STATEMENT_INTERVAL || 15000);
  }

  stopSurvey () {
    if (this.surveyInterval) {
      clearInterval(this.surveyInterval);
    }
  }

  load () {
    const { appStore } = this.props;
    if (appStore?.auth?.id_user && appStore?.authProfile?.ID) {
      this._isMounted && this.firstLoad && this.props.setNotificationsCount('loading');
      notificationsService.count({
        state: notificationsService.STATE_UNREAD,
        id_user: appStore?.auth?.id_user,
        id_profile: appStore?.authProfile?.ID,
      }).then(([count]) => {
        this.props.setNotificationsCount(count);
      }).catch((e) => {
        this.props.setNotificationsCount('error');
      }).finally(() => {
        if (this._isMounted) {
          this.firstLoad = false;
        }
      });
    }
  }

  render () {
    return (
      <React.Fragment>
        {this.props.children}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  appStore: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setNotificationsCount: (notificationsCount) => dispatch(setNotificationsCount(notificationsCount)),
  }
};

export default (connect(mapStateToProps, mapDispatchToProps)(AppNotificationsController));
