import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import orderMessagesService from '../../services/order-messages';
import utilsService from '../../services/utils';
import { isEmpty } from '../../services/utils';

import TextFieldLimited from '../commons/TextFieldLimited';
import EditControllers from '../commons/EditControllers';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';
import FormField from '../commons/FormField';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import { CircularProgress } from '@mui/material';

class OrderMessages extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      message: "", // nouveau message
      messages: [],
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.id_order) !== JSON.stringify(prevProps.id_order)){
      this.load();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
    const { id_order, onError } = this.props;
    this._isMounted && this.setState({ loading: true });
    orderMessagesService.getAll({id_order}).then(([messages]) => {
      this._isMounted && this.setState({messages});
    }).catch((e) => {
      onError && onError(e)
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  create (data) {
    const { id_order, onError } = this.props;
    const { message } = this.state;
    if (isEmpty(message)) {
      onError && onError("Message vide");
      return;
    }
    this._isMounted && this.setState({ loading: true });
    orderMessagesService.create(id_order, {message}).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      this._isMounted && this.setState({ message: '' });
    }).catch((error) => {
      onError && onError(error);
    }).finally(() => {
      this._isMounted && this.setState({ loading: false, openEdit_message: false });
      this.load();
    });
  }

  render () {
    const { classes, className } = this.props;
    const { loading } = this.state;

    return (
      <Box className={clsx(classes.root, className && className.root)}>
      
        <Typography className={classes.title} variant="h5" component="div">Notes</Typography>
        
        { loading &&  (
          <Box className={classes.loading}>
            <CircularProgress color="secondary" />
          </Box>
        )}

        { !loading && this.render_content() }

      </Box>
    );
  }

  render_content () {
    const { classes, permissions, appStore } = this.props;
    const { message, messages } = this.state;

    return (
      <>

        { messages && messages.length > 0 && messages.map((message, index) => {
          const is_owner = parseInt(appStore?.authProfile?.ID) === parseInt(message.id_profile);
          return (
            <Box key={index} className={clsx(classes.message, { [classes.message_owner]: is_owner})}>
              <Box className={clsx(classes.message_content, { [classes.message_content_owner]: is_owner})}>
                <Typography variant="body2" component="div" className={classes.message_text}>{ message?.message }</Typography>
                <Box className={clsx(classes.message_infos, { [classes.message_infos_owner]: is_owner})}>
                  <Typography variant="body3" component="div">{ message?.profile?.name }</Typography>
                  <Typography variant="body3" component="div">{ utilsService.dateStringToLocalString(message?.date_create, true) }</Typography>
                </Box>
              </Box>
            </Box>
          )
        }) }

        { permissions.can_create_message && (
          <Form onSubmit={ (e) => this.create(e) } noValidate>
            <FormFields className={classes.form_fields}>
              <FormField>
                <FormControl className={classes.form_control}>
                  <TextFieldLimited
                    className={classes.text_field}
                    value={message || ''}
                    onChange={ (message) => this._isMounted && this.setState({ message }) }
                    multiline
                    rows={4}
                  />
                </FormControl>
              </FormField>
              <EditControllers>
                <Button disabled={isEmpty(message)} className={classes.btn} onClick={ (e) => this.create(e) } color="primary" variant="outlined">Enregistrer la note</Button>
              </EditControllers>
            </FormFields>
          </Form>
        ) }
      </>
    );
  }
}

const styles = theme => ({
  root: {},
  loading: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  message: {
    width: '100%',
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  message_owner:{
    justifyContent: 'flex-start',
  },
  message_content: {
    maxWidth: '80%',
    border: `1px solid ${theme.palette.bg.light}`,
    padding: theme.spacing(1, 1, 0.5, 1),
    backgroundColor: '#fff',
    borderRadius: '3px',
  },
  message_content_owner: {},
  message_infos: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.secondary.main,
    textAlign: 'right',
  },
  message_infos_owner: {
    textAlign: 'left',
  },
  form_fields: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  form_control: {
    width: '100%',
  },
  text_field: {
    width: '100%',
  },
  create_btn: {
    width: '100%',
  }
});

OrderMessages.propTypes = {
  id_order: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onError: PropTypes.func.isRequired,
  permissions: PropTypes.object,
};

const mapStateToProps = state => ({
  appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(OrderMessages));
