import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import ConfirmDialog from './../commons/ConfirmDialog';

import TextField from '@mui/material/TextField';

/***********************************************************************************************
 Ce composant gère la quantité d'un produit manuellement via une popup
************************************************************************************************/

class SetOrderItemQtyInputPopup extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      inputValue: null,
    };
    this.input_ref = React.createRef();
  }

  componentDidMount () {
    this._isMounted = true;
    // this.setState({inputValue: cloneDeep(this.props.value)}); // on initialise l'inputValue avec la valeur passée en props
    // this.input_ref.current.focus();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { classes, title, value, startAdornment, endAdornment } = this.props;
    const { inputValue } = this.state;
    return (
      <ConfirmDialog
        title={title || "Quantité"}
        cancelLabel="Annuler"
        confirmLabel="Valider"
        open={true}
        onConfirm={ () => this.props.onChange(this.state.inputValue) }
        onClose={ () => this.props.onCancel() }
      >
        <TextField
          autoFocus
          onFocus={ (e) => e.target.select()}
          inputRef={this.input_ref}
          size="small"
          className={classes.input}
          type="number"
          value={ inputValue || value }
          InputProps={{ startAdornment, endAdornment, inputProps: { min: 0 } }}
          onChange={ (e) => this._isMounted && this.setState({inputValue: e.target.value}) }
          onKeyDown={ (e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              this.props.onChange(this.state.inputValue);
            }
          } }
        />
      </ConfirmDialog>
    );
  }
}

const styles = theme => ({
  input: {
    width: 180,
    padding: 6,
  },
});

SetOrderItemQtyInputPopup.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  prefix: PropTypes.any,
};

export default withStyles(styles, { withTheme: true })(SetOrderItemQtyInputPopup);
