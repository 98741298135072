import React from 'react';
import PropTypes from 'prop-types';
import { withHOCComponent } from '../hoc/HOCComponent';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import utilsService from '../../services/utils';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

class OrderPrice extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { classes, className, order } = this.props;
    if (!order || !order.price) {
      return null;
    }
    const delivery_price = order?.price?.delivery_price;
    const total_price = order?.price?.total_price;
    const total_price_tax = order?.price?.total_price_tax;
    const total_price_ttc = order?.price?.total_price_ttc;
    const estimated_price = order?.price?.estimated_price;
    return (
      <Box className={clsx(classes.root, className && className.root)}>

        <Box className={classes.header}>
          <Typography className={classes.title} component="div" variant="h5">Montant{order.estimated_price ? ' (estimation)' : ''}</Typography>
        </Box>

        <Box className={classes.item}>
          <Typography variant="body3" component="div"  className={classes.label}>Frais de livraison HT</Typography>
          { parseFloat(delivery_price) > 0 && (
            <Typography variant="body3" component="div"  className={classes.value}>{utilsService.prettyPrice(delivery_price, '€', '')}</Typography>
          ) }
          { parseFloat(delivery_price) === 0 && (
            <Typography variant="body3" component="div"  className={classes.value}>gratuit</Typography>
          ) }
        </Box>
        
        <Box className={classes.item}>
          <Typography variant="body3" component="div" className={classes.label}>Total HT</Typography>
          <Typography variant="body3" component="div" className={classes.value}>{utilsService.prettyPrice(total_price, '€', '')}</Typography>
        </Box>

        <Box className={classes.item}>
          <Typography variant="body3" component="div" className={classes.label}>Total TVA</Typography>
          <Typography variant="body3" component="div" className={classes.value}>{utilsService.prettyPrice(total_price_tax, '€', '')}</Typography>
        </Box>

        <Box className={classes.item_ttc}>
          <Typography variant="body2" component="div" className={classes.label}>Total TTC</Typography>
          <Typography variant="body2" component="div" className={classes.value}>{utilsService.prettyPrice(total_price_ttc, '€', '')}</Typography>
        </Box>

        {estimated_price && (
          <Box className={classes.item_estimated}>
            <Typography variant="body3" component="div" className={classes.estimated}>
              IMPORTANT : La quantité et le prix des produits à poids variable (produits proposés au vrac ou à la portion), sont <u>estimés</u> tant que la commande n'a pas été préparée.
							Le fournisseur s’engage à ce que le volume net se rapproche au maximum de la quantité commandée.
							Pour en savoir plus, veuillez consulter les conditions générales de vente du fournisseur.
            </Typography>
          </Box>
        )}

      </Box>
    );
  }
}

const styles = theme => ({
  root: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  title: {
    fontWeight: 600,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.2, 0, 0, 0),
    margin: theme.spacing(0.2, 0, 0, 0),
  },
  item_ttc: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 0, 0),
    margin: theme.spacing(1, 0, 0, 0),
    borderTop: `1px solid ${theme.palette.tertiary.main}`,
  },
  item_estimated: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0, 0, 0),
    margin: theme.spacing(1, 0, 0, 0),
    borderTop: `1px solid ${theme.palette.tertiary.main}`,
  },
  value: {
    fontWeight: 600,
  },
});

OrderPrice.propTypes = {
  order: PropTypes.object.isRequired,
};

export default withHOCComponent(withStyles(styles, { withTheme: true })(OrderPrice));
