import React, {Suspense, lazy} from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import routes from './../services/routes';
import { Switch, Route, withRouter } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { setOpenAuthDialog } from '../services/store/features/appSlice';

import BackdropLoading from './../components/commons/BackdropLoading';
import AppShortActionsController from './../components/main/AppShortActionsController';
import MaintenanceDialog from './../components/commons/MaintenanceDialog';
import { UnavailableIcon } from './../components/commons/Icons';

import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppPresentationController from '../components/main/AppPresentationController';
import { isEmpty } from '../services/utils';
import DialogAuth from '../components/dialog/DialogAuth';

const AuthView = lazy(() => import('./Auth'));

class Main extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  appEventNavigateTo = (event) => {
    const { history } = this.props;
    let route = event?.detail;
    if (history && !isEmpty(route)) {
      // si on nous envoi l'URL complète, on retire la base..
      if (route.startsWith(process.env.REACT_APP_MAIN_BASE_URL)) {
        route = route.replace(process.env.REACT_APP_MAIN_BASE_URL, "");
        if (!route.startsWith("/")) {
          route = "/" + route; // important to navigate from root location
        }
        history.push(route);
      } else {
        console.warn("Évènement demandant à naviguer vers une URL externe à l'application : ", route);
      }
    }
  }

  componentDidMount () {
    window.addEventListener("app-navigate-to", this.appEventNavigateTo);
  }

  componentWillUnmount () {
    window.removeEventListener("app-navigate-to", this.appEventNavigateTo);
  }

  render () {
    const { classes, appStore } = this.props;
    const backdrop_offline_opened = !this.props.appStore.online;
    const userLoggedInProfile = appStore.authProfile;
    const openAuthDialog = appStore.openAuthDialog;

    return (

        <SnackbarProvider maxSnack={5} classes={{
          variantSuccess: classes.snackbar_success,
          variantError: classes.snackbar_error,
          variantWarning: classes.snackbar_warning,
          variantInfo: classes.snackbar_info,
        }}>

          <main className={classes.root}>

            { /*---- Api maintenance mode ---------------------------------------------*/ }
            <MaintenanceDialog />

            { /*---- App OffLine ------------------------------------------------------*/ }
            <Backdrop className={classes.backdrop} open={backdrop_offline_opened}>
              <Box className={classes.backdropContent}>
                <UnavailableIcon color="inherit" />
                <Box>
                  <Typography>pas de connexion</Typography>
                </Box>
              </Box>
            </Backdrop>

            { /*---- App Short Actions Controller -------------------------------------*/ }
            <AppShortActionsController />

            { /*---- App Presentation Tour Controller ---------------------------------*/ }
            <AppPresentationController />

            { /*---- App Authentication Dialog ----------------------------------------*/ }
            <DialogAuth
              open={!userLoggedInProfile && openAuthDialog}
              onClose={() => this.props.setOpenAuthDialog && this.props.setOpenAuthDialog(false)}
              onAuthenticated={() => this.props.setOpenAuthDialog && this.props.setOpenAuthDialog(false)}
            />

            <Suspense fallback={<BackdropLoading open={true} />}>
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={() => {
                      if (route.loggedIn === true && !userLoggedInProfile) {
                        return (
                          <AuthView />
                        )
                      }
                      return (
                        <>
                          {route.component}
                        </>
                      )
                    }}
                  />
                ))}
              </Switch>
            </Suspense>
          </main>

        </SnackbarProvider>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
  backdropContent: {
    textAlign: 'center',
  },
  authMessageBox:{
    margin: theme.spacing(2, 0),
    textAlign: 'center',
  },
});

const mapStateToProps = state => ({
	appStore: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setOpenAuthDialog: (value) => dispatch(setOpenAuthDialog(value)),
  }
};

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Main)));
