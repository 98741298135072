import React, {lazy} from 'react';
const Home = lazy(() => import('./../views/Home'));
const Support = lazy(() => import('./../views/Support'));
const Profile = lazy(() => import('./../views/Profile'));
const AuthProfileSettings = lazy(() => import('../views/AuthProfileSettings'));
const AuthProfilePremium = lazy(() => import('../views/AuthProfilePremium'));
const AuthProfileAttachments = lazy(() => import('../views/AuthProfileAttachments'));
const AuthProfileUserRels = lazy(() => import('./../views/AuthProfileUserRels'));
const AuthProfileUserRel = lazy(() => import('./../views/AuthProfileUserRel'));
const AuthProfileRelsPending = lazy(() => import('../views/AuthProfileRelsPending'));
const AuthProfileCatalog = lazy(() => import('../views/AuthProfileCatalog'));
const AuthProfileOrdersTableDraftProvider = lazy(() => import('../views/AuthProfileOrdersTableDraftProvider'));
const AuthProfileOrdersTableRecurrent = lazy(() => import('../views/AuthProfileOrdersTableRecurrent'));
const AuthProfileOrdersTable = lazy(() => import('../views/AuthProfileOrdersTable'));
const AuthProfileOrdersTableBin = lazy(() => import('../views/AuthProfileOrdersTableBin'));
const AuthProfileOrdersTableInvoices = lazy(() => import('../views/AuthProfileOrdersTableInvoices'));
const AuthProfileProduction = lazy(() => import('../views/AuthProfileProduction'));
const AuthProfileProductionBin = lazy(() => import('../views/AuthProfileProductionBin'));
const AuthProfileProductionInvoices = lazy(() => import('../views/AuthProfileProductionInvoices'));
const AuthProfileNotifications = lazy(() => import('../views/AuthProfileNotifications'));
const AuthProfileDeliveryModes  = lazy(() => import('../views/AuthProfileDeliveryModes'));
const AuthProfileContacts  = lazy(() => import('../views/AuthProfileContacts'));
const ProfileRelsSubscribers = lazy(() => import('../views/ProfileRelsSubscribers'));
const ProfileRelsSubscriptions = lazy(() => import('../views/ProfileRelsSubscriptions'));
const Order = lazy(() => import('./../views/Order'));
const RenewPassword = lazy(() => import('./../views/RenewPassword'));
const InvitationAccept = lazy(() => import('./../views/InvitationAccept'));
const UserActivation = lazy(() => import('./../views/UserActivation'));
const Network = lazy(() => import('./../views/Network'));
const SalesSpace = lazy(() => import('../views/SalesSpace'));
const Search = lazy(() => import('./../views/Search'));
const NotFound = lazy(() => import('./../views/NotFound'));

const routes = [
  // Home
  {
    key: 'home',
    path: "/",
    exact: true,
    component: <Home />,
    loggedIn: true,
  },
  // Search
  {
    key: 'search',
    path: "/search",
    exact: true,
    component: <Search />,
    loggedIn: true,
  },
  // Sales Space
  {
    key: 'sales-space',
    path: "/sales-space",
    exact: true,
    component: <SalesSpace />,
    loggedIn: true,
  },
  // Network
  {
    key: 'network',
    path: "/network",
    exact: true,
    component: <Network />,
  },
  // Support
  {
    key: 'support',
    path: "/support",
    exact: true,
    component: <Support />,
  },
  // Auth Profile
  {
    key: 'auth-profile-settings',
    path: "/profile/settings",
    component: <AuthProfileSettings />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-premium',
    path: "/profile/premium",
    component: <AuthProfilePremium />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-attachments',
    path: "/profile/attachments",
    component: <AuthProfileAttachments />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-user-rel',
    path: "/profile/user-rels/:id",
    component: <AuthProfileUserRel />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-user-rels',
    path: "/profile/user-rels",
    component: <AuthProfileUserRels />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-rels-pending',
    path: "/profile/rels/pending",
    component: <AuthProfileRelsPending />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-catalog',
    path: "/profile/catalog",
    component: <AuthProfileCatalog />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-orders-draft-provider',
    path: "/profile/orders/draft/provider",
    component: <AuthProfileOrdersTableDraftProvider />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-orders-recurrent',
    path: "/profile/orders/reccurent",
    component: <AuthProfileOrdersTableRecurrent />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-orders-bin',
    path: "/profile/orders/bin",
    component: <AuthProfileOrdersTableBin />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-orders-invoices',
    path: "/profile/orders/invoices",
    component: <AuthProfileOrdersTableInvoices />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-orders',
    path: "/profile/orders",
    component: <AuthProfileOrdersTable />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-production-bin',
    path: "/profile/production/bin",
    component: <AuthProfileProductionBin />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-production-invoices',
    path: "/profile/production/invoices",
    component: <AuthProfileProductionInvoices />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-production',
    path: "/profile/production",
    component: <AuthProfileProduction />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-notifications',
    path: "/profile/notifications",
    component: <AuthProfileNotifications />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-delivery-modes',
    path: "/profile/delivery-modes",
    component: <AuthProfileDeliveryModes />,
    loggedIn: true,
  },
  {
    key: 'auth-profile-contacts',
    path: "/profile/contacts",
    component: <AuthProfileContacts />,
    loggedIn: true,
  },
  // Profile
  {
    key: 'profile-rels-subscribers',
    path: "/profiles/:id/rels/subscribers",
    component: <ProfileRelsSubscribers />,
    loggedIn: true,
  },
  {
    key: 'profile-rels-subscriptions',
    path: "/profiles/:id/rels/subscriptions",
    component: <ProfileRelsSubscriptions />,
    loggedIn: true,
  },
  {
    key: 'profile',
    path: "/profiles/:id",
    component: <Profile />,
  },
  {
    key: 'profile',
    path: "/profile/:id", // backcompat avec les anciennes URLs présentes dans les mails de Bridger => à supprimer à la prochaine version > 1.6.0
    component: <Profile />,
  },
  // User Activation
  {
    key: 'user-activation',
    path: "/user-activation/:token",
    component: <UserActivation />,
  },
  // Invitations
  {
    key: 'invitation-accept',
    path: "/invitation-accept/:token",
    component: <InvitationAccept />,
  },
  // Orders
  {
    key: 'order',
    path: "/orders/:id",
    component: <Order />,
    loggedIn: true,
  },
  {
    key: 'order',
    path: "/order/:id", // backcompat avec les anciennes URLs présentes dans les mails de Bridger => à supprimer à la prochaine version > 1.6.0
    component: <Order />,
    loggedIn: true,
  },
  // Renew password
  {
    key: 'renew-password',
    path: "/renew-password",
    component: <RenewPassword />,
  },
  // 404
  {
    key: 'not-found',
    path: "*",
    component: <NotFound />,
  }
];
export default routes;
