import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { withHOCComponent } from '../hoc/HOCComponent';
import invitationsService from '../../services/invitations';
import profileUserRelsService from '../../services/profile-user-rels';
import clsx from 'clsx';

import BackdropLoading from '../commons/BackdropLoading';
import EditControllers from '../commons/EditControllers';
import Forbidden from '../commons/Forbidden';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';
import FormField from '../commons/FormField';
import TextFieldLimited from '../commons/TextFieldLimited';
import { PaperPlaneIcon, InfoIcon } from '../commons/Icons';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class EditInvitationCollaborate extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      email: '',
      role: profileUserRelsService.ROLE_MANAGER,
      message: '',
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  invite (e) {
    e.preventDefault();
    if (!this.state.email || this.state.email === '') {
      this.props.hoc.showError("Email manquant");
      return;
    }
    if (!this.state.role || this.state.role === '') {
      this.props.hoc.showError("Rôle manquant");
      return;
    }
    const data = {
      email: this.state.email,
      type: invitationsService.TYPE_COLLABORATE,
      role: this.state.role,
      message: this.state.message,
    }
    this._isMounted && this.setState({loading: true});
    invitationsService.create(data).then(() => {
      this.props.hoc.showSuccess("Invitation envoyée");
      this.props.onSaved && this.props.onSaved(data);
    }).catch((e) => {
      this.props.onError && this.props.onError(e);
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
    return false;
  }

  render () {
    const { classes, appStore } = this.props;
    if (!appStore?.auth?.id_user || !appStore?.authProfile?.ID) {
      return <Forbidden />;
    }

    return (
      <Box className={classes.container}>
        <BackdropLoading open={this.state.loading} />

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields className={classes.formFields}>

            <FormField>
              <FormControl className={clsx(classes.form_control, classes.form_control_email)}>
                <TextField
                  required
                  type="email"
                  className={classes.text_field}
                  label="Email"
                  value={this.state.email}
                  id="email"
                  onChange={ (e) => this._isMounted && this.setState({ email: e.target.value }) }
                />
              </FormControl>
            </FormField>

            <FormField>
              <FormControl className={clsx(classes.form_control, classes.form_control_role)}>
                <InputLabel id="select-role-label">Rôle</InputLabel>
                <Select
                  required
                  labelId="select-role-label"
                  label="Rôle"
                  className={classes.select_field}
                  id="role"
                  value={this.state.role}
                  onChange={ (e) => this._isMounted && this.setState({ role: e.target.value }) }
                >
                  <MenuItem value={profileUserRelsService.ROLE_MANAGER}>{profileUserRelsService.getRoleLabel(profileUserRelsService.ROLE_MANAGER)}</MenuItem>
                </Select>
              </FormControl>
            </FormField>

            <FormField>
              <FormControl className={clsx(classes.form_control, classes.form_control_email)}>
                <TextFieldLimited
                  type="message"
                  className={classes.text_field}
                  label="Message"
                  value={this.state.message}
                  onChange={ (message) => this._isMounted && this.setState({ message }) }
                  multiline
                  rows={4}
                  limit={200}
                />
              </FormControl>
              <FormHelperText>Ce message sera intégré au mail d'invitation, il est optionel.</FormHelperText>
            </FormField>

            <FormField>
              <Box className={classes.actions}>
                <Box className={classes.actions_info}>
                  <Box className={classes.actions_info_icon}>
                    <InfoIcon color="primary" />
                  </Box>
                  <Typography className={classes.actions_info_text} component="p">Un email va être envoyé à l'utilisateur l'invitant a devenir <strong>{ profileUserRelsService.getRoleLabel(this.state.role) }</strong> de ce profil.</Typography>
                </Box>
              </Box>
            </FormField>

          </FormFields>

          <EditControllers sticky>
            <Button type="submit" className={classes.submit} onClick={ (e) => this.invite(e) } color="primary" variant="contained" startIcon={<PaperPlaneIcon />}>
              Envoyer l'invitation
            </Button>
          </EditControllers>

        </Form>
      </Box>
    )
  }
}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  formFields: {
    padding: theme.spacing(0, 2),
  },
  form_control: {
    width: '100%',
  },
  text_field: {
    width: '100%'
  },
  select_field: {
    width: '100%'
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  actions_info: {
    display: 'flex',
  },
  actions_info_icon: {
    marginRight: theme.spacing(1),
  },
  submit:{
    width: '100%',
  }
});

EditInvitationCollaborate.propTypes = {
  onSaved: PropTypes.func,
  onError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	appStore: state.app,
});

export default withHOCComponent(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditInvitationCollaborate)));
