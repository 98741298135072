import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import imageLogo from './../../static/img/logo.svg';
import Box from '@mui/material/Box';

class ViewContentLogo extends React.Component {

  render () {
    const { classes } = this.props;
    return (
      <Box className={classes.logo}>
        <img src={imageLogo} alt="bridger store" className={classes.imageLogo} />
      </Box>
    )
  }
}

const styles = theme => ({
  logo: {
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageLogo: {
    width: 120
  },
});

ViewContentLogo.propTypes = {
  classesContent: PropTypes.string,
  displayLogo: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(ViewContentLogo);
