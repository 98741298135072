import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import ListItem from '@mui/material/ListItem';

class ListItemComponent extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes, selected, onSelect, disableGutters, children } = this.props;

    return (
      <ListItem disableGutters={disableGutters} className={ clsx(classes.content, {
          [classes.clickable]: onSelect,
          [classes.selected]: selected,
        }) } onClick={onSelect}>
        { children }
      </ListItem>
    )
  }
}

const styles = theme => ({
  content: {
    flexDirection: 'column',
    alignItems: 'normal',
    backgroundColor: '#ffffff',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  clickable: {
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: '#f9f9f9',
    borderLeft: `3px solid ${theme.palette.primary.main}`,
  },
});

ListItemComponent.propTypes = {
  disableGutters: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
};

ListItemComponent.defaultProps = {
  selected: false,
  disableGutters: true,
};

export default withStyles(styles, { withTheme: true })(ListItemComponent);
