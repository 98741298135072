import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import productsService from '../../services/products';

import BackdropLoading from '../commons/BackdropLoading';
import EditControllers from '../commons/EditControllers';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';
import { SaveIcon } from '../commons/Icons';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormField from '../commons/FormField';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';

class EditProductStock extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      data: null,
    }
  }

  componentDidMount () {
    this._isMounted = true;
    const { product } = this.props;
    if (product) {
      // le produit est passé en props
      this._isMounted && this.setState({data: {
        state: product?.state || '',
        sku: product?.sku || '',
      }});
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showLoading() {
    this._isMounted && this.setState({loading: true})
  }

  hideLoading() {
    this._isMounted && this.setState({loading: false})
  }

  save (e) {
    e.preventDefault();
    const { data } = this.state;
    const { id_profile, product } = this.props;
    const parsed_data = {
      state: data.state,
      sku: data.sku,
    }
    this.showLoading();
    productsService.update(id_profile, product.ID, parsed_data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération de l'item à jour
      return productsService.get(id_profile, id);
    }).then(([product]) => {
      this.props.onSaved(product, this.state.is_new);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this.hideLoading();
    });
    return false;
  }

  render () {
    const { loading, data } = this.state;
    const { classes, id_profile, product } = this.props;
    if (!id_profile) {
      return (<Typography className={classes.error} component="p">Aucun profil de passé en paramètre.</Typography>)
    }
    if (!product) {
      return (<Typography className={classes.error} component="p">Aucun produit de passé en paramètre.</Typography>)
    }

    return (
      <Box className={classes.container}>
        <BackdropLoading open={loading} />

        { data && (

          <Form onSubmit={ (e) => this.save(e) } noValidate>

            <FormFields className={classes.formFields}>

            <FormField>
              <FormControl className={classes.form_control}>
                <InputLabel id="select-label-state">Disponibilité</InputLabel>
                <Select
                  labelId="select-label-state"
                  label="Disponibilité"
                  id="state"
                  value={data.state}
                  onChange={ (e) => this.setState({data: {...data, ...{state: e.target.value}}}) }
                >
                  <MenuItem value={productsService.META_STATE_AVAILABLE}>Disponible</MenuItem>
                  <MenuItem value={productsService.META_STATE_UNAVAILABLE}>Indisponible</MenuItem>
                </Select>
              </FormControl>
            </FormField>

              <FormField>
                <FormControl className={classes.form_control}>
                  <TextField
                    id="sku"
                    label="UGS/SKU"
                    type="text"
                    className={classes.input}
                    value={data.sku}
                    onChange={ (e) => this.setState({data: {...data, ...{sku: e.target.value}}}) }
                  />
                  <FormHelperText>Unité de gestion de stock</FormHelperText>
                </FormControl>
              </FormField>

              <FormField>
                <Typography variant="body3">Gestion des quantités produit bientôt disponible. Intéressé/e ? Contactez notre support pour nous faire part de vos attentes...</Typography>
              </FormField>

            </FormFields>

            <EditControllers sticky>
              <Button type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={<SaveIcon />}>
                Enregistrer
              </Button>
            </EditControllers>

          </Form>

        ) }

      </Box>
    )
  }
}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submit:{
    width: '100%',
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
});

EditProductStock.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  product: PropTypes.object.isRequired, // produit à modifier
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditProductStock);
