import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormFields from '../commons/FormFields';
import SetDeliveryModeVisibility from '../commons/SetDeliveryModeVisibility';

class EditNewDeliveryModeVisibility extends React.Component {

  render () {
    const { data, onChange, onError, id_profile } = this.props;
    return (
      <FormFields>
        <SetDeliveryModeVisibility
          id_profile={id_profile}
          data={data}
          onChange={onChange}
          onError={onError}
        />
      </FormFields>
    );
  }
}

const styles = theme => ({
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  info_text: {
    margin: theme.spacing(0, 0, 2, 0),
  }
});

EditNewDeliveryModeVisibility.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.object,
};

EditNewDeliveryModeVisibility.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(EditNewDeliveryModeVisibility);
