import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { isEmpty } from './../../services/utils';

import InfiniteScroll from './InfiniteScroll';
import ListItem from './ListItem';

import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

class ListComponent extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      initialized: false,
      searchInitialized: false,
      searchQuery: {},
      reload: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    // initialisation des critères de recherche par défaut
    if (this.props.defaultSearchQuery) {
      this._isMounted && this.setState({searchQuery: {...this.state.searchQuery, ...this.props.defaultSearchQuery}});
    }
    this._isMounted && this.setState({initialized: true});
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = JSON.stringify(this.props.reload) !== JSON.stringify(prevProps.reload);
    if (reload) {
      this._isMounted && this.setState({ reload: !this.state.reload });
    }
  }

  onSearchQueryChange = (searchQuery) => {
    // on met à jour les attributs de recherche et on reload
    this._isMounted && this.setState({searchQuery, reload: !this.state.reload});
  }

  onSearchInitialized = () => {
    if (this._isMounted) {
      this._isMounted && this.setState({searchInitialized: true});
    }
  }

  render() {
    const { classes, title, onSelect, renderItem, searchEnabled, renderSearch, listOnlyOnSearch, infiniteScrollProps, load, loaded, selectedItemsKeys, itemKey } = this.props;
    const { initialized, searchInitialized, searchQuery, reload } = this.state;
    const displayList = !searchEnabled || !renderSearch || (searchInitialized && (!listOnlyOnSearch || !isEmpty(searchQuery)));

    if (!initialized) {
      return null;
    }

    return (
      <Box className={classes.content}>

        { title && (
          <Typography className={classes.title} variant="h3" component="h2">
            { title }
          </Typography>
        )}

        { searchEnabled && renderSearch && (
          <Box className={classes.renderSearch}>
            { renderSearch(this.onSearchInitialized, searchQuery, this.onSearchQueryChange) }
          </Box>
        )}

        { displayList && (

          <Box className={classes.listWrap}>
            <InfiniteScroll
              onError={this.props.onError}
              itemKey={itemKey}
              reload={reload}
              load={ (limit, offset) => load(limit, offset, this.state.searchQuery) }
              loaded={loaded}
              {...infiniteScrollProps}
              renderItems={ (items) => (
                <List className={classes.list}>
                  {items && items.length > 0 && items.map((item) => {
                    if (!item[itemKey]) {
                      console.warn("List item must have '"+[itemKey]+"' key.");
                    }
                    const selected = selectedItemsKeys.filter((selectedItemKey) => JSON.stringify(selectedItemKey) === JSON.stringify(item[itemKey])).length > 0;
                    return (
                      <ListItem key={item[itemKey]} disableGutters={true} onSelect={() => onSelect && onSelect(item)} selected={selected}>
                        { renderItem(item, onSelect ? true : false, selected) }
                      </ListItem>
                    )
                  })}
                </List>
              )}
            />
          </Box>

        ) }

      </Box>
    )
  }
}

const styles = theme => ({
  content: {
  },
  title: {
    padding: theme.spacing(1, 2),
  },
  actions: {
    padding: theme.spacing(2),
  },
  listWrap: {
    padding: theme.spacing(0),
  },
  list: {
    padding: theme.spacing(0),
  },
});

ListComponent.propTypes = {
  reload: PropTypes.bool,
  load: PropTypes.func.isRequired,
  loaded: PropTypes.func.isRequired,

  searchEnabled: PropTypes.bool,
  renderSearch: PropTypes.func,
  listOnlyOnSearch: PropTypes.bool,
  defaultSearchQuery: PropTypes.object,

  title: PropTypes.string,

  itemKey: PropTypes.string,

  selectedItemsKeys: PropTypes.array,

  onSelect: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,

  infiniteScrollProps: PropTypes.object.isRequired,
  renderItem: PropTypes.func.isRequired,
};

ListComponent.defaultProps = {
  searchEnabled: true,
  listOnlyOnSearch: false,
  infiniteScrollProps: {},
  selectedItemsKeys: [],
  itemKey: 'ID',
};

export default withStyles(styles, { withTheme: true })(ListComponent);
