import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

class ToggleRadio extends React.Component {

  render () {
    const { classes, options, className, ...others } = this.props;
    return (
      <RadioGroup
        {...others}
      >
        { options && options.map((option, index) => {
          return (
            <FormControlLabel
              disabled={option.disabled}
              key={index}
              className={clsx(classes.radioFormControl, className?.radioFormControl)}
              value={option.value}
              control={<Radio />}
              disableTypography
              label={
                <Box className={clsx(classes.radioFormControlLabel, className?.radioFormControlLabel, {[classes.radioFormControlLabelDisabled] : option.disabled})}>
                  {option.label?.title && (<Typography variant="body1" component="div" sx={{fontWeight: 600}}>{option.label?.title}</Typography>)}
                  {option.label?.infos && !Array.isArray(option.label?.infos) && (<Typography variant="body3" component="div">{option.label?.infos}</Typography>)}
                  {option.label?.infos && Array.isArray(option.label?.infos) && option.label?.infos.map((info, index) => <Typography key={index} variant="body3" component="div">{info}</Typography>)}
                  {option.label?.el}
                </Box>
              }
            />
          );
        }) }
      </RadioGroup>
    );
  }
}

ToggleRadio.propTypes = {
  options: PropTypes.array.isRequired,
  className: PropTypes.object,
};

const styles = theme => ({
  radioFormControl: {
    alignItems: 'center',
    margin: theme.spacing(0,0,2,0),
  },
  radioFormControlLabel: {
    width: '100%',
    marginTop: theme.spacing(1),
    cursor: 'pointer',
  },
  radioFormControlLabelDisabled: {
    opacity: '0.5',
  },
});

export default withStyles(styles, { withTheme: true })(ToggleRadio);
