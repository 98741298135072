import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormFields from '../commons/FormFields';
import SetProductVisibility from '../commons/SetProductVisibility';

class EditNewProductVisibility extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { data, onChange, onError, id_profile } = this.props;

    return (
      <FormFields>
        
        <SetProductVisibility
          id_profile={id_profile}
          data={data}
          onChange={onChange}
          onError={onError}
        />

      </FormFields>
    );
  }
}

const styles = theme => ({
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  info_text: {
    margin: theme.spacing(0, 0, 2, 0),
  }
});

EditNewProductVisibility.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.object,
};

EditNewProductVisibility.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(EditNewProductVisibility);
