import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {
  get (id) {
    return API.get('/profile-user-rels/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAll (params) {
    return API.get('/profile-user-rels/', {params})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  create (data) {
    return API.post('/profile-user-rels/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  delete (id) {
    return API.delete('/profile-user-rels/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
