import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from '../../../services/profiles';

import FormFields from '../../commons/FormFields';
import SetProfileOptionsLegal from '../../commons/SetProfileOptionsLegal';
import SetProfileName from '../../commons/SetProfileName';
import SetProfileContact from '../../commons/SetProfileContact';
import { Box, Typography } from '@mui/material';
import SetBusinessSector from '../../commons/SetBusinessSector';

class EditNewProfileGeneral extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { classes, data, onChange } = this.props;

    return (
      <FormFields>

        <SetProfileName
          data={data}
          onChange={(data) => {
            onChange(data);
          }}
        />

        { data.type === profilesService.TYPE_PRO && (
          <>
            <SetBusinessSector
              onChange={ (business_sector) => onChange({...data, ...{business_sector}}) }
              value={ data.business_sector || '' }
              required
            />
            
            <Box className={classes.interTitle}>
              <Typography variant="h6" component="div">Informations légales</Typography>
            </Box>

            <SetProfileOptionsLegal
              value={data.legal || {}}
              onChange={ (legal) => onChange({...data, ...{legal}}) }
            />

            <Box className={classes.interTitle}>
              <Typography variant="h6" component="div">Coordonnées de l'entreprise</Typography>
            </Box>

            <SetProfileContact
              value={data.contact || {}}
              onChange={ (contact) => onChange({...data, ...{contact}}) }
              hide_role
              hide_tel
            />
          </>
        ) }

      </FormFields>
    );
  }
}

const styles = theme => ({
  form_control: {
    width: '100%',
  },
  interTitle: {
    marginTop: theme.spacing(2),
    textDecoration: 'underline',
  },
});

EditNewProfileGeneral.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.object,
};

EditNewProfileGeneral.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(EditNewProfileGeneral);
