import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

class ListItemContent extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes, avatar, avatarProps, badge, badgeProps, right, summary, details, gutterRight, gutterLeft, onClick } = this.props;

    const innerAvatarProps = { className: classes.avatar };
    let summary_avatar = avatar ? (<Avatar {...{...innerAvatarProps, ...avatarProps}}>{ avatar !== true && avatar }</Avatar>) : null;

    const innerBadgeProps = { className: classes.badge, color: "primary", overlap: "circular", badgeContent: " " };
    summary_avatar = badge ? (<Badge {...{...innerBadgeProps, ...badgeProps}}>{ summary_avatar }</Badge>) : summary_avatar;

    return (
      <Box className={clsx(classes.wrapper, { [classes.wrapper_gutter_left]: gutterLeft, [classes.wrapper_gutter_right]: gutterRight })}>
        <Box className={clsx(classes.content, {[classes.content_with_right]: right ? true : false})}>
          <Box
            className={clsx(classes.summary, {[classes.summary_clickable]: details ? true : false})}
            onClick={() => onClick && onClick()}
          >
            { summary_avatar && (
              <Box className={classes.summary_avatar}>
                { summary_avatar }
              </Box>
            ) }
            <Box className={classes.summary_content}>
              { summary }
            </Box>
          </Box>
          { right && (
            <Box className={classes.right}>
              { right }
            </Box>
          ) }
        </Box>
        { details && (
          <Box className={classes.content_details}>
            { details }
          </Box>
        )}
      </Box>
    )
  }
}

const styles = theme => ({
  wrapper: {
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
  },
  wrapper_gutter_right: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(1),
    },
  },
  wrapper_gutter_left: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  content_with_right: {},
  right: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  summary: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  summary_clickable: {
    cursor: 'pointer',
  },
  summary_avatar: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: '1rem',
    fontWeight: 400,
  },
  summary_content:{
    width: '100%',
  },
  content_details: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(5),
    width: 'calc(100% - '+theme.spacing(5)+')',
  },
  badge: {}
});

ListItemContent.propTypes = {
  avatar: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  avatarProps: PropTypes.object,
  right: PropTypes.node,
  summary: PropTypes.node.isRequired,
  details: PropTypes.node,
  gutterRight: PropTypes.bool,
  gutterLeft: PropTypes.bool,
  onClick: PropTypes.func,
};

ListItemContent.defaultProps = {
  gutterRight: true,
  gutterLeft: true,
};

export default withStyles(styles, { withTheme: true })(ListItemContent);
