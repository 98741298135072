import { isPushIOSEnabled, subscribeToPushIOS, surveyPushIOS, unsubscribeToPushIOS, unsurveyPushIOS } from './push-ios';
import { isPushWebEnabled, subscribeToPushWeb, unsubscribeToPushWeb } from './push-web';

/****************************************************
 Interface avec l'API relative aux notifications
****************************************************/

/**
 * Check if push notification is available
 * @returns boolean
 */
export function isPushEnabled () {
  return (isPushIOSEnabled() || isPushWebEnabled());
}

/**
 * Lance le processus d'inscription au service de notification push (web ou iOS)
 * en associant l'utilisateur authentifié le cas échéant - NOTE : demande l'authorisation de l'utilisateur seulement si il ne l'a pas déjà donnée
 * NOTE : le processus est asynchrone et n'est pas Promisable
 * @returns void
 */
export function subscribeToPush () {
  if (isPushIOSEnabled()) {
    subscribeToPushIOS(); // async
  } else if (isPushWebEnabled()) {
    subscribeToPushWeb(); // async
  }
}

/**
 * Lance le processus de désinscription au service de notification push (web ou iOS)
 * NOTE : le processus est asynchrone et n'est pas Promisable
 * @returns void
 */
export function unsubscribeToPush () {
  if (isPushIOSEnabled()) {
    unsubscribeToPushIOS(); // async
  } else if (isPushWebEnabled()) {
    unsubscribeToPushWeb(); // async
  }
}

const exportDefault = {
  /**
   * Surveille le service de notification push (iOS)
   * @returns void
   */
  survey () {
    if (isPushIOSEnabled()) {
      surveyPushIOS();
    }
  },
  /**
   * Arrête de surveiller le service de notification push (iOS)
   * @returns void
   */
  unsurvey () {
    if (isPushIOSEnabled()) {
      unsurveyPushIOS();
    }
  }
};

export default exportDefault;
