/**
 * Composant affichant une liste de produits
 * - La source peut être de plusieurs types :
 *    - une liste de produits
 *    - une catégorie (chargement asynchrone)
 * - Si une commande 'order' est passée en props, le composant permet
 * de gérer les quantités des produits de celle-ci
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import attachmentsService from './../../services/attachments';

import AttachmentsListSearch from './AttachmentsListSearch';
import AttachmentsListItem from './AttachmentsListItem';
import DialogEditAttachment from './../dialog/DialogEditAttachment';
import List from './../commons/List';
import { PlusIcon } from './../commons/Icons';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

class AttachmentsList extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      attachments: [],
      loading: false,
      reload: false,
      openDialog_newAttachment: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    const {onSelect, displayMenu} = this.props;
    if (onSelect && (displayMenu === true)) {
      console.warn("Attention, le composant AttachmentsList se comporte très mal lorsque que vous activez la sélection et les actions sur les items en même temps.");
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = JSON.stringify(this.props.query) !== JSON.stringify(prevProps.query);
    if (reload) {
      this._isMounted && this.setState({ reload: !this.state.reload });
    }
  }

  load (limit, offset, searchQuery) {
    return attachmentsService.getAll(this.props.id_profile, {...this.props.query, ...searchQuery, ...{limit, offset}}).then(([items]) => items);;
  }

  onAttachmentSaved (savedAttachment, is_new) {
    const { selectAfterCreate } = this.props;
    if (is_new) {
      this._isMounted && this.setState({attachments: [...[savedAttachment], ...this.state.attachments]});
      if (selectAfterCreate) {
        this.props.onSelect(savedAttachment);
      }
    } else {
      this._isMounted && this.setState(prevState => ({
        attachments: prevState.attachments.map((attachment) => {
          if (attachment.ID === savedAttachment.ID) {
            return savedAttachment;
          }
          return attachment;
        })
      }));
    }
  }

  onAttachmentDeleted (id_deletedAttachment) {
    this._isMounted && this.setState(prevState => ({
      attachments: prevState.attachments.filter((attachment) => {
        return attachment.ID !== id_deletedAttachment;
      })
    }))
  }

  openNewAttachment () {
    this._isMounted && this.setState({ openDialog_newAttachment: true });
  }

  closeNewAttachment () {
    this._isMounted && this.setState({ openDialog_newAttachment: false });
  }

  render () {
    const { classes, id_profile, canCreate } = this.props;
    const { attachments, loading, reload } = this.state;

    return (
      <Box className={classes.content}>

        { canCreate && (
          <>
            { this.state.openDialog_newAttachment && (
              <DialogEditAttachment
                openFileUpload={ true }
                open={ this.state.openDialog_newAttachment }
                id_profile={ id_profile }
                onSaved={ (attachment, is_new) => {
                  this.onAttachmentSaved(attachment, is_new);
                  this.closeNewAttachment();
                } }
                onError={ this.props.onError }
                onClose={ () => this.closeNewAttachment() }
              />
            ) }
            <Box className={classes.actions}>
              <Button fullWidth variant="outlined" color="primary" className={classes.button} startIcon={<PlusIcon />} onClick={ () => this.openNewAttachment() }>Ajouter</Button>
            </Box>
          </>
        ) }

        <List
          reload={reload}
          load={(limit, offset, searchQuery) => this.load(limit, offset, searchQuery)}
          loaded={(items) => this._isMounted && this.setState({attachments: items})}
          searchEnabled={this.props.searchEnabled}
          renderSearch={(onInitialize, searchQuery, onQueryChange) => (
            <AttachmentsListSearch
              query={searchQuery}
              onQueryChange={onQueryChange}
              onInitialize={onInitialize}
              searching={loading}
              onError={this.props.onError}
            />
          )}
          defaultSearchQuery={this.props.defaultSearchQuery}
          listOnlyOnSearch={this.props.listOnlyOnSearch}
          onSelect={this.props.onSelect}
          selectedItemsKeys={this.props.selectedItemsKeys}
          onError={this.props.onError}
          onSuccess={this.props.onSuccess}
          infiniteScrollProps={ {
            limit: this.props.limit || 25,
            scrollableTarget: this.props.scrollableTarget,
            items: attachments,
            onLoading: (loading) => this._isMounted && this.setState({loading}),
            noResult: this.props.noResult,
          } }
          renderItem={ (attachment, selectable) => (
            <AttachmentsListItem
              id_profile={id_profile}
              displayMenu={!selectable}
              attachment={attachment}
              onError={this.props.onError}
              onSuccess={this.props.onSuccess}
              onSaved={ (attachment, is_new) => this.onAttachmentSaved(attachment, is_new) }
              onDeleted={ (id) => this.onAttachmentDeleted(id) }
              right={this.props.listItemRight}
              disableExpand={selectable}
            />
          ) }
        />

      </Box>
    )
  }
}

const styles = theme => ({
  content: {
  },
  title: {
    padding: theme.spacing(1, 2),
  },
  actions: {
    padding: theme.spacing(2),
  },
});

AttachmentsList.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  limit: PropTypes.number,
  title: PropTypes.string,
  query: PropTypes.object,
  onSelect: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  noResult: PropTypes.element,
  scrollableTarget: PropTypes.string,
  selectedItemsKeys: PropTypes.array,

  searchEnabled: PropTypes.bool,
  listOnlyOnSearch: PropTypes.bool,
  defaultSearchQuery: PropTypes.object,

  canCreate: PropTypes.bool,
  selectAfterCreate: PropTypes.bool,

  listItemRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
};

AttachmentsList.defaultProps = {};

export default withStyles(styles, { withTheme: true })(AttachmentsList);
