import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import deliveryModesService from '../../services/delivery-modes';

import FormField from './FormField';
import TextFieldLimited from './TextFieldLimited';
import { FormControlLabel, RadioGroup, TextField, FormControl, Radio, Box, FormGroup, Switch, Typography } from '@mui/material';
import SetContact from './SetContact';
import InfoText from './InfoText';
import { isEmpty } from '../../services/utils';

class SetDeliveryModeGeneral extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      contactPickup_enabled: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data);
    if (reload) {
      this.load();
    }
  }

  load () {
    const { data } = this.props;
    if (isEmpty(data.address_name) &&
        isEmpty(data.address_1) &&
        isEmpty(data.address_2) &&
        isEmpty(data.address_3) &&
        isEmpty(data.address_cp) &&
        isEmpty(data.address_city) &&
        isEmpty(data.address_country)) {
      this._isMounted && this.setState({ contactPickup_enabled: false });
    } else {
      this._isMounted && this.setState({ contactPickup_enabled: true });
    }
  }

  render () {
    const { classes, data, onChange } = this.props;

    return (
      <>

        <FormField>
          <FormControl className={classes.form_control}>
            <RadioGroup
              row
              name="delivery-type"
              id="delivery-type"
              value={data?.type || ''}
              onChange={ (e) => onChange({...data, ...{type: e.target.value}}) }
            >
              <FormControlLabel value={deliveryModesService.TYPE_SHIPPING} control={<Radio />} label="Livraison" />
              <FormControlLabel value={deliveryModesService.TYPE_PICKUP} control={<Radio />} label="Retrait" />
            </RadioGroup>
          </FormControl>
        </FormField>

        <FormField>
          <FormControl className={classes.form_control}>
            <TextField
              required
              id="delivery-name"
              name="delivery-name"
              label="Nom"
              type="text"
              className={classes.input}
              value={data?.name || ''}
              onChange={ (e) => onChange({...data, ...{name: e.target.value}}) }
              autoFocus
            />
          </FormControl>
        </FormField>

        <FormField>
          <FormControl className={classes.form_control}>
            <TextFieldLimited
              id="delivery-description"
              name="delivery-description"
              label="Description"
              type="text"
              className={classes.input}
              value={data?.description || ''}
              onChange={ (description) => onChange({...data, ...{description}}) }
              multiline
              rows={4}
            />
          </FormControl>
        </FormField>

        { data?.type === deliveryModesService.TYPE_PICKUP && (
          <fieldset className={classes.fieldset}>
            <legend className={classes.fieldset_legend}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      id="contact-pickup-enabled"
                      name="contact-pickup-enabled"
                      checked={ this.state.contactPickup_enabled }
                      onChange={ () => {
                        this._isMounted && this.setState({contactPickup_enabled: !this.state.contactPickup_enabled});
                        if (!this.state.contactPickup_enabled === false) {
                          onChange({...data, ...{
                            address_name: '',
                            address_1: '',
                            address_2: '',
                            address_3: '',
                            address_cp: '',
                            address_city: '',
                            address_country: '',
                          }});
                        }
                      } }
                    />
                  }
                  label="Adresse de retrait"
                />
              </FormGroup>
            </legend>
            <Box>
              <InfoText classContent={classes.info_text}>
                <Typography variant="body2" component="span">Si différente de l'adresse par défaut de votre profil.</Typography>
              </InfoText>
              { this.state.contactPickup_enabled && (
                <Box className={classes.setContactWrapper}>
                  <SetContact
                    hide_tel
                    hide_role
                    address_name_required={false}
                    address_1_required={false}
                    address_2_required={false}
                    address_3_required={false}
                    address_cp_required={false}
                    address_city_required={false}
                    address_country_required={false}
                    value={{
                      address_name: data?.address_name || '',
                      address_1: data?.address_1 || '',
                      address_2: data?.address_2 || '',
                      address_3: data?.address_3 || '',
                      address_cp: data?.address_cp || '',
                      address_city: data?.address_city || '',
                      address_country: data?.address_country || '',
                    }}
                    onChange={ (value) => onChange({...data, ...value}) }
                  />
                </Box>
              ) }
            </Box>
          </fieldset>
        ) }
      </>
    )
  }
}

const styles = theme => ({
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  info_text: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  setContactWrapper: {
    paddingBottom: theme.spacing(1),
  }
});

SetDeliveryModeGeneral.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
};

SetDeliveryModeGeneral.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetDeliveryModeGeneral);
