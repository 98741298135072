import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import contactsService from '../../services/contacts';

import { FormControl, TextField, Grid, InputLabel, MenuItem, Select, Box } from '@mui/material';

class SetContact extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {};
    this.roles = [
      {value: contactsService.ROLE_DEFAULT, label: "Coordonnées principales"},
      {value: contactsService.ROLE_BILLING, label: "Coordonnées de facturation"},
      {value: contactsService.ROLE_SHIPPING, label: "Coordonnées de livraison"},
    ];
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const {
      classes,
      value,
      tel_required,
      address_name_required,
      address_1_required,
      address_2_required,
      address_3_required,
      address_cp_required,
      address_city_required,
      address_country_required,
      hide_tel,
      hide_role,
      hide_address_name,
      hide_address_1,
      hide_address_2,
      hide_address_3,
      hide_address_cp,
      hide_address_city,
      hide_address_country,
    } = this.props;

    return (

      <Box>
        <Grid container rowSpacing={2} columnSpacing={1} className={classes.gridContent}>

          { !hide_address_name && (
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                required={address_name_required}
                id="address_name"
                name="address_name"
                label="Nom complet"
                type="text"
                className={classes.input}
                value={value && value.address_name ? value.address_name : ''}
                onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{address_name: e.target.value}}) }
                autoComplete="organization"
              />
            </FormControl>
          </Grid>
          ) }
          { !hide_address_1 && (
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                required={address_1_required}
                id="address_1"
                name="address_1"
                label="Adresse"
                type="text"
                className={classes.input}
                value={value && value.address_1 ? value.address_1 : ''}
                onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{address_1: e.target.value}}) }
                autoComplete="address-line1"
              />
            </FormControl>
          </Grid>
          ) }
          { !hide_address_2 && (
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                required={address_2_required}
                id="address_2"
                name="address_2"
                label="Complément d'adresse"
                type="text"
                className={classes.input}
                value={value && value.address_2 ? value.address_2 : ''}
                onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{address_2: e.target.value}}) }
                autoComplete="address-line2"
              />
            </FormControl>
          </Grid>
          ) }
          { !hide_address_3 && (
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                required={address_3_required}
                id="address_3"
                name="address_3"
                label="Complément d'adresse supplémentaire"
                type="text"
                className={classes.input}
                value={value && value.address_3 ? value.address_3 : ''}
                onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{address_3: e.target.value}}) }
                autoComplete="address-line3"
              />
            </FormControl>
          </Grid>
          ) }
          { !hide_address_cp && (
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                required={address_cp_required}
                id="address_cp"
                name="address_cp"
                label="Code postal"
                type="text"
                className={classes.input}
                value={value && value.address_cp ? value.address_cp : ''}
                onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{address_cp: e.target.value}}) }
                autoComplete="postal-code"
              />
            </FormControl>
          </Grid>
          ) }
          { !hide_address_city && (
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <TextField
                required={address_city_required}
                id="address_city"
                name="address_city"
                label="Ville"
                type="text"
                className={classes.input}
                value={value && value.address_city ? value.address_city : ''}
                onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{address_city: e.target.value}}) }
                autoComplete="address-level2"
              />
            </FormControl>
          </Grid>
          ) }
          { !hide_address_country && (
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                required={address_country_required}
                id="address_country"
                name="address_country"
                label="Pays"
                type="text"
                className={classes.input}
                value={value && value.address_country ? value.address_country : ''}
                onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{address_country: e.target.value}}) }
                autoComplete="country-name"
              />
            </FormControl>
          </Grid>
          ) }
          { !hide_tel && (
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                required={tel_required}
                id="tel"
                name="tel"
                label="Téléphone"
                type="tel"
                className={classes.input}
                value={value && value.tel ? value.tel : ''}
                onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{tel: e.target.value}}) }
                autoComplete="tel"
              />
            </FormControl>
          </Grid>
          ) }
          { !hide_role && (
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="role">Type de coordonnées</InputLabel>
              <Select
                className={classes.select_field}
                labelId="role"
                label="Type de coordonnées"
                id="role"
                value={value && value.role ? value.role : ''}
                onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{role: e.target.value}}) }
              >
              { this.roles.map((role) => {
                return <MenuItem value={role.value} key={role.value}>{role.label}</MenuItem>
              }) }
              </Select>
            </FormControl>
          </Grid>
          ) }

        </Grid>
      </Box>
    );
  }
}

const styles = theme => ({
  gridContent: {
    marginLeft: "-" + theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
});

SetContact.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  hide_tel: PropTypes.bool,
  hide_role: PropTypes.bool,
  hide_address_name: PropTypes.bool,
  hide_address_1: PropTypes.bool,
  hide_address_2: PropTypes.bool,
  hide_address_3: PropTypes.bool,
  hide_address_cp: PropTypes.bool,
  hide_address_city: PropTypes.bool,
  hide_address_country: PropTypes.bool,
  tel_required: PropTypes.bool,
  address_name_required: PropTypes.bool,
  address_1_required: PropTypes.bool,
  address_2_required: PropTypes.bool,
  address_3_required: PropTypes.bool,
  address_cp_required: PropTypes.bool,
  address_city_required: PropTypes.bool,
  address_country_required: PropTypes.bool,
};

SetContact.defaultProps = {
  hide_tel: false,
  hide_role: false,
  hide_address_name: false,
  hide_address_1: false,
  hide_address_2: false,
  hide_address_3: true,
  hide_address_cp: false,
  hide_address_city: false,
  hide_address_country: false,
  tel_required: true,
  address_name_required: true,
  address_1_required: true,
  address_2_required: false,
  address_3_required: false,
  address_cp_required: true,
  address_city_required: true,
  address_country_required: true,
};

export default withStyles(styles, { withTheme: true })(SetContact);
