import React from 'react';
import { withStyles } from '@mui/styles';
import authService from './../../services/auth';

import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, Box, Typography, CircularProgress } from '@mui/material';
import { EyeIcon, EyeHideIcon, RenewIcon } from './Icons';
import clsx from 'clsx';
import { isEmpty } from '../../services/utils';

class PasswordNewField extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      showPassword: true,
      loading: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    if (isEmpty(this.props.value)) {
      this.generatePassword(true);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  generatePassword () {
    this._isMounted && this.setState({loading: true})
    authService.generatePassword().then((password) => {
      this._isMounted && this.change(password);
    }).catch(() => {
      // pas d'interruption... ce n'est qu'un confort.
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
  }

  change(password) {
    this.props.onChange(password);
  }

  render () {
    const { classes, settings, classNameRoot, onChange, ...others } = this.props;
    const { showPassword, loading } = this.state;
    return (
      <Box className={classNameRoot}>
        <TextField
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="generate password"
                  onClick={() => this.generatePassword()}
                >
                  {!loading ? <RenewIcon fontSize="small" /> : <CircularProgress size={15} />}
                </IconButton>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => this.setState({showPassword: !showPassword})}
                >
                  {showPassword ? <EyeIcon /> : <EyeHideIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
          onChange={(e) => this.change(e.target.value)}
          {...others}
        />
        <Box className={classes.securityInfos}>
          <Box className={classes.level}>
            { this.render_level_item_len() }
            { this.render_level_item_cap() }
            { this.render_level_item_num() }
            { this.render_level_item_spe() }
          </Box>
          <Typography variant="body3" component="div" className={classes.levelInfos}>
            Renseignez un mot de passe fort avec au moins 12 caractères dont une majuscule, une minuscule, un chiffre et un spécial.
          </Typography>
        </Box>
      </Box>
    );
  }

  render_level_item_num () {
    const { classes, value } = this.props;
    const is_level_num_ok = value && value.match(/\d/g);
    if (is_level_num_ok) {
      return <Typography variant="body3" component="div" className={clsx(classes.level_item, classes.level_item_ok)} title="renseignez au moins un chiffre">123</Typography>;
    }
    return <Typography variant="body3" component="div" className={clsx(classes.level_item, classes.level_item_ko)} title="renseignez au moins un chiffre">123</Typography>;
  }

  render_level_item_cap () {
    const { classes, value } = this.props;
    const is_level_min_ok = value && value.match(/[a-z]+/g);
    const is_level_cap_ok = value && value.match(/[A-Z]+/g);
    if (is_level_min_ok && is_level_cap_ok) {
      return <Typography variant="body3" component="div" className={clsx(classes.level_item, classes.level_item_ok)} title="renseignez au moins une minuscule et une majuscule">Aa</Typography>;
    }
    return <Typography variant="body3" component="div" className={clsx(classes.level_item, classes.level_item_ko)} title="renseignez au moins une minuscule et une majuscule">Aa</Typography>;
  }

  render_level_item_spe () {
    const { classes, value } = this.props;
    const is_level_spe_ok = value && value.match(/\W+/g);
    if (is_level_spe_ok) {
      return <Typography variant="body3" component="div" className={clsx(classes.level_item, classes.level_item_ok)} title="renseignez au moins un caractère spécial">@#?</Typography>;
    }
    return <Typography variant="body3" component="div" className={clsx(classes.level_item, classes.level_item_ko)} title="renseignez au moins un caractère spécial">@#?</Typography>;
  }

  render_level_item_len () {
    const { classes, value } = this.props;
    const is_level_len_ok = value && value.length > 11;
    if (is_level_len_ok) {
      return <Typography variant="body3" component="div" className={clsx(classes.level_item, classes.level_item_ok)} title="renseignez au moins 12 caractères">+ de 12</Typography>
    }
    return <Typography variant="body3" component="div" className={clsx(classes.level_item, classes.level_item_ko)} title="renseignez au moins 12 caractères">+ de 12</Typography>;
  }
}

const styles = theme => ({
  securityInfos: {
    padding: theme.spacing(0.5),
    position: 'relative',
  },
  level: {
    display: 'flex',
    gap: '5px'
  },
  level_item: {
    width: '25%',
    padding: '2px 3px',
    textAlign: 'center',
    backgroundColor: '#ffffff',
    border: '1px solid #999999',
    borderRadius: '3px',
    color: '#666666',
    fontSize: '10px',
  },
  level_item_ko: {
    borderColor: theme.palette.primary.main,
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  level_item_ok: {
    borderColor: theme.palette.success.main,
    borderTop: `3px solid ${theme.palette.success.main}`,
  },
  levelInfos: {
    color: '#555555',
    display: 'none',
  },
});

export default withStyles(styles, { withTheme: true })(PasswordNewField);
