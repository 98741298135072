import React from 'react';
import { withRouter } from "react-router-dom";
import { withSnackbar } from 'notistack';

/**
 * Return Higher Order Component for this app which purpose some view service, like title, loading...
 * @param  {[type]} WrappedComponent [description]
 * @return {[type]}                  [description]
 */
function withHOCComponent(WrappedComponent, childName) {

  class HOCComponent extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        uniqKey: escape(WrappedComponent.displayName).replace(/%/g, '')
      }
    }

    redirectTo (route) {
      this.props.history.push(route);
    }

    reload () {
      this.props.history.go(0);
    }

    historyGoBack () {
      this.props.history.goBack();
    }

    historyPush (path) {
      this.props.history.push(path);
    }

    historyReplace (path) {
      this.props.history.replace(path);
    }

    showInfo (message) {
      this.props.enqueueSnackbar(message);
    }

    showSuccess (message) {
      this.props.enqueueSnackbar(message, {variant: 'success'});
    }

    showWarning (message) {
      this.props.enqueueSnackbar(message, {variant: 'warning'});
    }

    showError (error) {
      this.props.enqueueSnackbar(this.getErrorString(error), {variant: 'error'});
    }

    getErrorString(error) {
      if (Array.isArray(error) && error.length > 0) {
        error = error.join(', ');
      } else if (typeof error === 'object' && error !== null) {
        if (error?.data?.message) {
          error = error.data.message;
        } else if (error?.response) {
          error = error.response;
        } else if (error?.message) {
          error = error.message;
        } else {
          error = JSON.stringify(error);
        }
      } else if (!error || error === undefined) {
        error = "Erreur inconnue";
      }
      return error;
    }

    render () {

      const { ...inheritProps } = this.props;

      // Injecte les props dans le composant enrobé. Il s’agit en général
      // de valeurs de l’état local ou de méthodes d’instance.
      const hocProps = {
        redirectTo: (route) => {
          this.redirectTo(route);
        },
        reload: () => {
          this.reload();
        },
        historyGoBack: () => {
          this.historyGoBack();
        },
        historyPush: (path) => {
          this.historyPush(path);
        },
        historyReplace: (path) => {
          this.historyReplace(path);
        },
        showInfo: (message) => {
          this.showInfo(message);
        },
        showSuccess: (message) => {
          this.showSuccess(message);
        },
        showWarning: (message) => {
          this.showWarning(message);
        },
        showError: (message) => {
          this.showError(message);
        },
        getErrorString: (error) => {
          return this.getErrorString(error);
        },
      };

      // Transmet les props au composant enrobé
      return (
        <WrappedComponent
          hoc={hocProps}
          {...inheritProps}
        />
      );
    }
  };
  return withRouter(withSnackbar(HOCComponent));
}

export {
  withHOCComponent
}
