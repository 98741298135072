import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import profilesService from '../../services/profiles';
import utilsService from '../../services/utils';

import BackdropLoading from './../commons/BackdropLoading';
import ListItemContent from './../commons/ListItemContent';
import ListItemContentSummary1 from './../commons/ListItemContentSummary1';
import ListItemContentSummary2 from './../commons/ListItemContentSummary2';
import { StarPlainIcon } from '../commons/Icons';
import { Chip } from '@mui/material';

class ProfilesListItem extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { profile, classes } = this.props;
    const profileAvatar = profilesService.getAvatar(profile);
    const isPremium = profilesService.isPremium(profile);
    const isPro = profilesService.isPro(profile);

    const badgeProps = {
      className: classes.avatar_badge,
      classes: {badge: classes.avatar_badge_badge},
      overlap: 'circular',
      color: 'profile_premium',
      badgeContent: (<StarPlainIcon fontSize="xsmall" />)};

    const avatarProps = {...profileAvatar.props, ...{className: clsx(classes.avatar, {[classes.avatar_pro]: isPro})}};

    return (
      <>
        <ListItemContent
          avatar={profileAvatar.content}
          avatarProps={avatarProps}
          badge={isPremium}
          badgeProps={isPremium && badgeProps}
          right={this.renderRight()}
          summary={this.renderSummary()}
        />
        <BackdropLoading open={this.state.loading} />
      </>
    )
  }

  renderSummary () {
    const { profile, classes } = this.props;
    const profileSubInfo = profilesService.getSubInfo(profile);
    return (
      <>
        <ListItemContentSummary1>
          {utilsService.showDebugID(profile)}{ profile.name }
        </ListItemContentSummary1>
        { profileSubInfo && (
          <ListItemContentSummary2>{ profileSubInfo }</ListItemContentSummary2>
        ) }
        { profile.is_subscribed && (
          <Chip label="suivi/e" size="small" variant="outlined" classes={{root: classes.relChip, label: classes.relChipLabel}} />
        ) }
        { profile.is_subscriber && (
          <Chip label="abonné/e" size="small" variant="outlined" classes={{root: classes.relChip, label: classes.relChipLabel}} />
        ) }
      </>
    )
  }

  renderRight () {
    const { right } = this.props;
    if (!right) {
      return null;
    }
    return right;
  }
}

const styles = theme => ({
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: '1.2rem',
    fontWeight: 400,
    backgroundColor: theme.palette.bg.main,
  },
  avatar_pro: {
    '&::after': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      borderRadius: '100%',
      top: '2px',
      right: '2px',
      bottom: '2px',
      left: '2px',
      border: `2px solid #fff`,
      boxShadow: `0 0 0 2px ${theme.palette.profile_pro.main}`,
    },
  },
  avatar_badge: {},
  avatar_badge_badge: {
    padding: '0px !important',
    boxShadow: 'none',
    background: 'transparent',
    color: theme.palette.profile_pro.main,
    top: '4px',
    right: '2px',
    height: 'auto',
    width: 'auto',
    fontSize: '18px',
  },
  relChip: {
    marginRight: theme.spacing(0.5),
    height: '18px',
  },
  relChipLabel: {
    fontSize: '10px',
  },
});

ProfilesListItem.propTypes = {
  profile: PropTypes.object,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onSaved: PropTypes.func,
  disableExpand: PropTypes.bool,
  right: PropTypes.element,
};

export default withStyles(styles, { withTheme: true })(ProfilesListItem);
