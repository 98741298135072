import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import subscriptionsService from './../../services/subscriptions';

import BackdropLoading from './../commons/BackdropLoading';
import SubscriptionViewHeader from './SubscriptionViewHeader';
import SubscriptionViewPlan from './SubscriptionViewPlan';
import SubscriptionViewPaymentMethod from './SubscriptionViewPaymentMethod';
import SubscriptionViewPayments from './SubscriptionViewPayments';

import Box from '@mui/material/Box';

class SubscriptionView extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubscriptionUpdated () {
    this.onUpated();
  }

  onPaymentMethodUpdated () {
    this.onUpated();
  }

  onUpated () {
    const { subscription, onUpdated } = this.props;
    // si le parent écoute les modifications d'un abonnement (cas d'une liste d'abonnements), alors quand l'abonnement est
    // mis à jour, on la reload pour l'avoir à jour et on le renvoi au parent qui va s'occuper du reste
    if (onUpdated) {
      this._isMounted && this.setState({ loading: true });
      subscriptionsService.get(subscription.ID).then(([subscription]) => {
        onUpdated(subscription);
      }).catch((e) => {
        this.props.onError(e);
      }).finally(() => {
        this._isMounted && this.setState({ loading: false });
      });
    }
  }

  render() {
    const { classes, subscription } = this.props;
    const { loading } = this.state;
    if (!subscription) {
      return null;
    }
    return (
      <Box className={classes.root}>

        <BackdropLoading open={loading} />

        <SubscriptionViewHeader
          subscription={subscription}
          onUpdated={() => this.onSubscriptionUpdated()}
          onError={this.props.onError}
        />

        <SubscriptionViewPlan
          subscription={subscription}
          onUpdated={() => this.onSubscriptionUpdated()}
          onError={this.props.onError}
        />

        <SubscriptionViewPaymentMethod
          id_profile={subscription.id_profile}
          onUpdated={() => this.onPaymentMethodUpdated()}
          onDeleted={() => this.onPaymentMethodUpdated()}
          onError={this.props.onError}
          canDelete={true}
        />

        <SubscriptionViewPayments
          subscription={subscription}
          onError={this.props.onError}
        />

      </Box>
    )
  }
}

const styles = theme => ({
  root: {},
});

SubscriptionView.propTypes = {
  subscription: PropTypes.object.isRequired,
  onUpdated: PropTypes.func,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(SubscriptionView);
