import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from '../../services/profiles';

import Dialog from './Dialog';
import { DeleteIcon } from '../commons/Icons';

import { Button, DialogContent, Typography } from '@mui/material';
import BackdropLoading from '../commons/BackdropLoading';

class DialogDeleteProfile extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  requestDeletion () {
    const { id_profile } = this.props;
    this._isMounted && this.setState({ loading: true });
    profilesService.requestDeletion(id_profile).then(([id, data]) => {
      this._isMounted && this.props.onDeleted && this.props.onDeleted(data);
    }).catch((e) => {
      this._isMounted && this.props.onError(e);
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  render () {
    const { classes, open, onClose, id_profile } = this.props;
    const { loading } = this.state;
    
    return (
      <Dialog
        keepMounted={false}
        id={"" + id_profile}
        fullScreen={false}
        disableGutters={true}
        open={open}
        onClose={onClose}
      >
        <DialogContent>
          <BackdropLoading open={loading} />
          <Typography>En confirmant cette action, une demande va être envoyée à nos services. Notez que pour des questions d'intégrité de données, ce profil ne sera pas proprement 'supprimé' mais 'anonymisé', ainsi il sera impossible de vous associé à celui-ci et vous n'y aurez plus accès.</Typography>
          <Button className={classes.item_action} variant="contained" startIcon={<DeleteIcon />} onClick={() => {
            this.requestDeletion();
          }}>Je confirme ma demande</Button>
        </DialogContent>
      </Dialog>
    )
  }
}

const styles = theme => ({
  item_action: {
    marginTop: theme.spacing(1),
    width: '100%',
  }
});

DialogDeleteProfile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onDeleted: PropTypes.func,
  id_profile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogDeleteProfile);
