import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Dialog from './Dialog';
import ProductCategoriesList from './../product/ProductCategoriesList';

class DialogProductCategories extends React.Component {

  render () {
    const { classes, open, onClose, fullScreen, keepMounted, id_profile, ...others } = this.props;
    const id = "dialog-profile-product-categories-"+id_profile;
    return (
      <Dialog
        keepMounted={keepMounted}
        id={id}
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        title="Catégories de produit"
      >
        <ProductCategoriesList
          scrollableTarget={id}
          id_profile={id_profile}
          hideTitle={true}
          {...others}
        />
      </Dialog>
    )
  }
}

const styles = theme => ({});

DialogProductCategories.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogProductCategories);
