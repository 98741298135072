import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { CloseIcon } from '../commons/Icons';
import { Dialog, IconButton, Slide } from '@mui/material';

const dialogTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogBase extends React.Component {

  render () {
    const { classes, open, onClose, keepMounted, children, maxWidth, hideClose } = this.props;
    return (
      <Dialog
        keepMounted={keepMounted}
        fullWidth={true}
        maxWidth={maxWidth || 'sm'}
        open={open}
        onClose={onClose}
        TransitionComponent={dialogTransition}
      >
        { !hideClose && (
          <IconButton className={classes.close} onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        ) }
        { children }
      </Dialog>
    );
  }
}

const styles = theme => ({
  close: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    zIndex: 15,
  },
});

DialogBase.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  keepMounted: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node,
  hideClose: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(DialogBase);
