import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ordersService from '../../../services/orders';

import { Box } from '@mui/material';
import BackdropLoading from '../../commons/BackdropLoading';
import OrderItemsList from '../../order/OrderItemsList';

class EditNewOrderStepResumeOrderItems extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      laoding: false,
      permissions: {},
    };
  }

  componentDidMount () {
    this._isMounted = true;
    const { order } = this.props;
    order && this.setState({permissions : ordersService.getOrderPermissions(order)});
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onOrderItemsUpdated () {
    const { order, onChange, onError } = this.props;
    this._isMounted && this.setState({ loading: true });
    ordersService.get(order.ID).then(([order]) => {
      onChange(order);
    }).catch((e) => {
      onError(e);
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  render () {
    const { order, permissions } = this.props;
    const { loading } = this.state;
    if (!order || !permissions) {
      return null;
    }
    return (
      <Box>
        { loading && (<BackdropLoading open={loading} />) }
        <OrderItemsList
          id_order={order.ID}
          id_profile_provider={order.id_profile_provider}
          id_profile_customer={order.id_profile_customer}
          catalogDisabled={true}
          permissions={permissions}
          onUpdated={() => {
            this.onOrderItemsUpdated();
          }}
          onError={this.props.onError}
        />
      </Box>
    );
  }
}

const styles = theme => ({});

EditNewOrderStepResumeOrderItems.propTypes = {
  onChange: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditNewOrderStepResumeOrderItems);
