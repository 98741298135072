import React from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { connect } from 'react-redux';
import { setPresentationHomeActive, setPresentationNetworkActive } from '../../services/store/features/appSlice';
import { withHOCComponent } from '../hoc/HOCComponent';
import profilesService from '../../services/profiles';
import { Typography } from '@mui/material';
import { StarPlainIcon } from '../commons/Icons';

/**
 * Manage app presentation tour
 */
class AppPresentationController extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      steps_for_home: [],
      steps_for_network: [],
    };
    this.joyride_style = {
      options: {
        primaryColor: '#ff5549',
        width: 350,
        zIndex: 10000,
      }
    };
    this.joyride_locale = {
      back: 'Préc.',
      close: 'Fermer',
      last: 'Terminer',
      next: 'Suiv.',
      open: 'Ouvrir',
      skip: 'Ignorer'
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load_steps();
  }

  componentDidUpdate(prevProps) {
    // si l'authentification a été mise à jour
    const reload = JSON.stringify(this.props.appStore?.authProfile) !== JSON.stringify(prevProps.appStore?.authProfile);
    if (reload) {
      this.load_steps();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load_steps () {
    this._isMounted && this.setState({
      steps_for_home: this.get_steps_for__home(),
      steps_for_network: this.get_steps_for__network(),
    });
  }

  handleJoyrideCallback (data) {
    const { location } = this.props;
    const { status, action, type } = data;
    if (EVENTS.STEP_AFTER === type && ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || ACTIONS.CLOSE === action)) {
      if (location.pathname === '/') {
        this.props.dispatchSetPresentationHomeActive(false);
      } else if (location.pathname === '/network') {
        this.props.dispatchSetPresentationNetworkActive(false);
      }
    }
  };

  render () {
    const { appStore, location } = this.props;
    if (!appStore?.auth?.id_user || !appStore?.authProfile?.ID) {
      return null;
    }

    const is_premium = profilesService.isPremium(appStore?.authProfile);

    if (location.pathname === '/' && appStore.presentationHomeActive) {
      return (
        <Joyride
          callback={(data) => this.handleJoyrideCallback(data)}
          continuous
          run={true}
          showProgress
          disableCloseOnEsc
          disableOverlayClose
          styles={this.joyride_style}
          locale={this.joyride_locale}
          scrollOffset={100}
          steps={this.get_steps_for__home(is_premium)}
        />
      );
    } else if (location.pathname === '/network' && appStore.presentationNetworkActive) {
      return (
        <Joyride
          callback={(data) => this.handleJoyrideCallback(data)}
          continuous
          run={true}
          showProgress
          disableCloseOnEsc
          disableOverlayClose
          styles={this.joyride_style}
          locale={this.joyride_locale}
          scrollOffset={100}
          steps={this.get_steps_for__network(is_premium)}
        />
      );
    }
    return null;
  }

  get_steps_for__home () {
    const { appStore } = this.props;
    if (!appStore?.auth?.id_user || !appStore?.authProfile?.ID) {
      return [];
    }
    const is_premium = profilesService.isPremium(appStore?.authProfile);
    const is_pro = profilesService.isPro(appStore?.authProfile);

    const step_welcome = {
      title: 'Votre tableau de bord',
      content: <Typography variant="body2" component="div">Vue d'ensemble de votre profil et accès aux principales fonctionnalités dont nous allons voir le détail...</Typography>,
      placement: 'center',
      target: 'body',
    };
    const step_catalog = {
      title: 'Gérez votre catalogue',
      content: <Typography variant="body2" component="div">Gérez vos produits, leurs catégories, prix, stocks, etc.</Typography>,
      placement: 'left',
      target: '#home-orders--btn-catalog',
    };
    const step_network = {
      title: 'Découvrez le réseau Bridger',
      content: <Typography variant="body2" component="div">Trouvez des partenaires parmi les utilisateurs Bridger et enrichissez votre réseau commercial.</Typography>,
      placement: 'top',
      target: '#main-footer--btn-network',
    };
    const step_network_premium = {
      title: 'Découvrez le réseau Bridger',
      content: <Typography variant="body2" component="div">Trouvez ou invitez des partenaires parmi les utilisateurs Bridger et enrichissez votre réseau commercial.</Typography>,
      placement: 'top',
      target: '#main-footer--btn-network',
    };
    const step_profile_network = {
      title: 'Votre réseau',
      content: <Typography variant="body2" component="div">Consultez votre réseau : les profils qui vous suivent (vos abonnés) et ceux auquels vous êtes vous-même abonné (vos abonnements)..</Typography>,
      placement: 'top',
      target: '#profile-header--rels',
    };
    const step_orders_production = {
      title: 'Journal de production',
      content: <Typography variant="body2" component="div">Retrouvez les commandes à traiter et accédez à des outils optimisés pour votre production.</Typography>,
      placement: 'top',
      target: '#home-orders--btn-production',
    };
    const step_orders_table = {
      title: 'Tableau de commandes',
      content: <Typography variant="body2" component="div">Retrouvez vos commandes et vos brouillons.</Typography>,
      placement: 'top',
      target: '#home-orders--btn-orders',
    };
    const step_new_order = {
      title: 'Nouvelles commandes',
      content: <Typography variant="body2" component="div">Passez commande auprès des profils auquels vous êtes abonné(e).</Typography>,
      placement: 'top',
      target: '#home-board--btn-new',
    };
    const step_new_order_premium = {
      title: 'Nouvelles commandes',
      content: <Typography variant="body2" component="div">Passez commande auprès des profils auquels vous êtes abonné(e), invitez de nouveaux abonnés, ajoutez de nouveaux produits à votre catalogue.</Typography>,
      placement: 'top',
      target: '#home-board--btn-new',
    };
    const step_profile_settings = {
      title: 'Paramètres et support',
      content: <Typography variant="body2" component="div">Modifiez vos options, créez et naviguez entre vos différents profils, gérez votre formule Premium, consultez l'aide...</Typography>,
      placement: 'bottom',
      target: '#main-header--right-toolbar',
    };
    const step_try_premium = {
      title: 'Essayez Premium',
      content: <Typography variant="body2" component="div">Gérez un catalogue produits, prennez et traitez les commandes de vos clients. Profitez d'une période d'essai gratuite et sans engagement.</Typography>,
      placement: 'bottom',
      target: '#profile-header--btn-premium',
    };
    if (is_premium) {
      return [step_welcome, step_catalog, step_network_premium, step_profile_network, step_orders_production, step_orders_table, step_new_order_premium, step_profile_settings];
    }
    if (is_pro) {
      return [step_welcome, step_network, step_profile_network, step_orders_table, step_new_order, step_profile_settings, step_try_premium];
    }
    return [step_welcome, step_network, step_profile_network, step_orders_table, step_new_order, step_profile_settings];
  }

  get_steps_for__network () {
    const { appStore } = this.props;
    if (!appStore?.auth?.id_user || !appStore?.authProfile?.ID) {
      return [];
    }
    const is_premium = profilesService.isPremium(appStore?.authProfile);

    const step_welcome = {
      title: 'Le réseau Bridger',
      content: <Typography variant="body2" component="div">Découvrez les utilisateurs professionnels Bridger et consultez des catalogues de produits exclusifs aux utilisateurs Bridger.</Typography>,
      placement: 'center',
      target: 'body',
    };
    const step_infos = {
      title: 'Les profils Premium',
      content: <Typography variant="body2" component="div">Reconnaissables à <StarPlainIcon fontSize="small" color="primary" /><br />, les utilisateurs Premium bénéficient d'options avancées grâce auquelles ils peuvent proposer un catalogue de produits et gérer en temps réel les commandes de leurs abonnés.</Typography>,
      placement: 'center',
      target: 'body',
    };
    const step_search = {
      title: 'Trouvez la perle rare',
      content: <Typography variant="body2" component="div">Cherchez les partenaires dont vous avez besoin.</Typography>,
      placement: 'bottom',
      target: '#search-profiles--searchbar',
    };
    const step_search_filters = {
      title: 'Filtres de recherche',
      content: <Typography variant="body2" component="div">Affinez votre recherche par secteur d'activité, ou localisation.</Typography>,
      placement: 'bottom',
      target: '#search-profiles--searchbar-filters',
    };
    const step_invite = {
      title: 'Invitation',
      content: <Typography variant="body2" component="div">Vous ne trouvez pas votre bonheur parmi les utilisateurs Bridger ? Vous souhaitez enrichir le réseau ? Invitez un nouvel utilisateur à découvrir et rejoindre la communauté Bridger.</Typography>,
      placement: 'bottom',
      target: '#main-header--right-toolbar',
    };
    if (is_premium) {
      return [ step_welcome, step_infos, step_search, step_search_filters, step_invite];
    }
    return [ step_welcome, step_infos, step_search, step_search_filters];
  }
}

const mapStateToProps = state => ({
  appStore: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSetPresentationHomeActive: (presentationHomeActive) => dispatch(setPresentationHomeActive(presentationHomeActive)),
    dispatchSetPresentationNetworkActive: (presentationNetworkActive) => dispatch(setPresentationNetworkActive(presentationNetworkActive)),
  }
};

export default withHOCComponent(connect(mapStateToProps, mapDispatchToProps)(AppPresentationController));
