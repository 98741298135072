import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditSubscription from './../edit/EditSubscription';
import Dialog from './Dialog';

class DialogEditSubscription extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      title: 'Version Premium',
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { classes, open, onClose, fullScreen, keepMounted, id, ...others } = this.props;
    return (
      <Dialog
        keepMounted={keepMounted}
        id={id}
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        title={this.state.title}
      >
        <EditSubscription
          scrollableTarget={id}
          hideTitle={true}
          fixedCtrl={true}
          {...others}
        />
      </Dialog>
    )
  }
}

const styles = theme => ({});

DialogEditSubscription.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditSubscription);
