/**
 * Composant affichant un scroll infini (en tant que controller uniquement)
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import { RenewIcon } from './../commons/Icons';

import InfiniteScrollComponent from 'react-infinite-scroll-component';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

class InfiniteScroll extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.offset = 0;
    this.state = {
      loading: false,
      error: false,
      hasMore: true,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.reload !== prevProps.reload) {
      this.offset = 0;
      this.load();
    }
  }

  loadNext () {
    this.offset++;
    this.load();
  }

  setLoading (loading) {
    this._isMounted && this.setState({ loading: loading });
    this.props.onLoading && this.props.onLoading(loading);
  }

  load() {
    this.setLoading(true);
    this._isMounted && this.setState({ error: false });
    this.props.load(this.props.limit, (this.offset * this.props.limit)).then((items) => {
      /* 2024/07/04 - BUG révélé : l'API renvoi visiblement parfois 2 fois le même élément pour une pagination différente - donc pour limiter les effets indésirables
      dans l'app, on ne fait pas le traitement suivant (qui n'est pas bloquant) - en attendant de savoir pourquoi l'API merde.
      let all_items = null;
      const itemsLength = items ? items.length : 0;
      if (this.offset === 0) {
        all_items = items
      } else {
        // On s'assure que les items qui proviennent de l'API ne sont pas déjà dans la liste, sinon on les exclue tout simplement.
        // (Ce cas peut arriver lorsqu'un nouvel item a été ajouté lors d'une création avant un nouvel appel à l'API ce qui crée un décalage de pagination côté API)
        // NOTE : par contre cela est déjà arriver que l'API renvoie 2 items identiques sur 2 paginations différentes, ça ne devrait pas arriver.
        items = items.filter((item) => !this.props.items.find((state_item) => {
          const found = state_item[this.props.itemKey] === item[this.props.itemKey];
          if (found) {
            console.warn("List - existing item #", item[this.props.itemKey]);
          }
          return found;
        }));
        all_items = [...this.props.items, ...items];
      }
      // on met à jour l'état de la pagination
      this._isMounted && this.setState({ hasMore: itemsLength >= this.props.limit });
      // on envoi les items chargé à la liste
      this.props.loaded && this.props.loaded(all_items);
      */
      /* 2024/07/04 - nouveau code */
      let all_items = null;
      if (this.offset === 0) {
        all_items = items;
      } else {
        all_items = [...this.props.items, ...items];
      }
      // on met à jour l'état de la pagination
      this._isMounted && this.setState({ hasMore: items && items.length >= this.props.limit });
      // les items sont chargés, on propage
      this.props.loaded && this.props.loaded(all_items);
      /* 2024/07/04 - fin nouveau code */
    }).catch((e) => {
      this._isMounted && this.setState({ error: true });
      this.props.onError && this.props.onError(e);
    }).finally(() => {
      this.setLoading(false);
    });
  }

  render () {
    const { classes, items } = this.props;
    const { loading, error, hasMore } = this.state;

    if (error) {
      return (
        <Box className={classes.error}>
          <h4>Erreur de chargement</h4>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    }

    return (
      <InfiniteScrollComponent
        className={classes.content}
        dataLength={items.length} // This is important field to render the next data
        next={ () => { this.loadNext() }}
        hasMore={hasMore}
        scrollableTarget={this.props.scrollableTarget}
      >

        { this.props.renderItems(items) }

        { loading && (
          <Box className={clsx(classes.loading, { [classes.loadingGlobal]: this.offset === 0 && items && items.length > 0 })}>
            <CircularProgress color="secondary" />
          </Box>
        ) }

        { (hasMore && !loading) && (
          <Box className={classes.loading}>
            <Button variant="outlined" onClick={() => this.loadNext()}>En voir plus...</Button>
          </Box>
        ) }

        { ((!items || items.length === 0) && !loading) && (
          <Box className={classes.noResult}>
            { this.props.noResult || (
              <Typography variant="body1">Aucun résultat</Typography>
            ) }
          </Box>
        ) }
      </InfiniteScrollComponent>
    )
  }
}

const styles = theme => ({
  content: {
    position: 'relative',
    minHeight: 20,
  },
  loading: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  loadingGlobal: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
  error: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  noResult: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  endInfiniteScroll: {
    fontSize: 8,
    flexGrow: 1,
    textAlign: 'center',
    color: "#999999",
  },
});

InfiniteScroll.propTypes = {
  scrollableTarget: PropTypes.string,
  limit: PropTypes.number,
  items: PropTypes.array.isRequired,
  itemKey: PropTypes.string,
  reload: PropTypes.bool,
  load: PropTypes.func.isRequired, // methode de chargement des items, doit renvoyer la Promise
  loaded: PropTypes.func.isRequired, // appelé lorsque load à renvoyé des résultats
  noResult: PropTypes.element,
};

InfiniteScroll.defaultProps = {
  limit: 15,
  reload: false,
  itemKey: 'ID',
};

export default withStyles(styles, { withTheme: true })(InfiniteScroll);
