import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  login (login, password) {
    // Prepare params from args
    return API.post('/auth/', {login, password})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  logInByInvitation (invitationToken) {
    // Prepare params from args
    return API.post('/auth/invitation/', {invitationToken})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  refreshToken(refresh_token) {
    return API.post('/auth/refreshToken/', {refresh_token})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  setProfile(id_profile) {
    return API.post('/auth/profile/', {id_profile})
    .then(response => response.data)
    .catch(error => Promise.reject(error.response));
  },
  lostPassword(login) {
    return API.post('/auth/lostPassword/', {login})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  renewPassword(token, password, password_confirm) {
    return API.post('/auth/renewPassword/', {token, password, password_confirm})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  generatePassword() {
    return API.post('/auth/generatePassword/')
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  register(data) {
    return API.post('/auth/register/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  }
}
export default exportedObject;
