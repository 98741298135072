import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import OrderInfos from '../../order/OrderInfos';

class EditNewOrderStepResumeInfos extends React.Component {

  render () {
    const { order, permissions } = this.props;
    return (
      <OrderInfos order={order} permissions={permissions} />
    );
  }
}

const styles = theme => ({});

EditNewOrderStepResumeInfos.propTypes = {
  order: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditNewOrderStepResumeInfos);
