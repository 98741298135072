import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import PaymentMethod from '../paymentMethod/PaymentMethod';

class SubscriptionViewPaymentMethod extends React.Component {

  render () {
    const { classes, className, ...others } = this.props;
    return (
      <Box className={clsx(classes.root, className && className.root)}>
        <PaymentMethod
          {...others}
        />
      </Box>
    );
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
  },
});

SubscriptionViewPaymentMethod.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onUpdated: PropTypes.func,
  onDeleted: PropTypes.func,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(SubscriptionViewPaymentMethod);
