import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditUser from './../edit/EditUser';
import Dialog from './Dialog';

class DialogEditUser extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      title: '',
    }
  }

  componentDidMount () {
    this._isMounted = true;
    const { user } = this.props;
    if (user !== null) {
      // commande existante
      let title = "Modifier un utilisateur";
      this._isMounted && this.setState({
        title,
      });
    } else {
      // nouvelle commande
      this._isMounted && this.setState({
        title: "Nouvel utilisateur",
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { classes, open, onClose, fullScreen, keepMounted, ...others } = this.props;
    return (
      <Dialog
        keepMounted={keepMounted}
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        title={this.state.title}
      >
        <EditUser
          fixedCtrl={true}
          {...others}
        />
      </Dialog>
    )
  }
}

const styles = theme => ({});

DialogEditUser.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditUser);
