import Payments_API from './api/payments';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux paiements
****************************************************/

const exportedObject = {

  /**************** payment provider ************************************/
  /**
   * Récupère les paiements
   * @return Promise(data, notices) : les paiements
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Payments_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Paiements récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un paiement
   * @return Promise(data, notices) : le paiement
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Payments_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Paiement récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère la clé publique Stripe
   * @return Promise(data, notices)
   */
  getKey (args) {
    return new Promise((resolve, reject) => {
      Payments_API.getKey(args).then(({data, notices}) => {
        utilsService.debugLog('Stripe public key : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Prépare une intention de paiement
   * @return Promise(data, notices) : données Stripe
   */
  setupIntent (data) {
    return new Promise((resolve, reject) => {
      Payments_API.setupIntent(data).then(({data, notices}) => {
        utilsService.debugLog('Stripe setup intent : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Valide la préparation d'une intention de paiement
   * @return Promise(data, notices) : données Stripe
   */
  setupIntentSuccess (data) {
    return new Promise((resolve, reject) => {
      Payments_API.setupIntentSuccess(data).then(({data, notices}) => {
        utilsService.debugLog('Stripe setup intent success : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  /***************************** Extras ************************/

};
export default exportedObject;
