import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormControl from '@mui/material/FormControl';
import FormField from './FormField';
import TextFieldLimited from './TextFieldLimited';

class SetProfileName extends React.Component {

  render () {
    const { data, onChange } = this.props;

    return (
      <FormField>
        <FormControl>
          <TextFieldLimited
            required
            id="name"
            label="Nom"
            type="text"
            name="name"
            value={data.name || ''}
            onChange={ (value) => onChange({...data, ...{name: value}}) }
            limit={50}
            helperText="Nom du profil, visible sur le réseau Bridger.store (en fonction de vos paramètres de confidentialité)."
            autoComplete="organization"
          />
        </FormControl>
      </FormField>
    )
  }
}

const styles = theme => ({});

SetProfileName.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
};

SetProfileName.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetProfileName);
