import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  online: true,
  api_maintenance_mode: false,
  api_minimal_version: null,
  notifications_count: 0,
  push_granted: false, // demande de permission pour l'inscription service de notification push faite
  push_subscribed: false, // inscrit au service de notification push
  shortAction: null,
  auth: null, // information sur l'authentification de l'utilisateur (token, refresh_token, id_user, etc.)
  authProfile: null, // profil utilisé par l'utilisateur authentifié
  authTime: null, // moment où l'authentification change (refresh, logout, login, setProfil) en millisecond
  authLastIdProfile: null, // dernier profil utilisé dans l'app
  presentationHomeCard: true, // affiche/désaffiche la carte de présentation sur la Home
  presentationHomeActive: false, // active/désactive la présentation sur la Home
  presentationNetworkActive: false, // active/désactive la présentation sur le Network
  carts: {}, // paniers de produits (pour plusieurs fournisseurs)
  openAuthDialog: false,
  reloadOrders: false, // en attendant react-query...
  reloadProducts: false, // en attendant react-query...
  reloadPaymentMethods: false, // en attendant react-query...
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setOnline: (state, action) => {
      state.online = action.payload;
    },
    setApiMinimalVersion: (state, action) => {
      state.api_minimal_version = action.payload;
    },
    setApiMaintenanceMode: (state, action) => {
      state.api_maintenance_mode = action.payload;
    },
    setNotificationsCount: (state, action) => {
      state.notifications_count = action.payload;
    },
    setPushGranted: (state, action) => {
      state.push_granted = action.payload;
    },
    setPushSubscribed: (state, action) => {
      state.push_subscribed = action.payload;
    },
    setShortAction: (state, action) => {
      state.shortAction = action.payload;
    },
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
    setAuthProfile: (state, action) => {
      state.authProfile = action.payload;
      state.authLastIdProfile = state.authProfile?.ID;
    },
    setAuthTime: (state, action) => {
      state.authTime = action.payload;
    },
    setOpenAuthDialog: (state, action) => {
      state.openAuthDialog = action.payload;
    },
    setPresentationHomeCard: (state, action) => {
      state.presentationHomeCard = action.payload;
    },
    setPresentationHomeActive: (state, action) => {
      state.presentationHomeActive = action.payload;
    },
    setPresentationNetworkActive: (state, action) => {
      state.presentationNetworkActive = action.payload;
    },
    setCart: (state, action) => {
      const { id_profile_provider, order_items } = action.payload;
      state.carts = {...state.carts, [id_profile_provider]: order_items};
    },
    cleanCart: (state, action) => {
      state.carts = {};
    },
    setReloadOrders: (state, action) => {
      state.reloadOrders = !state.reloadOrders;
    },
    setReloadProducts: (state, action) => {
      state.reloadProducts = !state.reloadProducts;
    },
    setReloadPaymentMethods: (state, action) => {
      state.reloadPaymentMethods = !state.reloadPaymentMethods;
    },
  },
});

// export actions
export const {
  setOnline,
  setApiMaintenanceMode,
  setApiMinimalVersion,
  setNotificationsCount,
  setPushGranted,
  setPushSubscribed,
  setShortAction,
  setAuth,
  setAuthProfile,
  setAuthTime,
  setOpenAuthDialog,
  setPresentationHomeCard,
  setPresentationHomeActive,
  setPresentationNetworkActive,
  setCart,
  cleanCart,
  setReloadOrders,
  setReloadProducts,
  setReloadPaymentMethods,
} = appSlice.actions;

// export reducers
export default appSlice.reducer;
