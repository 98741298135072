import Orders_API from './api/orders';
import { store } from './store';
import utilsService from './utils';
import profilesService from './profiles';
import deliveryModeServices from './delivery-modes';

/****************************************************
 Interface avec l'API relative aux commandes
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
  STATE_DONE: 'done', // la commande est traitée
	STATE_PROCESSING: 'processing', // la commande est en traitement
	STATE_DRAFT: 'draft', // la commande est en brouillon (c'est notamment le cas lorsqu'elle n'a pas encore de produit)
	STATE_PENDING: 'pending', // la commande est en cours ('active' pour une commande récurrente)
  STATE_VALIDATED: 'validated', // la commande est validée
	STATE_CANCELED: 'canceled', // la commande est annulée ('suspendue' pour une commande récurrente)
	STATE_MISSED: 'missed', // la commande est manquée
	STATE_REFUSED: 'refused', // la commande est refusée
	STATE_ERROR :'error', // la commande est en erreur

  TYPE_STANDARD: 'standard',
  TYPE_RECURRENT: 'recurrent',
  TYPE_SAMPLE: 'sample',

  RECURRENCE_MONDAY: 'mon',
  RECURRENCE_TUESDAY: 'tue',
  RECURRENCE_WEDNESDAY: 'wed',
  RECURRENCE_THURSDAY: 'thu',
  RECURRENCE_FRIDAY: 'fri',
  RECURRENCE_SATURDAY: 'sat',
  RECURRENCE_SUNDAY: 'sun',

  SCENARIO_CUSTOMER: 'customer',
  SCENARIO_PROVIDER: 'provider',

  /**************** orders ************************************/
  /**
   * Récupère les commandes du serveur
   * @return Promise(data, notices) : les commandes
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Orders_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Commandes récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Compte les commandes du serveur
   * @return Promise(data, notices) : les commandes
   */
  count (args) {
    return new Promise((resolve, reject) => {
      Orders_API.count(args).then(({data, notices}) => {
        utilsService.debugLog('Commandes comptées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère une commande du serveur
   * @return Promise(data, notices) : la commande
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Orders_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Commande récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé une nouvelle commande
   * @return Promise(data, notices) : la commande créée
   */
  create (data) {
    return new Promise((resolve, reject) => {
      Orders_API.create(data).then(({data, notices}) => {
        utilsService.debugLog('Commande créée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour une commande
   * @return Promise(data, notices) : la commande mise à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Orders_API.update(id, data).then(({data, notices}) => {
        utilsService.debugLog('Commande mise à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime une commande
   * @return Promise(id, data, notices) : l'identifiant de la commande supprimée
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      Orders_API.delete(id).then(({data, notices}) => {
        utilsService.debugLog('Commande supprimée : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour le statut d'une commande
   * @return Promise(data, notices) : la commande mise à jour
   */
  print (id) {
    return new Promise((resolve, reject) => {
      Orders_API.print(id).then(({data, notices}) => {
        utilsService.debugLog('Commande imprimée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** order summary ************************************/
  /**
   * Renvoi la query permettant de récupérer les commandes en attente de validation
   * @param {object} args 
   * @returns object
   */
  getPendingQuery(args = {}) {
    return {...{
      type: this.TYPE_STANDARD,
      state: [this.STATE_PENDING],
      order: [{order_by: 'date_delivery', order: 'DESC'}]
    }, ...args};
  },
  /**
   * Renvoi la query permettant de récupérer les commandes annulées
   * @param {object} args 
   * @returns object
   */
  getCanceledQuery(args = {}) {
    return {...{
      type: this.TYPE_STANDARD,
      state: [this.STATE_CANCELED],
      order: [{order_by: 'date_delivery', order: 'DESC'}]
    }, ...args};
  },
  /**
   * Renvoi la query permettant de récupérer les commandes manquées
   * @param {object} args 
   * @returns object
   */
  getMissedQuery(args = {}) {
    return {...{
      type: this.TYPE_STANDARD,
      state: [this.STATE_MISSED],
      order: [{order_by: 'date_delivery', order: 'DESC'}]
    }, ...args};
  },
  /**
   * Renvoi la query permettant de récupérer les commandes refusées
   * @param {object} args 
   * @returns object
   */
  getRefusedQuery(args = {}) {
    return {...{
      type: this.TYPE_STANDARD,
      state: [this.STATE_REFUSED],
      order: [{order_by: 'date_delivery', order: 'DESC'}]
    }, ...args};
  },
  /**
   * Renvoi la query permettant de récupérer les commandes en erreur
   * @param {object} args 
   * @returns object
   */
  getErrorQuery(args = {}) {
    return {...{
      type: this.TYPE_STANDARD,
      state: [this.STATE_ERROR],
      order: [{order_by: 'date_delivery', order: 'DESC'}]
    }, ...args};
  },
  /**
   * Renvoi la query permettant de récupérer les commandes en brouillon
   * @param {object} args 
   * @returns object
   */
  getDraftsQuery(args = {}) {
    return {...{
      type: this.TYPE_STANDARD,
      state: [this.STATE_DRAFT],
      order: [{order_by: 'date_delivery', order: 'DESC'}]
    }, ...args};
  },
  /**
   * Renvoi la query permettant de récupérer les commandes récurrentes
   * @param {object} args 
   * @returns object
   */
  getRecurrentQuery(args = {}) {
    return {...{
      type: this.TYPE_RECURRENT,
      order: [{order_by: 'date_create', order: 'DESC'}]
    }, ...args};
  },
  /**************** order state ************************************/
  /**
   * Met à jour le statut d'une commande
   * @return Promise(data, notices) : la commande mise à jour
   */
  setState (id, state, state_comment = '') {
    return new Promise((resolve, reject) => {
      Orders_API.setState(id, state, state_comment).then(({data, notices}) => {
        utilsService.debugLog('Statut de commande mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** order items ************************************/
  /**
   * Met à jour les items d'une commande
   * @return Promise(data, notices) : la commande mise à jour
   */
  getOrderItems (id, args) {
    return new Promise((resolve, reject) => {
      Orders_API.getOrderItems(id, args).then(({data, notices}) => {
        utilsService.debugLog('Items de commande récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour les items d'une commande
   * @return Promise(data, notices) : la commande mise à jour
   */
  setOrderItems (id, order_items) {
    return new Promise((resolve, reject) => {
      Orders_API.setOrderItems(id, order_items).then(({data, notices}) => {
        utilsService.debugLog('Items de commande mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** others ************************************/
  /**
   * Vérifie que tous les attributs de la commande nécessaires au bon fonctionnement de l'application sont présents
   * @return boolean
   */
  isOrderDataComplete (order) {
    if (!order || order === undefined || typeof order !== 'object') {
      return false;
    }
    if (!order.ID) { // attribut indispensable
      return false;
    }
    if (!order.id_user_customer) { // attribut indispensable
      return false;
    }
    if (!order.reference) { // attribut indispensable
      return false;
    }
    if (!order.state) { // attribut indispensable
      return false;
    }
    if (!order.date_delivery) { // attribut indispensable
      return false;
    }
    if (!order.order_items) { // attribut indispensable pour que l'utilisateur puisse ajouter ou retirer des produits
      return false;
    }
    return true;
  },
  /**
   * Affiche le libellé du statut
   * @return string
   */
  getStateLabel (slug, order_type = '') {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.STATE_DONE: {
        return 'terminée';
      }
      case this.STATE_PROCESSING: {
        return 'en traitement';
      }
      case this.STATE_VALIDATED: {
        return 'acceptée';
      }
      case this.STATE_DRAFT: {
        return 'brouillon';
      }
      case this.STATE_PENDING: {
        if (order_type === this.TYPE_RECURRENT) {
          return 'activée';
        } else if (order_type === this.TYPE_SAMPLE) {
          return 'actif';
        }
        return 'en attente';
      }
      case this.STATE_CANCELED: {
        if (order_type === this.TYPE_RECURRENT) {
          return 'désactivée';
        } else if (order_type === this.TYPE_SAMPLE) {
          return 'suspendu';
        }
        return 'annulée';
      }
      case this.STATE_MISSED: {
        return 'manquée';
      }
      case this.STATE_REFUSED: {
        return 'refusée';
      }
      case this.STATE_ERROR: {
        return 'en erreur';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Affiche le libellé du statut d'une commande
   * @return string
   */
  getOrderStateLabel (order) {
    if (!order) {
      return 'aucun';
    }
    return this.getStateLabel(order.state, order.type);
  },
  /**
   * Renvoi le slug de la couleur du statut de la commande
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * NOTE : la couleur peur être null dans certains cas.
   * @return string
   */
  getStateColor (slug, order_type = '') {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.STATE_DONE: {
        return 'order_state_done';
      }
      case this.STATE_PROCESSING: {
        return 'order_state_processing';
      }
      case this.STATE_VALIDATED: {
        return 'order_state_validated';
      }
      case this.STATE_DRAFT: {
        return null;
      }
      case this.STATE_PENDING: {
        if (order_type === this.TYPE_RECURRENT || order_type === this.TYPE_SAMPLE) {
          return 'order_state_done';
        }
        return 'order_state_pending';
      }
      case this.STATE_CANCELED: {
        if (order_type === this.TYPE_RECURRENT || order_type === this.TYPE_SAMPLE) {
          return null;
        }
        return 'order_state_error';
      }
      case this.STATE_MISSED: {
        return 'order_state_error';
      }
      case this.STATE_REFUSED: {
        return 'order_state_error';
      }
      case this.STATE_ERROR: {
        return 'order_state_error';
      }
      default: {
        return null;
      }
    }
  },
  /**
   * Renvoi le slug de la couleur du statut de la commande
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * @return string
   */
  getOrderStateColor (order) {
    if (!order) {
      return null;
    }
    return this.getStateColor(order.state, order.type);
  },
  /**
   * Indique si la commande est en statut 'terminée'
   * @return boolean
   */
  isStateDone(order) {
    if (order && !order.state) {
      console.warn("isStateDone - la commande n'a pas de statut");
    }
    return order && order.state && order.state === this.STATE_DONE ? true : false;
  },
  /**
   * Indique si la commande est en statut 'en traitement'
   * @return boolean
   */
  isStateProcessing(order) {
    if (order && !order.state) {
      console.warn("isStateProcessing - la commande n'a pas de statut");
    }
    return order && order.state && order.state === this.STATE_PROCESSING ? true : false;
  },
  /**
   * Indique si la commande est en statut 'en brouillon'
   * @return boolean
   */
  isStateDraft(order) {
    if (order && !order.state) {
      console.warn("isStateDraft - la commande n'a pas de statut");
    }
    return order && order.state && order.state === this.STATE_DRAFT ? true : false;
  },
  /**
   * Indique si la commande est en statut 'en attente'
   * @return boolean
   */
  isStatePending(order) {
    if (order && !order.state) {
      console.warn("isStatePending - la commande n'a pas de statut");
    }
    return order && order.state && order.state === this.STATE_PENDING ? true : false;
  },
  /**
   * Indique si la commande est en statut 'validée'
   * @return boolean
   */
  isStateValidated(order) {
    if (order && !order.state) {
      console.warn("isStateValidated - la commande n'a pas de statut");
    }
    return order && order.state && order.state === this.STATE_VALIDATED ? true : false;
  },
  /**
   * Indique si la commande est en statut 'annulée'
   * @return boolean
   */
  isStateCanceled(order) {
    if (order && !order.state) {
      console.warn("isStateCanceled - la commande n'a pas de statut");
    }
    return order && order.state && order.state === this.STATE_CANCELED ? true : false;
  },
  /**
   * Indique si la commande est en statut 'manquée'
   * @return boolean
   */
  isStateMissed(order) {
    if (order && !order.state) {
      console.warn("isStateMissed - la commande n'a pas de statut");
    }
    return order && order.state && order.state === this.STATE_MISSED ? true : false;
  },
  /**
   * Indique si la commande est en statut 'refusée'
   * @return boolean
   */
  isStateRefused(order) {
    if (order && !order.state) {
      console.warn("isStateRefused - la commande n'a pas de statut");
    }
    return order && order.state && order.state === this.STATE_REFUSED ? true : false;
  },
  /**
   * Indique si la commande est en statut 'en erreur'
   * @return boolean
   */
  isStateError(order) {
    if (order && !order.state) {
      console.warn("isStateError - la commande n'a pas de statut");
    }
    return order && order.state && order.state === this.STATE_ERROR ? true : false;
  },
  /**
   * Indique si la commande est de type 'standard'
   * @return boolean
   */
  isStandard (order) {
    return order && order.type === this.TYPE_STANDARD;
  },
  /**
   * Indique si la commande est de type 'récurrente'
   * @return boolean
   */
  isRecurrent (order) {
    return order && order.type === this.TYPE_RECURRENT;
  },
  /**
   * Indique si la commande est de type 'modèle'
   * @return boolean
   */
  isSample (order) {
    return order && order.type === this.TYPE_SAMPLE;
  },
  /**
   * Indique si la commande est soumise à modération
   * @return boolean
   */
  isModerate (order) {
    return order && (order.moderation === true || order.moderation === 'true' || order.moderation === '1' || order.moderation === 1);
  },
  /**
   * Indique si la commande a été facturée
   * @return boolean
   */
  hasInvoice (order) {
    return order && order.id_invoice;
  },
  /**
   * Indique si la commande est émise automatiquement
   * @return boolean
   */
  isAutoEmit (order) {
    return order && (order.auto_emit === true || order.auto_emit === 'true' || order.auto_emit === '1' || order.auto_emit === 1);
  },
  /**
   * Indique si la commande est issue d'une récurrence
   * @return boolean
   */
  isRecurrentInstance (order) {
    return order && order.id_order_recurrent && !isNaN(order.id_order_recurrent) ? true : false;
  },
  /**
   * Retourne une chaine de caractères comprenant le détail de la récurrence
   * @return array
   */
  getPrettyRecurrenceDetail (order) {
    if (!this.isRecurrent(order)) {
      return "";
    }
    if (!order.recurrence_day) {
      return "";
    }
    return "Chaque " + utilsService.getDayLabelFromSlug(order.recurrence_day) + " à " + (order?.recurrence_time || 'aucun horaire');
  },
  /**
   * Retourne une chaine de caractères comprenant le détail de la récurrence dont est issue l'instance
   * @return array
   */
  getPrettyRecurrenceInstanceDetail (order) {
    if (!this.isRecurrentInstance(order)) {
      return "";
    }
    if (!order.order_recurrent.recurrence_day) {
      return "";
    }
    return "Chaque " + utilsService.getDayLabelFromSlug(order?.order_recurrent?.recurrence_day) + " : " + (order?.order_recurrent?.recurrence_time || 'aucun horaire');
  },
  /**
   * Affiche le type du mode de livraison d'une commande
   * @return string
   */
  getOrderDeliveryModeTypeLabel (order, capitalize = false) {
    return deliveryModeServices.getTypeLabel(order?.delivery_mode?.type, capitalize);
  },
  /**
   * Affiche le scenario d'une commande
   * @return string
   */
  getOrderScenarioLabel (order) {
    let orderType = 'Bon de commande créé ';
    if (this.isRecurrentInstance(order)) {
      if (this.isStateDraft(order)) {
        return 'Commande issu d\'une récurrence ';
      }
      return 'Bon de commande issu d\'une récurrence ';
    }
    if (this.isStateDraft(order)) {
      orderType = 'Commande créée ';
    } else if (this.isRecurrent(order)) {
      orderType = 'Récurrence créée ';
    }
    const order_customer = order?.profile_customer?.name;
    const order_provider = order?.profile_provider?.name;
    const scenario = order?.scenario;
    switch (scenario) {
      case this.SCENARIO_CUSTOMER: {
        return orderType + 'par ' + order_customer;
      }
      case this.SCENARIO_PROVIDER: {
        return  orderType + ' par ' + order_provider;
      }
      default: {
        return '';
      }
    }
  },
  /**
   * Renvoi le label du scenario
   * @return string
   */
  getScenarioLabel (scenario, capitalize = false) {
    switch (scenario) {
      case this.SCENARIO_CUSTOMER: {
        return capitalize ? 'Créée par le client' : 'créée par le client';
      }
      case this.SCENARIO_PROVIDER: {
        return capitalize ? 'Créée par le fournisseur' : 'créée par le fournisseur';
      }
      default: {
        return '';
      }
    }
  },
  /**
   * Renvoi si la commande a été passée par le client
   * @param {object} order 
   */
  isScenarioCustomer (order) {
    return order?.scenario === this.SCENARIO_CUSTOMER;
  },
  /**
   * Renvoi si la commande a été prise par le fournisseur
   * @param {object} order 
   */
  isScenarioProvider (order) {
    return order?.scenario === this.SCENARIO_PROVIDER;
  },
  /**
   * Renvoi si le profil authentifié est le fournisseur de la commande
   * @param {object} order 
   */
  isAuthProvider (order) {
    const storeState = store.getState();
    const id_auth_profile = storeState?.app?.authProfile.ID;
    if (id_auth_profile && order?.id_profile_provider && parseInt(id_auth_profile) === parseInt(order?.id_profile_provider)) {
      return true;
    }
    return false;
  },
  /**
   * Renvoi si le profil authentifié est le client de la commande
   * @param {object} order 
   */
  isAuthCustomer (order) {
    const storeState = store.getState();
    const id_auth_profile = storeState?.app?.authProfile.ID;
    if (id_auth_profile && order?.id_profile_customer && parseInt(id_auth_profile) === parseInt(order?.id_profile_customer)) {
      return true;
    }
    return false;
  },
  /**
   * Renvoie les permissions de l'utilisateur connecté sur la commande
   * @param {object} order 
   */
  getOrderPermissions (order) {
    const isAuthProvider = this.isAuthProvider(order);
    const isAuthCustomer = this.isAuthCustomer(order);
    let permissions = {
      is_auth_provider: isAuthProvider, // indique si le profil authentifié est le fournisseur de la commande
      is_auth_customer: isAuthCustomer, // indique si le profil authentifié est le client de la commande
      is_restrained: false,
      can_store: false,
      can_update: false,
      can_delete: false,
      can_create_message: false,
      can_create_invoice: false,
      can_make_done: false,
      can_make_processing: false,
      can_make_validated: false,
      can_make_pending: false,
      can_make_canceled: false,
      can_make_refused: false,
      can_print: false,
    };

    if (!order) {
      console.warn("getOrderPermissions - commande manquante");
    }

    if (isAuthProvider) {
      // --
      // -- c'est le fournisseur qui est connecté
      // --
      permissions.is_restrained = order.restrained;
      if (this.isStandard(order)) {
        // -- commande standard
        if (this.isScenarioProvider(order)) {
          // -- commande prise par le fournisseur
          permissions.can_store = this.isStateDraft(order) || this.isStateCanceled(order) || this.isStateValidated(order) || this.isStateProcessing(order);
          permissions.can_update = this.isStateDraft(order) || this.isStateCanceled(order) || this.isStateValidated(order) || this.isStateProcessing(order);
          permissions.can_print = this.isStateDraft(order) || this.isStateValidated(order) || this.isStateProcessing(order) || this.isStateDone(order);
          permissions.can_create_message = this.isStateDraft(order) || this.isStatePending(order) || this.isStateValidated(order) || this.isStateProcessing(order);
          permissions.can_create_invoice = this.isStateDone(order) && !this.hasInvoice(order);
          permissions.can_make_pending = this.isStateDraft(order) || this.isStateCanceled(order);
          permissions.can_make_canceled = this.isStatePending(order);
          permissions.can_make_done = this.isStateValidated(order) || this.isStateProcessing(order);
          permissions.can_make_processing = this.isStateValidated(order) || this.isStateMissed(order);
          if (this.isModerate(order)) {
            // -- commande soumise à modération
            permissions.can_make_validated = this.isStatePending(order) || this.isStateMissed(order);
            permissions.can_make_refused = this.isStatePending(order) || this.isStateValidated(order) || this.isStateProcessing(order);
          } else {
            // -- commande sans modération
            permissions.can_make_processing = this.isStateValidated(order) || this.isStateMissed(order);
            permissions.can_make_refused = this.isStateValidated(order) || this.isStateProcessing(order);
          }
        } else {
          // -- commande passée par le client
          permissions.can_store = this.isStateValidated(order) || this.isStateProcessing(order);
          permissions.can_update = this.isStateValidated(order) || this.isStateProcessing(order);
          permissions.can_print = this.isStateValidated(order) || this.isStateProcessing(order) || this.isStateDone(order);
          permissions.can_create_message = this.isStatePending(order) || this.isStateValidated(order) || this.isStateProcessing(order);
          permissions.can_create_invoice = this.isStateDone(order) && !this.hasInvoice(order);
          permissions.can_make_done = this.isStateValidated(order) || this.isStateProcessing(order);
          if (this.isModerate(order)) {
            // -- commande soumise à modération
            permissions.can_make_processing = this.isStateValidated(order) || this.isStateMissed(order);
            permissions.can_make_validated = this.isStatePending(order) || this.isStateMissed(order);
            permissions.can_make_refused = this.isStatePending(order) || this.isStateValidated(order) || this.isStateProcessing(order);
          } else {
            // -- commande sans modération
            permissions.can_make_processing = this.isStateValidated(order) || this.isStateMissed(order);
            permissions.can_make_refused = this.isStateValidated(order) || this.isStateProcessing(order);
          }
        }
      } else if (this.isRecurrent(order)) {
        // -- commande récurrente
        if (this.isScenarioProvider(order)) {
          // -- commande prise par le fournisseur
          permissions.can_store = true;
          permissions.can_update = true;
          permissions.can_delete = true;
          permissions.can_create_message = true;
          permissions.can_make_pending = this.isStateCanceled(order) || this.isStateError(order);
          permissions.can_make_canceled = this.isStatePending(order) || this.isStateError(order);
        }
      } else if (this.isSample(order)) {
        // -- modèle de commande
        if (this.isScenarioProvider(order)) {
          // -- commande prise par le fournisseur
          permissions.can_store = true;
          permissions.can_update = true;
          permissions.can_delete = true;
          permissions.can_create_message = true;
        }
      }
    } else if (isAuthCustomer) {
      // --
      // -- c'est le client qui est connecté
      // --
      permissions.is_restrained = false; // l'affichage d'une commande n'est jamais restreinte pour le client
      if (this.isStandard(order)) {
        // -- commande standard
        permissions.can_store = this.isStateDraft(order) || this.isStateCanceled(order);
        permissions.can_update = this.isStateDraft(order) || this.isStateCanceled(order);
        permissions.can_delete = this.isStateDraft(order) || this.isStateCanceled(order) || this.isStateRefused(order);
        permissions.can_create_message = this.isStateDraft(order) || this.isStatePending(order) || this.isStateValidated(order) || this.isStateProcessing(order);
        permissions.can_make_pending = this.isStateDraft(order) || this.isStateCanceled(order);
        permissions.can_make_canceled = this.isStatePending(order);
      } else if (this.isRecurrent(order)) {
        // -- commande récurrente
        permissions.can_store = true;
        permissions.can_update = true;
        permissions.can_delete = true;
        permissions.can_create_message = true;
        permissions.can_make_pending = this.isStateCanceled(order) || this.isStateError(order);
        permissions.can_make_canceled = this.isStatePending(order) || this.isStateError(order);
      } else if (this.isSample(order)) {
        // -- modèle de commande
        permissions.can_store = true;
        permissions.can_update = true;
        permissions.can_delete = true;
        permissions.can_create_message = true;
      }
    }
    return permissions;
  },
  /**
   * Renvoie l'avatar de la commande en fonction de l'utilisateur connecté
   * @param {object} order 
   */
  getAvatar (order) {
    const isAuthProvider = this.isAuthProvider(order);
    if (isAuthProvider) {
      return profilesService.getAvatar(order.profile_customer);
    } else {
      return profilesService.getAvatar(order.profile_provider);
    }
  },
  /**
   * Renvoie le contact de livraison de la commande, donc le contact de type delivery du profil client
   * Si l'profil n'a pas de contact de livraison, son contact par défaut est utilisé
   * @param {object} order 
   */
  getShippingContact (order) {
    if (order && order.profile_customer && order.profile_customer.contacts && order.profile_customer.contacts.length > 0) {
      let contacts = order.profile_customer.contacts.filter((contact) => contact.role === profilesService.CONTACT_ROLE_SHIPPING);
      if (contacts.length > 0) {
        return contacts[0];
      }
      contacts = order.profile_customer.contacts.filter((contact) => contact.role === profilesService.CONTACT_ROLE_DEFAULT);
      if (contacts.length > 0) {
        return contacts[0];
      }
    }
    return null;
  },
  /**
   * Renvoie le contact du fournisseur de la commande, donc le contact de type default du profil fournisseur
   * @param {object} order 
   */
  getProviderContact (order) {
    if (order && order.profile_provider && order.profile_provider.contacts && order.profile_provider.contacts.length > 0) {
      let contacts = order.profile_provider.contacts.filter((contact) => contact.role === profilesService.CONTACT_ROLE_DEFAULT);
      if (contacts.length > 0) {
        return contacts[0];
      }
    }
    return null;
  },
};
export default exportedObject;
