import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import ordersService from '../../../services/orders';

import { Box, Button, Typography } from '@mui/material';
import { CheckIcon } from '../../commons/Icons';

class EditNewOrderFinalized extends React.Component {

  render () {
    const { order, onEnd, classes } = this.props;
    if (!order) {
      return null;
    }
    return (
      <Box className={classes.root}>
        { !ordersService.isRecurrent(order) && (
          <>
            { ordersService.isStatePending(order) && (
              <>
                <Typography className={classes.title} component="div" variant="h5">Votre bon de commande est en attente de validation</Typography>
              </>
            ) }
            { ordersService.isStateValidated(order) && (
              <>
                <Typography className={classes.title} component="div" variant="h5">Votre bon de commande a été accepté</Typography>
              </>
            ) }
            <Typography className={classes.infos} component="div" variant="body1">Vous serez tenu(e) informé(e) de son évolution.<br />Retrouver ce bon de commande depuis votre tableau de bord.</Typography>
          </>
        ) }
        { ordersService.isRecurrent(order) && (
          <>
            { ordersService.isStateCanceled(order) && (
              <>
                <Typography className={classes.title} component="div" variant="h5">La récurrence est désactivée</Typography>
              </>
            ) }
            { ordersService.isStatePending(order) && (
              <>
                <Typography className={classes.title} component="div" variant="h5">La récurrence est maintenant activée</Typography>
              </>
            ) }
            <Typography className={classes.infos} component="div" variant="body1">Retrouver ce modèle de récurrence depuis votre tableau de bord.</Typography>
          </>
        ) }
        { ordersService.isStandard(order) && (
          <Typography className={classes.reference} component="div" variant="body3">Référence de votre bon de commande<br />{ order.reference }</Typography>
        ) }
        <Box className={classes.action}>
          <Button variant="outlined" color="primary" onClick={() => onEnd()} startIcon={<CheckIcon />}>Fermer</Button>
        </Box>
      </Box>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  sep: {
    width: '100%',
    height: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    fontWeight: 600,
  },
  infos: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  reference: {
    padding: theme.spacing(1),
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    border: '1px solid ' + theme.palette.bg.light,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

EditNewOrderFinalized.propTypes = {
  order: PropTypes.object.isRequired,
  onEnd: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditNewOrderFinalized);
