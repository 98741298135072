import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from '../../../services/profiles';
import { isEmpty } from '../../../services/utils';

import EditControllers from '../../commons/EditControllers';
import { ArrowLeftIcon, ArrowRightIcon } from '../../commons/Icons';
import BackdropLoading from '../../commons/BackdropLoading';
import EditNewProfileType from './EditNewProfileType';
import EditNewProfileVisibility from './EditNewProfileVisibility';
import EditNewProfileGeneral from './EditNewProfileGeneral';

import { Box, Button, Typography } from '@mui/material';

class EditNewProfile extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      profile: null, // profil une fois créé
      loading: false,
      step: 'type',
      data: props.preset || {},
      partProfileAlreadyExists: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
    const { appStore, onError } = this.props;
    this._isMounted && this.setState({loading: true});
    profilesService.count({id_user_owned_profiles: appStore?.auth?.id_user, type: profilesService.TYPE_PART}).then((count) => {
      if (parseInt(count) > 0) {
        this._isMounted && this.setState({partProfileAlreadyExists: true});
      }
    }).catch((error) => {
      onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false})
    });
  }

  save (e) {
    const { data } = this.state;
    const { onError, onSaved } = this.props;
    e.preventDefault();
    const parsed_data = {
      name: data.name,
      description: data.description,
      type: data.type,
      state: data.state,
      visibility: data.visibility,
      btoc: data.btoc || false,
      business_sector: data.business_sector,
      id_profile_sponsor: data.id_profile_sponsor,
      options: {
        legal: data.legal,
      },
      contact: data.contact
    }
    this._isMounted && this.setState({loading: true});
    profilesService.create(parsed_data).then(([id, notices]) => {
      notices && notices.length > 0 && onError(notices);
      // récupération de l'item à jour
      return profilesService.get(id);
    }).then(([profile]) => {
      // on propage au parent que le profil est créé
      onSaved && onSaved(profile);
    }).catch((error) => {
      onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false})
    });
    return false;
  }

  isStepCompleted_type () {
    const { data } = this.state;
    return !isEmpty(data.type);
  }

  isStepCompleted_visibility () {
    const { data } = this.state;
    return !isEmpty(data.visibility);
  }

  isStepCompleted_general () {
    const { data } = this.state;
    if (isEmpty(data.name)) {
      return false;
    }
    if (data.type === profilesService.TYPE_PRO) {
      if (isEmpty(data.business_sector)) {
        return false;
      }
      if (isEmpty(data.legal?.name)) {
        return false;
      }
      if (isEmpty(data.legal?.siret)) {
        return false;
      }
      if (isEmpty(data.legal?.type)) {
        return false;
      }
      if (isEmpty(data.contact?.address_name)) {
        return false;
      }
      if (isEmpty(data.contact?.address_1)) {
        return false;
      }
      if (isEmpty(data.contact?.address_cp)) {
        return false;
      }
      if (isEmpty(data.contact?.address_city)) {
        return false;
      }
      if (isEmpty(data.contact?.address_country)) {
        return false;
      }
    }
    return true;
  }

  nextStep () {
    const { onStepChange } = this.props;
    const { step } = this.state;
    let newStep = null;
    if (step === 'type') {
      newStep = 'visibility';
    } else if (step === 'visibility') {
      newStep = 'general';
    }
    this.setState({step: newStep});
    onStepChange && onStepChange(newStep);
  }

  prevStep () {
    const { onStepChange } = this.props;
    const { step } = this.state;
    let newStep = null;
    if (step === 'visibility') {
      newStep = 'type';
    } else if (step === 'general') {
      newStep = 'visibility';
    }
    this.setState({step: newStep});
    onStepChange && onStepChange(newStep);
  }

  render () {
    const { classes } = this.props;
    return (
      <Box className={classes.container}>
        { this.render_step() }
      </Box>
    );
  }

  render_step () {
    const { classes, onError, onCancel, appStore, disableTypePart } = this.props;
    const { data, step, loading, partProfileAlreadyExists } = this.state;

    return (
      <Box className={classes.root}>

        <BackdropLoading open={loading} />

        { step === 'type' && (
          <Box className={classes.step}>
            <Box className={classes.step_title}>
              <Typography variant="h6" component="div">Type de profil</Typography>
            </Box>
            <Box className={classes.step_content}>
              <EditNewProfileType
                data={data}
                onChange={(data) => {
                  const defaultName = appStore?.auth?.user_name || '';
                  // nom du profil par défaut (celui de l'utilisateur connecté)
                  if (isEmpty(data.name)) {
                    data.name = defaultName;
                  }
                  // dénomination sociale par défaut pour les pros (le nom du profil)
                  if (data.type === profilesService.TYPE_PRO && isEmpty(data?.legal?.name)) {
                    data = {...data, ...{legal: {...data?.legal, ...{name: defaultName}}}};
                  }
                  this.setState({data});
                  !isEmpty(data.type) && this.nextStep();
                }}
                onError={onError}
                disableTypePart={disableTypePart || partProfileAlreadyExists}
                disableTypePartMessage={partProfileAlreadyExists ? "(Vous avez déjà un profil personnel)" : undefined}
              />
            </Box>
            <EditControllers sticky>
              { !!onCancel && (
                <Button className={classes.btn_prev} onClick={ () => onCancel() } color="secondary" variant="outlined" startIcon={ <ArrowLeftIcon /> }></Button>
              ) }
              <Button disabled={!this.isStepCompleted_type()} className={classes.btn_next} onClick={ () => this.nextStep() } color="primary" variant="contained" endIcon={ <ArrowRightIcon /> }>
                Suivant
              </Button>
            </EditControllers>
          </Box>
        ) }

        { step === 'visibility' && (
          <Box className={classes.step}>
            <Box className={classes.step_title}>
              <Typography variant="h6" component="div">Confidentialité du profil</Typography>
            </Box>
            <Box className={classes.step_content}>
              <EditNewProfileVisibility
                data={data}
                onChange={(data) => {
                  this.setState({data});
                  !isEmpty(data.visibility) && this.nextStep();
                }}
                onError={onError}
              />
            </Box>
            <EditControllers sticky>
              <Button className={classes.btn_prev} onClick={ () => this.prevStep() } color="secondary" variant="outlined" startIcon={ <ArrowLeftIcon /> }></Button>
              <Button disabled={!this.isStepCompleted_visibility()} className={classes.btn_next} onClick={ () => this.nextStep() } color="primary" variant="contained" endIcon={ <ArrowRightIcon /> }>
                Suivant
              </Button>
            </EditControllers>
          </Box>
        ) }

        { step === 'general' && (
          <Box className={classes.step}>
            <Box className={classes.step_title}>
              <Typography variant="h6" component="div">Informations générales</Typography>
            </Box>
            <Box className={classes.step_content}>
              <EditNewProfileGeneral
                data={data}
                onChange={(data) => {
                  this.setState({data});
                }}
                onError={onError}
              />
            </Box>
            <EditControllers sticky>
              <Button className={classes.btn_prev} onClick={ () => this.prevStep() } color="secondary" variant="outlined" startIcon={ <ArrowLeftIcon /> }></Button>
              <Button disabled={!this.isStepCompleted_general()} className={classes.btn_next} onClick={ (e) => this.save(e) } color="primary" variant="contained">
                Créer mon profil
              </Button>
            </EditControllers>
          </Box>
        ) }

      </Box>
    );
  }
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0),
  },
  btn_next: {
    width: '100%',
  },
  btn_prev: {},
  step: {},
  step_title: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 2),
    textDecoration: 'underline',
  },
  step_content: {
    padding: theme.spacing(0, 2),
  },
});

const mapStateToProps = state => ({
  appStore: state.app,
});

EditNewProfile.propTypes = {
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  preset: PropTypes.object,
  onStepChange: PropTypes.func,
  disableTypePart: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditNewProfile));
