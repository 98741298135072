import React from 'react';
import { withStyles } from '@mui/styles';

import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, Box } from '@mui/material';
import { EyeIcon, EyeHideIcon } from './Icons';

class PasswordField extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  render () {
    const { classes, classNameRoot, ...others } = this.props;
    const { showPassword } = this.state;
    return (
      <Box className={classNameRoot}>
        <TextField
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => this.setState({showPassword: !showPassword})}
                >
                  {showPassword ? <EyeIcon /> : <EyeHideIcon />}
                </IconButton>
              </InputAdornment>
            )
          }}
          {...others}
        />
      </Box>
    );
  }
}

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(PasswordField);
