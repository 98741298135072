import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { subscribeToPush, unsubscribeToPush, isPushEnabled } from '../../services/push';

import { BellIcon, UnavailableIcon } from '../commons/Icons';

import { Box, Button, Typography } from '@mui/material';

class EditProfileNotifications extends React.Component {

  render () {
    const { classes, appStore } = this.props;
    if (!isPushEnabled()) {
      return null;
    }
    return (
      <Box className={classes.container}>

        { !appStore?.push_subscribed && (
          <>
            <Typography variant="body2" component="div" className={classes.infos}>
            Les notifications push permettent d'être informé en temps réel des actualités de votre profil (suivi de commande, réseau...).
            </Typography>
            <Button variant="outlined" fullWidth onClick={ () => subscribeToPush() } startIcon={<BellIcon />}>
              Activez les notifications push
            </Button>
          </>
        ) }
        { appStore?.push_subscribed && (
          <>
            <Typography variant="body2" component="div" className={classes.infos}>
              Les notifications push sont activées sur cet appareil. Si vous constatez que vous ne les recevez pas, vous pouvez essayer de désactiver et d'activer de nouveau.
            </Typography>
            <Button variant="outlined" fullWidth onClick={ () => unsubscribeToPush() } startIcon={<UnavailableIcon />}>
              Désactivez les notifications push
            </Button>
          </>
        ) }

      </Box>
    )
  }

}

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  infos: {
    marginBottom: theme.spacing(2),
  },
});

EditProfileNotifications.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const mapStateToProps = state => ({
  appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditProfileNotifications));
