import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import subscriptionsService from './../../services/subscriptions';
import authService from './../../services/auth';
import subscriptionPlansService from './../../services/subscription-plans';
import utilsService from './../../services/utils';

import BackdropLoading from './../commons/BackdropLoading';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

class SubscriptionViewHeader extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  activate (e) {
    e.preventDefault();
    const { subscription } = this.props;
    this._isMounted && this.setState({ loading: true });
    subscriptionsService.activate(subscription.ID).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      this.props.onUpdated && this.props.onUpdated(id);

      // Mise à jour du profil authentifié (afin qu'il prenne en compte la souscription)
      return authService.maybeRefreshToken(subscription.id_profile);
    }).catch((error) => {
      this.props.onError && this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
    return false;
  }

  render () {
    const { classes, className } = this.props;
    const { loading } = this.state;
    return (
      <Box className={clsx(classes.root, className && className.root)}>
        <BackdropLoading open={loading} />
        { this.renderContent() }
        { this.renderActions() }
      </Box>
    );
  }

  renderContent () {
    const { classes, subscription } = this.props;
    if (!subscription) {
      return null;
    }
    const subscriptionStateColor = subscriptionsService.getSubscriptionStateColor(subscription);
    const date = subscriptionsService.isStateActive(subscription) ? subscription.date_end : subscription.date_update;
    const date_prefix = subscriptionsService.isStateActive(subscription) ? "jusqu'au " : "le ";

    return (
      <Box className={classes.header}>
        <Typography className={classes.title} component="div" variant="h5">Abonnement</Typography>
        <Typography className={classes.subtitle} component="div" variant="body3">{ subscription.reference }</Typography>
        <Typography className={classes.state} component="div" variant="body3" sx={{
          backgroundColor: subscriptionStateColor && (subscriptionStateColor + '.main'),
          color: subscriptionStateColor && (subscriptionStateColor + '.contrastText'),
        }}>
          { subscriptionsService.getSubscriptionStateLabel(subscription) }
        </Typography>
        { date && (
          <Box className={classes.dates}>
            <Typography className={classes.date_issuance} component="div" variant="body3">
               { date_prefix + utilsService.dateStringToLocalString(date, true, false) }
            </Typography>
          </Box>
        ) }
      </Box>
    );

  }

  renderActions () {
    const { classes, subscription } = this.props;
    if (!subscription) {
      return null;
    }
    const is_free = subscriptionPlansService.isFree(subscription.plan);

    let actions = [];
    if (!subscriptionsService.isStateActive(subscription)) {
      actions.push(<Button disabled={is_free} className={classes.action_item_btn} color="quaternary" onClick={ (e) => this.activate(e) }>Activer l'abonnement</Button>);
    }

    if (actions && actions.length > 0) {
      return (
        <Box className={classes.actions}>
          { actions.map((action, index) => {
            return <Box className={classes.action_item} key={index}>{action}</Box>
          }) }
        </Box>
      );
    }
    return null;
  }
}

const styles = theme => ({
  root: {
    padding: 0,
    backgroundColor: '#fff',
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    padding: theme.spacing(2),
    paddingLeft: '60px',
    paddingRight: '60px',
  },
  title: {
    marginBottom: theme.spacing(0.25),
    fontWeight: 600,
  },
  subtitle: {},
  state: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 1),
    textTransform: 'uppercase',
  },
  short_actions: {
    position: 'absolute',
    top: theme.spacing(1),
    right: 0,
    bottom: theme.spacing(1),
    left: 'auto',
    width: '60px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dates: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  actions: {
    width: '100%',
  },
  action_item: {
    borderTop: `1px solid ${theme.palette.tertiary.main}`,
  },
  action_item_btn: {
    width: '100%',
  },
});

SubscriptionViewHeader.propTypes = {
  subscription: PropTypes.object.isRequired,
  onUpdated: PropTypes.func,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(SubscriptionViewHeader);
