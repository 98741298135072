import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormFields from '../../commons/FormFields';
import SetOrderDelivery from '../../commons/SetOrderDelivery';

class EditNewOrderDelivery extends React.Component {

  render () {
    const { order, onChange, onError } = this.props;

    return (
      <FormFields>
        <SetOrderDelivery
          data={order}
          onChange={(order) => onChange(order)}
          onError={onError}
        />
     </FormFields>
    );
  }
}

const styles = theme => ({});

EditNewOrderDelivery.propTypes = {
  onChange: PropTypes.func,
  order: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditNewOrderDelivery);
