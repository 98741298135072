import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormFields from '../../commons/FormFields';
import SetProfileVisibility from '../../commons/SetProfileVisibility';

class EditNewProfileVisibility extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { data, onChange } = this.props;

    return (
      <FormFields>
        <SetProfileVisibility
          data={data}
          onChange={(data) => onChange(data)}
        />
      </FormFields>
    );
  }
}

const styles = theme => ({});

EditNewProfileVisibility.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.object,
};

EditNewProfileVisibility.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(EditNewProfileVisibility);
