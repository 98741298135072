import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import deliveryModesService from '../../services/delivery-modes';

import FormField from './FormField';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

class SetDeliveryModeRequiredTime extends React.Component {

  render () {
    const { classes, data, onChange } = this.props;
    let helperText = "Délai moyen de mise à disponibilité d'une commande pour une livraison (hors temps de préparation, hors temps de livraison)";
    if (data.type === deliveryModesService.TYPE_PICKUP) {
      helperText = "Délai moyen de mise à disponibilité d'une commande en retrait (hors temps de préparation)";
    }

    return (
        <FormField>
          <FormControl className={classes.form_control}>
            <TextField
              id="required_time"
              name="required_time"
              label="Délai de mise à disposition (h)"
              type="number"
              className={classes.input}
              value={data?.required_time || ''}
              onChange={ (e) => onChange({...data, ...{required_time: e.target.value}}) }
              helperText={helperText}
            />
          </FormControl>
        </FormField>
    )
  }
}

const styles = theme => ({
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
});

SetDeliveryModeRequiredTime.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
};

SetDeliveryModeRequiredTime.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetDeliveryModeRequiredTime);
