import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import profileRelsService from '../../services/profile-rels';
import profilesService from '../../services/profiles';

import Dialog from './Dialog';
import { DeleteIcon, RenewIcon } from '../commons/Icons';

import { Avatar, Box, Button, CircularProgress, DialogContent, IconButton, Typography } from '@mui/material';
import ConfirmDialog from '../commons/ConfirmDialog';

class DialogDeleteProfileRel extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      error: false,
      items: [],
      openConfirm_delete: false,
      to_delete_item: null,
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * En fonction des paramètres passé, on tente de récupérer la ou les relations à supprimer.
   * Si plusieurs relations existent, on propose à l'utilisateur de choisir celle qu'il veut supprimer,
   * sinon on demande simplement une confirmation
   */
  load () {
    this._isMounted && this.setState({ loading: true, error: false });
    this._load().then(([items]) => {
      this._isMounted && this.setState({ items });
    }).catch((e) => {
      this._isMounted && this.setState({ error: true });
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  async _load () {
    const { id_profile_customer, id_profile_provider } = this.props;
    let query = {};
    if (id_profile_customer) {
      query.id_profile_customer = id_profile_customer
    }
    if (id_profile_provider) {
      query.id_profile_provider = id_profile_provider
    }
    return profileRelsService.getAll(query);
  }

  openDelete() {
    this._isMounted && this.setState({openConfirm_delete: true})
  }
  closeDelete() {
    this._isMounted && this.setState({openConfirm_delete: false})
  }

  delete () {
    const { to_delete_item } = this.state;
    if (to_delete_item) {
      this._isMounted && this.setState({ loading: true, error: false });
      profileRelsService.delete(to_delete_item.ID).then(([items]) => {
        this._isMounted && this.setState({ to_delete_item: null });
        return this.deleteSuccess();
      }).catch((e) => {
        this._isMounted && this.setState({ error: true });
      }).finally(() => {
        this._isMounted && this.setState({ loading: false });
      });
    }
  }

  async deleteSuccess () {
    // on met à jour les relations affichées à l'utilisateur
    return this._load().then(([items]) => {
      this._isMounted && this.setState({ items });
    })
  }

  render_content () {
    const { classes } = this.props;
    const { loading, error, items } = this.state;
    if (error) {
      return (
        <Box className={classes.loading_error}>
          <h4>Erreur de chargement</h4>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    } else if (loading) {
      return (
        <Box className={classes.loading_error}>
          <CircularProgress color="secondary" />
        </Box>
      );
    } else if (!items || items.length < 1) {
      return (
        <Box className={classes.loading_error}>
          <Typography variant="body2" component="div">Aucune relation trouvée</Typography>
        </Box>
      );
    } else {
      return (
        <Box className={classes.list}>
          { items.map((item, index) => {
            const profile_provider_avatar = profilesService.getAvatar(item?.profile_provider);
            const profile_customer_avatar = profilesService.getAvatar(item?.profile_customer);
            return (
              <Box key={index} className={clsx(classes.item, { [classes.item_last]: index === (items.length - 1) })}>
                <Typography variant="h6">Relation</Typography>
                <Box className={classes.item_profile}>
                  <Avatar {...profile_provider_avatar.props}>{ profile_provider_avatar.avatar !== true && profile_provider_avatar.avatar }</Avatar>
                  <Typography variant="body2" component="span" className={classes.item_profile_title}>{item?.profile_provider?.name}</Typography>
                </Box>
                <Typography variant="body2" component="div" className={classes.item_rel}>est fournisseur de</Typography>
                <Box className={classes.item_profile}>
                  <Avatar {...profile_customer_avatar.props}>{ profile_customer_avatar.avatar !== true && profile_customer_avatar.avatar }</Avatar>
                  <Typography variant="body2" component="span" className={classes.item_profile_title}>{item?.profile_customer?.name}</Typography>
                </Box>
                <Button className={classes.item_action} variant="outlined" startIcon={<DeleteIcon />} onClick={() => {
                  this._isMounted && this.setState({to_delete_item: item});
                  this.openDelete();
                }}>Supprimer cette relation</Button>
              </Box>
            )
          }) }
        </Box>
      );
    }
  }

  render () {
    const { open, onClose, id } = this.props;
    
    return (
      <Dialog
        keepMounted={false}
        id={id}
        fullScreen={false}
        disableGutters={true}
        open={open}
        onClose={onClose}
      >
        <DialogContent>
          { this.render_content() }
        </DialogContent>
        { this.state.openConfirm_delete && (
          <ConfirmDialog
            open={this.state.openConfirm_delete}
            message="Voulez-vous vraiment supprimer cette relation ? Cette action est irréversible."
            onConfirm={ () => {
              this.closeDelete();
              this.delete();
            } }
            onClose={ () => this.closeDelete() }
          />
        ) }
      </Dialog>
    )
  }
}

const styles = theme => ({
  loading_error: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  item: {
    padding: theme.spacing(1, 0),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.bg.light}`,
  },
  item_last: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(0),
    borderBottom: `none`,
  },
  item_rel: {
    margin: theme.spacing(1, 0),
  },
  item_profile: {
    display: 'flex',
    alignItems: 'center'
  },
  item_profile_title: {
    marginLeft: theme.spacing(1),
    fontWeight: 600,
  },
  item_action: {
    marginTop: theme.spacing(1),
  }
});

DialogDeleteProfileRel.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogDeleteProfileRel);
