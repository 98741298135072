import Complaints_API from './api/complaints';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux signalements
****************************************************/

const exportedObject = {

  /**************** business-sectors ************************************/
  /**
   * Récupère les plaintes
   * @return Promise(data, notices)
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Complaints_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Signalements récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un signalement
   * @return Promise(data, notices)
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Complaints_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Signalement récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un signalement
   * @return Promise(data, notices)
   */
  create (data) {
    return new Promise((resolve, reject) => {
      Complaints_API.create(data).then(({data, notices}) => {
        utilsService.debugLog('Signalement créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un signalement
   * @return Promise(data, notices)
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Complaints_API.update(id, data).then(({data, notices}) => {
        utilsService.debugLog('Signalement mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un signalement
   * @return Promise(data, notices)
   */
  delete (id, data) {
    return new Promise((resolve, reject) => {
      Complaints_API.delete(id, data).then(({data, notices}) => {
        utilsService.debugLog('Signalement supprimé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  /***************************** Extras ************************/

};
export default exportedObject;
