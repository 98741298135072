import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import utilsService from './../../services/utils';

import SelectTimeHours from './SelectTimeHours';
import SelectTimeMinutes from './SelectTimeMinutes';
import { PlusIcon, DeleteIcon } from './../commons/Icons';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

class SetTimes extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      selected_hours: "00",
      selected_minutes: "00",
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.setState({
      selected_hours: this.props.defaultHours,
      selected_minutes: this.props.defaultMinutes
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  add () {
    this.props.onChange && this.props.onChange(this.cleanDuplication([
      ...this.props.value,
      ...[ this.state.selected_hours + ':' + (this.props.fixedMinutes ? this.props.fixedMinutes : this.state.selected_minutes) ]
    ]));
    this._isMounted && this.setState({ selected_hours: utilsService.getHours2Digits((parseInt(this.state.selected_hours) < 23 ? parseInt(this.state.selected_hours) + 1 : 0)) });
  }

  update (time, hours, minutes) {
    let value = this.props.value.map((value_time) => {
      if (value_time !== time) {
        return value_time;
      }
      const time_parts = value_time.split(':');
      if (time_parts.length < 2) {
        return value_time;
      }
      return (hours ? hours : time_parts[0]) + ':' + (minutes ? minutes : time_parts[1]);
    });
    this.props.onChange && this.props.onChange(this.cleanDuplication(value));
  }

  cleanDuplication (value) {
    let cleanedValue = [];
    if (value && value.length > 0) {
      value.forEach((v) => {
        if (!cleanedValue.includes(v)) {
          cleanedValue.push(v);
        }
      });
    }
    return cleanedValue;
  }

  remove (time) {
    this.props.onChange && this.props.onChange(this.props.value.filter((value_time) => value_time !== time));
  }

  render () {
    const { classes, label, value, fixedMinutes } = this.props;
    const { selected_hours, selected_minutes } = this.state;

    return (
      <Box className={classes.content}>

        { label && (
          <Typography className={classes.label}>{ label }</Typography>
        ) }

        { value && value.map((time) => {
          let hours = 8;
          let minutes = 0;
          if (time) {
            const time_parts = time.split(':');
            if (time_parts.length > 0) {
              hours = parseInt(time_parts[0]);
            }
            if (time_parts.length > 1) {
              minutes = parseInt(time_parts[1]);
            }
          }
          return (
            <Card key={time} className={classes.item}>
              <Box className={classes.selects}>
                <SelectTimeHours
                  value={ hours }
                  onChange={ (value, value_str) => this.update(time, value_str, null) }
                />
                <Typography className={classes.time_sep} component="span">:</Typography>
                { fixedMinutes && (
                  <Typography className={classes.fixed_minutes} component="span">{fixedMinutes}</Typography>
                ) }
                { !fixedMinutes && (
                  <SelectTimeMinutes
                    value={ minutes }
                    onChange={ (value, value_str) => this.update(time, null, value_str) }
                  />
                ) }
              </Box>
              <IconButton aria-label="delete" className={classes.item_button} size="small" onClick={ () => this.remove(time) }>
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Card>
          )
        }) }

        <Card className={classes.item}>
          <Box className={classes.selects}>
            <SelectTimeHours
              value={ parseInt(selected_hours) }
              onChange={ (value, value_str) => this._isMounted && this.setState({selected_hours: value_str}) }
            />
            <Typography className={classes.time_sep} component="span">:</Typography>
            { fixedMinutes && (
              <Typography className={classes.fixed_minutes} component="span">{fixedMinutes}</Typography>
            ) }
            { !fixedMinutes && (
              <SelectTimeMinutes
                value={ parseInt(selected_minutes) }
                onChange={ (value, value_str) => this._isMounted && this.setState({selected_minutes: value_str}) }
              />
            ) }
          </Box>
          <Button
            aria-label="add"
            variant="outlined"
            size="small"
            className={classes.item_button}
            startIcon={<PlusIcon fontSize="inherit" />}
            onClick={ () => this.add() }
          >
            Ajouter
          </Button>
        </Card>

      </Box>
    );
  }
}

const styles = theme => ({
  item: {
    padding: theme.spacing(1, 1, 1, 2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item_delete: {
  },
  item_name: {
    fontWeight: 'bold'
  },
  action: {
    display: 'flex',
    marginLeft: theme.spacing(4),
  },
  action_select: {
    marginRight: theme.spacing(2),
  },
  action_button: {},
  selects: {
    display: 'flex',
    alignItems: 'center',
  },
  time_sep: {
    margin: theme.spacing(0, 2),
  },
  fixed_minutes: {
  },
});

SetTimes.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  fixedMinutes: PropTypes.string, // désactive la notion de minute en prenant systématiquement cette valeur - format "00"
  defaultHours: PropTypes.string,
  defaultMinutes: PropTypes.string,
};

SetTimes.defaultProps = {
  defaultHours: "00",
  defaultMinutes: "00",
};

export default withStyles(styles, { withTheme: true })(SetTimes);
