import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async get (id, params) {
    return API.get('/invoices/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getAll (params) {
    return API.get('/invoices/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async count (params) {
    return API.get('/invoices/count', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async downloadAll (params) {
    return API.get('/invoices/download', { params, responseType: 'arraybuffer' })
      .catch(error => Promise.reject(error.response));
  },
  async download (id, params) {
    return API.get('/invoices/' + id + '/download', { params, responseType: 'arraybuffer' })
      .catch(error => Promise.reject(error.response));
  },
  async getBase64 (id, params = {}) {
    return API.get('/invoices/' + id + '/download', { ...{...params, ...{base64: true}} })
      .catch(error => Promise.reject(error.response));
  },
  async createForOrder (id_order) {
    return API.post('/invoices/order/' + id_order)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async createForPayment (id_payment) {
    return API.post('/invoices/payment/' + id_payment)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async createForOrders (data) {
    return API.post('/invoices/orders', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async delete (id) {
    return API.delete('/invoices/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async setState (id, state, state_comment = '') {
    return API.post('/invoices/' + id + '/state', {state, state_comment})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
