import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import utilsService from './../../services/utils';
import subscriptionPlansService from './../../services/subscription-plans';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

class SubscriptionPlan extends React.Component {

  render () {
    const { classes, className, center, plan, title } = this.props;
    const is_free = subscriptionPlansService.isFree(plan);
    return (
      <Box className={clsx(classes.root, className && className.root, {[classes.root_center]: center} )}>
        { title && (
          <Typography variant="h4" component="div">{title}</Typography>
        ) }
        <Typography className={classes.title} component="div" variant="h5">{plan.name}</Typography>
        <Typography className={classes.info} component="p" variant="body3">{plan.description}</Typography>
        { !is_free && (
          <Box className={classes.info_price}>
            <Typography className={classes.info_price_ht} component="div" variant="body3">Prix : {utilsService.prettyPrice(plan.price, '€', 'HT')}</Typography>
            <Typography className={classes.info_price_ttc} component="div" variant="body3"> ({utilsService.prettyPrice(plan.price_ttc, '€')}TTC / TVA {plan.tax_rate}%)</Typography>
          </Box>
        ) }
        { is_free && (
          <Typography className={classes.info_free} component="div" variant="body3">Gratuit, sans engagement et sans CB</Typography>
        ) }
      </Box>
    );
  }
}

const styles = theme => ({
  root: {
    textAlign: 'left',
  },
  root_center: {
    textAlign: 'center',
  },
  title: {
    fontWeight: 600,
    textDecoration: 'underline',
  },
  info_price_ht: {
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
  },
  info_free: {
    fontWeight: 500,
  }
});

SubscriptionPlan.propTypes = {
  plan: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  title: PropTypes.string,
  center: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(SubscriptionPlan);
