import Invitations_API from './api/invitations';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux utilisateurs
****************************************************/

const exportedObject = {

  /**
   * Constantes
   */
	TYPE_SPONSORED_REGISTER: 'sponsored-register', // parrainage pour la création d'un compte utilisateur
  TYPE_COLLABORATE: 'collaborate', // invitation à collaborer avec le profil demandeur
  TYPE_SUBSCRIBE: 'subscribe', // invitation à s'abonner au profil demandeur

 	STATE_ACCEPTED: 'accepted', // invitation acceptée
 	STATE_PENDING: 'pending', // invitation en cours
 	STATE_ERROR: 'error', // invitation en erreur

  /**
   * Récupère une invitation du serveur
   * @return Promise(data) : l'invitation'
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Invitations_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Invitation récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
 getByToken (token, args) {
   return new Promise((resolve, reject) => {
     Invitations_API.getByToken(token, args).then(({data, notices}) => {
       utilsService.debugLog('Invitation récupérée par token : ', data, 'avertissements : ', notices);
       resolve([data, notices]);
     }).catch((error) => {
       reject(error);
     });
   });
 },
  /**
   * Récupère les invitations du serveur
   * @return Promise(data) : les invitations
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Invitations_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Invitations récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Crée une invitation
   * @return Promise()
   */
  create (data) {
    return new Promise((resolve, reject) => {
      Invitations_API.create(data).then(({data, notices}) => {
        utilsService.debugLog('Invitation créée', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour une invitation
   * @return Promise(data) : l'invitation mise à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Invitations_API.update(id, data).then(({data, notices}) => {
        utilsService.debugLog('Invitation mise à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  registerUser (token, data) {
    return new Promise((resolve, reject) => {
      Invitations_API.registerUser(token, data).then(({data, notices}) => {
        utilsService.debugLog('Utilisateur créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  accept (token, data = {}) {
    return new Promise((resolve, reject) => {
      Invitations_API.accept(token, data).then(({data, notices}) => {
        utilsService.debugLog('Invitation acceptée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  request (data) {
    return new Promise((resolve, reject) => {
      Invitations_API.request(data).then(({data, notices}) => {
        utilsService.debugLog('Demande d\'invitation : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  }
};
export default exportedObject;
