import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withHOCComponent } from '../hoc/HOCComponent';
import invitationsService from '../../services/invitations';
import authService from '../../services/auth';
import { isEmpty } from '../../services/utils';

import BackdropLoading from '../commons/BackdropLoading';
import { ArrowRightIcon } from '../commons/Icons';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import PasswordNewField from '../commons/PasswordNewField';

class AuthUserRegister extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  create (e) {
    const { onAuthenticated, invitationToken, invitation, data } = this.props;
    e.preventDefault();

    if ((invitation && isEmpty(invitation.email)) || isEmpty(data.login)) {
      this.props.hoc.showError('Email manquant');
      return false;
    }
    if (isEmpty(data.lastname)) {
      this.props.hoc.showError('Nom manquant');
      return false;
    }
    if (isEmpty(data.firstname)) {
      this.props.hoc.showError('Prénom manquant');
      return false;
    }
    if (isEmpty(data.password)) {
      this.props.hoc.showError('Mot de passe manquant');
      return false;
    }
    let terms_of_service = !isEmpty(invitation?.terms_of_service) ? invitation?.terms_of_service : (data.terms_of_service ? process.env.REACT_APP_SITE_CU_VERSION : null);
    if (isEmpty(terms_of_service)) {
      this.props.hoc.showError('Vous devez accepter les conditions d\'utilisation');
      return false;
    }
    const registrationData = {
      email: data.login,
      lastname: data.lastname,
      firstname: data.firstname,
      password: data.password,
      terms_of_service
    }
    if (invitation) {
      // dans le cas d'une invitation
      this._isMounted && this.setState({loading: true});
      invitationsService.registerUser(invitationToken, registrationData).then(([data, notices]) => {
        notices && notices.length > 0 && this.props.hoc.showError(notices);
        // le compte est créé, l'API a authentifié l'utilisateur et nous renvoit les données d'authentification
        // on authentifie donc ce nouvel utilisateur dans l'app
        return authService.onAuthenticated(data);
      }).then((data) => {
        onAuthenticated && onAuthenticated(data);
      }).catch((error) => {
        this.props.hoc.showError(error);
      }).finally(() => {
        this._isMounted && this.setState({loading: false});
      });
    } else {
      // dans le cas d'une inscription sans invitation
      this._isMounted && this.setState({loading: true});
      authService.register(registrationData).then((data) => {
        // le compte est créé et l'utilisateur est authentifié dans l'app
        onAuthenticated && onAuthenticated(data);
      }).catch((error) => {
        this.props.hoc.showError(error);
      }).finally(() => {
        this._isMounted && this.setState({loading: false});
      });
    }
    return false;
  }

  render () {
    const { loading } = this.state;
    const { classes, data, onChangeData, onChangeAction, invitation } = this.props;
    return (
      <Box>
        <BackdropLoading open={loading} />
        <form onSubmit={ (e) => this.create(e) } className={classes.form} noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  required
                  id="email"
                  label="Email"
                  type="email"
                  className={classes.input}
                  value={data.login || ''}
                  onChange={ (e) => onChangeData({...data, ...{login: e.target.value}}) }
                  autoComplete="username"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  required
                  id="lastname"
                  label="Nom"
                  type="text"
                  className={classes.input}
                  value={data.lastname || ''}
                  onChange={ (e) => onChangeData({...data, ...{lastname: e.target.value}}) }
                  autoComplete="family-name"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  required
                  id="firstname"
                  label="Prénom"
                  type="text"
                  className={classes.input}
                  value={data.firstname || ''}
                  onChange={ (e) => onChangeData({...data, ...{firstname: e.target.value}}) }
                  autoComplete="given-name"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <PasswordNewField
                  inputProps={{autoCapitalize: 'none', autoComplete: "new-password"}}
                  required
                  id="password"
                  label="Mot de passe"
                  className={classes.input}
                  value={data.password || ''}
                  onChange={ (password) => onChangeData({...data, ...{password}}) }
                />
              </FormControl>
            </Grid>
            { isEmpty(invitation?.terms_of_service) && (
              <Grid item xs={12}>
                <FormControlLabel className={classes.formControl_legal}
                  control={
                    <Switch
                      required
                      checked={data.terms_of_service || false}
                      onChange={ (e) => onChangeData({...data, ...{terms_of_service: e.target.checked}}) }
                      inputProps={{ 'id': 'accept-legal', 'aria-label': "acceptation légales" }}
                    />
                  }
                  label={
                    <Typography component="div" variant="body2" className={classes.legal}>J'accepte les <a className={classes.legal_lnk} href={process.env.REACT_APP_SITE_URL_CU} target="_blank" rel="noreferrer">Conditions d'utilisation</a> du site Web {process.env.REACT_APP_MAIN_BASE_URL}</Typography>
                  }
                />
              </Grid>
            ) }
            <Grid item xs={12}>
              <Button className={classes.button} variant="contained" endIcon={<ArrowRightIcon />} type="submit" onClick={ (e) => this.create(e) }>
                Continuer
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" className={classes.button} color="primary" onClick={() => onChangeAction('login')}>
                J'ai déjà un compte
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }

}

const styles = theme => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
  formControl_legal: {
    marginBottom: theme.spacing(1),
  },
  legal_lnk: {
    color: theme.palette.primary.main,
  },
});

AuthUserRegister.propTypes = {
  onAuthenticated: PropTypes.func.isRequired,
  onChangeAction: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onChangeData: PropTypes.func.isRequired,
  invitation: PropTypes.object, // si l'authentification est faite dans le contexte d'une invitation
  invitationToken: PropTypes.string, // si l'authentification est faite dans le contexte d'une invitation
};

export default withHOCComponent(withStyles(styles, { withTheme: true })(AuthUserRegister));