import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import usersService from '../../services/users';
import utilsService from '../../services/utils';

import ListItemContent from './../commons/ListItemContent';
import ListItemContentSummary1 from './../commons/ListItemContentSummary1';
import ListItemContentSummary2 from './../commons/ListItemContentSummary2';
import ListItemContentSummary3 from './../commons/ListItemContentSummary3';

class UserListItem extends React.Component {

  render() {
    const { user } = this.props;

    const userAvatar = usersService.getAvatar(user);
    return (
      <ListItemContent
        avatar={userAvatar.content}
        avatarProps={userAvatar.props}
        right={this.renderRight()}
        summary={this.renderSummary()}
      />
    )
  }

  renderSummary () {
    const { user } = this.props;
    return (
      <React.Fragment>
        <ListItemContentSummary1>
          {utilsService.showDebugID(user)}{user.display_name}
        </ListItemContentSummary1>
        { user.contact && user.contact.address_city && (
          <ListItemContentSummary2>
            { user.contact.address_city }
          </ListItemContentSummary2>
        ) }
        { user.email && (
          <ListItemContentSummary3>
            { user.email }
          </ListItemContentSummary3>
        ) }
      </React.Fragment>
    )
  }

  renderRight () {
    const { right } = this.props;
    if (!right) {
      return null;
    }
    return right;
  }
}

const styles = theme => ({
  details: {},
  detail: {
    marginBottom: theme.spacing(0.5),
  },
  detail_with_start_icon: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  detail_icon: {
    marginRight: theme.spacing(1),
    color: '#555555',
    fontSize: 16,
  },
  detail_txt: {
  },
  details_sep: {
    margin: theme.spacing(0.5, 0),
    padding: 0,
    width: 80,
    height: 1,
    backgroundColor: theme.palette.bg.main,
    clear: 'both',
  },
});

UserListItem.propTypes = {
  user: PropTypes.object,
  onUpdated: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(UserListItem);
