import React from 'react';
import { withStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

class BackdropLoading extends React.Component {

  render () {
    const { classes } = this.props;
    return (
      <Backdrop className={classes.backdrop} open={this.props.open}>
        <Box className={classes.backdropContent}>
          <CircularProgress color="inherit" />
        </Box>
      </Backdrop>
    )
  }
}

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
  backdropContent: {
    textAlign: 'center',
  }
});

export default withStyles(styles, { withTheme: true })(BackdropLoading);
