import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import utilsService from './../../services/utils';

import BackdropLoading from './../commons/BackdropLoading';
import ListItemContent from './../commons/ListItemContent';
import ListItemContentSummary1 from './../commons/ListItemContentSummary1';
import ListItemContentSummary2 from './../commons/ListItemContentSummary2';
import ListItemContentSummary3 from './../commons/ListItemContentSummary3';
import PaymentInvoiceButton from './PaymentInvoiceButton';
import { Box } from '@mui/material';

class PaymentsListItem extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <React.Fragment>
        <ListItemContent
          right={this.renderRight()}
          summary={this.renderSummary()}
        />
        <BackdropLoading open={this.state.loading} />
      </React.Fragment>
    )
  }

  renderSummary () {
    const { classes, payment } = this.props;
    return (
      <React.Fragment>
        <ListItemContentSummary1>
          Montant : { utilsService.prettyPrice(payment.amount, '€', 'TTC') }
        </ListItemContentSummary1>
        <ListItemContentSummary2>
          Abonnement : { payment.subscription ? payment.subscription.reference : 'inconnu' }
        </ListItemContentSummary2>
        <ListItemContentSummary3>
          Date : { utilsService.dateStringToLocalString(payment.date_create, true, true) }
        </ListItemContentSummary3>
        <Box className={classes.download}>
          <PaymentInvoiceButton id_payment={payment.ID} />
        </Box>
      </React.Fragment>
    )
  }

  renderRight () {
    const { right } = this.props;
    if (!right) {
      return null;
    }
    return right;
  }
}

const styles = theme => ({
  download: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(0.5,0,0,0),
  },
});

PaymentsListItem.propTypes = {
  payment: PropTypes.object,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  disableExpand: PropTypes.bool,
  right: PropTypes.element,
};

export default withStyles(styles, { withTheme: true })(PaymentsListItem);
