import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

class SelectTimeMinutes extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.items = [
      { value: 0, label: '00' },
      { value: 5, label: '05' },
      { value: 10, label: '10' },
      { value: 15, label: '15' },
      { value: 20, label: '20' },
      { value: 25, label: '25' },
      { value: 30, label: '30' },
      { value: 35, label: '35' },
      { value: 40, label: '40' },
      { value: 45, label: '45' },
      { value: 50, label: '50' },
      { value: 55, label: '55' },
    ];
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getLabel (value) {
    const items = this.items.filter((item) => item.value === value);
    return (items && items.length > 0) ? items[0].label : 'Erreur de récupération du label';
  }

  render () {
    const { classes, className, label, value } = this.props;
    const form_control = className ? className.form_control : null;
    return (
      <FormControl className={clsx(form_control, classes.form_control)} disabled={this.props.disabled}>
        <InputLabel id="select-minutes">{ label || 'Minutes' }</InputLabel>
        <Select
          className={classes.select_field}
          labelId="select-minutes"
          label={label || 'Minutes'}
          id="select-minutes"
          value={value || 0}
          onChange={ (e) => this.props.onChange && this.props.onChange(e.target.value, this.getLabel(e.target.value)) }
        >
          { this.items.map((item) => {
            return item.component || (
              <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
            )
          }) }
        </Select>
      </FormControl>
    )
  }
}

const styles = theme => ({
  form_control: {
    position: 'relative',
  },
});

SelectTimeMinutes.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(SelectTimeMinutes);
