import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditProductClassification from '../edit/EditProductClassification';
import DialogBase from './DialogBase';
import { DialogContent, DialogTitle } from '@mui/material';

class DialogEditProductClassification extends React.Component {

  render () {
    const { classes, open, onClose, ...others } = this.props;
    const scrollableTargetId = "dialog-edit-product-classification";
    return (
      <DialogBase
        open={open}
        onClose={onClose}
      >
        <DialogTitle>Catégorisation du produit</DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}} id={scrollableTargetId}>
          <EditProductClassification
            scrollableTarget={scrollableTargetId}
            {...others}
          />
        </DialogContent>
      </DialogBase>
    )
  }
}

const styles = theme => ({
  dialogContent: {
    padding: theme.spacing(0),
  },
});

DialogEditProductClassification.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditProductClassification);
