import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profileUserRelsService from '../../services/profile-user-rels';

import Dialog from './Dialog';
import { DeleteIcon } from '../commons/Icons';

import { Button, DialogContent, Typography } from '@mui/material';
import BackdropLoading from '../commons/BackdropLoading';

class DialogDeleteProfileUserRel extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  delete () {
    const { id_profile_user_rel } = this.props;
    this._isMounted && this.setState({ loading: true });
    profileUserRelsService.delete(id_profile_user_rel).then(([items]) => {
      return this.props.onDeleted && this.props.onDeleted();
    }).catch((e) => {
      this._isMounted && this.props.onError(e);
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  render () {
    const { classes, open, onClose, id_profile_user_rel } = this.props;
    const { loading } = this.state;
    
    return (
      <Dialog
        keepMounted={false}
        id={"" + id_profile_user_rel}
        fullScreen={false}
        disableGutters={true}
        open={open}
        onClose={onClose}
      >
        <DialogContent>
          <BackdropLoading open={loading} />
          <Typography>Vous êtes sur le point de retirer un utilisateur du profil, l'utilisateur ne pourra plus l'utiliser <em>(note : l'utilisateur n'est pas supprimé)</em>.</Typography>
          <Button className={classes.item_action} variant="contained" startIcon={<DeleteIcon />} onClick={() => {
            this.delete();
          }}>Retirer l'utilisateur</Button>
        </DialogContent>
      </Dialog>
    )
  }
}

const styles = theme => ({
  item_action: {
    marginTop: theme.spacing(1),
    width: '100%',
  }
});

DialogDeleteProfileUserRel.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onDeleted: PropTypes.func,
  id_profile_user_rel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogDeleteProfileUserRel);
