import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './services/store';
import networkService from './services/network';
import pushService from './services/push';
import { BrowserRouter as Router } from "react-router-dom";
import AppUpdateController from './components/main/AppUpdateController';
import AppNotificationsController from './components/main/AppNotificationsController';
import PushNotificationsController from './components/main/PushNotificationsController';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
import CssBaseline from '@mui/material/CssBaseline';

// Date provider
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';

import Main from './views/Main';

class App extends React.Component {

  constructor (props) {
    super(props);
    this.color_orange = '#ff5549';
    this.color_grey = '#555555';
    this.color_grey_medium = '#aaaaaa';
    this.color_grey_light = '#cccccc';
    this.color_blue = '#0b69ff';
    this.color_blue_dark = '#031B4E';
    this.color_yellow = '#fff088';
    this.color_green = '#00de88';
    this.color_red = '#e81a1a';
    this.color_black = '#000000';
    this.color_white = '#ffffff';
    this.customTheme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          mobile: 400,
        },
      },
      palette: {
        primary: {
          main: this.color_orange,
          contrastText: this.color_white,
        },
        secondary: {
          main: this.color_grey,
          contrastText: this.color_white,
        },
        tertiary: {
          main: this.color_grey_light,
          contrastText: this.color_white,
        },
        quaternary: {
          main: this.color_blue,
          contrastText: this.color_white,
        },
        black: {
          main: this.color_black,
        },
        info: {
          main: this.color_grey_light,
          contrastText: this.color_white,
        },
        success: {
          main: this.color_green,
          contrastText: this.color_white,
        },
        warning: {
          main: this.color_yellow,
          contrastText: this.color_black,
        },
        error: {
          main: this.color_red,
          contrastText: this.color_white,
        },
        divider: this.color_orange,
        bg: {
          white: '#ffffff',
          main: '#e1e1e1',
          light: '#f1f1f1',
          dark: '#b4b4b4',
        },
        /****** Profile ******/
        profile_premium: {
          main: this.color_orange,
          contrastText: this.color_white,
        },
        profile_pro: {
          main: this.color_orange,
          contrastText: this.color_white,
        },
        /****** Subscription state ******/
        subscription_state_active: {
          main: this.color_green,
          contrastText: this.color_white,
        },
        subscription_state_initial: {
          main: this.color_yellow,
          contrastText: this.color_black,
        },
        subscription_state_closed: {
          main: this.color_yellow,
          contrastText: this.color_black,
        },
        subscription_state_error: {
          main: this.color_red,
          contrastText: this.color_white,
        },
        /****** Order Item state ******/
        order_item_state_done: {
          main: this.color_blue,
          contrastText: this.color_white,
        },
        order_item_state_alert: {
          main: this.color_red,
          contrastText: this.color_white,
        },
        /****** Order state ******/
        order_state_done: {
          main: this.color_green,
          contrastText: this.color_white,
        },
        order_state_processing: {
          main: this.color_blue,
          contrastText: this.color_white,
        },
        order_state_validated: {
          main: this.color_yellow,
          contrastText: this.color_black,
        },
        order_state_pending: {
          main: this.color_grey,
          contrastText: this.color_white,
        },
        order_state_draft: {
          main: 'transparent',
          contrastText: this.color_grey_medium,
        },
        order_state_canceled: {
          main: this.color_black,
          contrastText: this.color_white,
        },
        order_state_missed: {
          main: this.color_red,
          contrastText: this.color_white,
        },
        order_state_refused: {
          main: this.color_red,
          contrastText: this.color_white,
        },
        order_state_error: {
          main: this.color_red,
          contrastText: this.color_white,
        },
        /****** Notification state ******/
        notification_state_unread: {
          main: this.color_blue,
          contrastText: this.color_white,
        },
        notification_state_error: {
          main: this.color_red,
          contrastText: this.color_white,
        },
        /****** Invoice state ******/
        invoice_state_issued: {
          main: this.color_grey,
          contrastText: this.color_white,
        },
        invoice_state_payed: {
          main: this.color_green,
          contrastText: this.color_white,
        },
        invoice_state_unpayed: {
          main: this.color_red,
          contrastText: this.color_white,
        },
        invoice_state_canceled: {
          main: this.color_orange,
          contrastText: this.color_white,
        },
        mode: 'light',
      },
      shape: {
        // borderRadius: 0,
      },
      spacing: 10,
      typography: {
        h1: {
          fontSize: '1.875rem',
          letterSpacing: '-0.021em',
          lineHeight: 1.4,
          marginBottom: '0.6em',
        },
        h2: {
          fontSize: '1.5rem',
          letterSpacing: '-0.019em',
          lineHeight: 1.4,
          marginBottom: '0.6em',
        },
        h3: {
          fontSize: '1.25rem',
          letterSpacing: '-0.017em',
          lineHeight: 1.4,
          marginBottom: '0.6em',
        },
        h4: {
          fontSize: '1.125rem',
          letterSpacing: '-0.014em',
          lineHeight: 1.4,
          marginBottom: '0.6em',
          fontWeight: 700,
        },
        h5: {
          fontSize: '1.125rem',
          letterSpacing: '-0.014em',
          lineHeight: 1.4,
          marginBottom: '0.6em',
        },
        h6: {
          fontSize: '1rem',
          letterSpacing: '-0.011em',
          lineHeight: 1.4,
          marginBottom: '0.6em',
          fontWeight: 700,
        },
        subtitle1: {
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: 1.5,
          letterSpacing: '-0.006em',
        },
        subtitle2: {
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: 1.5,
          letterSpacing: '0',
        },
        subtitle3: {
          fontSize: '0.625rem',
          fontWeight: 400,
          lineHeight: 1.5,
          letterSpacing: '0.01em',
        },
        body1: {
          fontSize: '1rem',
          letterSpacing: '-0.011em',
          lineHeight: 1.5,
          hyphens: 'auto',
        },
        body2: {
          fontSize: '0.875rem',
          letterSpacing: '0',
          lineHeight: 1.5,
          hyphens: 'auto',
        },
        body3: {
          fontSize: '0.75rem',
          letterSpacing: '0',
          lineHeight: 1.5,
          hyphens: 'auto',
        },
        button: {
          fontWeight: 400,
          textTransform: 'none',
        },
        caption: {
          lineHeight: 1.6,
          letterSpacing: '0em',
        },
        fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
      },
      components: {
        MuiBadge: {
          styleOverrides: {
            badge: {
              boxShadow: '0 0 0 1px #fff',
              fontSize: '0.655rem',
              lineHeight: 1,
            },
            colorPrimary: {
              backgroundColor: this.color_orange,
            },
            colorSecondary: {
              backgroundColor: this.color_grey,
            },
            root: {
              '& .MuiBadge-badge': {
                height: '16px',
                minHeight: '16px',
                minWidth: '16px',
                padding: '0 3px',
              },
              '& .MuiBadge-dot': {
                minHeight: 'auto',
                minWidth: 'auto',
                height: '10px',
                width: '10px',
                padding: '0',
                borderRadius: '50%',
              }
            }
          }
        },
        MuiTab: {
          styleOverrides: {
            root: {
              '&.Mui-selected': {
                fontWeight: 'bold',
                color: '#000 !important',
              }
            },
          }
        },
        MuiButton: {
          styleOverrides: {
            root: {
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              }
            },
          }
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
            },
          }
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              borderBottom: '1px solid ' + this.color_orange,
              '&.Mui-disabled': {
                opacity: '.7',
                backgroundColor: this.color_white,
              },
              '&.Mui-expanded': {
                margin: 0,
              },
            },
          }
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              borderBottom: '1px solid ' + this.color_orange,
              boxShadow: 'none',
              backgroundColor: this.color_white,
            },
          }
        },
        MuiBottomNavigation: {
          styleOverrides: {
            root: {
              borderTop: '1px solid ' + this.color_orange,
              backgroundColor: this.color_white,
            },
          }
        },
        MuiBottomNavigationAction: {
          styleOverrides: {
            root: {
              color: 'rgba(0, 0, 0, 1)',
            },
            label: {
              fontSize: 12,
              '&.Mui-selected': {
                fontSize: 12,
                color: 'rgba(0, 0, 0, 1)',
              },
            },
          }
        },
        MuiAvatar: {
          styleOverrides: {
            root: {
              backgroundColor: '#e8e2de',
            }
          },
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              p: {
                margin: "0 !important",
              }
            }
          },
        },
      },
    }, frFR);
  }

  componentDidMount () {
    networkService.init();
    networkService.survey();
    pushService.survey();
  }

  componentWillUnmount () {
    pushService.unsurvey();
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={this.customTheme}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                <AppUpdateController>
                  <Router>
                    <PushNotificationsController>
                      <AppNotificationsController>
                        <CssBaseline />
                        <Main />
                      </AppNotificationsController>
                    </PushNotificationsController>
                  </Router>
                </AppUpdateController>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
