import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withHOCComponent } from '../hoc/HOCComponent';
import productsService from '../../services/products';
import attachmentsService from '../../services/attachments';
import { isEmpty } from '../../services/utils';

import { TagIcon } from '../commons/Icons';
import { Chip, CardMedia, CardContent, Card, Typography, Box } from '@mui/material';
import ProductPrice from './ProductPrice';

class Product extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes, product } = this.props;
    if (!product) {
      return null;
    }

    const image = attachmentsService.get_url(product?.attachment, 'small');

    let terms = [];
    if (!isEmpty(product.categories)) {
      for (const cat of product.categories) {
        terms.push(<Chip key={'term-cat-' + cat.ID} classes={{label: classes.term_label}} icon={<TagIcon fontSize="xsmall" />} label={cat.name} size="small" />);
      }
    }
    if (!isEmpty(product.tags)) {
      for (const tag of product.tags) {
        terms.push(<Chip key={'term-tag-' + tag.ID} classes={{label: classes.term_label}} icon={<TagIcon fontSize="xsmall" />} label={tag.name} size="small" />);
      }
    }

    return (

      <Card className={classes.root}>
        { image && (
          <CardMedia
            component="img"
            image={image}
            alt={product.name}
          />
        ) }
        <CardContent className={classes.cardContent}>

          <Box className={classes.content}>
            
            <Box className={classes.head}>
              <Typography className={classes.head_title} component="h3" variant="body1">{product.name}</Typography>
              <Box className={classes.head_meta}>
                { product?.state !== productsService.META_STATE_AVAILABLE && (
                  <Typography className={classes.visibility} component="span" variant="body2" color="primary">indisponible</Typography>
                ) }
                { product?.visibility === productsService.VISIBILITY_PRIVATE && (
                  <Typography className={classes.visibility} component="span" variant="body2" color="primary">distribution exclusive</Typography>
                ) }
                { product?.visibility === productsService.VISIBILITY_SCOPED && (
                  <Typography className={classes.visibility} component="span" variant="body2" color="primary">distribution sélective</Typography>
                ) }
              </Box>
            </Box>

            { (product?.description) && (
              <Typography className={classes.description} component="div" variant="body2">{ product.description }</Typography>
            ) }

            {!isEmpty(terms) && (
              <Box className={classes.terms}>{ terms }</Box>
            ) }

            <Box className={classes.bottom}>
              <Box className={classes.bottom_infos}>
                <Box className={classes.bottom_infos_format}>
                  <Typography className={classes.product_format} component="span" variant="body3">{ productsService.getFormatLabel(product?.format, product.format_label) }</Typography>
                </Box>
                <Box className={classes.bottom_infos_price}>
                  <ProductPrice
                    price={product?.price}
                    price_ttc={product?.price_ttc}
                    format={product?.format}
                    tax_rate={product?.tax_rate}
                    volume_unit={product?.volume_unit}
                    format_label={product?.format_label}
                  />
                </Box>
              </Box>
            </Box>

          </Box>

        </CardContent>
      </Card>
    )
  }
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.bg.white,
  },
  cardContent: {
    padding: '0',
    paddingBottom: '0 !important',
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
  },
  head: {
    marginBottom: theme.spacing(1.5),
  },
  head_title: {
    fontWeight: 600,
  },
  head_meta: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
  },
  description: {
    marginBottom: theme.spacing(1.5),
  },
  terms: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
  },
  term_label: {
    fontSize: '12px',
  },
  bottom: {
    borderTop: `1px solid ${theme.palette.bg.light}`,
    paddingTop: theme.spacing(1),
  },
  bottom_infos: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('mobile')]: {
      flexDirection: 'column',
    },
  },
  product_format: {},
});

Product.propTypes = {
  product: PropTypes.object.isRequired,
};

export default withHOCComponent(withStyles(styles, { withTheme: true })(Product));
