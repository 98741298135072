import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import OrderPrice from '../../order/OrderPrice';

class EditNewOrderStepResumePrice extends React.Component {

  render () {
    const { order } = this.props;
    return (
      <OrderPrice order={order} />
    );
  }
}

const styles = theme => ({});

EditNewOrderStepResumePrice.propTypes = {
  order: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(EditNewOrderStepResumePrice);
