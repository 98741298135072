import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withHOCComponent } from '../hoc/HOCComponent';
import authService from '../../services/auth';
import { isEmpty } from '../../services/utils';

import BackdropLoading from '../commons/BackdropLoading';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PasswordField from '../commons/PasswordField';

class AuthUserLogin extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  logIn (e) {
    const { onAuthenticated, data } = this.props;
    e.preventDefault();
    if (isEmpty(data.login) || isEmpty(data.password)) {
      this.props.hoc.showError('Identifiant / Mot de passe manquant');
      return;
    }
    this._isMounted && this.setState({loading: true});
    authService.logIn(data.login, data.password).then((data) => {
      onAuthenticated(data);
    }).catch((error) => {
      this.props.hoc.showError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
    return false;
  }

  render () {
    const { classes, invitation, onChangeData, data, onChangeAction } = this.props;
    const { loading } = this.state;
    return (
      <Box>
        <BackdropLoading open={loading} />
        <form onSubmit={ (e) => this.logIn(e) } className={classes.form} noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <TextField
                  inputProps={{autoCapitalize: 'none'}}
                  required
                  id="login"
                  autoComplete="username"
                  label="Email"
                  className={classes.input}
                  value={data.login || ''}
                  onChange={ (e) => onChangeData({...data, ...{login: e.target.value}}) }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl} fullWidth>
                <PasswordField
                  inputProps={{autoCapitalize: 'none', autoComplete: "current-password"}}
                  required
                  id="password"
                  label="Mot de passe"
                  className={classes.input}
                  value={data.password || ''}
                  onChange={ (e) => onChangeData({...data, ...{password: e.target.value}}) }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" className={classes.button} onClick={ (e) => this.logIn(e) } color="primary" variant="contained">
                Connexion
              </Button>
            </Grid>
            { invitation && (
              <Grid item xs={12}>
                <Button type="submit" className={classes.button} color="primary" variant="outlined" onClick={() => onChangeAction('register')}>
                  Je n'ai pas de compte
                </Button>
              </Grid>
            ) }
            { !invitation && (
              <Grid item xs={12}>
                <Button variant="outlined" className={classes.button} onClick={() => onChangeAction('register')}>
                  Inscription
                </Button>
              </Grid>
            ) }
            <Grid item xs={12}>
              <Button size="small" className={classes.button} onClick={() => onChangeAction('lostpassword')}>
                Mot de passe perdu
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }
}

const styles = theme => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
});

AuthUserLogin.propTypes = {
  onAuthenticated: PropTypes.func.isRequired,
  onChangeAction: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onChangeData: PropTypes.func.isRequired,
  invitation: PropTypes.object, // si l'authentification est faite dans le contexte d'une invitation
  invitationToken: PropTypes.string, // si l'authentification est faite dans le contexte d'une invitation
};

export default withHOCComponent(withStyles(styles, { withTheme: true })(AuthUserLogin));