import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import attachmentsService from './../../services/attachments';

import BackdropLoading from './../commons/BackdropLoading';
import EditControllers from './../commons/EditControllers';
import Form from './../commons/Form';
import FormFields from './../commons/FormFields';
import FormField from './../commons/FormField';
import TextFieldLimited from './../commons/TextFieldLimited';
import { CheckIcon, InfoIcon, SaveIcon } from './../commons/Icons';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Avatar from '@mui/material/Avatar';

/**
 * Ce composant permet :
 * - d'uploader un nouveau fichier
 * - de modifier les attributs d'un fichier existant
 * - de supprimer un fichier existant
 */
class EditAttachment extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      is_new: false,
      name: '',
      description: '',
      file: null,
      upload_button_disabled: false,
      upload_progress: 0,
    }
    this.fileUploadButtonRef = React.createRef();
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showLoading() {
    this._isMounted && this.setState({loading: true})
  }

  hideLoading() {
    this._isMounted && this.setState({loading: false})
  }

  load () {
    const { attachment } = this.props;
    if (typeof attachment === 'object' && attachment !== null && attachment.ID) {
      // le fichier est passé en props
      this._isMounted && this.setState({
        name: attachment.name || '',
        description: attachment.description || '',
      });
    } else {
      // c'est un nouveau produit
      this._isMounted && this.setState({ is_new: true });
      // à la demande du parent, on ouvre directement le file upload
      if (this.props.openFileUpload === true) {
        this.fileUploadButtonRef.current.click();
      }
    }
  }

  upload (e) {
    e.preventDefault();
    if (!e.target.files || e.target.files.length < 1) {
      this.props.onError('Aucun fichier');
      return;
    }
    const file = e.target.files[0];
    if (!file) {
      this.props.onError('Aucun fichier');
      return;
    }
    const { id_profile } = this.props;

    if (!this.state.is_new) {
      this.props.onError('Vous ne pouvez pas changer de fichier.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    this._isMounted && this.setState({ upload_button_disabled: true });
    attachmentsService.upload(id_profile, formData, (event) => {
      this._isMounted && this.setState({
        upload_progress: Math.round((100 * event.loaded) / event.total),
      });
    }).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération de l'item à jour
      return attachmentsService.get(id_profile, id);
    }).then(([attachment]) => {
      this.props.onSaved(attachment, this.state.is_new);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error, this.state.is_new);
    }).finally(() => {
      this._isMounted && this.setState({ upload_progress: 0, upload_button_disabled: false });
    });
    return false;
  }

  save (e) {
    e.preventDefault();
    const { id_profile, attachment } = this.props;
    if (this.state.is_new) {
      this.props.onError('Vous ne pouvez pas modifier les attributs avant d\'uploader le fichier.');
      return;
    }
    const data = {
      name: this.state.name,
      description: this.state.description,
    }
    this.showLoading();
    attachmentsService.update(id_profile, attachment.ID, data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération de l'item à jour
      return attachmentsService.get(id_profile, id);
    }).then(([attachment]) => {
      this.props.onSaved(attachment, this.state.is_new);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error, this.state.is_new);
    }).finally(() => {
      this.hideLoading();
    });
    return false;
  }

  render () {
    const { classes, id_profile, attachment } = this.props;
    if (!id_profile) {
      return (
        <Typography className={classes.error} component="p">Aucun profil de passé en paramètre.</Typography>
      )
    }

    return (
      <Box className={classes.container}>
        <BackdropLoading open={this.state.loading} />

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          { !attachment && (
            this.render_upload ()
          ) }

          { attachment && (
            this.render_update ()
          ) }

        </Form>

      </Box>
    )
  }


  render_upload () {
    const { classes } = this.props;

    return (
      <>
        <FormFields className={classes.formFields}>

          <FormField>
            <Typography variant="body2" component="div" className={classes.info}>
              <CheckIcon fontSize="medium" color="primary" />
              <span>Le format d'image <strong>carré</strong> doit être privilégié.</span>
            </Typography>
            <Typography variant="body2" component="div" className={classes.info}>
              <InfoIcon fontSize="medium" color="primary" />
              <span>L'image doit faire au moins <strong>300x300px</strong>.</span>
            </Typography>
            <Typography variant="body2" component="div" className={classes.info}>
              <InfoIcon fontSize="medium" color="primary" />
              <span>Le poids du fichier ne doit pas dépasser <strong>4Mo</strong>.</span>
            </Typography>
          </FormField>

        </FormFields>

        { this.state.upload_progress === 0 && (
          <EditControllers sticky>
            <Button fullWidth ref={this.fileUploadButtonRef} variant="contained" component="label" disabled={this.state.upload_button_disabled}>
              Choisir un fichier
              <input type="file" hidden accept="image/*" onChange={ (e) => this.upload(e) } />
            </Button>
          </EditControllers>
        ) }

        { this.state.upload_progress > 0 && (
          <Box className={classes.content_uploading}>
            <Typography variant="body1" className={classes.content_uploading_text}>Envoi...</Typography>
            <LinearProgress variant="determinate" value={this.state.upload_progress} />
          </Box>
        ) }
      </>
    )
  }

  render_update () {
    const { classes, attachment } = this.props;

    const image = attachmentsService.get_url(attachment, 'thumb');
    if (!attachment) {
      return;
    }
    return (
      <>
        <FormFields className={classes.formFields}>

          <FormField>
            <Avatar alt={attachment.name} variant="square" src={image} className={classes.image} />
          </FormField>

          <FormField>
            <FormControl className={classes.form_control}>
              <TextField
                required
                id="name"
                label="Nom"
                type="text"
                className={classes.input}
                value={this.state.name}
                onChange={ (e) => this._isMounted && this.setState({name: e.target.value}) }
              />
            </FormControl>
          </FormField>

          <FormField>
            <FormControl className={classes.form_control}>
              <TextFieldLimited
                id="description"
                label="Description"
                type="text"
                className={classes.input}
                value={this.state.description}
                onChange={ (description) => this._isMounted && this.setState({description}) }
                multiline
                rows={4}
                limit={100}
              />
            </FormControl>
          </FormField>

        </FormFields>

        <EditControllers sticky>
          <Button type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={<SaveIcon />}>
            Enregistrer
          </Button>
        </EditControllers>
      </>
    )
  }
}

const styles = theme => ({
  container: {},
  form_divider: {
    margin: theme.spacing(2, 0),
  },
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  file: {
    marginBottom: theme.spacing(2),
  },
  image: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: 100,
      height: 100,
    },
    [theme.breakpoints.up('md')]: {
      width: 150,
      height: 150,
    },
  },
  content_uploading: {
    padding: theme.spacing(2),
  },
  content_uploading_text: {
    paddingBottom: theme.spacing(1),
  },
  submit:{
    width: '100%',
  },
  info: {
    display: 'flex',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
});

EditAttachment.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  openFileUpload: PropTypes.bool, // open file upload if is new attachment
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  attachment: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(EditAttachment);
