import Auth_API from './api/auth';
import { store } from './store';
import profilesServices from './profiles';
import utilsService from './utils';
import { setAuth, setAuthProfile, setAuthTime, cleanCart } from './store/features/appSlice';

const exportedObject = {
  maybeRefreshToken (id_profile) {
    if (this.isLoggedInProfile(id_profile)) {
      return this.refreshToken();
    }
    return new Promise((resolve) => {
      resolve(false);
    });
  },
  refreshToken () {
    return new Promise((resolve, reject) => {
      let refresh_token = this.getRefreshToken();
      if (refresh_token && refresh_token !== '') {
        Auth_API.refreshToken(refresh_token).then(({data, notices}) => {
          return this.onAuthenticated(data);
        }).then((data) => {
          resolve(data);
        }).catch((error) => {
          reject(Error('Authentification impossible'));
          this.logOut();
        });
      } else {
        reject(Error('Authentification impossible'));
        this.logOut();
      }
    });
  },
  register (data) {
    return new Promise((resolve, reject) => {
      Auth_API.register(data).then(({data, notices}) => {
        return this.onAuthenticated(data);
      }).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  logIn (login, password) {
    return new Promise((resolve, reject) => {
      Auth_API.login(login, password).then(({data, notices}) => {
        return this.onAuthenticated(data);
      }).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  logInByInvitation (invitationToken) {
    return new Promise((resolve, reject) => {
      Auth_API.logInByInvitation(invitationToken).then(({data, notices}) => {
        return this.onAuthenticated(data);
      }).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  setProfile (id_profile) {
    return new Promise((resolve, reject) => {
      Auth_API.setProfile(id_profile).then(({data, notices}) => {
        // NOTE : ne pas vider le panier car lorsque l'utilisateur commande sans être authentifié ou qu'il change de profil au moment de commnander, 
        // il ne faut pas qu'il perde les produits de son panier quand il sélectionne son profil.
        return this.onAuthenticated(data);
      }).then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /** Après toute authentification (refesh ou non), on met à jour certaines informations dans le store */
  onAuthenticated (data) {
    return new Promise((resolve, reject) => {
      (async () => {
        // si l'authentification s'est bien déroulée
        if (data?.token && data?.refresh_token && data?.id_user) {
          store.dispatch(setAuth(data));
          // on met à jour le profil authentifié (on reste synchrone)
          if (data?.id_profile) {
            try {
              const [profile] = await profilesServices.get(data?.id_profile);
              store.dispatch(setAuthProfile(profile));
            } catch (e) {
              reject(Error('Impossible de récupérer le profil'));
            }
          } else {
            store.dispatch(setAuthProfile(null));
          }
          store.dispatch(setAuthTime(Date.now()));
          resolve(data);
        } else {
          this.logOut();
          reject(Error('Erreur d\'authentification'));
        }
      })();
    });
  },
  logOut () {
    store.dispatch(setAuthProfile(null));
    store.dispatch(setAuth(null));
    store.dispatch(cleanCart()); // on vide le panier
  },
  lostPassword(login) {
    return new Promise((resolve, reject) => {
      Auth_API.lostPassword(login).then(({data, notices}) => {
        utilsService.debugLog('lostPassword - data : ', data, 'avertissements : ', notices);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  renewPassword(token, password, password_confirm) {
    return new Promise((resolve, reject) => {
      Auth_API.renewPassword(token, password, password_confirm).then(({data, notices}) => {
        utilsService.debugLog('renewPassword - data : ', data, 'avertissements : ', notices);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  generatePassword() {
    return new Promise((resolve, reject) => {
      Auth_API.generatePassword().then(({data, notices}) => {
        utilsService.debugLog('generatePassword - data : ', data, 'avertissements : ', notices);
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  getToken () {
    let appStore = store.getState().app;
    return appStore.auth?.token;
  },
  getRefreshToken () {
    let appStore = store.getState().app;
    return appStore.auth?.refresh_token;
  },
  isLoggedInUserAdmin() {
    let appStore = store.getState().app;
    return appStore?.auth?.is_admin === true;
  },
  isLoggedIn() {
    let appStore = store.getState().app;
    return !!appStore?.auth?.id_user;
  },
  isLoggedInWithProfile() {
    let appStore = store.getState().app;
    return !!appStore?.auth?.id_user && !!appStore?.authProfile?.ID;
  },
  isLoggedInUser(id) {
    let appStore = store.getState().app;
    return appStore?.auth?.id_user && parseInt(appStore?.auth?.id_user) === parseInt(id);
  },
  isLoggedInProfile(id) {
    let appStore = store.getState().app;
    return appStore?.authProfile?.ID && parseInt(appStore?.authProfile?.ID) === parseInt(id);
  }
};

export default exportedObject;
