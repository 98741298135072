import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

class AlertDialog extends React.Component {

  render () {
    const { open, message, children } = this.props;
    return (
      <Dialog open={open} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
        { !this.props.hideTitle && (
          <DialogTitle id="form-dialog-title">{ this.props.title || "Alerte" }</DialogTitle>
        ) }
        <DialogContent>
          { message && (
            <DialogContentText>
              {message}
            </DialogContentText>
          ) }
          { children }
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => this.props.onClose() } color="primary" variant="contained">
            { this.props.closeLabel || 'Fermer' }
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = theme => ({});

AlertDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
};

export default withStyles(styles, { withTheme: true })(AlertDialog);
