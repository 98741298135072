import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import deliveryModesService from '../../services/delivery-modes';
import contactsService from '../../services/contacts';

import FormControl from '@mui/material/FormControl';
import FormField from './FormField';
import { Box, CircularProgress, FormControlLabel, IconButton, Switch, Typography } from '@mui/material';
import { RenewIcon } from './Icons';
import SelectContact from './SelectContact';

class SetOrderContacts extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      error: false,
      deliveryMode: null,
      differentContact: false,
      reloadContacts: false,
    };
  }

  componentDidMount () {
    const { order } = this.props;
    this._isMounted = true;
    if (order?.delivery_contact && order?.customer_billing_contact && JSON.stringify(order?.delivery_contact) !== JSON.stringify(order?.customer_billing_contact)) {
      this.setState({differentContact: true});
    }
    this.load();
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.order?.id_delivery_mode) !== JSON.stringify(prevProps.order?.id_delivery_mode)){
      this.load();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
    const { order } = this.props;
    this._isMounted && this.setState({ loading: true });
    deliveryModesService.get(order?.id_profile_provider, order?.id_delivery_mode).then(([deliveryMode]) => {
      this._isMounted && this.setState({ deliveryMode });
    }).catch((error) => {
      this._isMounted && this.setState({ error: true });
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  render () {
    const { classes, order, onChange, onError } = this.props;
    const { loading, error, deliveryMode, differentContact, reloadContacts } = this.state;
    if (loading) {
      return (
        <Box className={classes.loading}>
          <CircularProgress color="secondary" />
        </Box>
      );
    } else if (error) {
      return (
        <Box className={classes.error}>
          <h4>Erreur de chargement</h4>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    } else if (deliveryMode) {
      return (
        <>
          { deliveryMode?.type === deliveryModesService.TYPE_PICKUP && (
            <>
              <Typography variant="body1" component="div" className={classes.title}>{ order?.scenario === 'provider' ? "Coordonnées de facturation du client" : "Coordonnées de facturation" }</Typography>
              <FormField>
                <FormControl fullWidth>
                  <SelectContact
                    contact={order?.customer_billing_contact || null}
                    role={contactsService.ROLE_BILLING}
                    onChange={(contact) => onChange({...order, ...{customer_billing_contact: contact}})}
                    onContactsUpdated={() => this._isMounted && this.setState({ reloadContacts: !reloadContacts })}
                    reload={reloadContacts}
                    id_profile={order?.id_profile_customer}
                    onError={onError}
                    disableEdit={order?.scenario === 'provider'}
                  />
                </FormControl>
              </FormField>
            </>
          ) }
          { deliveryMode?.type === deliveryModesService.TYPE_SHIPPING && (
            <>
              <Typography variant="body1" component="div" className={classes.title}>{ order?.scenario === 'provider' ? "Coordonnées de livraison du client" : "Coordonnées de livraison" }</Typography>
              <FormField>
                <FormControl fullWidth>
                  <SelectContact
                    contact={order?.delivery_contact || null}
                    role={contactsService.ROLE_SHIPPING}
                    onChange={(contact) => {
                      if (differentContact) {
                        onChange({...order, ...{delivery_contact: contact}});
                      } else {
                        onChange({...order, ...{delivery_contact: contact, customer_billing_contact: contact}});
                      }
                    }}
                    onContactsUpdated={() => this._isMounted && this.setState({ reloadContacts: !reloadContacts })}
                    reload={reloadContacts}
                    id_profile={order?.id_profile_customer}
                    onError={onError}
                    disableEdit={order?.scenario === 'provider'}
                  />
                </FormControl>
              </FormField>

              <FormField>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={differentContact}
                        onChange={ (e) => {
                          this._isMounted && this.setState({differentContact: e.target.checked});
                          if (!e.target.checked) {
                            // on remet le contact de facturation identique au contact de livraison
                            onChange({...order, ...{customer_billing_contact: order?.delivery_contact}});
                          } else {
                            // on vide le contact de facturation
                            onChange({...order, ...{customer_billing_contact: null}});
                          }
                        }}
                        inputProps={{ 'id': 'auto_emit', 'aria-label': 'Automatisation' }}
                      />
                    }
                    label={
                      <Typography component="div" variant="body2">Coordonnées de facturation si différentes des coordonnées de livraison</Typography>
                    }
                  />
                </FormControl>
              </FormField>
              { differentContact && (
                <>
                  <Typography variant="body1" component="div" className={classes.title}>{ order?.scenario === 'provider' ? "Coordonnées de facturation du client" : "Coordonnées de facturation" }</Typography>
                  <FormField>
                    <FormControl fullWidth>
                      <SelectContact
                        contact={order?.customer_billing_contact || null}
                        role={contactsService.ROLE_BILLING}
                        onChange={(contact) => onChange({...order, ...{customer_billing_contact: contact}})}
                        onContactsUpdated={() => this._isMounted && this.setState({ reloadContacts: !reloadContacts })}
                        reload={reloadContacts}
                        id_profile={order?.id_profile_customer}
                        onError={onError}
                        disableEdit={order?.scenario === 'provider'}
                      />
                    </FormControl>
                  </FormField>
                </>
              ) }
            </>
          ) }
        </>
      );
    }
    return null;
  }
}

const styles = theme => ({
  loading: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  error: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  title:{
    padding: theme.spacing(0,0,0,1),
    fontWeight: 600,
    textDecoration: 'underline',
  },
});

SetOrderContacts.propTypes = {
  onChange: PropTypes.func.isRequired,
  order: PropTypes.object,
  onError: PropTypes.func.isRequired,
};

SetOrderContacts.defaultProps = {
  order: {}
};

export default withStyles(styles, { withTheme: true })(SetOrderContacts);
