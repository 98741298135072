import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {
  get (id_profile, id) {
    return API.get('/profiles/'+id_profile+'/products/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAll (id_profile, params) {
    return API.get('/profiles/'+id_profile+'/products', {params})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  count (id_profile, params) {
    return API.get('/profiles/'+id_profile+'/products/count', {params})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  create (id_profile, data) {
    return API.post('/profiles/'+id_profile+'/products', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  update (id_profile, id, data) {
    return API.post('/profiles/'+id_profile+'/products/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  delete (id_profile, id) {
    return API.delete('/profiles/'+id_profile+'/products/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getScopes (id_profile, id_product, params) {
    return API.get('/profiles/'+id_profile+'/products/' + id_product + '/scopes', {params})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  setScopes (id_profile, id_product, data) {
    return API.post('/profiles/'+id_profile+'/products/' + id_product + '/scopes/bulk/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  search (id_profile, params) {
    return API.get('/profiles/'+id_profile+'/products/search', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getStructure (id_profile, params) {
    return API.get('/profiles/'+id_profile+'/products/structure', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
