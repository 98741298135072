import React from 'react';
import { withStyles } from '@mui/styles';
import usersService from './../../services/users';

import { UserIcon } from './../commons/Icons';

import SelectAsync from './SelectAsync';
import UsersList from './../user/UsersList';

/**
 * Sélecteur d'utilisateur
 * Ce composant est asynchrone, il charge les valeurs à sélectionner depuis une API
 * Pour savoir quand le composant est initialisé, il faut écouter props.onInitialized
 */
class SelectUser extends React.Component {

  render () {
    const { classes, ...other } = this.props;
    return (
      <SelectAsync
        { ...{...other, ...{
          renderSelect: this.renderSelect,
          label: this.props.label || "Utilisateur",
          label_icon: this.props.label_icon || <UserIcon />,
          renderSelected: (selected) => selected.display_name,
        }} }
        load={(query, value) => usersService.get(value)}
      />
    );
  }

  renderSelect = (onSelect, selectedItemKey, selected) => {
    return (
      <UsersList
        onSelect={onSelect}
        onError={this.props.onError}
        query={this.props.query}
        selectedItemsKeys={[selectedItemKey]}
      />
    )
  }
}

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(SelectUser);
