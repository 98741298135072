/**
 * Composant affichant un produit du profil
 * - Si une commande 'order' est passée en props, le composant permet
 * de gérer la quantité de celui-ci
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import attachmentsService from './../../services/attachments';
import authService from './../../services/auth';
import utilsService from './../../services/utils';
import imagePlaceholder from './../../static/img/placeholder.png';

import BackdropLoading from './../commons/BackdropLoading';
import ConfirmDialog from './../commons/ConfirmDialog';
import IconMenu from './../commons/IconMenu';
import DialogEditAttachment from './../dialog/DialogEditAttachment';
import ListItemContent from './../commons/ListItemContent';
import ListItemContentSummary1 from './../commons/ListItemContentSummary1';
import ListItemContentSummary2 from './../commons/ListItemContentSummary2';
import { MoreVertIcon, DeleteIcon, EditIcon } from './../commons/Icons';

import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

class AttachmentsListItem extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      openDialog_edit: false,
      openConfirm_delete: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openEdit() {
    this._isMounted && this.setState({openDialog_edit: true})
  }
  closeEdit() {
    this._isMounted && this.setState({openDialog_edit: false})
  }

  openDelete() {
    this._isMounted && this.setState({openConfirm_delete: true})
  }
  closeDelete() {
    this._isMounted && this.setState({openConfirm_delete: false})
  }

  delete () {
    const { id_profile, attachment } = this.props;
    this._isMounted && this.setState({loading: true});
    attachmentsService.delete(id_profile, attachment.ID).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      this.props.onDeleted(id);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error, this.state.is_new);
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
  }

  render () {
    const { id_profile, attachment } = this.props;
    const image = attachmentsService.get_url(attachment, 'thumb', imagePlaceholder);
    return (
      <React.Fragment>
        <ListItemContent
          avatar={true}
          avatarProps={{ src: image, variant: "square" }}
          right={this.renderRight()}
          summary={this.renderSummary()}
        />
        <BackdropLoading open={this.state.loading} />
        { this.state.openDialog_edit && (
          <DialogEditAttachment
            open={this.state.openDialog_edit}
            id_profile={id_profile}
            attachment={attachment}
            onSaved={ (attachment, is_new) => {
              this.closeEdit();
              this.props.onSaved && this.props.onSaved(attachment, is_new);
            } }
            onError={this.props.onError}
            onSuccess={this.props.onSuccess}
            onClose={ () => this.closeEdit() }
          />
        ) }
        { this.state.openConfirm_delete && (
          <ConfirmDialog
            open={this.state.openConfirm_delete}
            message="Voulez-vous vraiment supprimer ce fichier ? Cette action est irréversible."
            onConfirm={ () => {
              this.closeDelete();
              this.delete();
            } }
            onClose={ () => this.closeDelete() }
          />
        ) }
      </React.Fragment>
    );
  }

  renderSummary () {
    const { classes, attachment } = this.props;
    return (
      <Box className={classes.summary}>
        <ListItemContentSummary1>
          {utilsService.showDebugID(attachment)}{attachment.name}
        </ListItemContentSummary1>
        { (attachment && attachment.description) && (
          <ListItemContentSummary2>
            { utilsService.truncate(utilsService.stripHtmlTags(attachment.description), 12) }
          </ListItemContentSummary2>
        ) }
      </Box>
    );
  }

  renderRight () {
    const { classes, id_profile, right } = this.props;
    if (!right) {
      return null;
    }
    if (right === 'actions') {
      if (!authService.isLoggedInProfile(id_profile)) {
        return null;
      }
      return (
        <IconMenu icon={<MoreVertIcon fontSize="small" />} iconColor="secondary" render={ () => {
          let items = [];

          items.push(<MenuItem key={1} onClick={(e) => {
            this.openEdit();
          }} className={classes.actions_menu_item}>
            <ListItemIcon><EditIcon /></ListItemIcon>
            <ListItemText disableTypography={true} primary="Modifier" />
          </MenuItem>);

          items.push(<MenuItem key={2} onClick={(e) => {
            this.openDelete();
          }} className={classes.actions_menu_item}>
            <ListItemIcon><DeleteIcon /></ListItemIcon>
            <ListItemText disableTypography={true} primary="Supprimer" />
          </MenuItem>);

          return items;
        } } />
      )
    } else {
      return right;
    }
  }
}

const styles = theme => ({
  content: {
    width: '100%',
    display: 'flex',
  },
  image: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      width: 100,
      height: 100,
    },
    [theme.breakpoints.up('md')]: {
      width: 150,
      height: 150,
    },
  },
  infos: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 9,
  },
});

AttachmentsListItem.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  attachment: PropTypes.object.isRequired,
  displayMenu: PropTypes.bool,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onSaved: PropTypes.func,
  onDeleted: PropTypes.func,
};

AttachmentsListItem.defaultProps = {
  displayMenu: true,
};

export default withStyles(styles, { withTheme: true })(AttachmentsListItem);
