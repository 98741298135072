import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  get (id, params) {
    return API.get('/groups/items/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAll (params) {
    return API.get('/groups/items', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  create (id_group, data) {
    return API.post('/groups/' + id_group + '/items', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  update (id_group, id, data) {
    return API.post('/groups/' + id_group + '/items/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  delete (id_group, id) {
    return API.delete('/groups/' + id_group + '/items/'  +id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  set (id_group, group_items = []) {
    return API.post('/groups/' + id_group + '/items/bulk/', {group_items})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
