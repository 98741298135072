import React from 'react';
import { withStyles } from '@mui/styles';
import { FormControl } from '@mui/material';
import FormField from './FormField';
import TextFieldLimited from './TextFieldLimited';

class SetBusinessSector extends React.Component {

  render () {
    const { value, onChange, ...others } = this.props;

    return (
      <FormField>
        <FormControl fullWidth>
          <TextFieldLimited
            required
            id="business_sector"
            label="Secteur d'activité"
            type="text"
            name="business_sector"
            value={value || ''}
            onChange={ (value) => onChange(value) }
            limit={50}
            autoComplete="organization"
            fullWidth
            {...others}
          />
        </FormControl>
      </FormField>
    )
  }
}

const styles = theme => ({});

export default withStyles(styles, { withTheme: true })(SetBusinessSector);
