import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import groupsService from '../../services/groups';
import utilsService, { isEmpty } from '../../services/utils';

import BackdropLoading from '../commons/BackdropLoading';
import ListItemContent from '../commons/ListItemContent';
import ListItemContentSummary1 from '../commons/ListItemContentSummary1';
import ListItemContentSummary2 from '../commons/ListItemContentSummary2';

class Group extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { group } = this.props;
    const groupAvatar = groupsService.getAvatar(group);

    return (
      <React.Fragment>
        <ListItemContent
          avatar={groupAvatar.content}
          avatarProps={groupAvatar.props}
          right={this.renderRight()}
          summary={this.renderSummary()}
        />
        <BackdropLoading open={this.state.loading} />
      </React.Fragment>
    )
  }

  renderSummary () {
    const { group } = this.props;
    const nb_group_items = parseInt(group.nb_group_items);
    return (
      <React.Fragment>
        <ListItemContentSummary1>
        {utilsService.showDebugID(group)}{ group.name }
        </ListItemContentSummary1>
        {group?.target === groupsService.TARGET_PRO_SUBSCRIBERS && (
          <ListItemContentSummary2>Tous mes abonnés professionnels</ListItemContentSummary2>
        )}
        {group?.target === groupsService.TARGET_PART_SUBSCRIBERS && (
          <ListItemContentSummary2>Tous mes abonnés particuliers</ListItemContentSummary2>
        )}
        {isEmpty(group?.target) && (
          <ListItemContentSummary2>{ isNaN(nb_group_items) || nb_group_items < 1 ? "aucun abonné sélectionné" : (nb_group_items === 1 ? "1 abonné sélectionné" : nb_group_items + " abonnés sélectionnés") }</ListItemContentSummary2>
        )}
      </React.Fragment>
    )
  }

  renderRight () {
    const { right } = this.props;
    if (!right) {
      return null;
    }
    return right;
  }
}

const styles = theme => ({});

Group.propTypes = {
  group: PropTypes.object,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onSaved: PropTypes.func,
  disableExpand: PropTypes.bool,
  right: PropTypes.element,
};

export default withStyles(styles, { withTheme: true })(Group);
