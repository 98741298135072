import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import GroupsList from '../group/GroupsList';
import DialogBase from './DialogBase';
import { DialogContent, DialogTitle } from '@mui/material';

class DialogGroups extends React.Component {

  render () {
    const { classes, open, onClose, ...others } = this.props;
    const scrollableTargetId = "dialog-groups";
    return (
      <DialogBase
        open={open}
        onClose={onClose}
      >
        <DialogTitle>Mes groupes</DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}} id={scrollableTargetId}>
          <GroupsList
            scrollableTarget={scrollableTargetId}
            hideTitle={true}
            {...others}
          />
        </DialogContent>
      </DialogBase>
    )
  }
}

const styles = theme => ({
  dialogContent: {
    padding: theme.spacing(0),
  },
});

DialogGroups.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogGroups);
