import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import AuthProfileSwitch from '../auth/AuthProfileSwitch';
import { Box, DialogContent, DialogTitle } from '@mui/material';
import DialogBase from './DialogBase';
import ViewContentLogo from '../main/ViewContentLogo';

class DialogAuthProfileSwitch extends React.Component {

  render () {
    const { classes, open, onClose, ...others } = this.props;
    const scrollableTargetId = "dialog-auth-profiles-switch";
    return (
      
      <DialogBase
        maxWidth="xs"
        open={open}
        onClose={onClose}
        hideClose
      >
        <DialogTitle classes={{root: classes.dialogTitle}}>
          <ViewContentLogo className={classes.logo} />
        </DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}} id={scrollableTargetId}>
          <Box className={classes.content}>
            <AuthProfileSwitch
              scrollableTarget={scrollableTargetId}
              hideTitle={true}
              onEnd={onClose}
              {...others}
            />
          </Box>
        </DialogContent>
      </DialogBase>
    )
  }
}

const styles = theme => ({
  dialogTitle: {
    margin: 0,
  },
  dialogContent: {
    padding: theme.spacing(1,0,0,0),
  },
});

DialogAuthProfileSwitch.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  keepMounted: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(DialogAuthProfileSwitch);
