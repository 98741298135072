import OrderMessages_API from './api/order-messages';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux commandes
****************************************************/

const exportedObject = {

  /**************** order-messages ************************************/
  /**
   * Récupère les messages de commande du serveur
   * @return Promise(data, notices) : les messages
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      OrderMessages_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Messages récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un message de commande du serveur
   * @return Promise(data, notices) : la commande
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      OrderMessages_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Message récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouveau message de commande
   * @return Promise(data, notices) : la commande créée
   */
  create (id_order, data) {
    return new Promise((resolve, reject) => {
      OrderMessages_API.create(id_order, data).then(({data, notices}) => {
        utilsService.debugLog('Message créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un message de commande
   * @return Promise(data, notices) : la commande mise à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      OrderMessages_API.update(id, data).then(({data, notices}) => {
        utilsService.debugLog('Message mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un message de commande
   * @return Promise(id, data, notices) : l'identifiant de la commande supprimée
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      OrderMessages_API.delete(id).then(({data, notices}) => {
        utilsService.debugLog('Message supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
};
export default exportedObject;
