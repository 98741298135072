/**
 * Composant permettant de parcourir les produits du profil et de composer les items de la commande
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import orderItemsService from '../../services/order-items';

import ProductsList from '../product/ProductsList';
import BackdropLoading from '../commons/BackdropLoading';
import { SaveIcon } from '../commons/Icons';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

class ProfileCatalogForOrder extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      order_items: null,
      order_items_changed: false,
      can_make_cart: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = (JSON.stringify(this.props.id_order) !== JSON.stringify(prevProps.id_order));
    if (reload) {
      this._isMounted && this.load();
    }
  }

  load () {
    const { id_order } = this.props;
    if (id_order) {
      // On charge les order_items de la commande
      this.showLoading();
      orderItemsService.getAll({id_order}).then(([order_items]) => {
        this._isMounted && this.setState({order_items});
      }).catch((e) => {
        this.props.onError(e);
      }).finally(() => {
        this.hideLoading();
      });
    }
  }

  showLoading() {
    this._isMounted && this.setState({loading: true})
  }

  hideLoading() {
    this._isMounted && this.setState({loading: false})
  }

  saveOrderItems() {
    const { id_order } = this.props;
    const { order_items } = this.state;
    this.showLoading();
    // On enregistre les order_items de la commande
    orderItemsService.set(id_order, order_items).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération des order items à jour de la commande
      return orderItemsService.getAll({id_order});
    }).then(([order_items]) => {
      if (this._isMounted) {
        this.setState({order_items});
        // Propagation au parent
        if (this.props.onOrderItemsUpdated) {
          this.props.onOrderItemsUpdated(id_order, order_items);
        }
      }
    }).catch((error) => {
      this.props.onError(error);
    }).finally(() => {
      this.hideLoading();
    });
  }

  render () {
    const { classes, id_profile_provider, id_profile_customer } = this.props;
    const { loading, order_items, order_items_changed } = this.state;
    if (!id_profile_provider) {
      return null;
    }
    return (
      <Box className={classes.content}>
        
        <BackdropLoading open={loading} />

        <Box className={classes.profile_search}>
          <ProductsList
            id_profile_provider={id_profile_provider}
            id_profile_customer={id_profile_customer}
            hideTitle={true}
            onError={this.props.onError}
            onSuccess={this.props.onSuccess}
            order_items={order_items}
            onOrderItemsChange={ (order_items) => {
              this._isMounted && this.setState({order_items, order_items_changed: true});
            }}
            scrollableTarget={this.props.scrollableTarget}
          />
        </Box>

        <Box className={classes.actions}>
          <Button disabled={!order_items_changed} type="submit" onClick={() => this.saveOrderItems()} color="primary" variant="contained" className={classes.submit} startIcon={<SaveIcon />}>
            Enregistrer
          </Button>
        </Box>
      </Box>
    );
  }
}

const styles = theme => ({
  actions: {
    padding: theme.spacing(2),
    width: '100%',
    position: 'sticky',
    bottom: 0,
    borderTop: `1px solid ${theme.palette.primary}`,
    backgroundColor: '#ffffff',
  },
  submit: {
    width: '100%',
  },
});

ProfileCatalogForOrder.propTypes = {
  id_profile_provider: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id_profile_customer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id_order: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired, // pour gérer les order items
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onOrderItemsUpdated: PropTypes.func,
  scrollableTarget:  PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(ProfileCatalogForOrder);
