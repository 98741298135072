/**
 * Composant affichant les champs de recherche
 * des produits du profil
 */
import React from 'react';
import { withStyles } from '@mui/styles';

import Search from './../commons/Search';

import Box from '@mui/material/Box';

class ProductTagsListSearch extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {};
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    return (
      <Box displayPrint="none">
        <Search
          {...this.props}
        />
      </Box>
    )
  }
}

const styles = theme => ({
});

export default withStyles(styles, { withTheme: true })(ProductTagsListSearch);
