import { store } from './store';
import { setOnline } from './store/features/appSlice';

const exportedObject = {
  init () {
    store.dispatch(setOnline(navigator.onLine || false));
  },
  survey () {
    var el = window
    if (el.addEventListener) {
      el.addEventListener('online', () => {
        store.dispatch(setOnline(true));
      }, true)
      el.addEventListener('offline', () => {
        store.dispatch(setOnline(false));
      }, true)
    } else if (el.attachEvent) {
      el.attachEvent('ononline', () => {
        store.dispatch(setOnline(true));
      })
      el.attachEvent('onoffline', () => {
        store.dispatch(setOnline(false));
      })
    } else {
      el.ononline = () => {
        store.dispatch(setOnline(true));
      }
      el.onoffline = () => {
        store.dispatch(setOnline(false));
      }
    }
  }
}
export default exportedObject;
