/**
 * Composant affichant les champs de recherche
 * des produits du profil
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import cloneDeep from'lodash.clonedeep';
import clsx from 'clsx';
import { isEmpty } from './../../services/utils';
import debounce from'lodash.debounce';

import { FiltersIcon, SearchIcon, CloseIcon } from './../commons/Icons';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * NOTE : la recherche libre active un Debounce afin de laisser l'utilisateur saisir plusieurs caractères avant de lancer la recherche,
 * ainsi nous alimentons state.search depuis les props ou le champs input suivant qu'il s'agit d'un update du parent ou d'une saisie utilisateur
 */

class Search extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      search: '',
      expanded: false,
    };
    this.search_ref = React.createRef();
    this.queryChangeDebounce = debounce(this.queryChangeDebounce.bind(this), 700);
  }

  componentDidMount () {
    this._isMounted = true;
    if (this.props.query) {
      this._isMounted && this.setState({
        search: this.props.query.search ? cloneDeep(this.props.query.search) : ''
      });
    }
    if (!this.props.onQueryChange) {
      console.warn("L'attribut onQueryChange est manquant.");
    }
    this._isMounted && this.setState({expanded: this.props.expanded});
    this.props.onInitialize && this.props.onInitialize();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.query) {
      if (!prevProps.query || JSON.stringify(this.props.query.search) !== JSON.stringify(prevProps.query.search)) {
        this._isMounted && this.setState({
          search: this.props.query.search ? cloneDeep(this.props.query.search) : ''
        });
      }
    }
  }

  toggleAdvancedFilter = () => {
    // On ouvre/ferme les champs avancés que s'il y en a,
    // sinon on lance la recherche
    if (this.hasAdvanced()) {
      this._isMounted && this.setState({expanded: !this.state.expanded});
    } else {
      this._isMounted && this.search();
    }
  }

  search (event) {
    event && event.stopPropagation();
    event && event.preventDefault();
    if (!this.props.searching) {
      this.queryChange();
    }
  }

  queryChangeDebounce() {
    this._isMounted && this.queryChange();
  }

  queryChange() {
    let query = cloneDeep(this.props.query);
    if (this.state.search) {
      query.search = this.state.search;
    } else {
      delete query.search;
    }
    this.props.onQueryChange && this.props.onQueryChange(query);
  }

  clearQuery () {
    this._isMounted && this.setState({expanded: false});
    this.props.onQueryChange && this.props.onQueryChange({});
  }

  hasSearch (query) {
    return !isEmpty(query);
  }

  hasAdvanced () {
    return this.props.renderAdvanced ? true : false;
  }

  render () {
    const { classes } = this.props;

    return (
      <Box className={classes.content}>
        { this.props.searching && (
          <Box className={classes.searching}>
            <CircularProgress size={10} color="secondary" />
          </Box>
        ) }
        <form onSubmit={ (event) => this.search(event) } className={classes.search_form}>
          <Accordion elevation={0} className={classes.filter_accordion} expanded={this.state.expanded} onChange={() => this.toggleAdvancedFilter()}>
            <AccordionSummary className={classes.filter_accordion_summary} expandIcon={ this.hasAdvanced() && <FiltersIcon />}>

              { this.props.renderBar && (

                <Card elevation={0} className={clsx(classes.search, {[classes.search_with_adv]: this.hasAdvanced()})}
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}>

                  <IconButton
                    className={classes.search_button}
                    aria-label="search"
                    onClick={() => this.search()}
                    size="large">
                    <SearchIcon />
                  </IconButton>

                  <Box className={classes.custom_bar}>
                    { this.props.renderBar(this.toggleAdvancedFilter) }
                  </Box>

                  { this.hasSearch(this.props.query) && (
                  <IconButton
                    className={classes.clear_button}
                    aria-label="clear-search"
                    onClick={() => this.clearQuery()}
                    size="large">
                    <CloseIcon />
                  </IconButton>
                  ) }

                </Card>

              ) }

              { !this.props.renderBar && (

                <Card elevation={0} className={clsx(classes.search, {[classes.search_with_adv]: this.hasAdvanced()})}
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}>

                  <IconButton
                    className={classes.search_button}
                    aria-label="search"
                    onClick={() => this.search()}
                    size="large">
                    <SearchIcon />
                  </IconButton>

                  <FormControl className={classes.search_form_ctrl}>
                    <InputBase
                      inputRef={this.search_ref}
                      id="search"
                      className={classes.search_input}
                      placeholder={ this.props.labelSearchField || "Recherche" }
                      inputProps={{ 'aria-label': this.props.labelSearchField || "Recherche" }}
                      value={this.state.search}
                      onChange={ (e) => {
                        if (!this.props.searching) {
                          this._isMounted && this.setState({search: e.target.value});
                          this.queryChangeDebounce();
                        }
                      } }
                      type="search"
                    />
                  </FormControl>

                  { this.hasSearch(this.props.query) && (
                  <IconButton
                    className={classes.clear_button}
                    aria-label="clear-search"
                    onClick={() => this.clearQuery()}
                    size="large">
                    <CloseIcon />
                  </IconButton>
                  ) }

                </Card>

              ) }

            </AccordionSummary>
            <AccordionDetails className={classes.filter_accordion_details}>

              { this.props.renderAdvanced && (
                <Box className={classes.search_advanced_box}>
                  { this.props.renderAdvanced() }
                </Box>
              ) }

            </AccordionDetails>
          </Accordion>
        </form>
      </Box>
    );
  }
}

const styles = theme => ({
  content: {
    position: 'relative',
  },
  searching: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255,255,255,0.5)',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filter_accordion: {
    border: 'none',
  },
  filter_accordion_summary: {
    margin: 0,
  },
  filter_accordion_details: {
    margin: 0,
    flexDirection: "column"
  },
  search: {
    padding: theme.spacing(0.5, 1),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.bg.light,
  },
  search_with_adv: {
    marginRight: theme.spacing(1),
  },
  search_form_ctrl: {
    flex: 1,
    width: '100%',
  },
  custom_bar: {
    flex: 1,
    width: '100%',
  },
  search_input: {
    margin: 0,
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  search_button: {
    padding: theme.spacing(0),
    marginRight: theme.spacing(0.5),
  },
  clear_button: {
    padding: theme.spacing(0),
  },
  search_advanced_box: {
    display: 'flex',
    flexDirection: "column",
  },
  search_advanced_box_ctrls: {
    display: 'flex',
    flexDirection: "column"
  },
  search_advanced_box_ctrls_search: {
    width: '100%',
  }
});

Search.propTypes = {
  onInitialize: PropTypes.func.isRequired, // la recherche est initialisée
  query: PropTypes.object, // critères de recherche, Search les modifie via onQueryChange
  onQueryChange: PropTypes.func, // critères de recherche ont changé (le parent peut actualiser les résultats des recherche)
  searching: PropTypes.bool, // la recherche est en cours (permet d'empêcher l'utilisateur de continuer à rechercher)
  onError: PropTypes.func.isRequired, // appelé lors d'une erreur
  labelSearchField: PropTypes.string, // label du champs de recherche libre
  labelSearch: PropTypes.string, // label du bouton de recherche
  renderBar: PropTypes.func, // permet de remplacer la barre de recherche
  renderAdvanced: PropTypes.func, // permet d'afficher les champs avancés
  scrollableTarget: PropTypes.string, // identifiant du conteneur scrollable
  expanded: PropTypes.bool, // la recherche avancée est dépliée (uniquement s'il y a une recherche avancée et au chargement du composant, ensuite il prend le contrôle)
};

Search.defaultProps = {
  query: {},
  expanded: false,
};

export default withStyles(styles, { withTheme: true })(Search);
