import Groups_API from './api/groups';
import utilsService from './utils';
import attachmentsService from './attachments';

/****************************************************
 Interface avec l'API relative aux groupes d'groupes
****************************************************/

const exportedObject = {

  TARGET_PRO_SUBSCRIBERS: 'pro_subscribers', // le groupe cible dynamiquement les abonnés professionnels
	TARGET_PART_SUBSCRIBERS: 'part_subscribers', // le groupe cible dynamiquement les abonnés particuliers

  /**************** groups ************************************/
  /**
   * Récupère les groupes
   * @return Promise(data, notices) : les groupes
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Groups_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Groupes récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un groupe
   * @return Promise(data, notices) : le groupe
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Groups_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Groupe récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouvel groupe
   * @return Promise(data, notices) : id
   */
  create (data) {
    return new Promise((resolve, reject) => {
      Groups_API.create(data).then(({data, notices}) => {
        utilsService.debugLog('Groupe créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un groupe
   * @return Promise(data, notices) : id
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Groups_API.update(id, data).then(({data, notices}) => {
        utilsService.debugLog('Groupe mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un groupe
   * @return Promise(data, notices) : id
   */
  delete (id, data) {
    return new Promise((resolve, reject) => {
      Groups_API.delete(id, data).then(({data, notices}) => {
        utilsService.debugLog('Groupe supprimé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  /***************************** Extras ************************/

  getAvatar (group) {
    const profileImage = attachmentsService.get_url(group?.profile?.attachment, 'thumb');
    if (profileImage) {
      return { content: true, props: {src: profileImage} };
    } else {
      return { content: group && group.name ? utilsService.getInitials(group.name) : "", props: {} };
    }
  },
};
export default exportedObject;
