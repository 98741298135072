import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import invoicesService from '../../services/invoices';

import BackdropLoading from '../commons/BackdropLoading';
import EditControllers from '../commons/EditControllers';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';
import FormField from '../commons/FormField';
import { AlertIcon, SaveIcon } from '../commons/Icons';
import SelectPeriodDates from '../commons/SelectPeriodDates';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { withHOCComponent } from '../hoc/HOCComponent';

class EditInvoices extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    const dateNow = new Date();
    this.state = {
      loading: false,
      dateFrom: new Date(dateNow.getFullYear(), (dateNow.getMonth() - 1), dateNow.getDate()),
      dateTo: new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate()),
      error: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  save (e) {
    e.preventDefault();
    const { hoc } = this.props;
    const { dateFrom, dateTo } = this.state;
    const periodDateFrom = new Date(Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), 0, 0, 0));
    const periodDateTo = new Date(Date.UTC(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate(), 23, 59, 59));
    const parsed_data = {
      complete_period: {
        date_from: periodDateFrom,
        date_to: periodDateTo,
      },
    }
    this._isMounted && this.setState({loading: true})
    invoicesService.createForOrders(parsed_data).then(([ids, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      if (!ids || ids.length < 1) {
        hoc.showSuccess("aucune facture crée");
      } else if (ids.length > 1) {
        hoc.showSuccess((ids.length) + " factures crées");
      } else {
        hoc.showSuccess("1 facture crée");
      }
      this.props.onSaved(ids);
    }).catch((error) => {
      this._isMounted && this.setState({error})
    }).finally(() => {
      this._isMounted && this.setState({loading: false})
    });
    return false;
  }

  render () {
    const { loading, dateFrom, dateTo, error } = this.state;
    const { classes, hoc } = this.props;

    return (
      <Box className={classes.container}>
        <BackdropLoading open={loading} />

        <Form onSubmit={ (e) => this.save(e) } noValidate>

        <FormFields className={classes.formFields}>

            <FormField>
              <Typography component="span" variant="body2">Sélectionner la période pour laquelle vous voulez émettre des factures. Seules les commandes <strong>finalisées</strong> durant cette période et n'ayant pas déjà été facturées seront prises en compte.</Typography>
            </FormField>

            <SelectPeriodDates
              dateFrom={dateFrom}
              dateTo={dateTo}
              onChangeDateFrom={ (date) => {
                this._isMounted && this.setState({dateFrom: date})
              } }
              onChangeDateTo={ (date) => {
                this._isMounted && this.setState({dateTo: date})
              } }
            />

            { error && (
              <FormField>
                <Typography component="span" variant="body2" className={classes.error}><AlertIcon /><span>{hoc.getErrorString(error)}</span></Typography>
              </FormField>
            ) }

          </FormFields>

          <EditControllers sticky>
            <Button type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={<SaveIcon />}>
              Émettre les factures
            </Button>
          </EditControllers>

        </Form>

      </Box>
    )
  }
}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  submit:{
    width: '100%',
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
});

EditInvoices.propTypes = {
  onSaved: PropTypes.func,
};

export default withHOCComponent(withStyles(styles, { withTheme: true })(EditInvoices));
