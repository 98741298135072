import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from '../../services/profiles';

import FormControl from '@mui/material/FormControl';
import FormField from './FormField';
import ToggleRadio from './ToggleRadio';
import { Typography } from '@mui/material';

class SetProfileType extends React.Component {

  render () {
    const { data, onChange, disableTypePart, disableTypePartMessage } = this.props;

    return (
      <FormField>
        <FormControl>
          <ToggleRadio
            name="type"
            id="type"
            value={data?.type || ''}
            onChange={(e) => onChange({...data, ...{type: e.target.value}})}
            options={[
              {
                value: profilesService.TYPE_PRO,
                label: {
                  title: "Professionnel",
                  infos: "Ce type de profil vous permet, en tant que personne morale (société), d'animer un réseau de partenaires parmi les profils Bridger.store, passer commandes auprès des professionnels, créer un catalogue produits et gérer des commandes. Certaines fonctionnalités de ce type profil nécessite un abonnement Premium.",
                }
              },
              {
                disabled: disableTypePart,
                value: profilesService.TYPE_PART,
                label: {
                  title: <span>Personnel{(disableTypePartMessage && <Typography variant="body3" component="span"> {disableTypePartMessage}</Typography>)}</span>,
                  infos: "Ce type de profil vous permet, en tant que personne physique, de constituer un réseau de partenaires parmi les profils Bridger.store, consulter les catalogues et passer commandes auprès des professionnels.",
                }
              }
            ]}
          />
        </FormControl>
      </FormField>
    )
  }
}

const styles = theme => ({});

SetProfileType.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
  disableTypePart: PropTypes.bool,
};

SetProfileType.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetProfileType);
