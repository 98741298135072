import { store } from './store';
import { setApiMinimalVersion } from './store/features/appSlice';

/**
 * Check if value is empty
 * @param {any} value 
 * @returns boolean
 */
export function isEmpty (value) {
  return value === undefined || value === null ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
};

const exportedObject = {

  isDebug() {
    return process.env.REACT_APP_DEBUG === 'true' || process.env.REACT_APP_DEBUG === true;
  },
  debugLog() {
    if (this.isDebug()) {
      console.log(...arguments);
    }
  },
  debugWarn() {
    if (this.isDebug()) {
      console.warn(...arguments);
    }
  },
  debugError() {
    if (this.isDebug()) {
      console.error(...arguments);
    }
  },
  /**
   * Renvoi l'ID de l'objet pour debug si l'env le permet
   */
  showDebugID (obj) {
    if (process.env.NODE_ENV === 'development') {
      return "#" + obj?.ID + " ";
    }
    return null;
  },
  isEqual (a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
  },
  isObject (item) {
    return item && (typeof item === 'object');
  },
  stripHtmlTags (str) {
    if (str) {
      return str.replace(/<\/?[^>]+(>|$)/g, "");
    }
    return str;
  },
  truncate(str, limit, end = '...') {
    let words = str.split(" ");
    if (words.length > limit) {
      return words.splice(0, limit).join(" ") + end;
    }
    return str;
  },
  upperCaseFirstLetter (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  dateStringToDate (dateString) {
    return new Date(this.dateStringToDateTime(dateString));
  },
  dateStringToDateTime (dateString) {
    return Date.parse(dateString);
  },
  dateStringToLocalString (dateString, short = true, hours = true, weekday = true) {
    let datetime = this.dateStringToDateTime(dateString);
    if (isNaN(datetime)) {
      return 'date incorrecte';
    } else {
      return this.dateTimeToLocalString(datetime, short, hours, weekday);
    }
  },
  dateTimeToLocalString (datetime, short = true, hours = true, weekday = true) {
    return this.dateToLocalString(new Date(datetime), short, hours, weekday);
  },
  dateToLocalString (date, short = true, hours = true, weekday = true) {
    let options = {
      day: 'numeric',
      month: short ? 'short' : 'long',
      year: 'numeric',
      timeZone: 'Europe/Paris',
    };
    if (hours) {
      options.hour = '2-digit';
      options.minute = '2-digit';
    }
    if (weekday) {
      options.weekday = short ? 'short' : 'long';
    }
    return date.toLocaleString('fr-FR', options);
  },
  dateStringToLocalTimeString (dateString) {
    let datetime = this.dateStringToDateTime(dateString);
    if (isNaN(datetime)) {
      return 'date incorrecte';
    } else {
      return this.dateTimeToLocalTimeString(datetime);
    }
  },
  dateTimeToLocalTimeString (datetime) {
    return this.dateToLocalTimeString(new Date(datetime));
  },
  dateToLocalTimeString (date) {
    let options = {
      hour: '2-digit',
      minute: '2-digit',
    };
    return date.toLocaleTimeString('fr-FR', options);
  },
  dateToMysqlDateTime (date) {
    return date.getFullYear() + "-"
     + this.twoDigits(1 + date.getMonth())
      + "-" + this.twoDigits(date.getDate())
       + " " + this.twoDigits(date.getHours())
        + ":" + this.twoDigits(date.getMinutes())
         + ":" + this.twoDigits(date.getSeconds());
  },
  dateToMysqlDateTimeUTC (date) {
    return date.getUTCFullYear() + "-"
     + this.twoDigits(1 + date.getUTCMonth())
      + "-" + this.twoDigits(date.getUTCDate())
       + " " + this.twoDigits(date.getUTCHours())
        + ":" + this.twoDigits(date.getUTCMinutes())
         + ":" + this.twoDigits(date.getUTCSeconds());
  },
  dateDayName (date, capitalize = false) {
    var days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
    if (capitalize) {
      days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    }
    return days[date.getDay()];
  },
  dateMonthName (date, capitalize = false) {
    var months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
    if (capitalize) {
      months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    }
    return months[date.getMonth()];
  },
  getHours2Digits(hours) {
    return ("0" + hours).slice(-2);
  },
  getMinutes2Digits(minutes) {
    return ("0" + minutes).slice(-2);
  },
  getDatesFromMonthPeriod(period, timeZone = 'UTC') {
    let periodDateFrom = null;
    let periodDateTo = null;
    if (period) {
      const periodVals = period.split("-");
      if (timeZone === 'UTC') {
        periodDateFrom = periodVals[1] && periodVals[0] ? new Date(Date.UTC(parseInt(periodVals[1]), parseInt(periodVals[0]), 1, 0, 0)) : null;
        periodDateTo = periodVals[1] && periodVals[0] ? new Date(Date.UTC(parseInt(periodVals[1]), parseInt(periodVals[0]) + 1, 0, 23, 59)) : null; // trick to get the last day of month
      } else {
        periodDateFrom = periodVals[1] && periodVals[0] ? new Date(parseInt(periodVals[1]), parseInt(periodVals[0]), 1, 0, 0) : null;
        periodDateTo = periodVals[1] && periodVals[0] ? new Date(parseInt(periodVals[1]), parseInt(periodVals[0]) + 1, 0, 23, 59) : null; // trick to get the last day of month
      }
    }
    const dates = {date_from: periodDateFrom, date_to: periodDateTo};
    return dates;
  },
  twoDigits(d) {
    if(0 <= d && d < 10) return "0" + d.toString();
    if(-10 < d && d < 0) return "-0" + (-1*d).toString();
    return d.toString();
  },
  stringSearch(string, search) {
    string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    search = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    return string.search(search) > -1;
  },
  clearAppCache() {
    if (!window.caches) {
        console.warn("Impossible de vider les window.caches");
    }
    caches.keys().then((names) => {
        for (var index = 0; index < names.length; index++) {
            var name = names[index];
            caches.delete(name);
        }
    });
  },
  /**
   * Reload current application with this actions :
   *  - clear window.caches
   *  - clear api_minimal_version from store (it will be set in next API request)
   *  - notifiy SW if update is waiting
   *  - reload current location
   */
  reloadApp() {
    // clear window.caches
    this.clearAppCache();
    // clear api_minimal_version from store
    store.dispatch(setApiMinimalVersion(null));
    // notifiy SW if update is waiting
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistration().then(registration => {
        if (registration && registration.waiting) {
          registration.waiting.postMessage({type: 'SKIP_WAITING'});
        } else {
          console.warn("Aucune mise à jour de SW en attente");
        }
      });
    }
    // reload current location after 2s waiting
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  },
  getBaseURL() {
    return window.location.protocol + '//' + window.location.host;
  },
  /**
   * Renvoie une date prochaine en fonction de la date de départ spécifiée et
   * du prochain jour spécifié
   */
  getNextDate(date_from, next_day = "P1D") {
    if (next_day.startsWith('P') && next_day.endsWith('D')) {
      date_from.setDate(date_from.getDate() + parseInt(next_day.replaceAll('P', '').replaceAll('D', '')));
    } else {
      let next_day_num = 1;
      switch (next_day) {
        case "next-mon": {
          next_day_num = 1;
          break;
        }
        case "next-tue": {
          next_day_num = 2;
          break;
        }
        case "next-wed": {
          next_day_num = 3;
          break;
        }
        case "next-thu": {
          next_day_num = 4;
          break;
        }
        case "next-fri": {
          next_day_num = 5;
          break;
        }
        case "next-sat": {
          next_day_num = 6;
          break;
        }
        case "next-sun": {
          next_day_num = 0;
          break;
        }
        default: {
          next_day_num = 1;
          break;
        }
      }
      // calcul du prochain jour
      date_from.setDate(date_from.getDate() + (next_day_num + 7 - date_from.getDay()) % 7);
    }
    return date_from;
  },
  getDaySlugFromIndex (index) {
    let day = '';
    switch (parseInt(index)) {
      case 1: {
        day = 'mon';
        break;
      }
      case 2: {
        day = 'tue';
        break;
      }
      case 3: {
        day = 'wed';
        break;
      }
      case 4: {
        day = 'thu';
        break;
      }
      case 5: {
        day = 'fri';
        break;
      }
      case 6: {
        day = 'sat';
        break;
      }
      case 0: {
        day = 'sun';
        break;
      }
      default: {
        day = null;
        break;
      }
    }
    return day;
  },
  getDayLabelFromIndex (index) {
    let day = '';
    switch (parseInt(index)) {
      case 1: {
        day = 'Lundi';
        break;
      }
      case 2: {
        day = 'Mardi';
        break;
      }
      case 3: {
        day = 'Mercredi';
        break;
      }
      case 4: {
        day = 'Jeudi';
        break;
      }
      case 5: {
        day = 'Vendredi';
        break;
      }
      case 6: {
        day = 'Samedi';
        break;
      }
      case 0: {
        day = 'Dimanche';
        break;
      }
      default: {
        day = 'Inconnu';
        break;
      }
    }
    return day;
  },
  getDayLabelFromSlug (slug) {
    let day = '';
    switch (slug) {
      case "mon": {
        day = 'Lundi';
        break;
      }
      case "tue": {
        day = 'Mardi';
        break;
      }
      case "wed": {
        day = 'Mercredi';
        break;
      }
      case "thu": {
        day = 'Jeudi';
        break;
      }
      case "fri": {
        day = 'Vendredi';
        break;
      }
      case "sat": {
        day = 'Samedi';
        break;
      }
      case "sun": {
        day = 'Dimanche';
        break;
      }
      default: {
        day = 'Inconnu';
        break;
      }
    }
    return day;
  },
  /**
   * Renvoie les initiales d'un nom
   */
  getInitials (name) {
    let initials = '';
    if (name) {
      const name_parts = name.split(" ");
      if (name_parts && name_parts.length > 0) {
        initials = name_parts[0].charAt(0).toUpperCase() + (name_parts.length > 1 ? name_parts[1].charAt(0).toUpperCase() : '');
      }
    }
    return initials;
  },
  /**
   * Renvoi la chaine de caractère avec une majuscule au premier mot
   */
  capitalizeFirstWord(str) {
    return str.split(' ').map((word, index) => index === 0 ? this.capitalizeFirstLetter(word) : word).join(' ');
  },
  /**
   * Renvoi la chaine de caractère avec la première lettre en majuscule
   */
  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  /**
   * Renvoi le numéro de département à partir du code postal
   */
  getCpCode (cp) {
    if (cp && cp.length > 1) {
       return cp.charAt(0) + cp.charAt(1);
    }
    return null;
  },
  prettyPrice (price, devise = '€', taxInfo = '') {
    return parseFloat(price).toFixed(2) + devise + taxInfo;
  },
  /**
   * Renvoi le nom de fichier indiqué dans l'entête d'une réponse de réquête de téléchargement
   * @param {HTTP_Response} response 
   * @param {string} defaultName 
   * @returns 
   */
  getDownloadResponseHeaderFileName (response, defaultName = 'fichier') {
    let filename = defaultName;
    const disposition = response?.headers && response.headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) { 
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  },
};
export default exportedObject;
