import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { Box, Grid, TextField } from '@mui/material';

class SelectPeriodMonth extends React.Component {

  render () {
    const {classes, dateFrom, dateTo, minDate, maxDate, labelDateFrom, labelDateTo, onChangeDateFrom, onChangeDateTo} = this.props;
    return (

      <Box className={classes.container}>

        <Grid container spacing={2}>

          <Grid item xs={12} md={6}>
            <MobileDatePicker
              disableCloseOnSelect={false}
              disableFuture
              cancelText="Annuler"
              clearText="Effacer"
              okText="Valider"
              label={labelDateFrom || "Du"}
              value={dateFrom}
              onChange={(date) => onChangeDateFrom(date, date.toISOString())}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} className={classes.inputDate} />}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MobileDatePicker
              disableCloseOnSelect={false}
              disableFuture
              cancelText="Annuler"
              clearText="Effacer"
              okText="Valider"
              label={labelDateTo || "Au"}
              value={dateTo}
              onChange={(date) => onChangeDateTo(date, date.toISOString())}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} className={classes.inputDate} />}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Grid>

        </Grid>

      </Box>
    );
  }
}

const styles = theme => ({
  container: {},
  inputDate: {
    width: '100%',
  },
});

SelectPeriodMonth.propTypes = {
  dateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  labelDateFrom: PropTypes.string,
  labelDateTo: PropTypes.string,
  onChangeDateFrom: PropTypes.func.isRequired,
  onChangeDateTo: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectPeriodMonth);
