import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  get (id, params) {
    return API.get('/payment-methods/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAll (params) {
    return API.get('/payment-methods/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  delete (id) {
    return API.delete('/payment-methods/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
