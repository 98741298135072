import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import { Box } from '@mui/material';
import ProductPrice from './ProductPrice';

class ProductsListItemPrice extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes, product } = this.props;
    if (!product) {
      return null;
    }

    return (
      <Box className={classes.infos}>
        
        <ProductPrice
          price={product?.price}
          price_ttc={product?.price_ttc}
          format={product?.format}
          tax_rate={product?.tax_rate}
          volume_unit={product?.volume_unit}
          format_label={product?.format_label}
        />
        
      </Box>
    )
  }
}

const styles = theme => ({
  infos: {
  },
});

ProductsListItemPrice.propTypes = {
  product: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ProductsListItemPrice);


