import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import authService from '../../services/auth';
import utilsService from '../../services/utils';
import subscriptionsService from '../../services/subscriptions';
import subscriptionPlansService from '../../services/subscription-plans';

import ConfirmDialog from '../commons/ConfirmDialog';
import BackdropLoading from '../commons/BackdropLoading';
import Forbidden from '../commons/Forbidden';
import SelectSubscriptionPlan from '../commons/SelectSubscriptionPlan';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CheckIcon } from '../commons/Icons';
import { Box } from '@mui/material';
import PaymentMethod from '../paymentMethod/PaymentMethod';
import clsx from 'clsx';

class EditSubscription extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.initialPlan = this.props?.subscription?.id_plan || null;
    this.isNew = !this.initialPlan ? true : false;
    this.state = {
      loading: false,
      plan: this.initialPlan,
      isPlanFree: false,
      isPlanChanged: false,
      terms_of_sales: false,
      hasPaymentMethod: false,
      updatingPaymentMethod: false,
      openConfirm_changePlan: false,
    }
  }

  componentDidMount () {
    this._isMounted = true;
    if (!this.getIdProfile()) {
      console.error("identifiant profil manquant");
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  isNew () {
    return !this.props.subscription ? true : false;
  }

  getIdProfile() {
    const { id_profile, subscription } = this.props;
    return subscription?.id_profile || id_profile;
  }

  getIdPlan(plan) {
    return plan ? (utilsService.isObject(plan) ? plan.ID : plan) : null;
  }

  getData () {
    const { terms_of_sales, plan } = this.state;
    return {
      id_profile: this.getIdProfile(),
      id_plan: this.getIdPlan(plan),
      terms_of_sales: terms_of_sales ? process.env.REACT_APP_SITE_CGV_VERSION : null,
    }
  }

  onChangePlan (plan) {
    const isPlanChanged = parseInt(this.getIdPlan(plan)) !== parseInt(this.getIdPlan(this.initialPlan));
    const isPlanFree = subscriptionPlansService.isFree(plan);
    this._isMounted && this.setState({
      plan, 
      isPlanFree,
      isPlanChanged,
      terms_of_sales: false, // on décoche systématiquement pour que l'utilisateur termine par cette action
    });
  }

  isCompleted () {
    const data = this.getData();
    if (!data.id_plan) {
      return false;
    }
    if (!data.terms_of_sales) {
      return false;
    }
    return true;
  }

  save (e) {
    e && e.preventDefault();

    const data = this.getData();

    if (!data.terms_of_sales) {
      this.props.onError('Vous devez accepter les conditions générales de vente');
      return false;
    }

    if (this.isNew) {
      // nouvel abonnement Premium
      this.create();
    } else {
      // si changement de plan, on demande confirmation en expliquant ce qu'il va se passer au niveau du paiement
      // et notamment au niveau du remboursement de l'éventuel en-cours
      if (parseInt(this.getIdPlan(this.initialPlan)) !== parseInt(data.id_plan)) {
        this._isMounted && this.setState({ openConfirm_changePlan: true });
        // suite dans update()
      } else {
        this.update();
      }
    }
    return false;
  }

  create () {
    const data = this.getData();

    this._isMounted && this.setState({ loading: true });
    subscriptionsService.create(data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération de l'item à jour
      return subscriptionsService.get(id);
    }).then(([subscription]) => {
      this.props.onSaved(subscription, this.state.is_new);

      // Mise à jour du profil authentifié (afin qu'il prenne en compte la souscription)
      return authService.maybeRefreshToken(subscription.id_profile);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error, this.state.is_new);
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  update () {
    const { subscription } = this.props;

    const data = this.getData();

    this._isMounted && this.setState({ loading: true });
    subscriptionsService.update(subscription.ID, data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération de l'item à jour
      return subscriptionsService.get(id);
    }).then(([subscription]) => {
      this.props.onSaved(subscription, this.state.is_new);

      // Mise à jour du profil authentifié (afin qu'il prenne en compte la souscription)
      return authService.maybeRefreshToken(subscription.id_profile);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error, this.state.is_new);
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  render () {
    const { classes, appStore, subscription, onError, disableGutters } = this.props;
    const { plan, isPlanFree, isPlanChanged, hasPaymentMethod, updatingPaymentMethod, openConfirm_changePlan } = this.state;

    if (!appStore?.auth?.id_user) {
      return <Forbidden />;
    }

    const showPaymentMethod = !isPlanFree && isPlanChanged;
    const showSubmit = isPlanChanged && (isPlanFree || (hasPaymentMethod && !updatingPaymentMethod));
    const submitDisabled = !this.isCompleted();

    return (
      <Box className={clsx(classes.container, disableGutters ? undefined : classes.containerGutters)}>

          <BackdropLoading open={this.state.loading} />

          <Box className={classes.selectPlan}>
            <SelectSubscriptionPlan
              value={plan}
              onChange={({plan}) => {
                this.onChangePlan(plan)
              }}
              onError={onError}
              query={{free: (subscription ? false : undefined)}}
            />
          </Box>

          { showPaymentMethod && (
            <Box className={classes.paymentMethod}>
              <PaymentMethod
                id_profile={this.getIdProfile()}
                onError={onError}
                onUpdating={(updating) => {
                  // l'utilisateur est en train de modifier son moyen de paiement (une fois la modification terminée, ça repasse par onLoaded)
                  this._isMounted && this.setState({ updatingPaymentMethod: updating });
                }}
                onUpdated={() => {
                  // NOTE : les CGV ont été acceptées à la création/modification du moyen de paiement
                  this._isMounted && this.setState({ terms_of_sales: true });
                }}
                onLoaded={(paymentMethod) => {
                  if (paymentMethod) {
                    this._isMounted && this.setState({ hasPaymentMethod: true });
                  }
                }}
                canDelete={false}
              />
            </Box>
          ) }

          { showSubmit && (
            <Box className={classes.submitWrapper}>
              <FormControlLabel className={classes.termsOfSales}
                control={
                  <Switch
                    required
                    checked={this.state.terms_of_sales}
                    onChange={ (e) => this._isMounted && this.setState({terms_of_sales: e.target.checked}) }
                    inputProps={{ 'id': 'accept-legal', 'aria-label': "acceptation légales" }}
                  />
                }
                label={
                  <Typography component="div" variant="body2" className={classes.legal}>J'accepte les <a className={classes.legal_lnk} href={process.env.REACT_APP_SITE_URL_CGV} target="_blank" rel="noreferrer">Conditions générales de vente</a> du site Web {process.env.REACT_APP_MAIN_BASE_URL}</Typography>
                }
              />

              <Button fullWidth disabled={submitDisabled} type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={<CheckIcon />}>
                { isPlanFree ? "Démarrer l'essai" : "Activer l'abonnement" }
              </Button>

            </Box>
          ) }

          { openConfirm_changePlan && (
            <ConfirmDialog
              open={openConfirm_changePlan}
              title="Changement de plan d'abonnement"
              message="En changeant de plan d'abonnement, vous résiliez votre plan actuel pour activer le nouveau. Votre moyen de paiement va être débité du montant du nouveau plan d'abonnement. Si un éventuel avoir vous est dû, vous serez remboursé(e) dans les jours à venir."
              onConfirm={ () => {
                  this._isMounted && this.setState({openConfirm_changePlan: false});
                  this.update();
                }
              }
              onClose={ () => this._isMounted && this.setState({ openConfirm_changePlan: false }) }
            />
          ) }

        </Box>
    )
  }
}

const styles = theme => ({
  container: {},
  containerGutters: {
    padding: theme.spacing(2),
  },
  form_control: {
    width: '100%',
  },
  submit:{
    width: '100%',
  },
  paymentMethod: {
    borderTop: `1px solid ${theme.palette.bg.light}`,
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  submitWrapper: {
    borderTop: `1px solid ${theme.palette.bg.light}`,
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  termsOfSales: {
    marginBottom: theme.spacing(2),
  },
});

EditSubscription.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]), // en cas de création
  subscription: PropTypes.object, // en cas de modification
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditSubscription));
