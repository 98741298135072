import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import notificationsService from './../../services/notifications';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

class SetNotificationsPreferences extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {};
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setChecked (changedTopic, changedWay, checked) {
    const { value } = this.props;
    let newValue = {};
    for (const topic of notificationsService.getTopics()) {
      if (changedTopic === topic) {
        newValue[topic] = [];
        for (const way of notificationsService.getWays()) {
          if (changedWay === way) {
            if (checked) {
              newValue[topic].push(way);
            }
          } else if (value[topic] && value[topic].includes(way)) {
            newValue[topic].push(way);
          }
        }
      } else {
        newValue[topic] = value[topic];
      }
    }
    this.props.onChange && this.props.onChange(newValue);
  }

  render () {
    const { classes, value } = this.props;

    return (
      <Box className={classes.content}>
        <Typography component="p" variant="body2">Choisissez comment être notifié/e pour chaque thème : </Typography>
        { notificationsService.getTopics().map((topic) => {
          return (
            <Card key={topic} className={classes.topic} elevation={0}>
              <Typography component="div" variant="body2" className={classes.topic_label}>{ notificationsService.getTopicLabel(topic) }</Typography>
              <Box className={classes.topic_ways}>
                { notificationsService.getWays().map((way) => {
                  const checked = value && value[topic] && value[topic].includes(way) ? true : false;
                  return (
                    <FormControlLabel
                      className={classes.way}
                      key={way}
                      control={
                        <Switch
                          checked={checked}
                          onChange={ (e) => this.setChecked(topic, way, e.target.checked) }
                          inputProps={{ 'id': way, 'aria-label': way }}
                        />
                      }
                      label={
                        <Typography component="div" variant="body2" className={classes.way_label}>{ notificationsService.getWayLabel(way) }</Typography>
                      }
                    />
                  )
                }) }
              </Box>
            </Card>
          )
        }) }
      </Box>
    );
  }
}

const styles = theme => ({
  content: {},
  topic: {

  },
  topic_label: {
    color: '#333333',
    textTransform: 'capitalize',
  },
  topic_ways: {
    margin: theme.spacing(0, 0, 0, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  way: {
  },
  way_label: {
    color: '#555555',
  },
});

SetNotificationsPreferences.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};


export default withStyles(styles, { withTheme: true })(SetNotificationsPreferences);
