import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import ordersService from './../../services/orders';

import BackdropLoading from './../commons/BackdropLoading';
import EditControllers from './../commons/EditControllers';
import Forbidden from './../commons/Forbidden';
import Form from './../commons/Form';
import FormFields from './../commons/FormFields';
import FormField from './../commons/FormField';
import { SaveIcon } from './../commons/Icons';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import SetOrderDelivery from '../commons/SetOrderDelivery';
import { Box } from '@mui/material';

class EditOrder extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      loaded: false,
      data: {
        id_profile_customer: null,
        id_profile_provider: null,
        id_delivery_mode: null,
        date_delivery: '',
        title: '',
        type: '',
        auto_emit: '0',
        recurrence: {day: '', time: ''},
      },
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
    const { id_order, onError } = this.props;
    this._isMounted && this.setState({loading: true});
    ordersService.get(id_order).then(([order]) => {
      if (order) {
        this.setState({
          data: {...this.state.data, ...{
            id_profile_customer: order.id_profile_customer,
            id_profile_provider: order.id_profile_provider,
            id_delivery_mode: order.id_delivery_mode,
            date_delivery: order.date_delivery,
            title: order.title,
            type: order.type,
            auto_emit: ordersService.isAutoEmit(order) ? '1' : '0',
            recurrence: {day: order.recurrence_day, time: order.recurrence_time},
          }},
          loaded: true
       });
      }
    }).catch((e) => {
      onError(e);
    }).finally(() => {
      this._isMounted && this.setState({loading: false})
    });
  }

  save (e) {
    e.preventDefault();
    const { id_order } = this.props;
    const { data } = this.state;
    const parsed_data = {
      date_delivery: data.date_delivery,
      id_delivery_mode: data.id_delivery_mode,
      recurrence_day: data.recurrence.day,
      recurrence_time: data.recurrence.time,
      title: data.title,
      auto_emit: data.auto_emit,
    }
    this._isMounted && this.setState({loading: true})
    ordersService.update(id_order, parsed_data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération de l'item à jour
      return ordersService.get(id);
    }).then(([order]) => {
      this.props.onSaved(order);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false})
    });
    return false;
  }

  render () {
    const { classes, appStore, id_order, onError } = this.props;
    const { loaded, data, loading } = this.state;

    if (!id_order) {
      return <Forbidden message={<Typography variant="body1">Commande manquante.</Typography>} />;
    }

    if (!appStore?.auth?.id_user) {
      return <Forbidden />;
    }

    return (
      <Box className={classes.container}>

        <BackdropLoading open={loading} />

        { loaded && (

          <Form onSubmit={ (e) => this.save(e) } noValidate>

            <FormFields className={classes.formFields}>

              {(data.type === ordersService.TYPE_RECURRENT || data.type === ordersService.TYPE_SAMPLE) && (
                <FormField>
                  <FormControl className={clsx(classes.form_control, classes.form_control_title)}>
                    <TextField
                      required={data.type === ordersService.TYPE_SAMPLE ? true : false}
                      className={classes.text_field}
                      label="Titre"
                      value={data.title || ''}
                      onChange={ (e) => this._isMounted && this.setState({ data: {...this.state.data, ...{title: e.target.value}} }) }
                    />
                  </FormControl>
                </FormField>
              )}

              <SetOrderDelivery
                data={data}
                onChange={(data) => this._isMounted && this.setState({data})}
                onError={onError}
              />

            </FormFields>

            <EditControllers sticky>
              <Button type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={ <SaveIcon /> }>
                Enregistrer
              </Button>
            </EditControllers>

          </Form>

        ) }

      </Box>
    )
  }
}

const styles = theme => ({
  container: {},
  form_control: {
    width: '100%',
  },
  text_field: {
    width: '100%'
  },
  submit:{
    width: '100%',
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
});

EditOrder.propTypes = {
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  id_order: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const mapStateToProps = state => ({
	appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditOrder));
