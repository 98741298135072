import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import notificationsService from './../../services/notifications';

import BackdropLoading from './../commons/BackdropLoading';
import SelectUser from './../commons/SelectUser';
import SelectProfile from './../commons/SelectProfile';
import SelectGroup from './../commons/SelectGroup';
import EditControllers from './../commons/EditControllers';
import Forbidden from './../commons/Forbidden';
import Form from './../commons/Form';
import FormFields from './../commons/FormFields';
import FormField from './../commons/FormField';
import TextFieldLimited from './../commons/TextFieldLimited';
import { PaperPlaneIcon } from './../commons/Icons';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DateTimePicker from '@mui/lab/DateTimePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Box } from '@mui/material';

class EditNotification extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      user: null,
      profile: null,
      group: null,
      profile_sender: null,
      recipient: 'profile',
      state: notificationsService.STATE_UNREAD,
      topic: notificationsService.TOPIC_GENERAL,
      subject: '',
      message: '',
      date_scheduling: '',
      is_new: true,
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showLoading() {
    this._isMounted && this.setState({loading: true})
  }

  hideLoading() {
    this._isMounted && this.setState({loading: false})
  }

  load () {
    const { notification, appStore } = this.props;
    if (typeof notification === 'object' && notification !== null && notification.ID) {
      // la commande est passée en props
      this._isMounted && this.setState({
        user: notification.id_user,
        profile: notification.id_profile,
        group: notification.id_group,
        profile_sender: notification.profile_sender || notification.id_profile_sender,
        state: notification.state,
        topic: notification.topic,
        subject: notification.subject,
        message: notification.message,
        date_scheduling: notification.date_scheduling,
        recipient: notification.id_group ? 'group' : 'profile',
        is_new: false
      });
    } else {
      // c'est une nouvelle notification, on initialise les valeurs
      let date_scheduling = new Date();
      date_scheduling.setDate(date_scheduling.getDate() + 1)
      this._isMounted && this.setState({
        date_scheduling: date_scheduling.toISOString(),
        profile_sender: appStore?.authProfile?.ID,
        is_new: true,
      });
    }
  }

  save (e) {
    e.preventDefault();
    const { notification } = this.props;

    const data = {
      id_profile: this.state.recipient === 'profile' ? (this.state.profile && this.state.profile.ID ? this.state.profile.ID : this.state.profile) : null,
      id_group: this.state.recipient === 'group' ? (this.state.group && this.state.group.ID ? this.state.group.ID : this.state.group) : null,
      id_user: this.state.recipient === 'user' ? (this.state.user && this.state.user.ID ? this.state.user.ID : this.state.user) : null,
      id_profile_sender: this?.state?.profile_sender?.ID || this?.state?.profile_sender,
      state: this.state.state,
      topic: this.state.topic,
      subject: this.state.subject,
      message: this.state.message,
      date_scheduling: this.state.date_scheduling && this.state.date_scheduling !== '' && this.state.date_scheduling !== 'Invalid Date' ? this.state.date_scheduling : null,
    }

    if (!data.id_profile && !data.id_group && !data.id_user) {
      this.props.onError("Destinataire manquant");
      return;
    }
    if (!data.id_profile_sender) {
      this.props.onError("Expéditeur manquant");
      return;
    }
    if (!data.subject || data.subject.length < 1) {
      this.props.onError("Sujet manquant");
      return;
    }
    if (!data.message || data.message.length < 1) {
      this.props.onError("Message manquant");
      return;
    }
    if (data.state === notificationsService.STATE_SCHEDULED) {
      if (!data.date_scheduling || data.date_scheduling === '' || isNaN(data.date_scheduling)) {
        this.props.onError("Date invalide");
        return;
      }
    }

    if (this.state.is_new) {
      this.showLoading();
      notificationsService.create(data).then(([id, notices]) => {
        notices && notices.length > 0 && this.props.onError(notices);
        // récupération de l'item à jour
        return notificationsService.get(id);
      }).then(([notification]) => {
        this.props.onSaved(notification, this.state.is_new);
      }).catch((error) => {
        console.error('error : ', error);
        this.props.onError(error, this.state.is_new);
      }).finally(() => {
        this.hideLoading();
      });
    } else {
      this.showLoading();
      notificationsService.update(notification.ID, data).then(([id, notices]) => {
        notices && notices.length > 0 && this.props.onError(notices);
        // récupération de l'item à jour
        return notificationsService.get(id);
      }).then(([notification]) => {
        this.props.onSaved(notification, this.state.is_new);
      }).catch((error) => {
        console.error('error : ', error);
        this.props.onError(error, this.state.is_new);
      }).finally(() => {
        this.hideLoading();
      });
    }
    return false;
  }

  render () {
    const { classes, appStore, fixedCtrl } = this.props;

    if (!appStore?.auth?.id_user || !appStore?.authProfile) {
      return <Forbidden />;
    }

    return (
      <Box className={classes.container}>
        <BackdropLoading open={this.state.loading} />

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields>

            <FormField>
              <FormControl fullWidth>
                <InputLabel id="select-label-recipient">Type de destinataire</InputLabel>
                <Select
                  className={classes.select_field}
                  labelId="select-label-recipient"
                  label="Type de destinataire"
                  id="recipient"
                  nale="recipient"
                  value={this.state.recipient}
                  onChange={ (e) => this._isMounted && this.setState({ recipient: e.target.value })}
                >
                  <MenuItem value={'profile'}>Profil</MenuItem>
                  <MenuItem value={'group'}>Groupe</MenuItem>
                  <MenuItem value={'user'}>Utilisateur</MenuItem>
                </Select>
              </FormControl>
            </FormField>

            { this.state.recipient === 'profile' && (
              <FormField>
                <SelectProfile
                  fullwidth
                  title="Destinataire"
                  label="Choisir le profil"
                  value={this.state.profile}
                  onChange={(profile) => this._isMounted && this.setState({profile})}
                  onError={this.props.onError}
                />
              </FormField>
            ) }

            { this.state.recipient === 'group' && (
              <FormField>
                <SelectGroup
                  fullwidth
                  title="Destinataire"
                  label="Choisir le groupe"
                  value={this.state.group}
                  onChange={(group) => this._isMounted && this.setState({group})}
                  onError={this.props.onError}
                  canCreate={true}
                />
              </FormField>
            ) }

            { this.state.recipient === 'user' && (
              <FormField>
                <SelectUser
                  fullwidth
                  title="Destinataire"
                  label="Choisir l'utilisateur"
                  value={this.state.user}
                  onChange={(user) => this._isMounted && this.setState({user})}
                  onError={this.props.onError}
                />
              </FormField>
            ) }

            { this.state.is_new && appStore?.auth?.is_admin && (
              <FormField>
                <FormControl className={clsx(classes.form_control, classes.form_control_type)}>
                  <InputLabel id="select-label-topic">Thème</InputLabel>
                  <Select
                    className={classes.select_field}
                    labelId="select-label-topic"
                    label="Thème"
                    id="topic"
                    value={this.state.topic}
                    onChange={ (e) => this._isMounted && this.setState({ topic: e.target.value }) }
                  >
                    <MenuItem value={notificationsService.TOPIC_GENERAL}>{notificationsService.getTopicLabel(notificationsService.TOPIC_GENERAL)}</MenuItem>
                    <MenuItem value={notificationsService.TOPIC_ACCOUNT}>{notificationsService.getTopicLabel(notificationsService.TOPIC_ACCOUNT)}</MenuItem>
                    <MenuItem value={notificationsService.TOPIC_ORDER}>{notificationsService.getTopicLabel(notificationsService.TOPIC_ORDER)}</MenuItem>
                    <MenuItem value={notificationsService.TOPIC_MODERATION}>{notificationsService.getTopicLabel(notificationsService.TOPIC_MODERATION)}</MenuItem>
                    <MenuItem value={notificationsService.TOPIC_SUBSCRIPTION}>{notificationsService.getTopicLabel(notificationsService.TOPIC_SUBSCRIPTION)}</MenuItem>
                  </Select>
                </FormControl>
              </FormField>
            ) }

            <FormField>
              <FormControl className={clsx(classes.form_control, classes.form_control_title)}>
                <TextFieldLimited
                  className={classes.text_field}
                  label="Sujet"
                  name="subject"
                  value={this.state.subject || ''}
                  onChange={ (subject) => this._isMounted && this.setState({ subject }) }
                  limit={50}
                />
              </FormControl>
            </FormField>

            <FormField>
              <FormControl className={clsx(classes.form_control, classes.form_control_note)}>
                <TextFieldLimited
                  className={classes.text_field}
                  label="Message"
                  name="message"
                  value={this.state.message || ''}
                  onChange={ (message) => this._isMounted && this.setState({ message }) }
                  multiline
                  rows={4}
                />
              </FormControl>
            </FormField>

            <FormField>
              <FormControlLabel
                className={clsx(classes.form_control, classes.form_control_type)}
                control={
                  <Switch
                    checked={this.state.state === notificationsService.STATE_SCHEDULED}
                    onChange={ (e) => {
                      if (e.target.checked) {
                        this._isMounted && this.setState({ state: notificationsService.STATE_SCHEDULED });
                      } else {
                        this._isMounted && this.setState({ state: notificationsService.STATE_UNREAD });
                      }
                    } }
                    inputProps={{ 'aria-label': 'scheduled' }}
                  />
                }
                label={
                  <Typography component="div" variant="body2" className={classes.way_label}>programmer la notification</Typography>
                }
              />
            </FormField>

            { this.state.state === notificationsService.STATE_SCHEDULED && (
              <FormField>
                <FormControl className={clsx(classes.form_control, classes.form_control_type)}>
                  <DateTimePicker
                    disablePast
                    ampm={false}
                    ampmInClock={false}
                    minutesStep={10}
                    className={clsx(classes.datePicker, this.props.className)}
                    label="Programmée pour"
                    value={this.state.date_scheduling}
                    onChange={ (date) => this._isMounted && this.setState({date_scheduling: date}) }
                    inputFormat="dd/MM/yyyy HH:mm"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FormControl>
              </FormField>
            ) }

            <FormField>
              <EditControllers fixed={fixedCtrl}>
                <Button type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={<PaperPlaneIcon />}>
                  Envoyer
                </Button>
              </EditControllers>
            </FormField>

          </FormFields>

        </Form>

      </Box>
    )
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
  },
  form_control: {
    width: '100%',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  accordion_heading: {
    fontWeight: 'bold',
    // fontSize: theme.typography.pxToRem(15),
    margin: 0,
  },
  accordion_details: {
    flexDirection: 'column',
  },
  text_field: {
    width: '100%'
  },
  date_field: {
    width: '100%'
  },
  label: {
    display: 'inline-block',
    margin: theme.spacing(1, 0),
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
  },
  infos: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  info: {},
  infoText: {
    marginBottom: theme.spacing(2),
  },
  submit:{
    width: '100%',
  }
});

EditNotification.propTypes = {
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  notification: PropTypes.object,
  fixedCtrl: PropTypes.bool,
};

EditNotification.defaultProps = {
  fixedCtrl: false,
};

const mapStateToProps = state => ({
	appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditNotification));
