import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import contactsService from '../../services/contacts';
import { isEmpty } from '../../services/utils';

import Contact from '../contact/Contact';
import { PencilIcon, PlusIcon, RenewIcon } from './Icons';
import ToggleRadio from './ToggleRadio';
import DialogEditContact from '../dialog/DialogEditContact';
import { Box, IconButton, Button, CircularProgress } from '@mui/material';

class SelectContact extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this._isInitialized = false;
    this.state = {
      loading: false,
      error: false,
      contacts: [],
      showSelected: false,
      openCreateDialog: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
    if (!isEmpty(this.props.contact)) {
      this._isMounted && this.setState({ showSelected: true });
    }
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.id_profile) !== JSON.stringify(prevProps.id_profile)){
      this.load();
      return;
    }
    if (JSON.stringify(this.props.reload) !== JSON.stringify(prevProps.reload)){
      this.load();
      return;
    }
  }

  load () {
    const { id_profile, contact, role } = this.props;
    if (id_profile) {
      this._isMounted && this.setState({ loading: true, error: false });
      contactsService.getAll(id_profile).then(([contacts]) => {
        this._isMounted && this.setState({ contacts });
        if (isEmpty(contact)) {
          let defaultContact = null;
          let roleContact = null;
          contacts.forEach(contactItem => {
            if (role && contactItem.role === role) {
              roleContact = {...contactItem};
            }
            if (contactItem.role === contactsService.ROLE_DEFAULT) {
              defaultContact = {...contactItem};
            }
          });
          if (!isEmpty(roleContact)) {
            // sélectionne le contact du profil qui correspond au rôle attendu
            this.props.onChange && this.props.onChange(roleContact);
            this._isMounted && this.setState({ showSelected: true });
            console.log("contact par défaut du role !!!");
          } else if (!isEmpty(defaultContact)) {
            // sélectionne le contact par défaut du profil
            this.props.onChange && this.props.onChange(defaultContact);
            this._isMounted && this.setState({ showSelected: true });
            console.log("contact par défaut !!!");
          }
        } else {
          this._isMounted && this.setState({ showSelected: true });
        }
      }).catch((e) => {
        this.props.onError(e);
        this._isMounted && this.setState({ error: true });
      }).finally(() => {
        this._isMounted && this.setState({ loading: false });
      });
    }
  }

  onContactSaved (savedContact, isNew) {
    if (isNew) {
      this._isMounted && this.setState({contacts: [...[savedContact], ...this.state.contacts]});
    } else {
      this._isMounted && this.setState(prevState => ({
        contacts: prevState.contacts.map((contact) => {
          if (contact.ID === savedContact.ID) {
            return savedContact;
          }
          return contact;
        })
      }));
    }
    // sélectionne ce contact qui vient d'être créé/modifié
    this.props.onChange && this.props.onChange(savedContact);
    // on propage au parent qu'un contact a subit un changement
    this.props.onContactsUpdated && this.props.onContactsUpdated();
  }

  onContactDeleted (id_deletedContact) {
    this._isMounted && this.setState(prevState => ({
      contacts: prevState.contacts.filter((contact) => {
        return contact.ID !== id_deletedContact;
      })
    }));
    // on désélectionne 
    // NOTE : on ne peut pas savoir si c'est celui qui était choisi précédemment, on raz dans tous les cas
    this.props.onChange && this.props.onChange(null);
    // on propage au parent qu'un contact a subit un changement
    this.props.onContactsUpdated && this.props.onContactsUpdated();
  }

  getContact (id_contact) {
    const { contacts } = this.state;
    let contact = null;
    if (contacts && contacts.length > 0) {
      contacts.forEach(item => {
        if (parseInt(item.ID) === parseInt(id_contact)) {
          contact = item;
        }
      });
    }
    return contact;
  }

  render () {
    const { classes, className, id_profile } = this.props;
    if (!id_profile) {
      return (<Box>Aucun profil</Box>);
    }
    return (
      <Box className={clsx(classes.root, className && className.root)}>
        { this.renderContent() }
      </Box>
    );
  }

  renderContent() {
    const { classes } = this.props;
    const { loading, error, showSelected } = this.state;
    if (loading) {
      return (
        <Box className={classes.loading}>
          <CircularProgress color="secondary" />
        </Box>
      );
    } else if (error) {
      return (
        <Box className={classes.error}>
          <h4>Erreur de chargement</h4>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    }
    return (
      <>
        {!showSelected && this.renderSelect()}
        {showSelected && this.renderSelected()}
      </>
    );
  }

  renderSelect () {
    const { classes, id_profile, disableEdit, onError } = this.props;
    const { contacts, openCreateDialog } = this.state;
    const options = contacts && contacts.length > 0 ? contacts.map((contact) => ({
      label: {
        el: <Box className={classes.contactWrapper}>
              <Contact
                contact={contact}
                withActions={!disableEdit}
                onSaved={(contact, isNew) => this.onContactSaved(contact, isNew)}
                onDeleted={(id_contact) => this.onContactDeleted(id_contact)}
                onError={(e) => onError && onError(e)}
              />
            </Box>
      },
      value: contact.ID
    })) : [];
    return (
      <Box>
        <ToggleRadio
          value={null}
          onChange={(e) => {
            this.props.onChange && this.props.onChange(this.getContact(e.target.value));
            this._isMounted && this.setState({showSelected: true});
          }}
          options={options}
          className={{radioFormControl: classes.radioFormControl, radioFormControlLabel: classes.radioFormControlLabel}}
        />
        { !disableEdit && (
          <>
            <Box className={classes.action}>
              <Button color="secondary" onClick={() => this._isMounted && this.setState({openCreateDialog: true})} startIcon={<PlusIcon />}>Ajouter des coordonnées</Button>
            </Box>
            { openCreateDialog && (
              <DialogEditContact
                open={openCreateDialog}
                id_profile={id_profile}
                onSaved={(contact, isNew) => {
                  this.onContactSaved(contact, isNew);
                  this._isMounted && this.setState({openCreateDialog: false});
                }}
                onError={ (e) => this.props.onError && this.props.onError(e) }
                onClose={ () => this._isMounted && this.setState({openCreateDialog: false}) }
              />
            ) }
          </>
        ) }
      </Box>
    );
  }

  renderSelected () {
    const { classes, contact } = this.props;
    if (contact) {
      return (
        <Box className={classes.selected}>
          <Box className={classes.contactWrapper}>
            <Contact contact={contact} />
          </Box>
          <Box className={classes.action}>
            <IconButton size="small" color="secondary" onClick={() => {
              this._isMounted && this.setState({showSelected: false});
              this.props.onChange && this.props.onChange(null);
            }}>
              <PencilIcon />
            </IconButton>
          </Box>
        </Box>
      );
    }
    return null;
  }
}

const styles = theme => ({
  loading: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  error: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  radioFormControl: {
    alignItems: 'center',
    margin: theme.spacing(0, 0, 1, 0),
  },
  radioFormControlLabel: {
    margin: 0,
  },
  selected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(0.5),
    padding: theme.spacing(0,0,0,1),
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  contactWrapper: {
    margin: theme.spacing(1,0),
  },
});

SelectContact.propTypes = {
  contact: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onContactsUpdated: PropTypes.func, // appelée lorsque la liste des contacts à subit mise à jour (ajour/modification/suppression)
  onError: PropTypes.func.isRequired,
  id_profile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  role: PropTypes.string,
  disableEdit: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(SelectContact);
