import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withHOCComponent } from '../hoc/HOCComponent';

import AuthUser from './AuthUser';
import AuthProfileSwitch from './AuthProfileSwitch';

class Auth extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {};
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { appStore, onAuthenticated } = this.props;
    const userLoggedIn = appStore.auth?.id_user;
    const userLoggedInProfile = appStore.authProfile;
    return (
      <>
        { !userLoggedIn && (
          <AuthUser onEnd={() => {
            if (userLoggedInProfile) {
              onAuthenticated()
            }
          }} />
        ) }
        { userLoggedIn && !userLoggedInProfile && (
          <AuthProfileSwitch onEnd={() => {
            onAuthenticated()
          }} />
        ) }
      </>
    );
  }
}

const styles = theme => ({
});

Auth.propTypes = {
  onAuthenticated: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	appStore: state.app,
});

export default withHOCComponent(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(Auth)));