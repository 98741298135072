import React from 'react';
import PropTypes from 'prop-types';
import { withHOCComponent } from '../hoc/HOCComponent';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import ordersService from './../../services/orders';
import utilsService, { isEmpty } from './../../services/utils';

import { OrderRecurringIcon, StoreIcon, ArrowRightIcon, InfoIcon, BagIcon, UserIcon, ChevronRightIcon, AlertIcon } from './../commons/Icons';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';

class OrderInfos extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { classes, className, order, permissions } = this.props;
    if (!order || !permissions) {
      return null;
    }
    return (
      <Box className={clsx(classes.root, className && className.root)}>

        { ordersService.isStandard(order) && ordersService.isStateDraft(order) && ordersService.isAutoEmit(order) && (
          <Box className={classes.detail}>
            <InfoIcon className={classes.detail_icon} />
            <Box className={classes.detail_content}>
              <Typography className={classes.detail_text} variant="body2" component="span">Cette commande sera émise automatiquement.</Typography>
            </Box>
          </Box>
        ) }
        { ordersService.isRecurrent(order) && ordersService.isStatePending(order) && ordersService.isAutoEmit(order) && (
          <Box className={classes.detail}>
            <InfoIcon className={classes.detail_icon} />
            <Box className={classes.detail_content}>
              <Typography className={classes.detail_text} variant="body2" component="span">Chaque occurence sera émise automatiquement.</Typography>
            </Box>
          </Box>
        ) }
        { order.profile_provider && (
          <Box className={classes.detail}>
            <StoreIcon className={classes.detail_icon} />
            <Box className={classes.detail_content}>
              { this.render_profile(order.profile_provider, order.provider_billing_contact, ordersService.isStateDraft(order) || permissions.is_auth_customer) }
            </Box>
          </Box>
        ) }
        { order.profile_customer && (
          <Box className={classes.detail}>
            <BagIcon className={classes.detail_icon} />
            <Box className={classes.detail_content}>
              { this.render_profile(order.profile_customer, order.customer_billing_contact, ordersService.isStateDraft(order) || permissions.is_auth_provider) }
            </Box>
          </Box>
        ) }

        <Box className={classes.detail}>
          <ArrowRightIcon className={classes.detail_icon} />
          <Box className={classes.detail_content}>
            { !isEmpty(order?.delivery_mode?.name) && (
              <Typography className={classes.detail_text} variant="body2" component="div">{ order.delivery_mode.name }</Typography>
            ) }
            { ordersService.isStandard(order) && order.date_delivery && (
              <Typography className={classes.detail_text} variant="body2" component="div">Le {utilsService.dateStringToLocalString(order.date_delivery, false)}</Typography>
            ) }
            { !isEmpty(order?.delivery_contact) && (
              <>
                { this.render_contact(order.delivery_contact) }
              </>
            ) }
          </Box>
        </Box>

        {ordersService.isRecurrentInstance(order) && (
          <Box className={classes.detail}>
            <OrderRecurringIcon className={classes.detail_icon} />
            <Box className={classes.detail_content}>
              <Box className={classes.detail_content_recurrent}>
                <Box>
                  <Typography className={classes.detail_text} variant="body2" component="div">Commande récurrente</Typography>
                  <Typography className={classes.detail_text} variant="body2" component="div">{ordersService.getPrettyRecurrenceInstanceDetail(order)}</Typography>
                </Box>
                { permissions.is_auth_customer && (
                  <IconButton className={classes.detail_content_recurrent_btn} color="secondary" onClick={() => this.props.hoc.redirectTo('/orders/' + order.id_order_recurrent)}>
                    <ChevronRightIcon />
                  </IconButton>
                ) }
              </Box>
            </Box>
          </Box>
        ) }

        { ordersService.isRecurrent(order) && (
          <Box className={classes.detail}>
            <OrderRecurringIcon className={classes.detail_icon} />
            <Box className={classes.detail_content}>
              <Typography className={classes.detail_text} variant="body2" component="div">{ordersService.getPrettyRecurrenceDetail(order)}</Typography>
            </Box>
          </Box>
        ) }

        { order.scenario && !ordersService.isRecurrentInstance(order) && (
          <Box className={classes.detail}>
            <UserIcon className={classes.detail_icon} />
            <Box className={classes.detail_content}>
              <Typography className={classes.detail_text} variant="body2" component="span">{ ordersService.getOrderScenarioLabel(order) }</Typography>
            </Box>
          </Box>
        ) }

        { order.state_comment && order.state_comment !== '' && (
          <Box className={classes.detail}>
            { ordersService.isStateError(order) && (
              <>
                <AlertIcon className={classes.detail_icon} />
                <Box className={classes.detail_content}>
                  <Typography className={classes.detail_text} variant="body2" component="div">Raison de l'erreur : { order.state_comment }</Typography>
                </Box>
              </>
            ) }
            { !ordersService.isStateError(order) && (
              <>
                <InfoIcon className={classes.detail_icon} />
                <Box className={classes.detail_content}>
                  <Typography className={classes.detail_text} variant="body2" component="div">{ order.state_comment }</Typography>
                </Box>
              </>
            ) }
          </Box>
        ) }

      </Box>
    );
  }

  render_profile (profile, contact, display_contact_details = true) {
    const { classes } = this.props;
    if (!profile) {
      return null;
    }
    return (
      <>
        <Typography className={classes.detail_text} variant="body2" component="span">{ profile?.name }</Typography>
        { display_contact_details && contact && (
          <Box className={classes.customer_address}>
            { this.render_contact(contact, profile?.name !== contact?.address_name) }
          </Box>
        ) }
      </>
    );
  }

  render_contact (contact, display_address_name = true) {
    const { classes } = this.props;
    return (
      <>
        { display_address_name && !isEmpty(contact?.address_name) && (
          <Typography component="div" variant="body3" className={clsx(classes.info, classes.address_name)}>{ contact?.address_name }</Typography>
        ) }
        { !isEmpty(contact?.address_1) && (
          <Typography component="div" variant="body3" className={clsx(classes.info, classes.address_1)}>{ contact?.address_1 }</Typography>
        ) }
        { !isEmpty(contact?.address_2) && (
          <Typography component="div" variant="body3" className={clsx(classes.info, classes.address_2)}>{ contact?.address_2 }</Typography>
        ) }
        { !isEmpty(contact?.address_3) && (
          <Typography component="div" variant="body3" className={clsx(classes.info, classes.address_3)}>{ contact?.address_3 }</Typography>
        ) }
        <Typography component="div" variant="body3" className={clsx(classes.info, classes.address_city)}>{ contact?.address_cp } { contact?.address_city }, { contact?.address_country }</Typography>
        { !isEmpty(contact?.tel) && (
          <Typography component="div" variant="body3" className={clsx(classes.info, classes.tel)}>Tél. <a href={"tel:" + contact?.tel}>{contact?.tel}</a></Typography>
        ) }
      </>
    );
  }
}

const styles = theme => ({
  root: {},
  title: {
    fontWeight: 600,
  },
  detail: {
    display: 'flex',
  },
  detail_icon: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  detail_content: {
    borderBottom: `1px solid ${theme.palette.tertiary.main}`,
    width: '100%',
    padding: theme.spacing(0, 0, 1, 0),
    margin: theme.spacing(0, 0, 1, 0),
  },
  detail_text: {},
  detail_content_recurrent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: 1
  },
});

OrderInfos.propTypes = {
  order: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default withHOCComponent(withStyles(styles, { withTheme: true })(OrderInfos));
