import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import EditOrderItem from './../edit/EditOrderItem';
import DialogBase from './DialogBase';
import { DialogContent, DialogTitle } from '@mui/material';

class DialogEditOrderItem extends React.Component {

  render () {
    const { classes, open, onClose, ...others } = this.props;
    const scrollableTargetId = "dialog-edit-order-item";
    return (
      <DialogBase
        open={open}
        onClose={onClose}
        maxWidth="xs"
      >
        <DialogTitle>Élément de commande</DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}} id={scrollableTargetId}>
          <EditOrderItem
            scrollableTarget={scrollableTargetId}
            hideTitle={true}
            {...others}
          />
        </DialogContent>
      </DialogBase>
    )
  }
}

const styles = theme => ({
  dialogContent: {
    padding: theme.spacing(0),
  },
});

DialogEditOrderItem.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles, { withTheme: true })(DialogEditOrderItem);
