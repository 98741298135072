import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import { CloseIcon } from './../commons/Icons';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { DialogTitle } from '@mui/material';
import clsx from 'clsx';

const dialogTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * IMPORTANT : ce composant est obsolète, vous devez utiliser DialogBase
 * @deprecated
 */
class DialogComponent extends React.Component {

  render () {
    const { classes, open, onClose, fullScreen, fullWidth, keepMounted, id, title, children, maxWidth, disableGutters, className, classNameContainer } = this.props;
    let diag_id = ""+id;
    if (diag_id === "") {
      diag_id = "dialog";
    }
    return (
      <Dialog
        keepMounted={keepMounted}
        PaperProps={ {id: diag_id} }
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={onClose}
        TransitionComponent={dialogTransition}
        classes={className}
      >
        { fullScreen && (
          <AppBar color="inherit" position="fixed" className={classes.appBar}>
            <Toolbar>
              <Container disableGutters={true} maxWidth="md" className={classes.contentainer}>
                <Box className={classes.left}>
                </Box>
                <Box className={classes.middle}>
                  <Typography variant="h3" component="h1" noWrap className={classes.title}>
                    {title}
                  </Typography>
                </Box>
                <Box className={classes.right}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    size="large">
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Container>
            </Toolbar>
          </AppBar>
        ) }
        <Container maxWidth={maxWidth} className={clsx(fullScreen ? classes.containerFS :classes.container, classNameContainer)} disableGutters={disableGutters}>
          { !fullScreen && title &&  (
            <DialogTitle id="form-dialog-title">{ title }</DialogTitle>
          ) }
          { children }
        </Container>
      </Dialog>
    );
  }
}

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
  },
  contentainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  left: {
    minWidth: 80,
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: theme.spacing(-1),
  },
  middle: {
    maxWidth: 'calc(100% - '+160+')',
    overflow: 'hidden',
  },
  right: {
    minWidth: 80,
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(-1),
  },
  title: {
    textAlign: 'center',
    margin: 0,
  },
  title_box: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.bg.light}`,
  },
  container: {
    padding: 0,
  },
  containerFS: {
    padding: 0,
    marginTop: theme.spacing(6.4),
    marginBottom: theme.spacing(6.4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5.5),
      marginBottom: theme.spacing(5.5),
    },
  }
});

DialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  disableGutters: PropTypes.bool,
  keepMounted: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

DialogComponent.defaultProps = {
  fullScreen: true,
  keepMounted: false,
  disableGutters: false,
  maxWidth: "sm",
};

export default withStyles(styles, { withTheme: true })(DialogComponent);