import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async subscribe (data) {
    const response = await API.post('/push/subscribe', data)
    return response.data;
  },

  async unsubscribe (data) {
    const response = await API.post('/push/unsubscribe', data);
    return response.data;
  },

  async getKey () {
    const response = await API.get('/push/key');
    return response.data;
  },

}

export default exportedObject;
