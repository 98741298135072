import React from 'react';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { AlertIcon, AlertPlainIcon, CheckIcon, InfoIcon } from './Icons';
import Box from '@mui/material/Box';
import IconRound from './IconRound';
import { Paper } from '@mui/material';

class Message extends React.Component {

  render() {
    const { classes, children } = this.props;
    const classNameRoot = this.props.className && this.props.className.root ? this.props.className.root : this.props.className;
    const classNameContent = this.props.className && this.props.className.content ? this.props.className.content : null;
    const classNameIcon = this.props.className && this.props.className.icon ? this.props.className.icon : null;
    let iconcolor = 'info.contrastText';
    let iconbgcolor = 'info.main';
    let icon = <InfoIcon />;

    if (this.props.level === 'success') {
      iconcolor = 'success.contrastText';
      iconbgcolor = 'success.main';
      icon = <CheckIcon />;
    } else if (this.props.level === 'warning') {
      iconcolor = 'warning.contrastText';
      iconbgcolor = 'warning.main';
      icon = <AlertIcon />;
    } else if (this.props.level === 'error') {
      iconcolor = 'error.contrastText';
      iconbgcolor = 'error.main';
      icon = <AlertPlainIcon />;
    }
    const paperprops = {...{square: true, elevation: 0}, ...this.props.paper};
    return (
      <Paper className={ clsx(classes.root, classNameRoot) } {...paperprops}>
        <Box className={ clsx(classes.content, classNameContent) }>
          <IconRound bgcolor={iconbgcolor} color={iconcolor} className={ clsx(classes.icon, classNameIcon) }>{ this.props.icon || icon }</IconRound>
          { children }
        </Box>
        { this.props.right }
      </Paper>
    )
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: '#ffffff',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
});

export default withStyles(styles, { withTheme: true })(Message);
