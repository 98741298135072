import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormControl from '@mui/material/FormControl';
import FormField from './FormField';
import TextFieldLimited from './TextFieldLimited';

class SetProfileDescription extends React.Component {

  render () {
    const { data, onChange } = this.props;

    return (
      <FormField>
        <FormControl>
          <TextFieldLimited
            id="description"
            label="Description"
            type="text"
            name="description"
            value={data.description || ''}
            onChange={ (value) => onChange({...data, ...{description: value}}) }
            multiline
            rows={4}
            limit={400}
            helperText="Décrivez succintement votre activité. Cette description sera visible sur votre profil et aidera à vous référencer."
          />
        </FormControl>
      </FormField>
    )
  }
}

const styles = theme => ({});

SetProfileDescription.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
};

SetProfileDescription.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetProfileDescription);
