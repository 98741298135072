import PaymentMethods_API from './api/payment-methods';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux moyens de paiement
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
  STATE_ACTIVE: 'active', // le moyen de paiement est actif
 	STATE_ERROR: 'error', // le moyen de paiement est en erreur

  /**************** subscriptions ************************************/
  /**
   * Récupère les moyens de paiement du serveur
   * @return Promise(data, notices) : les moyens de paiement
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      PaymentMethods_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Moyens de paiement récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un moyen de paiement du serveur
   * @return Promise(data, notices) : le moyen de paiement
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      PaymentMethods_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Moyen de paiement récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un moyen de paiement
   * @return Promise(id, data, notices) : l'identifiant du moyen de paiement supprimé
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      PaymentMethods_API.delete(id).then(({data, notices}) => {
        utilsService.debugLog('Moyen de paiement supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  /***************************** Extras ************************/

  /**
   * Affiche le libellé du statut
   * @return string
   */
  getStateLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.STATE_ACTIVE: {
        return 'actif';
      }
      case this.STATE_ERROR: {
        return 'en erreur';
      }
      default: {
        return slug;
      }
    }
  },

};
export default exportedObject;
