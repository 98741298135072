import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import { CloseIcon } from './../commons/Icons';

import { Box, Typography } from '@mui/material';

/**
 * Composant du Sélecteur Asynchrone
 */
class SelectAsyncChip extends React.Component {

  render () {
    const { classes, icon, iconDelete, label, onClick, onDelete } = this.props;
    return (
      <Box cli className={clsx(classes.chip, {[classes.chipCliquable]: onClick})}>
        {icon && (<Box className={classes.chipIcon} onClick={onClick}>{icon}</Box>)}
        {label && (<Typography variant="body2" component="div" className={classes.chipLabel} onClick={onClick}>{label}</Typography>)}
        {onDelete && (<Box onClick={onDelete} className={classes.chipDeleteIcon}>{iconDelete || <CloseIcon />}</Box>)}
      </Box>
    );
  }
}

const styles = theme => ({
  chip: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  chipCliquable: {
    cursor: 'pointer',
  },
  chipIcon: {
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  chipLabel: {
    /*
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    */
    width: '100%',
  },
  chipDeleteIcon: {
    marginLeft: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

SelectAsyncChip.propTypes = {
  icon: PropTypes.element,
  iconDelete: PropTypes.element,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(SelectAsyncChip);
