import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from './../../services/profiles';

import ProfilesListSearch from './ProfilesListSearch';
import ProfilesListItem from './ProfilesListItem';
import List from './../commons/List';

class ProfilesList extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      profiles: [],
      loading: false,
      reload: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = JSON.stringify(this.props.query) !== JSON.stringify(prevProps.query);
    if (reload) {
      this._isMounted && this.setState({ reload: !this.state.reload });
    }
  }

  load (limit, offset, searchQuery) {
    return profilesService.getAll({...this.props.query, ...searchQuery, ...{limit, offset}}).then(([items]) => items);;
  }

  onProfileSaved (savedProfile, is_new) {
    if (is_new) {
      this._isMounted && this.setState({profiles: [...[savedProfile], ...this.state.profiles]});
    } else {
      this._isMounted && this.setState(prevState => ({
        profiles: prevState.profiles.map((profile) => {
          if (profile.ID === savedProfile.ID) {
            return savedProfile;
          }
          return profile;
        })
      }));
    }
  }

  render() {
    const { onItemsLoaded } = this.props;
    const { profiles, loading, reload } = this.state;

    return (
      <List
        title={this.props.title}
        reload={reload}
        load={(limit, offset, searchQuery) => this.load(limit, offset, searchQuery)}
        loaded={(items) => {
          this._isMounted && this.setState({profiles: items});
          onItemsLoaded && onItemsLoaded(items);
        }}
        searchEnabled={this.props.searchEnabled}
        renderSearch={(onInitialize, searchQuery, onQueryChange) => (
          <ProfilesListSearch
            labelSearchField={this.props.searchPlaceholder}
            query={searchQuery}
            onQueryChange={onQueryChange}
            onInitialize={onInitialize}
            searching={loading}
            onError={this.props.onError}
            expanded={this.props.searchExpanded}
          />
        )}
        defaultSearchQuery={this.props.defaultSearchQuery}
        listOnlyOnSearch={this.props.listOnlyOnSearch}
        onSelect={this.props.onSelect}
        selectedItemsKeys={this.props.selectedItemsKeys}
        onError={this.props.onError}
        onSuccess={this.props.onSuccess}
        infiniteScrollProps={ {
          limit: this.props.limit || 25,
          scrollableTarget: this.props.scrollableTarget,
          items: profiles,
          onLoading: (loading) => this._isMounted && this.setState({loading}),
          noResult: this.props.noResult,
        } }
        renderItem={ (profile, selectable) => (
          <ProfilesListItem
            profile={profile}
            onSaved={ (profile, is_new) => this.onProfileSaved(profile, is_new) }
            onError={this.props.onError}
            onSuccess={this.props.onSuccess}
            right={this.props.listItemRight}
            disableExpand={selectable}
          />
        ) }
      />
    )
  }
}

const styles = theme => ({});

ProfilesList.propTypes = {
  limit: PropTypes.number,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  query: PropTypes.object,
  noResult: PropTypes.element,
  displayContact:PropTypes.bool,
  scrollableTarget: PropTypes.string,
  selectedItemsKeys: PropTypes.array,

  searchPlaceholder: PropTypes.string,
  searchEnabled: PropTypes.bool,
  searchExpanded: PropTypes.bool,
  listOnlyOnSearch: PropTypes.bool,
  defaultSearchQuery: PropTypes.object,

  listItemRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),

  onItemsLoaded: PropTypes.func,
};

ProfilesList.defaultProps = {
  searchEnabled: true,
};

export default withStyles(styles, { withTheme: true })(ProfilesList);
