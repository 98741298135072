/**
 * Composant affichant un scroll infini (en tant que controller uniquement)
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import { MoreVertIcon } from './../commons/Icons';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

class IconMenu extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  open (target) {
    this._isMounted && this.setState({
      open: true,
      anchorEl: target
    });
  }

  close () {
    this._isMounted && this.setState({
      open: false,
      anchorEl: null,
    });
  }

  render () {
    const { icon, keepMounted, iconColor, iconVariant, iconEdge, iconAriaLabel, iconSize } = this.props;
    const render = this.props.render && this.props.render();
    if (!render || render.length < 1) {
      return null;
    }
    return (
      <React.Fragment>
        <IconButton
          aria-haspopup="true"
          aria-label={iconAriaLabel}
          aria-controls="icon-menu"
          onClick={(e) => {
            e.stopPropagation();
            this.open(e.currentTarget);
          }}
          variant={iconVariant}
          color={iconColor}
          edge={iconEdge}
          size={iconSize}>
          { icon || ( <MoreVertIcon /> ) }
        </IconButton>
        <Menu
          keepMounted={keepMounted}
          id="icon-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={(e) => {
            e.stopPropagation();
            this.close();
          }}
          onClick={(e) => {
            // permet de fermer le Menu lorsque l'utilisateur click sur un des items du menu
            // il est important que l'enfant (MenuIem) ne stoppe pas la propagation l'évènement pour que Menu le reçoive
            this.close();
          }}
        >
          { render }
        </Menu>
      </React.Fragment>
    );
  }
}

const styles = theme => ({});

IconMenu.propTypes = {
  icon: PropTypes.element,
  keepMounted: PropTypes.bool,
  iconColor: PropTypes.string,
  iconVariant: PropTypes.string,
  iconEdge: PropTypes.string,
  iconSize: PropTypes.string,
};

IconMenu.defaultProps = {
  iconColor: "primary",
  iconVariant: "contained",
  iconSize: "large",
};

export default withStyles(styles, { withTheme: true })(IconMenu);
