import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import usersService from './../../services/users';

import UsersListSearch from './UsersListSearch';
import UsersListItem from './UsersListItem';
import List from './../commons/List';

class UsersList extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      users: [],
      loading: false,
      reload: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    const {onSelect, displayMenu} = this.props;
    if (onSelect && (displayMenu === true)) {
      console.warn("Attention, le composant UsersList se comporte très mal lorsque que vous activez la sélection et les actions sur les items en même temps.");
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = JSON.stringify(this.props.query) !== JSON.stringify(prevProps.query);
    if (reload) {
      this._isMounted && this.setState({ reload: !this.state.reload });
    }
  }

  load (limit, offset, searchQuery) {
    return usersService.getAll({...this.props.query, ...searchQuery, ...{limit, offset}}).then(([items]) => items);;
  }

  onUserUpdated (updatedUser) {
    this._isMounted && this.setState(prevState => ({
      users: prevState.users.map((user) => {
        if (user.ID === updatedUser.ID) {
          return updatedUser;
        }
        return user;
      })
    }))
  }

  render() {
    const { users, loading, reload } = this.state;

    return (

      <List
        title={this.props.title}
        reload={reload}
        load={(limit, offset, searchQuery) => this.load(limit, offset, searchQuery)}
        loaded={(items) => this._isMounted && this.setState({users: items})}
        searchEnabled={this.props.searchEnabled}
        renderSearch={(onInitialize, searchQuery, onQueryChange) => (
          <UsersListSearch
            query={searchQuery}
            onQueryChange={onQueryChange}
            onInitialize={onInitialize}
            searching={loading}
            onError={this.props.onError}
            expanded={this.props.searchExpanded}
          />
        )}
        defaultSearchQuery={this.props.defaultSearchQuery}
        listOnlyOnSearch={this.props.listOnlyOnSearch}
        onSelect={this.props.onSelect}
        selectedItemsKeys={this.props.selectedItemsKeys}
        onError={this.props.onError}
        onSuccess={this.props.onSuccess}
        infiniteScrollProps={ {
          limit: this.props.limit || 25,
          scrollableTarget: this.props.scrollableTarget,
          items: users,
          onLoading: (loading) => this._isMounted && this.setState({loading}),
          noResult: this.props.noResult,
        } }
        renderItem={ (user, selectable, selected) => (
          <UsersListItem
            user={user}
            onUpdated={ (user) => this.onUserUpdated(user) }
            onError={this.props.onError}
            onSuccess={this.props.onSuccess}
            right={this.props.listItemRight}
            disableExpand={selectable}
          />
        ) }
      />
    )
  }
}

const styles = theme => ({});

UsersList.propTypes = {
  limit: PropTypes.number,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  query: PropTypes.object,
  noResult: PropTypes.element,
  scrollableTarget: PropTypes.string,
  selectedItemsKeys: PropTypes.array,

  searchEnabled: PropTypes.bool,
  searchExpanded: PropTypes.bool,
  listOnlyOnSearch: PropTypes.bool,
  defaultSearchQuery: PropTypes.object,

  listItemRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
};

UsersList.defaultProps = {
  searchEnabled: true,
};

export default withStyles(styles, { withTheme: true })(UsersList);
