import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  /**************** profiles ************************************/
  get (id, params) {
    return API.get('/profiles/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAll (params) {
    return API.get('/profiles/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  count (params) {
    return API.get('/profiles/count/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  create (data) {
    return API.post('/profiles/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  update (id, data) {
    return API.post('/profiles/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  requestDeletion (id) {
    return API.post('/profiles/' + id + '/request-deletion/')
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  delete (id) {
    return API.delete('/profiles/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  /**************** options ***********************************/
  getOptions (id, params) {
    return API.get('/profiles/' + id + '/options/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  /**************** contacts ***********************************/
  getContacts (id, params) {
    return API.get('/profiles/' + id + '/contacts/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  /**************** products categories **********************/
  getProductCategory(id_profile, id) {
    return API.get('/profiles/' + id_profile + '/product-categories/'  +id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAllProductCategories(id_profile, params) {
    return API.get('/profiles/' + id_profile + '/product-categories', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  createProductCategory(id_profile, data) {
    return API.post('/profiles/' + id_profile + '/product-categories/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  updateProductCategory (id_profile, id, data) {
    return API.post('/profiles/' + id_profile + '/product-categories/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  deleteProductCategory (id_profile, id) {
    return API.delete('/profiles/' + id_profile + '/product-categories/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  /**************** products tags **********************/
  getProductTag(id_profile, id) {
    return API.get('/profiles/' + id_profile + '/product-tags/'  +id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAllProductTags(id_profile, params) {
    return API.get('/profiles/' + id_profile + '/product-tags', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  createProductTag(id_profile, data) {
    return API.post('/profiles/' + id_profile + '/product-tags/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  updateProductTag (id_profile, id, data) {
    return API.post('/profiles/' + id_profile + '/product-tags/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  deleteProductTag (id_profile, id) {
    return API.delete('/profiles/' + id_profile + '/product-tags/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
