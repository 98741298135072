import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import orderItemsService from './../../services/order-items';

import DialogProfileCatalog from './../dialog/DialogProfileCatalog';
import OrderItemsListItem from './OrderItemsListItem';
import List from './../commons/List';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

class OrderItemsList extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      order_items: [],
      loading: false,
      reload: false,
      openDialog_profileCatalog: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = JSON.stringify(this.props.query) !== JSON.stringify(prevProps.query);
    if (reload) {
      this._isMounted && this.setState({ reload: !this.state.reload });
    }
  }

  load (limit, offset, searchQuery) {
    return orderItemsService.getAll({...this.props.query, ...searchQuery, ...{limit, offset}, ...{id_order: this.props.id_order}}).then(([items]) => items);
  }

  onOrderItemUpdated (updatedOrderItem) {
    this._isMounted && this.setState(prevState => ({
      order_items: prevState.order_items.map((order_item) => {
        if (order_item.ID === updatedOrderItem.ID) {
          return updatedOrderItem;
        }
        return order_item;
      })
    }));
    // on prévient le parent que la liste des order_items a changé
    this.props.onUpdated && this.props.onUpdated();
  }

  onOrderItemDeleted (deletedOrderItem) {
    this._isMounted && this.setState(prevState => ({
      order_items: prevState.order_items.filter((order_item) => {
        return parseInt(order_item.ID) !== parseInt(deletedOrderItem.ID);
      })
    }));
    // on prévient le parent que la liste des order_items a changé
    this.props.onUpdated && this.props.onUpdated();
  }

  render() {
    const { classes, id_order, permissions, displayHeader, catalogDisabled } = this.props;
    const { order_items, reload } = this.state;

    const has_order_items = order_items && order_items.length > 0;
    const total_qty = orderItemsService.getTotalQty(order_items);

    if (!id_order) {
      return null;
    }

    return (

      <Box className={classes.root}>

        { displayHeader && (
          <>
            { this.renderDialogs() }
            <Box className={classes.header}>
              { has_order_items && (
                <Typography className={classes.title} component="div" variant="h5">{total_qty} { total_qty > 1 ? "produits" : "produit" }</Typography>
              ) }
              { !has_order_items && (
                <Typography className={classes.title} component="div" variant="h5">Aucun produit</Typography>
              ) }
              { !catalogDisabled && permissions && permissions.can_store && (
                <Button color="quaternary" className={classes.btn_add} onClick={ () => this._isMounted && this.setState({openDialog_profileCatalog: true}) }>Ajouter des produits</Button>
              ) }
            </Box>
          </>
        ) }

        <List
          title={this.props.title}
          reload={reload}
          load={(limit, offset, searchQuery) => this.load(limit, offset, searchQuery)}
          loaded={(items) => this._isMounted && this.setState({order_items: items})}
          searchEnabled={this.props.searchEnabled}
          renderSearch={(onInitialize, searchQuery, onQueryChange) => null}
          defaultSearchQuery={this.props.defaultSearchQuery}
          listOnlyOnSearch={this.props.listOnlyOnSearch}
          onSelect={this.props.onSelect}
          selectedItemsKeys={this.props.selectedItemsKeys}
          onError={this.props.onError}
          onSuccess={this.props.onSuccess}
          infiniteScrollProps={ {
            limit: this.props.limit || 1000,
            scrollableTarget: this.props.scrollableTarget,
            items: order_items,
            noResult: this.props.noResult,
          } }
          renderItem={ (order_item, selectable) => (
            <OrderItemsListItem
              order_item={order_item}
              onChanged={ (order_item) => this.onOrderItemUpdated(order_item) }
              onDeleted={ (order_item) => this.onOrderItemDeleted(order_item) }
              onError={this.props.onError}
              permissions={permissions}
            />
          ) }
        />

      </Box>
    )
  }

  renderDialogs () {
    const { id_order, id_profile_provider, id_profile_customer } = this.props;
    return (
      <React.Fragment>
        { this.state.openDialog_profileCatalog && (
          <DialogProfileCatalog
            id_profile_provider={id_profile_provider}
            id_profile_customer={id_profile_customer}
            id_order={id_order}
            open={this.state.openDialog_profileCatalog}
            onOrderItemsUpdated={ (id_order, order_items) => {
              this._isMounted && this.setState({
                openDialog_profileCatalog: false,
                order_items
              });
              this.props.onUpdated && this.props.onUpdated(id_order, order_items);
            } }
            onError={this.props.onError}
            onClose={ () => this._isMounted && this.setState({openDialog_profileCatalog: false}) }
          />
        ) }
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  root: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  title: {
    fontWeight: 600,
  },
});

OrderItemsList.propTypes = {
  limit: PropTypes.number,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  query: PropTypes.object,
  noResult: PropTypes.element,
  scrollableTarget: PropTypes.string,
  selectedItemsKeys: PropTypes.array,

  searchEnabled: PropTypes.bool,
  searchExpanded: PropTypes.bool,
  listOnlyOnSearch: PropTypes.bool,
  defaultSearchQuery: PropTypes.object,

  id_order: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id_profile_provider: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  id_profile_customer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,

  permissions: PropTypes.object,
  displayHeader: PropTypes.bool,
  onUpdated: PropTypes.func.isRequired,
  catalogDisabled: PropTypes.bool,
};

OrderItemsList.defaultProps = {
  searchEnabled: false,
  listOnlyOnSearch: false,
  defaultSearchQuery: {},
  displayHeader: true,
};

export default withStyles(styles, { withTheme: true })(OrderItemsList);
