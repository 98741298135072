import cloneDeep from 'lodash.clonedeep';
import OrderItems_API from './api/order-items';
import productsService from './products';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux items de commande
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
  STATE_DONE: 'done', // l'item de commande est traitée
	STATE_PENDING: 'pending', // l'item de commande est en cours

  /**************** order items ************************************/
  /**
   * Récupère les items de commandes du serveur
   * @return Promise(data, notices) : les commandes
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      OrderItems_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Items de commande récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un item de commande du serveur
   * @return Promise(data, notices) : l'item de commande
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      OrderItems_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Item de commande récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouvel item de commande
   * @return Promise(data, notices) : l'item de commande créé
   */
  create (id_order, data) {
    return new Promise((resolve, reject) => {
      OrderItems_API.create(id_order, data).then(({data, notices}) => {
        utilsService.debugLog('Item de commande créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un item de commande
   * @return Promise(data, notices) : l'item de commande mis à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      OrderItems_API.update(id, data).then(({data, notices}) => {
        utilsService.debugLog('Item de commande mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un item de commande
   * @return Promise(id, data, notices) : l'identifiant de l'item de commande supprimé
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      OrderItems_API.delete(id).then(({data, notices}) => {
        utilsService.debugLog('Item de commande supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour les items d'une commande
   * @return Promise(data, notices) : la commande mise à jour
   */
  set (id_order, order_items) {
    return new Promise((resolve, reject) => {
      OrderItems_API.set(id_order, order_items).then(({data, notices}) => {
        utilsService.debugLog('Items de commande mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** others ************************************/
  /**
   * Affiche le libellé du statut
   * @return string
   */
  getStateLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.STATE_DONE: {
        return 'traité';
      }
      case this.STATE_PENDING: {
        return 'en attente';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Renvoi le slug de la couleur du statut de l'item de commande
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * NOTE : la couleur peur être null dans certains cas.
   * @return string
   */
  getStateColor (slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.STATE_DONE: {
        return 'order_item_state_done';
      }
      default: {
        return null;
      }
    }
  },
  /**
   * Renvoi l'avatar de l'order item
   */
  getAvatar (order_item, defaultImage = null) {
    return productsService.getAvatar(order_item.product, defaultImage);
  },
  /**
   * Extrait, de la liste des order_items, l'order_item correspondant au produit
   * et le retourne.
   * NOTE : si aucun order_item n'est trouvé dans la liste, un nouvel order_item est retourné
   * NOTE 2 : l'order_item retourné, et ses propriétés, est conforme au format du produit
   * @return object
   */
  getForProduct (order_items, product) {
    if (!product) {
      return null;
    }
    let found_order_items = order_items && order_items.filter((order_item) => {
      if (Number(product.ID) === Number(order_item.id_product)) {
        return true;
      }
      return false;
    });
    let order_item = {};
    if (found_order_items && found_order_items.length > 0) {
      order_item = cloneDeep(found_order_items[0]); // Il ne doit y avoir qu'un seul order_item par produit
    }
    // mise en conformité de l'order_item par rapport aux caractéristiques du produit
    // - dans le cas d'un nouvel order_item, on le peuple des caractéristiques du produit
    // - dans le cas d'un order_item existant, on met à jour les caractéristiques du produit (elles peuvent avoir changé
    // depuis l'enregistrement de l'order_item), le serveur en fera de même de toute façon. De cette façon notre UI est cohérente pour le client de la commande
    order_item.id_product = order_item.id_product || product.ID;
    order_item.product_format = product.format ? product.format : productsService.META_FORMAT_UNIT;
    order_item.product_format_label = product.format_label;
    let default_qty = 0;
    if (order_item.product_format === productsService.META_FORMAT_BULK) {
      default_qty = 1;
      order_item.volume = order_item.volume || 0;
      order_item.product_volume_unit = product.volume_unit ? product.volume_unit : null;
    }
    order_item.qty = order_item.qty || default_qty;
    return order_item;
  },
  /**
   * Met à jour l'order_item dans la liste des order_items
   * @return la liste des order_items à jour
   */
  getUpdatedOrderItems (order_items, updated_order_item) {
    if (!Array.isArray(order_items)) {
      order_items = [];
    }
    let updated = false;
    order_items = order_items.map((order_item) => {
      if (Number(updated_order_item.id_product) === Number(order_item.id_product)) {
        updated = true;
        return updated_order_item;
      }
      return order_item;
    });
    if (!updated) {
      order_items.push(updated_order_item);
    }
    // on retire les order_items ayant une quantité vide
    order_items = order_items.filter((order_item) => {
      if (order_item.product_format === productsService.META_FORMAT_BULK) {
        return parseInt(order_item.qty) > 0 && parseFloat(order_item.volume) > 0;
      }
      return parseInt(order_item.qty) > 0;
    });
    return order_items;
  },
  /**
   * Renvoie la quantité totale des order items
   */
  getTotalQty (order_items) {
    let qty = 0;
    if (order_items && order_items.length > 0) {
      order_items.map((order_item) => {
        qty += parseInt(order_item.qty);
        return order_item;
      });
    }
    return qty;
  },
  /**
   * Renvoie le prix HT total des order items
   */
  getTotalPriceHT (order_items, pretty = false) {
    let price = 0;
    if (order_items && order_items.length > 0) {
      order_items.map((order_item) => {
        price += parseFloat(order_item.price);
        return order_item;
      });
    }
    if (pretty) {
      return utilsService.prettyPrice(price, '€', 'HT');
    }
    return price;
  },
  /**
   * Renvoie le prix TTC total des order items
   */
  getTotalPriceTTC (order_items, pretty = false) {
    let price = 0;
    if (order_items && order_items.length > 0) {
      order_items.map((order_item) => {
        price += parseFloat(order_item.price_ttc);
        return order_item;
      });
    }
    if (pretty) {
      return utilsService.prettyPrice(price, '€', 'TTC');
    }
    return price;
  }
};
export default exportedObject;
