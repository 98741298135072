import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import productsService from '../../services/products';
// import ordersService from './../../services/orders';
import utilsService from '../../services/utils';
import orderItemsService from '../../services/order-items';
import imagePlaceholder from './../../static/img/placeholder.png';

import BackdropLoading from './../commons/BackdropLoading';
import EditControllers from './../commons/EditControllers';
import SetOrderItemQty from './../commons/SetOrderItemQty';
import ConfirmDialog from './../commons/ConfirmDialog';
import Form from './../commons/Form';
import FormFields from './../commons/FormFields';
import FormField from './../commons/FormField';
import TextFieldLimited from './../commons/TextFieldLimited';
import { SaveIcon, TrashIcon } from './../commons/Icons';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

class EditOrderItem extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      openConfirm_delete: false,
      batch_number: '',
      expiration_date: '',
      volume_net: '',
      note_int: '',
      qty: null,
      volume: null,
      state_done: false,
      windowWidth: window.innerWidth,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    window.addEventListener('resize', this.updateWindowWidth);
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth = () => {
    this._isMounted && this.setState({ windowWidth: window.innerWidth });
  }

  load () {
    const { order_item } = this.props;
    this._isMounted && this.setState({
      batch_number: order_item.batch_number || '',
      expiration_date: order_item.expiration_date || '',
      volume_net: order_item.volume_net || '',
      note_int: order_item.note_int || '',
      qty: order_item.qty || '',
      volume: order_item.volume || '',
      state_done: order_item.state && order_item.state === orderItemsService.STATE_DONE ? true : false,
    });
  }

  save (e) {
    e.preventDefault();
    this.saveConfirmed();
    return false;
  }

  saveConfirmed () {
    const { order_item } = this.props;
    this._isMounted && this.setState({loading: true});
    const data = {...order_item, ...{
      batch_number: this.state.batch_number,
      expiration_date: this.state.expiration_date,
      volume_net: this.state.volume_net !== '' ? this.state.volume_net : null,
      note_int: this.state.note_int,
      qty: this.state.qty !== '' ? this.state.qty : null,
      volume: this.state.volume !== '' ? this.state.volume : null,
      state: this.state.state_done ? orderItemsService.STATE_DONE : orderItemsService.STATE_PENDING,
    }};
    if (!order_item.ID) {
      // create
      orderItemsService.create(order_item.id_order, data).then(([id, notices]) => {
        notices && notices.length > 0 && this.props.onError(notices);
        // récupération de l'item créé
        return orderItemsService.get(id, this.props.get_additionnal_fields ? {additionnal_fields: this.props.get_additionnal_fields} : {});
      }).then(([order_item]) => {
        this.props.onChanged(order_item, true);
      }).catch((error) => {
        console.error('error : ', error);
        this.props.onError(error);
      }).finally(() => {
        this._isMounted && this.setState({loading: false});
      });
   } else {
      // update
      orderItemsService.update(order_item.ID, data).then(([id, notices]) => {
        notices && notices.length > 0 && this.props.onError(notices);
        // récupération de l'item à jour
        return orderItemsService.get(id, this.props.get_additionnal_fields ? {additionnal_fields: this.props.get_additionnal_fields} : {});
      }).then(([order_item]) => {
        this.props.onChanged(order_item, false);
      }).catch((error) => {
        console.error('error : ', error);
        this.props.onError(error);
      }).finally(() => {
        this._isMounted && this.setState({loading: false});
      });
    }
  }

  delete (e) {
    e.preventDefault();
    this._isMounted && this.setState({openConfirm_delete: true});
    return false;
  }

  deleteConfirmed () {
    const { order_item } = this.props;
    this._isMounted && this.setState({loading: true, openConfirm_delete: false});
    orderItemsService.delete(order_item.ID).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      this.props.onDeleted(order_item);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false});
    });
  }

  render () {
    const { classes, order_item, permissions } = this.props;
    const { windowWidth } = this.state;
    const is_bulk = order_item.product_format === productsService.META_FORMAT_BULK;
    const is_portion = order_item.product_format === productsService.META_FORMAT_PORTION;
    const avatar = orderItemsService.getAvatar(order_item, imagePlaceholder);
    const smallWidth = windowWidth < 420;
    return (
      <Box className={classes.container}>
        <BackdropLoading open={this.state.loading} />

        { this.state.openConfirm_delete && (
          <ConfirmDialog
            open={this.state.openConfirm_delete}
            title="Supprimer cet élément de commande"
            message="Cette action est irréversible, confirmez-vous ?"
            onConfirm={ () => this.deleteConfirmed() }
            onClose={ () => this._isMounted && this.setState({ openConfirm_delete: false }) }
            cancelLabel="Annuler"
            confirmLabel={ "Supprimer" }
          />
        ) }

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields className={classes.formFields}>

            <fieldset className={classes.fieldset}>
              <legend className={classes.fieldset_legend}>Quantité commandée</legend>
              <Box className={ clsx(classes.item, {[classes.item_sm]: smallWidth}) }>
                <Box className={classes.item_left}>
                  <Box className={classes.item_avatar}>
                    <Avatar {...{...avatar.props}}>{ avatar.content !== true && avatar.content }</Avatar>
                  </Box>
                  <Box className={classes.item_name}>
                    {utilsService.showDebugID(order_item)}{ order_item.product_name }
                  </Box>
                </Box>
                <Box className={ clsx(classes.item_right, {[classes.item_right_sm]: smallWidth}) }>
                  <SetOrderItemQty
                    order_item={ {...order_item, ...{volume: this.state.volume, qty: this.state.qty}} }
                    onChange={(order_item) => this._isMounted && this.setState({volume: order_item.volume, qty: order_item.qty})}
                  />
                </Box>
              </Box>
            </fieldset>

            { permissions && permissions.is_auth_provider && (

              <>

                { (is_bulk || is_portion) && (

                  <FormField>
                    <FormControl className={classes.form_control}>
                      <TextField
                        required
                        id="volume_net"
                        label="Volume net"
                        type="number"
                        className={classes.input}
                        value={this.state.volume_net}
                        onChange={ (e) => this._isMounted && this.setState({volume_net: e.target.value}) }
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{order_item.product_volume_unit}</InputAdornment>,
                        }}
                      />
                    </FormControl>
                  </FormField>

                ) }

                <FormField>
                  <FormControl className={classes.form_control}>
                    <TextFieldLimited
                      id="batch_number"
                      label="Numéro de lot"
                      type="text"
                      className={classes.input}
                      value={this.state.batch_number}
                      onChange={ (batch_number) => this._isMounted && this.setState({ batch_number }) }
                      limit={50}
                    />
                  </FormControl>
                </FormField>

                <FormField>
                  <FormControl className={classes.form_control}>
                    <TextFieldLimited
                      id="expiration_date"
                      label="DLC"
                      type="text"
                      className={classes.input}
                      value={this.state.expiration_date}
                      onChange={ (expiration_date) => this._isMounted && this.setState({ expiration_date }) }
                      limit={10}
                    />
                  </FormControl>
                </FormField>

                <FormField>
                  <FormControl className={classes.form_control}>
                    <TextFieldLimited
                      id="note_int"
                      label="Note interne"
                      type="text"
                      className={classes.input}
                      value={this.state.note_int}
                      onChange={ (note_int) => this._isMounted && this.setState({ note_int }) }
                      multiline
                      rows={4}
                    />
                  </FormControl>
                </FormField>

                <FormField>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={ this.state.state_done }
                          onChange={ () => this._isMounted && this.setState({state_done: !this.state.state_done}) }
                        />
                      }
                      label="Traité"
                    />
                  </FormGroup>
                </FormField>

              </>

            ) }

            <EditControllers sticky>
              { smallWidth && (
                <IconButton aria-label="supprimer" className={classes.delete_sm} onClick={ (e) => this.delete(e) } color="primary" variant="outlined">
                  <TrashIcon />
                </IconButton>
              ) }
              { !smallWidth && (
                <Button aria-label="supprimer" className={classes.delete} onClick={ (e) => this.delete(e) } color="primary" variant="outlined" startIcon={<TrashIcon />}>
                  Supprimer
                </Button>
              ) }
              <Button aria-label="enregistrer" type="submit" className={classes.submit} onClick={ (e) => this.save(e) } color="primary" variant="contained" startIcon={<SaveIcon />}>
                Enregistrer
              </Button>
            </EditControllers>

          </FormFields>

        </Form>
      </Box>
    );
  }
}

const styles = theme => ({
  container: {},
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  submit: {
    width: '100%',
  },
  delete: {
    width: '100%',
    marginRight: theme.spacing(1),
  },
  delete_sm: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '1rem',
    fontWeight: 400,
  },
  fieldset_legend: {
    fontSize: 12,
  },
  item: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item_sm: {
    flexDirection: 'column',
  },
  item_left: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  item_right_sm: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  item_avatar: {
    marginRight: theme.spacing(1),
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
});

EditOrderItem.propTypes = {
  order_item: PropTypes.object.isRequired,
  onChanged: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  permissions: PropTypes.object,
  get_additionnal_fields: PropTypes.array, // champs additionnels à chargé depuis l'API pour l'order_item
};

export default withStyles(styles, { withTheme: true })(EditOrderItem);
