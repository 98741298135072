import React from 'react';
import PropTypes from 'prop-types';

import SetContact from './SetContact';

class SetProfileContact extends React.Component {

  render () {
    const { contact, ...others } = this.props;

    return (
      <SetContact
        value={contact || {}}
        onChange={(contact) => this.onChange(contact)}
        {...others}
      />
    );
  }
}

SetProfileContact.propTypes = {
  contact: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default SetProfileContact;
