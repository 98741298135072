import ProfileRels_API from './api/profile-rels';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux relations entre
 un profil et un autre profil
****************************************************/

const exportedObject = {

  STATE_PENDING: 'pending', // la relation est en attente de modération
	STATE_ACTIVE: 'active', // la relation est active

	TYPE_SUBSCRIBED: 'subscribed', // la relation est de type 'abonné'

  /**
   * Récupère une relation entre profils
   * @return Promise(data, notices) : la relation
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      ProfileRels_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Relation récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les relations entre profils
   * @return Promise(data, notices) : les relations
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      ProfileRels_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Relations récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Compte les relations entre profils
   * @return Promise(data, notices) : les relations
   */
  count (args) {
    return new Promise((resolve, reject) => {
      ProfileRels_API.count(args).then(({data, notices}) => {
        utilsService.debugLog('Relations comptées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Crée une relation entre profils
   * @return Promise(data, notices) : l'identifiant de la relation créée
   */
  create (data) {
    return new Promise((resolve, reject) => {
      ProfileRels_API.create(data).then(({data, notices}) => {
        utilsService.debugLog('Relation créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour une relation entre profils
   * @return Promise(id, data, notices) : l'identifiant de la relation modifiée
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      ProfileRels_API.update(id, data).then(({data, notices}) => {
        utilsService.debugLog('Relation modifiée : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime une relation entre profils
   * @return Promise(id, data, notices) : l'identifiant de la relation supprimée
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      ProfileRels_API.delete(id).then(({data, notices}) => {
        utilsService.debugLog('Relation supprimée : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** relation state ************************************/
  /**
   * Met à jour le statut d'une relation
   * @return Promise(data, notices) : la relation mise à jour
   */
  setState (id, state) {
    return new Promise((resolve, reject) => {
      ProfileRels_API.setState(id, state).then(({data, notices}) => {
        utilsService.debugLog('Statut de relation  mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** others ************************************/
  /**
   * Renvoie l'avatar de la relation
   */
  getAvatar (profileRel) {
    return { content: "REL", props: {} };
  },
};
export default exportedObject;
