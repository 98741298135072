import ProfileUserRels_API from './api/profile-user-rels';
import usersService from './users';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux relations entre
 un profil et un utilisateur
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
  ROLE_OWNER : 'owner', // propriétaire du profile
	ROLE_MANAGER : 'manager', // gestionnaire du profile

  /**
   * Récupère une relation
   * @return Promise(data, notices) : la relation
   */
  get (id) {
    return new Promise((resolve, reject) => {
      ProfileUserRels_API.get(id).then(({data, notices}) => {
        utilsService.debugLog('Relation récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les relations entre utilisateurs et profils
   * @return Promise(data, notices) : les relations
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      ProfileUserRels_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Relations récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé une relation
   * @return Promise(data, notices) : l'identifiant de la relation créée
   */
  create (data) {
    return new Promise((resolve, reject) => {
      ProfileUserRels_API.create(data).then(({data, notices}) => {
        utilsService.debugLog('Relation créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime une relation
   * @return Promise(id, data, notices) : l'identifiant de la relation supprimée
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      ProfileUserRels_API.delete(id).then(({data, notices}) => {
        utilsService.debugLog('Relation supprimée : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** others ************************************/
  /**
   * Affiche le libellé du role
   * @return string
   */
  getRoleLabel (slug) {
    if (!slug) {
      return 'aucun rôle';
    }
    switch (slug) {
      case this.ROLE_OWNER: {
        return 'propriétaire';
      }
      case this.ROLE_MANAGER: {
        return 'collaborateur';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Renvoie l'avatar de la relation
   */
  getAvatar (profileUserRel) {
    if (profileUserRel?.user) {
      return usersService.getAvatar(profileUserRel?.user);
    }
    return { content: "REL", props: {} };
  },
};
export default exportedObject;
