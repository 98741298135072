import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import authService from '../../services/auth';
import profilesService from '../../services/profiles';

import BackdropLoading from '../commons/BackdropLoading';
import EditControllers from '../commons/EditControllers';
import Forbidden from '../commons/Forbidden';
import Form from '../commons/Form';
import FormFields from '../commons/FormFields';
import SetProfilePrint from '../commons/SetProfilePrint';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

class EditProfilePrinters extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      print: {}
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load () {
    const { id_profile, onError } = this.props;
    if (id_profile) {
      this._isMounted && this.setState({loading: true});
      profilesService.getOptions(id_profile).then(([options]) => {
        this._isMounted && this.setState({
          print: options?.print && !Array.isArray(options?.print) ? options?.print : {},
        });
      }).catch((e) => {
        onError(e);
      }).finally(() => {
        this._isMounted && this.setState({loading: false})
      });
    }
  }

  save (e) {
    e.preventDefault();
    const { id_profile } = this.props;
    const data = {
      options: {
        print: this.state.print,
      },
    }
    this._isMounted && this.setState({loading: true})
    profilesService.update(id_profile, data).then(([id, notices]) => {
      notices && notices.length > 0 && this.props.onError(notices);
      // récupération de l'item à jour
      return profilesService.get(id);
    }).then(([profile]) => {
      this.props.onSaved(profile);

      // Mise à jour du profil si c'est celui qui est authentifié
      return authService.maybeRefreshToken(profile.ID);
    }).catch((error) => {
      console.error('error : ', error);
      this.props.onError(error);
    }).finally(() => {
      this._isMounted && this.setState({loading: false})
    });
    return false;
  }

  render () {
    const { classes, id_profile, appStore } = this.props;

    if (!id_profile) {
      return <Forbidden message={<Typography variant="body1">Profil manquant.</Typography>} />;
    }

    if (!appStore?.auth?.id_user) {
      return <Forbidden />;
    }

    return (
      <Box className={classes.container}>
        <BackdropLoading open={this.state.loading} />

        <Form onSubmit={ (e) => this.save(e) } noValidate>

          <FormFields className={classes.formFields}>
            
            <SetProfilePrint value={this.state.print} onChange={(print) => this.setState({print})} />

          </FormFields>

          <EditControllers sticky>
            <Button type="submit" onClick={ (e) => this.save(e) } color="primary" variant="contained" className={classes.submit}>
              Enregistrer
            </Button>
          </EditControllers>

        </Form>

      </Box>
    )
  }

}

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(1),
  },
  submit: {
    width: '100%',
  },
  formFields: {
    padding: theme.spacing(0,2),
  },
});

EditProfilePrinters.propTypes = {
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const mapStateToProps = state => ({
  appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditProfilePrinters));
