import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';

import Box from '@mui/material/Box';

class IconRound extends React.Component {

  render () {
    const { classes, children, color, bgcolor, spacing, className } = this.props;
    return (
        <Box className={clsx(classes.content, className)} sx={{
          color: color,
          backgroundColor: bgcolor,
          padding: spacing
        }}>
          { children }
        </Box>
    );
  }
}

const styles = theme => ({
  content: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

IconRound.propTypes = {
  color: PropTypes.string,
  bgcolor: PropTypes.string,
  spacing: PropTypes.number,
};

IconRound.defaultProps = {
  color: "primary.contrastText",
  bgcolor: "primary.main",
  spacing: 0.5,
};

export default withStyles(styles, { withTheme: true })(IconRound);
