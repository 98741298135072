import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import subscriptionPlansService from './../../services/subscription-plans';
import utilsService from './../../services/utils';

import SubscriptionPlan from './../subscriptionPlan/SubscriptionPlan';
import { CheckIcon, RenewIcon, SwapIcon } from './../commons/Icons';
import ToggleRadio from './../commons/ToggleRadio';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

/**
 * Sélecteur de plan d'abonnement
 * Ce composant est asynchrone, il charge les valeurs à sélectionner depuis une API
 * Pour savoir quand le composant est initialisé, il faut écouter props.onInitialized
 */
class SelectSubscriptionPlan extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this._isInitialized = false;
    this.state = {
      loading: false,
      error: false,
      plans: [],
      showResume: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount () {
    this._isMounted = false;
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.id_profile) !== JSON.stringify(prevProps.id_profile)){
      this.load()
    }
  }

  load () {
    const { query } = this.props;
    this._isMounted && this.setState({ loading: true, error: false });
    subscriptionPlansService.getAll(query).then(([plans]) => {
      this._isMounted && this.setState({ plans });
      if (plans && plans.length === 1) {
        // on sélectionne le seul plan par défaut
        // this.props.onChange && this.props.onChange(plans[0]?.ID);
      }
    }).catch((e) => {
      this.props.onError(e);
      this._isMounted && this.setState({ error: true });
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  getPlan (value) {
    const id_plan = value ? (utilsService.isObject(value) ? value.ID : value) : null;
    let plan = null;
    const { plans } = this.state;
    if (plans && plans.length > 0) {
      plans.forEach(item => {
        if (parseInt(item.ID) === parseInt(id_plan)) {
          plan = item;
        }
      });
    }
    return plan;
  }

  getValue () {
    const { value } = this.props;
    return value ? (utilsService.isObject(value) ? value.ID : value) : null;
  }

  render () {
    const { classes, className } = this.props;
    return (
      <Box className={clsx(classes.root, className && className.root)}>
        { this.renderContent() }
        <Box className={classes.info}>
          <Typography className={classes.info_title} component="div" variant="body2">Avantages inclus dans tous les abonnements Bridger Premium :</Typography>
          <Typography className={classes.info_item} component="div" variant="body2"><CheckIcon color="secondary" fontSize="small" /><span>Créer et gérer un catalogue de produits.</span></Typography>
          <Typography className={classes.info_item} component="div" variant="body2"><CheckIcon color="secondary" fontSize="small" /><span>Recevoir des commandes* et gérer votre production.</span></Typography>
          <Typography className={classes.info_item} component="div" variant="body2"><CheckIcon color="secondary" fontSize="small" /><span>Inviter de nouveaux utilisateurs.</span></Typography>
          <Typography className={classes.info_item_sup} component="div" variant="body3">* dans les limites prévues par le plan souscrit, consultez nos <a className={classes.legal_lnk} href={process.env.REACT_APP_SITE_URL_CGV} target="_blank" rel="noreferrer">Conditions générales de vente</a> pour en savoir plus.</Typography>
        </Box>
      </Box>
    );
  }

  renderContent() {
    const { classes } = this.props;
    const { loading, error } = this.state;

    if (loading) {
      return (
        <Box className={classes.loading}>
          <CircularProgress color="secondary" />
        </Box>
      );
    } else if (error) {
      return (
        <Box className={classes.error}>
          <h4>Erreur de chargement</h4>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    }

    return (
      <>
        {this.renderContentResume()}
        {this.renderContentOptions()}
      </>
    );
  }

  renderContentOptions() {
    const { classes } = this.props;
    const { plans, showResume } = this.state;
    const options = plans && plans.length > 0 ? plans.map((plan) => ({
      label: {
        el: <SubscriptionPlan plan={plan} />
      },
      value: plan.ID
    })) : [];
    return (
      <Box sx={{display: !showResume ? 'block' : 'none'}}>
        <ToggleRadio
          value={this.getValue()}
          onChange={(e) => {
            this.props.onChange && this.props.onChange({id_plan: e.target.value, plan: this.getPlan(e.target.value)});
            this._isMounted && this.setState({showResume: true});
          }}
          options={options}
          className={{radioFormControl: classes.radioFormControl, radioFormControlLabel: classes.radioFormControlLabel}}
        />
      </Box>
    );
  }

  renderContentResume() {
    const { classes, value } = this.props;
    const { showResume } = this.state;
    const selectedPlan = this.getPlan(value);
    if (selectedPlan && showResume) {
      const options = [{
        label: {
          el: (
            <Box className={classes.resume}>
              <SubscriptionPlan plan={selectedPlan} />
              <IconButton size="small" className={classes.resumeSwap} color="secondary" onClick={() => this._isMounted && this.setState({showResume: false})}>
                <SwapIcon />
              </IconButton>
            </Box>
          )
        },
        value: selectedPlan.ID
      }];
      return (
        <ToggleRadio
          value={this.getValue()}
          options={options}
          className={{radioFormControl: classes.radioFormControl, radioFormControlLabel: classes.radioFormControlLabel}}
        />
      );
    }
    return null;
  }
}

const styles = theme => ({
  loading: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  error: {
    flexGrow: 1,
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  info: {
    marginBottom: theme.spacing(2),
  },
  info_title: {
  },
  info_item: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  radioFormControl: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.tertiary.main}`,
    borderRadius: '3px',
    margin: theme.spacing(0, 0, 2, 0),
  },
  radioFormControlLabel: {
    margin: 0,
    padding: theme.spacing(2),
  },
  resume: {
    position: 'relative',
  },
  resumeSwap: {
    position: 'absolute',
    top: '-15px',
    right: '-15px',
    /*backgroundColor: theme.palette.bg.light,
    borderRadius: '100%',*/
  }
});

SelectSubscriptionPlan.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  query: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(SelectSubscriptionPlan);
