import GroupItems_API from './api/group-items';
import utilsService from './utils';

/****************************************************
 Interface avec l'API relative aux items de groupe
****************************************************/

const exportedObject = {

  /**************** group items ************************************/
  /**
   * Récupère les items de groupes du serveur
   * @return Promise(data, notices) : les groupes
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      GroupItems_API.getAll(args).then(({data, notices}) => {
        utilsService.debugLog('Items de groupe récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un item de groupe du serveur
   * @return Promise(data, notices) : l'item de groupe
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      GroupItems_API.get(id, args).then(({data, notices}) => {
        utilsService.debugLog('Item de groupe récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouvel item de groupe
   * @return Promise(data, notices) : l'item de groupe créé
   */
  create (id_group, data) {
    return new Promise((resolve, reject) => {
      GroupItems_API.create(id_group, data).then(({data, notices}) => {
        utilsService.debugLog('Item de groupe créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un item de groupe
   * @return Promise(id, data, notices) : l'identifiant de l'item de groupe supprimé
   */
  delete (id_group, id) {
    return new Promise((resolve, reject) => {
      GroupItems_API.delete(id_group, id).then(({data, notices}) => {
        utilsService.debugLog('Item de groupe supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour les items d'une groupe
   * @return Promise(data, notices) : la groupe mise à jour
   */
  set (id_group, group_items) {
    return new Promise((resolve, reject) => {
      GroupItems_API.set(id_group, group_items).then(({data, notices}) => {
        utilsService.debugLog('Items de groupe mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
};
export default exportedObject;
