import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import deliveryModesService from '../../services/delivery-modes';

import SetDaysTimes from './SetDaysTimes';
import { isEmpty } from '../../services/utils';
import { FormControlLabel, Box, FormGroup, Switch } from '@mui/material';

class SetDeliveryModePrice extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      times_enabled: false,
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data);
    if (reload) {
      this.load();
    }
  }

  load () {
    const { data } = this.props;
    if (isEmpty(data.times)) {
      this._isMounted && this.setState({ times_enabled: false });
    } else {
      this._isMounted && this.setState({ times_enabled: true });
    }
  }

  render () {
    const { data, onChange } = this.props;
    let label = "Définir des horaires de livraison";
    if (data.type === deliveryModesService.TYPE_PICKUP) {
      label = "Définir des horaires de retrait";
    }

    return (
      <>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                id="times-enabled"
                name="times-enabled"
                checked={ this.state.times_enabled }
                onChange={ () => {
                  this._isMounted && this.setState({times_enabled: !this.state.times_enabled});
                  if (!this.state.times_enabled === false) {
                    onChange({...data, ...{ times: null }});
                  }
                } }
              />
            }
            label={label}
          />
        </FormGroup>
        <Box>
          { this.state.times_enabled && (
            <SetDaysTimes
              label_times="Horaires"
              value={ data?.times || {} }
              onChange={ (value) => onChange({...data, ...{times: value}}) }
            />
          ) }
        </Box>
      </>
    );
  }
}

const styles = theme => ({
  form_control: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  info_text: {
    margin: theme.spacing(0, 0, 2, 0),
  }
});

SetDeliveryModePrice.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object,
};

SetDeliveryModePrice.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetDeliveryModePrice);
