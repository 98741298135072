import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import utilsService from './../../services/utils';

import { PlusIcon, DeleteIcon } from './../commons/Icons';
import SetTimes from './SetTimes';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Card from '@mui/material/Card';

class SetDaysTimes extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      days: [],
      selected_day: '',
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.setDays();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate (prevProps) {
    if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) {
      this.setDays();
    }
  }

  setDays () {
    const { value } = this.props;
    let days = [];
    let selected_day = '';
    if (!value || !value['mon']) {
      days.push('mon');
      selected_day = selected_day === '' ? 'mon' : selected_day;
    }
    if (!value || !value['tue']) {
      days.push('tue');
      selected_day = selected_day === '' ? 'tue' : selected_day;
    }
    if (!value || !value['wed']) {
      days.push('wed');
      selected_day = selected_day === '' ? 'wed' : selected_day;
    }
    if (!value || !value['thu']) {
      days.push('thu');
      selected_day = selected_day === '' ? 'thu' : selected_day;
    }
    if (!value || !value['fri']) {
      days.push('fri');
      selected_day = selected_day === '' ? 'fri' : selected_day;
    }
    if (!value || !value['sat']) {
      days.push('sat');
      selected_day = selected_day === '' ? 'sat' : selected_day;
    }
    if (!value || !value['sun']) {
      days.push('sun');
      selected_day = selected_day === '' ? 'sun' : selected_day;
    }
    this._isMounted && this.setState({days, selected_day});
  }

  add () {
    if (!this.state.selected_day) {
      return;
    }
    let value = {};
    value[this.state.selected_day] = [];
    this.props.onChange && this.props.onChange({...this.props.value, ...value});
  }

  updateTimes (day, times) {
    let value = {};
    for (const key in this.props.value) {
      if (key === day) {
        value[key] = times;
      } else {
        value[key] = this.props.value[key];
      }
    }
    this.props.onChange && this.props.onChange(value);
  }

  remove (day) {
    let value = {};
    for (const key in this.props.value) {
      if (key !== day) {
        value[key] = this.props.value[key];
      }
    }
    this.props.onChange && this.props.onChange(value);
  }

  render () {
    const { classes, label, label_times, value } = this.props;
    const { days, selected_day } = this.state;

    return (
      <Box className={classes.content}>

        { label && (
          <Typography className={classes.label}>{ label }</Typography>
        ) }

        { value && Object.keys(value).map((day) => {
          return (
            <Card key={day} className={classes.item}>
              <Box className={classes.item_label}>
                <Typography className={classes.item_name}>{ utilsService.getDayLabelFromSlug(day) }</Typography>
                <IconButton aria-label="delete" className={classes.item_button} size="small" onClick={ () => this.remove(day) }>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <Box className={classes.item_times}>
                <Typography className={classes.label}>{ label_times }</Typography>
                <SetTimes
                  value={value[day]}
                  onChange={ (value) => this.updateTimes(day, value) }
                  defaultHours="08"
                />
              </Box>
            </Card>
          )
        }) }

        { days && days.length > 0 && (

          <Card className={clsx(classes.item, classes.item_new)}>
            <FormControl className={classes.action_select}>
              <InputLabel id="select-day">Jour</InputLabel>
              <Select
                className={classes.select_field}
                labelId="select-day"
                label="Jour"
                id="day"
                value={selected_day}
                onChange={ (e) => this._isMounted && this.setState({ selected_day: e.target.value }) }
              >
                { days.map((day) => {
                  return <MenuItem key={day} value={day}>{ utilsService.getDayLabelFromSlug(day) }</MenuItem>
                }) }
              </Select>
            </FormControl>
            <Button
              aria-label="add"
              variant="outlined"
              size="small"
              className={classes.item_button}
              startIcon={<PlusIcon fontSize="inherit" />}
              onClick={ () => this.add() }
            >
              Ajouter
            </Button>
          </Card>

        ) }

      </Box>
    );
  }
}

const styles = theme => ({
  item: {
    padding: theme.spacing(1, 1, 1, 2),
    marginBottom: theme.spacing(2),
  },
  item_new: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item_label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item_name: {
    fontWeight: 'bold'
  },
  item_times: {
    marginTop: theme.spacing(1),
  },
});

SetDaysTimes.propTypes = {
  label: PropTypes.string,
  label_times: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SetDaysTimes);
