import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {
  get (id_profile, id) {
    return API.get('/profiles/'+id_profile+'/attachments/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAll (id_profile, params) {
    return API.get('/profiles/'+id_profile+'/attachments/', {params})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  upload (id_profile, formData, onUploadProgress) {
    return API.post('/profiles/'+id_profile+'/attachments/upload/', formData, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  update (id_profile, id, data) {
    return API.post('/profiles/'+id_profile+'/attachments/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  delete (id_profile, id) {
    return API.delete('/profiles/'+id_profile+'/attachments/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
