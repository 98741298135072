import React from 'react';
import { connect } from 'react-redux';
import { setShortAction } from './../../services/store/features/appSlice';

import DialogEditAttachment from './../dialog/DialogEditAttachment';
import DialogEditOrder from './../dialog/DialogEditOrder';
import DialogEditNewOrder from './../dialog/DialogEditNewOrder';
import DialogEditNewProduct from './../dialog/DialogEditNewProduct';
import DialogEditInvitationSponsoredRegister from './../dialog/DialogEditInvitationSponsoredRegister';
import DialogEditInvitationSubscribe from './../dialog/DialogEditInvitationSubscribe';
import DialogEditInvitationCollaborate from './../dialog/DialogEditInvitationCollaborate';
import DialogEditNotification from './../dialog/DialogEditNotification';
import DialogEditInvoices from './../dialog/DialogEditInvoices';
import DialogEditProfile from './../dialog/DialogEditProfile';
import DialogEditProfileVisibility from './../dialog/DialogEditProfileVisibility';
import DialogEditProfilePro from './../dialog/DialogEditProfilePro';
import DialogEditProfileBilling from './../dialog/DialogEditProfileBilling';
import DialogEditProfileProduction from './../dialog/DialogEditProfileProduction';
import DialogEditProfilePrinters from './../dialog/DialogEditProfilePrinters';
import DialogEditProfileNotifications from './../dialog/DialogEditProfileNotifications';
import DialogEditContact from './../dialog/DialogEditContact';
import DialogGroups from './../dialog/DialogGroups';
import DialogProductCategories from './../dialog/DialogProductCategories';
import DialogProductTags from './../dialog/DialogProductTags';
import DialogEditUser from './../dialog/DialogEditUser';
import DialogEditUserPreferences from './../dialog/DialogEditUserPreferences';
import DialogSubscription from './../dialog/DialogSubscription';
import DialogDeleteProfile from './../dialog/DialogDeleteProfile';
import DialogDeleteProfileRels from './../dialog/DialogDeleteProfileRels';
import DialogDeleteProfileUserRel from './../dialog/DialogDeleteProfileUserRel';
import DialogAuthProfileSwitch from './../dialog/DialogAuthProfileSwitch';
import DialogEditNewComplaint from './../dialog/DialogEditNewComplaint';
import DialogEditNewDeliveryMode from './../dialog/DialogEditNewDeliveryMode';

/**
 * Manage in-app short actions in Dialogs, edit order, invitation, notification, etc.
 */
class AppShortActionsController extends React.Component {

  onSuccess = (...params) => {
    const { appStore } = this.props;
    const shortActionOnSuccess = appStore && appStore.shortAction && appStore.shortAction.callbacks ? appStore.shortAction.callbacks.onSuccess : null;
    if (shortActionOnSuccess) {
      shortActionOnSuccess(...params);
    }
  }

  onError = (...params) => {
    const { appStore } = this.props;
    const shortActionOnError = appStore && appStore.shortAction && appStore.shortAction.callbacks ? appStore.shortAction.callbacks.onError : null;
    if (shortActionOnError) {
      shortActionOnError(...params);
    }
  }

  onClose = (...params) => {
    const { appStore, setShortAction } = this.props;
    const shortActionOnClose = appStore && appStore.shortAction && appStore.shortAction.callbacks ? appStore.shortAction.callbacks.onClose : null;
    setShortAction(null);
    if (shortActionOnClose) {
      shortActionOnClose(...params);
    }
  }

  onEnd = (...params) => {
    const { appStore } = this.props;
    const shortActionOnEnd = appStore && appStore.shortAction && appStore.shortAction.callbacks ? appStore.shortAction.callbacks.onEnd : null;
    if (shortActionOnEnd) {
      shortActionOnEnd(...params);
    }
  }

  render () {
    const { appStore } = this.props;
    const shortActionName = appStore && appStore.shortAction ? appStore.shortAction.name : null;
    const shortActionArgs = appStore && appStore.shortAction ? appStore.shortAction.args : null;

    return (
      <React.Fragment>

        { shortActionName === 'editAttachment' && (
          <DialogEditAttachment
            open={shortActionName === 'editAttachment'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editNewOrder' && (
          <DialogEditNewOrder
            open={shortActionName === 'editNewOrder'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editOrder' && (
          <DialogEditOrder
            open={shortActionName === 'editOrder'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editInvitationSponsoredRegister' && (
          <DialogEditInvitationSponsoredRegister
            open={shortActionName === 'editInvitationSponsoredRegister'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editInvitationSubscribe' && (
          <DialogEditInvitationSubscribe
            open={shortActionName === 'editInvitationSubscribe'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editInvitationCollaborate' && (
          <DialogEditInvitationCollaborate
            open={shortActionName === 'editInvitationCollaborate'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editNotification' && (
          <DialogEditNotification
            open={shortActionName === 'editNotification'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editInvoices' && (
          <DialogEditInvoices
            open={shortActionName === 'editInvoices'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editNewProduct' && (
          <DialogEditNewProduct
            open={shortActionName === 'editNewProduct'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onEnd={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editNewComplaint' && (
          <DialogEditNewComplaint
            open={shortActionName === 'editNewComplaint'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onEnd={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editNewDeliveryMode' && (
          <DialogEditNewDeliveryMode
            open={shortActionName === 'editNewDeliveryMode'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onEnd={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editProfile' && (
          <DialogEditProfile
            open={shortActionName === 'editProfile'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editProfileVisibility' && (
          <DialogEditProfileVisibility
            open={shortActionName === 'editProfileVisibility'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editProfilePro' && (
          <DialogEditProfilePro
            open={shortActionName === 'editProfilePro'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editProfileBilling' && (
          <DialogEditProfileBilling
            open={shortActionName === 'editProfileBilling'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editProfileNotifications' && (
          <DialogEditProfileNotifications
            open={shortActionName === 'editProfileNotifications'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editContact' && (
          <DialogEditContact
            open={shortActionName === 'editContact'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editProfileProduction' && (
          <DialogEditProfileProduction
            open={shortActionName === 'editProfileProduction'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editProfilePrinters' && (
          <DialogEditProfilePrinters
            open={shortActionName === 'editProfilePrinters'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'listGroups' && (
          <DialogGroups
            open={shortActionName === 'listGroups'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'listProductCategories' && (
          <DialogProductCategories
            open={shortActionName === 'listProductCategories'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'listProductTags' && (
          <DialogProductTags
            open={shortActionName === 'listProductTags'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editUser' && (
          <DialogEditUser
            open={shortActionName === 'editUser'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'editUserPreferences' && (
          <DialogEditUserPreferences
            open={shortActionName === 'editUserPreferences'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onSaved={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'viewSubscription' && (
          <DialogSubscription
            open={shortActionName === 'viewSubscription'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'upgradeSubscription' && (
          <DialogSubscription
            open={shortActionName === 'upgradeSubscription'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'deleteProfile' && (
          <DialogDeleteProfile
            open={shortActionName === 'deleteProfile'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onDeleted={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'deleteProfileRels' && (
          <DialogDeleteProfileRels
            open={shortActionName === 'deleteProfileRels'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'deleteProfileUserRel' && (
          <DialogDeleteProfileUserRel
            open={shortActionName === 'deleteProfileUserRel'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            onDeleted={this.onEnd}
            {...shortActionArgs}
          />
        )}

        { shortActionName === 'authProfilesSwitch' && (
          <DialogAuthProfileSwitch
            open={shortActionName === 'authProfilesSwitch'}
            onError={this.onError}
            onSuccess={this.onSuccess}
            onClose={this.onClose}
            {...shortActionArgs}
          />
        )}

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  appStore: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setShortAction: (shortAction) => dispatch(setShortAction(shortAction)),
  }
}

export default (connect(mapStateToProps, mapDispatchToProps)(AppShortActionsController));
