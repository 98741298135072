import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormField from './FormField';
import SelectProductCategories from './SelectProductCategories';
import SelectProductTags from './SelectProductTags';
import { Typography } from '@mui/material';

class SetProductClassification extends React.Component {

  render () {
    const { classes, data, onChange, onError, id_profile } = this.props;

    return (
      <>
        <FormField>
          <SelectProductCategories
            title="Catégories"
            className={{root: classes.form_control}}
            id_profile={ id_profile }
            onChange={ (categories) => onChange({...data, ...{categories}}) }
            onError={ onError }
            value={ data?.categories || [] }
            query={ {} }
          />
          <Typography className={classes.info_text} variant="body3" component="div" color="secondary">Les catégories permettent d'organiser les produits à l'intérieur du catalogue. Elle participent également au référencement de vos produits.<br />Exemple : boulangerie, patisserie, viennoiserie...</Typography>
        </FormField>

        <FormField>
          <SelectProductTags
            title="Tags"
            className={{root: classes.form_control}}
            id_profile={ id_profile }
            onChange={ (tags) => onChange({...data, ...{tags}}) }
            onError={ onError }
            value={ data?.tags || [] }
            query={ {} }
          />
          <Typography className={classes.info_text} variant="body3" component="div" color="secondary">Les tags (mots-clés) sont utiles pour optimiser le référencement d'un produit en complément des catégories.<br />Exemple : sans gluten, bio, local, lot...</Typography>
        </FormField>
      </>
    )
  }
}

const styles = theme => ({
  form_control: {
    width: '100%',
  },
  info_text: {
    margin: theme.spacing(1, 0, 2, 0),
  }
});

SetProductClassification.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.object,
};

SetProductClassification.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(SetProductClassification);
