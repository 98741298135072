import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';

import FormFields from '../commons/FormFields';
import SetDeliveryModeGeneral from '../commons/SetDeliveryModeGeneral';

class EditNewDeliveryModeGeneral extends React.Component {

  render () {
    const { data, onChange } = this.props;
    return (
      <FormFields>
        <SetDeliveryModeGeneral
          data={data}
          onChange={(data) => onChange(data)}
        />
      </FormFields>
    );
  }
}

const styles = theme => ({});

EditNewDeliveryModeGeneral.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  data: PropTypes.object,
};

EditNewDeliveryModeGeneral.defaultProps = {
  data: {}
};

export default withStyles(styles, { withTheme: true })(EditNewDeliveryModeGeneral);
