import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import schemaService from './../../services/schema';
import { RenewIcon } from './Icons';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

class MaintenanceDialog extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
    };
  }

  retry () {
    this._isMounted && this.setState({ loading: true });
    schemaService.get().then(() => {
      // rien à faire, la mise à jour du store est faite par l'API
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  render () {
    const { classes } = this.props;
    const { loading } = this.state;
    const open = this.props.appStore.api_maintenance_mode;
    return (
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.title}>
          Service en maintenance
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Une opération de maintenance est en cours, nous faisons le maximum pour écourter cette coupure de service. Merci de votre compréhension.</DialogContentText>
          <DialogActions>
            <Button disabled={loading} onClick={ () => this.retry() } color="primary" variant="contained" startIcon={loading ? <CircularProgress size={20} color="tertiary" /> : <RenewIcon />}>
              Tester le service
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }
}

const styles = theme => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

MaintenanceDialog.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
};

const mapStateToProps = state => ({
	appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(MaintenanceDialog));
