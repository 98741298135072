import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import profilesService from './../../services/profiles';

import { FormControl, TextField, CircularProgress, IconButton, Box } from '@mui/material';
import FormField from './FormField';
import { RenewIcon } from './Icons';

class SetProfileOptionsLegal extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      loading: false,
      error: false,
      legal: {},
    };
  }

  componentDidMount () {
    this._isMounted = true;
    this.load();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = JSON.stringify(this.props.id_profile) !== JSON.stringify(prevProps.id_profile);
    if (reload) {
      this.load();
    }
  }

  load () {
    const { id_profile, onChange } = this.props;
    if (id_profile) {
      this._isMounted && this.setState({ loading: true, error: false });
      profilesService.getOptions(id_profile).then(([options]) => {
        const legal = options && options.legal ? options.legal : {};
        this._isMounted && this.setState({ legal });
        onChange(legal);
      }).catch((e) => {
        this.props.onError(e);
        this._isMounted && this.setState({ error: true });
      }).finally(() => {
        this._isMounted && this.setState({ loading: false });
      });
    }
  }

  render () {
    const { classes, value, className } = this.props;
    const { loading, error } = this.state;
    const classes_form_control = className ? className.form_control : null;

    if (loading) {
      return (
        <Box className={classes.loading}>
          <CircularProgress color="secondary" />
        </Box>
      );
    } else if (error) {
      return (
        <Box className={classes.loading}>
          <h4>Erreur de chargement</h4>
          <IconButton onClick={() => this.load()} size="large">
            <RenewIcon />
          </IconButton>
        </Box>
      );
    }

    return (
      <>
        <FormField>
          <FormControl className={clsx(classes_form_control, classes.form_control)}>
            <TextField
              required
              id="name"
              label="Dénomination sociale"
              type="text"
              value={value && value.name ? value.name : ''}
              onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{name: e.target.value}}) }
              autoComplete="off"
            />
          </FormControl>
        </FormField>

        <FormField>
          <FormControl className={clsx(classes_form_control, classes.form_control)}>
            <TextField
              required
              id="type"
              label="Forme juridique (SARL, SAS, EU, etc.)"
              type="text"
              value={value && value.type ? value.type : ''}
              onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{type: e.target.value}}) }
              autoComplete="off"
            />
          </FormControl>
        </FormField>

        <FormField>
          <FormControl className={clsx(classes_form_control, classes.form_control)}>
            <TextField
              required
              id="siret"
              label="SIRET"
              type="text"
              value={value && value.siret ? value.siret : ''}
              onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{siret: e.target.value}}) }
              autoComplete="off"
            />
          </FormControl>
        </FormField>

        <FormField>
          <FormControl className={clsx(classes_form_control, classes.form_control)}>
            <TextField
              id="vat"
              label="N° de TVA intracommunautaire"
              type="text"
              value={value && value.vat ? value.vat : ''}
              onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{vat: e.target.value}}) }
              autoComplete="off"
            />
          </FormControl>
        </FormField>

        { false && (
          <>
            <FormField>
              <FormControl className={clsx(classes_form_control, classes.form_control)}>
                <TextField
                  id="capital"
                  label="Capital social"
                  type="text"
                  value={value && value.capital ? value.capital : ''}
                  onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{capital: e.target.value}}) }
                  autoComplete="off"
                  helperText="Montant en euro du capital social, vous pouvez indiquer ici si ce capital est variable"
                />
              </FormControl>
            </FormField>

            <FormField>
              <FormControl className={clsx(classes_form_control, classes.form_control)}>
                <TextField
                  id="rcs"
                  label="N° RCS"
                  type="text"
                  value={value && value.rcs ? value.rcs : ''}
                  onChange={ (e) => this.props.onChange && this.props.onChange({...value, ...{rcs: e.target.value}}) }
                  autoComplete="off"
                  helperText="N° d'enregistrement au registre du commerce et des sociétés"
                />
              </FormControl>
            </FormField>
          </>
        ) }
      </>
    );
  }
}

const styles = theme => ({
  content: {},
  form_control: {
    width: '100%',
  },
});

SetProfileOptionsLegal.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  hide_tel: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(SetProfileOptionsLegal);
