import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  count (params) {
    return API.get('/notifications/count/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  get (id, params) {
    return API.get('/notifications/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  getAll (params) {
    return API.get('/notifications/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  create (data) {
    return API.post('/notifications/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  update (id, data) {
    return API.post('/notifications/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  delete (id) {
    return API.delete('/notifications/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
