import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { withHOCComponent } from '../../hoc/HOCComponent';
import ordersService from '../../../services/orders';
import orderItemsService from '../../../services/order-items';
import { isEmpty } from '../../../services/utils';

import { Box, Button, Step, StepButton, Stepper, Typography } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon, CheckIcon } from '../../commons/Icons';
import EditNewOrderStepProfile from './EditNewOrderStepProfile';
import EditNewOrderStepCatalog from './EditNewOrderStepCatalog';
import EditNewOrderStepType from './EditNewOrderStepType';
import EditNewOrderStepDelivery from './EditNewOrderStepDelivery';
import EditNewOrderStepContacts from './EditNewOrderStepContacts';
import EditNewOrderStepResume from './EditNewOrderStepResume';
import BackdropLoading from '../../commons/BackdropLoading';
import EditNewOrderFinalized from './EditNewOrderFinalized';
import EditControllers from '../../commons/EditControllers';

class EditNewOrder extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      step: '',
      order: {},
      orderItems: [],
      loading: false,
    };
    this.steps = {
      'profile': 0,
      'catalog': 1,
      'type': 2,
      'delivery': 3,
      'contacts': 4,
      'resume': 5,
    };
  }

  componentDidMount () {
    const { appStore, preset, presetOrderItems } = this.props;
    this._isMounted = true;

    // on fixe la commande
    let order = {...preset};
    if (order.scenario === 'customer') {
      order.id_profile_customer = appStore?.authProfile?.ID;
    } else if (order.scenario === 'provider') {
      order.id_profile_provider = appStore?.authProfile?.ID;
    }

    // on fixe les items de commande
    let orderItems = [...presetOrderItems];

    // on détermine l'étape idéale
    let step = 'resume';
    if (order.scenario === 'customer' && isEmpty(order.id_profile_provider)) {
      step = 'profile';
    } else if (order.scenario === 'provider' && isEmpty(order.id_profile_customer)) {
      step = 'profile';
    } else if (isEmpty(orderItems)) {
      step = 'catalog';
    } else if (isEmpty(order.type)) {
      step = 'type';
    } else if (isEmpty(order.delivery)) {
      step = 'delivery';
    } else if (isEmpty(order.contacts)) {
      step = 'contacts';
    }

    // quelques vérifications
    if (!appStore?.authProfile?.ID) {
      console.warn("Authentification requise");
      return;
    }
    if (!preset?.scenario) {
      console.warn("Scénario requis");
      return;
    }
    if (!presetOrderItems) {
      console.warn("Items de commande requis (même vide)");
      return;
    }

    // on va à l'étape
    this.goToStep(step, order, orderItems);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Vérifie si l'étape est complètée
   * @param {object} order 
   * @returns boolean
   */
  isStepCompletedProfile (order) {
    return !isEmpty(order?.id_profile_customer) && !isEmpty(order?.id_profile_provider);
  }

  /**
   * Vérifie si l'étape est complètée
   * @param {array} orderItems 
   * @returns boolean
   */
  isStepCompletedCatalog (orderItems) {
    return !isEmpty(orderItems);
  }

  /**
   * Vérifie si l'étape est complètée
   * @param {object} order 
   * @returns boolean
   */
  isStepCompletedType (order) {
    if (isEmpty(order?.type)) {
      return false;
    }
    if (order.type === ordersService.TYPE_RECURRENT) {
      return !isEmpty(order?.title);
    }
    return true;
  }

  /**
   * Vérifie si l'étape est complètée
   * @param {object} order 
   * @returns boolean
   */
  isStepCompletedDelivery (order) {
    if (isEmpty(order?.id_delivery_mode)) {
      return false;
    }
    if (order.type === ordersService.TYPE_RECURRENT && (isEmpty(order?.recurrence_day) || isEmpty(order?.recurrence_time))) {
      return false;
    }
    return true;
  }

  /**
   * Vérifie si l'étape est complètée
   * @param {object} order 
   * @returns boolean
   */
  isStepCompletedContacts (order) {
    if (isEmpty(order?.customer_billing_contact?.address_name)) {
      return false;
    }
    if (isEmpty(order?.customer_billing_contact?.address_1)) {
      return false;
    }
    if (isEmpty(order?.customer_billing_contact?.address_cp)) {
      return false;
    }
    if (isEmpty(order?.customer_billing_contact?.address_city)) {
      return false;
    }
    if (isEmpty(order?.customer_billing_contact?.address_country)) {
      return false;
    }
    return true;
  }

  /**
   * Vérifie que l'étape spécifiée est accessible
   * en fonction de l'état de la commande et de ses items
   * @param {string} step 
   * @param {object} order 
   * @param {array} orderItems 
   * @returns 
   */
  isStepEnabled (step, order, orderItems) {
    if (step === 'profile') {
      if (order?.ID) {
        // L'accès à l'étape du profil n'est plus possible une fois que la commande est crée
        // L'API ne permettant pas de modifier les profils d'une commande
        return false;
      }
      return true;
    }
    if (!this.isStepCompletedProfile(order)) {
      return false;
    }
    if (step === 'catalog') {
      return true;
    }
    if (!this.isStepCompletedCatalog(orderItems)) {
      return false;
    }
    if (step === 'type') {
      return true;
    }
    if (!this.isStepCompletedType(order)) {
      return false;
    }
    if (step === 'delivery') {
      return true;
    }
    if (!this.isStepCompletedDelivery(order)) {
      return false;
    }
    if (step === 'contacts') {
      return true;
    }
    if (!this.isStepCompletedContacts(order)) {
      return false;
    }
    if (step === 'resume') {
      return true;
    }
    return false;
  }

  /**
   * Renvoi le libellé de l'étape spécifiée
   * @param {string} step 
   * @returns 
   */
  getStepLabel (step) {
    const { order } = this.state;
    if (step === 'profile') {
      if (order?.scenario === 'customer') {
        return "Founisseur";
      } else if (order?.scenario === 'provider') {
        return "Client";
      }
      return "Client/Fournisseur";
    }
    if (step === 'catalog') {
      return "Produits";
    }
    if (step === 'type') {
      return "Type de commande";
    }
    if (step === 'delivery') {
      return "Mode de livraison";
    }
    if (step === 'contacts') {
      return "Coordonnées";
    }
    if (step === 'resume') {
      return "Résumé";
    }
    return "";
  }

  /**
   * Renvoi l'index de l'étape précédente
   */
  getPrevStep () {
    const { step } = this.state;
    let newStep = '';
    if (step === 'resume') {
      newStep = 'contacts';
    } else if (step === 'contacts') {
      newStep = 'delivery';
    } else if (step === 'delivery') {
      newStep = 'type';
    } else if (step === 'type') {
      newStep = 'catalog';
    } else if (step === 'catalog') {
      newStep = 'profile';
    }
    return newStep;
  }

  /**
   * Renvoi l'index de l'étape suivante
   */
  getNextStep () {
    const { step } = this.state;
    let newStep = 'resume';
    if (step === 'profile') {
      newStep = 'catalog';
    } else if (step === 'catalog') {
      newStep = 'type';
    } else if (step === 'type') {
      newStep = 'delivery';
    } else if (step === 'delivery') {
      newStep = 'contacts';
    }
    return newStep;
  }

  /**
   * Place l'ui sur l'étape précédente
   */
  prevStep () {
    const { order, orderItems } = this.state;
    this.goToStep(this.getPrevStep(), order, orderItems);
  }

  /**
   * Place l'ui l'étape suivante
   */
  nextStep () {
    const { order, orderItems } = this.state;
    this.goToStep(this.getNextStep(), order, orderItems);
  }

  /**
   * Place l'ui sur l'étape spécifiée et créer la commande si nécessaire
   * @param {string} step 
   * @param {object} order 
   * @param {array} orderItems 
   */
  goToStep (step, order, orderItems) {
    // on enregistre les données (commande et items de commande) qu'au moment de passer au résumé
    if (step !== 'resume') {
      this._isMounted && this.setState({ order, orderItems, step });
    } else {
      if (!order?.ID) {
        this.create(order, orderItems, step);
      } else {
        this.update(order, orderItems, step);
      }
    }
  }

  /**
   * les données de la commande ont été modifiées
   * @param {object} changedOrder 
   * @param {array} changedOrderItems (false : pas de changement)
   */
  onOrderChange (changedOrder, changedOrderItems = false) {
    const { step, orderItems } = this.state;

    // dans certains cas de figure, on passe directement à l'étape suivante
    if (step === 'profile' && this.isStepCompletedProfile(changedOrder)) {
      this.goToStep(this.getNextStep(), changedOrder, changedOrderItems ? changedOrderItems : orderItems);
    } if (step === 'type' && this.isStepCompletedType(changedOrder) && changedOrder.type === ordersService.TYPE_STANDARD) {
      this.goToStep(this.getNextStep(), changedOrder, changedOrderItems ? changedOrderItems : orderItems);
    } else {
      // sinon on met simplement à jour le state
      this._isMounted && this.setState({order: changedOrder, orderItems: changedOrderItems ? changedOrderItems : orderItems});
    }
  }

  /**
   * Les items de commande ont été modifiés
   * @param {array} changedOrderItems
   */
  onOrderItemsChange (changedOrderItems) {
    this._isMounted && this.setState({orderItems: changedOrderItems});
  }

  /**
   * Crée la commande et ses items de commande dans l'API
   * @param {object} order la commande issue du state
   * @param {array} orderItems les items de commande issus du state
   * @param {string} step l'étape vers laquelle aller
   */
  create (order, orderItems, step) {
    this._isMounted && this.setState({loading: true});
    ordersService.create(order).then(([id_order, notices]) => {
      notices && notices.length > 0 && this.props.hoc.showWarning(notices);
      // on enregistre les items de commande
      const orderItemsPromise = orderItemsService.set(id_order, orderItems);
      return Promise.all([id_order, orderItemsPromise]);
    }).then(([id_order]) => {
      return this.afterSaved(id_order, step);
    }).catch((e) => {
      this.props.hoc.showError(e);
      // erreur lors de l'enregistrement, on met à jour le state afin que l'utilisateur ne perde pas ses données et puisse retenter
      this._isMounted && this.setState({ order, orderItems });
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  /**
   * Met à jour la commande et ses items de commande dans l'API
   * @param {object} order la commande issue du state
   * @param {array} orderItems les items de commande issus du state
   * @param {string} step l'étape vers laquelle aller
   */
  update (order, orderItems, step) {
    this._isMounted && this.setState({loading: true});
    ordersService.update(order.ID, order).then(([id_order, notices]) => {
      notices && notices.length > 0 && this.props.hoc.showWarning(notices);
      // on enregistre les items de commande
      const orderItemsPromise = orderItemsService.set(id_order, orderItems);
      return Promise.all([id_order, orderItemsPromise]);
    }).then(([id_order]) => {
      return this.afterSaved(id_order, step);
    }).catch((e) => {
      this.props.hoc.showError(e);
      // erreur lors de l'enregistrement, on met à jour le state afin que l'utilisateur ne perde pas ses données et puisse retenter
      this._isMounted && this.setState({ order, orderItems });
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  /**
   * Finalise le tunnel de commande en la passant en statut 'attente'
   * - pour une commande standard, cela revient à l'émettre
   * - pour une commande récurrente, cela revient à l'activer
   */
  finalize () {
    const { order } = this.state;
    this._isMounted && this.setState({loading: true});
    ordersService.setState(order.ID, ordersService.STATE_PENDING).then(([id_order, notices]) => {
      notices && notices.length > 0 && this.props.hoc.showWarning(notices);
      return this.afterSaved(id_order, 'finalized');
    }).catch((e) => {
      this.props.hoc.showError(e);
    }).finally(() => {
      this._isMounted && this.setState({ loading: false });
    });
  }

  /**
   * Après un enregistrement de la commande et ses items dans l'API (create / update)
   * @param {number} id_order l'ID de la commande enregistrée
   * @param {object} order la commande issue du state
   * @param {array} orderItems les items de commande issus du state
   * @param {string} step l'étape vers laquelle aller
   * @returns 
   */
  async afterSaved (id_order, step) {
    const { onSaved } = this.props;
    // récupération de la commande à jour
    const orderPromise = ordersService.get(id_order).then(([order]) => order);
    // récupération des items de commande à jour
    const orderItemsPromise = orderItemsService.getAll({id_order}).then(([orderItems]) => orderItems);
    return Promise.all([orderPromise, orderItemsPromise]).then(([order, orderItems]) => {
      // on met à jour le state
      this._isMounted && this.setState({ order, orderItems, step });
      // on informe le parent
      onSaved && onSaved(order);
    });
  }

  render () {
    const { classes, onQuit, scrollableTarget } = this.props;
    const { step, order, orderItems, loading } = this.state;
    const step_index = this.steps[step];
    if (step === 'finalized') {
      return this.renderFinalized();
    }
    return (
      <Box className={classes.root}>
        <BackdropLoading open={loading} />
        <Box className={classes.stepper}>
          <Box className={classes.stepper_for_desktop}>
            <Stepper activeStep={step_index} alternativeLabel>
              {Object.keys(this.steps).map((step_key) => (
                <Step key={step_key} disabled={!this.isStepEnabled(step_key, order, orderItems)}>
                  <StepButton color="inherit" onClick={() => this.goToStep(step_key, order, orderItems)}>
                    <Typography variant="body3" component="span" className={classes.stepper_label}>{this.getStepLabel(step_key)}</Typography>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box className={classes.stepper_for_mobile}>
            <Typography variant="body2" component="div" className={classes.stepper_label_for_mobile}>{this.getStepLabel(step)}</Typography>
          </Box>
        </Box>
        <Box className={classes.step_content}>
          { step === 'profile' && (
            <Box>
              <EditNewOrderStepProfile scrollableTarget={scrollableTarget} order={order} onChange={(newOrder) => {
                // dans le cas d'un changement de profil, on retire les données de la commande qui ne correspondent plus au nouveau profil
                if (parseInt(newOrder.id_profile_customer) !== parseInt(order.id_profile_customer) || parseInt(newOrder.id_profile_provider) !== parseInt(order.id_profile_provider)){
                  this.onOrderChange({
                    scenario: newOrder.scenario,
                    id_profile_customer: newOrder.id_profile_customer,
                    id_profile_provider : newOrder.id_profile_provider,
                  }, []);
                } else {
                  this.onOrderChange(newOrder);
                }
              }} onError={(e) => this.props.hoc.showError(e)} onQuit={() => onQuit && onQuit()} />
            </Box>
          ) }
          { step === 'catalog' && (
            <Box>
              <EditNewOrderStepCatalog scrollableTarget={scrollableTarget} order={order} orderItems={orderItems} onChange={(orderItems) => this.onOrderItemsChange(orderItems)} onError={(e) => this.props.hoc.showError(e)} />
            </Box>
          ) }
          { step === 'type' && (
            <Box className={classes.step_content_inner}>
              <EditNewOrderStepType scrollableTarget={scrollableTarget} order={order} onChange={(order) => this.onOrderChange(order)} onError={(e) => this.props.hoc.showError(e)} />
            </Box>
          ) }
          { step === 'delivery' && (
            <Box className={classes.step_content_inner}>
              <EditNewOrderStepDelivery scrollableTarget={scrollableTarget} order={order} onChange={(order) => this.onOrderChange(order)} onError={(e) => this.props.hoc.showError(e)} />
            </Box>
          ) }
          { step === 'contacts' && (
            <Box className={classes.step_content_inner}>
              <EditNewOrderStepContacts scrollableTarget={scrollableTarget} order={order} onChange={(order) => this.onOrderChange(order)} onError={(e) => this.props.hoc.showError(e)} />
            </Box>
          ) }
          { step === 'resume' && (
            <Box className={classes.step_content_inner_resume}>
              <EditNewOrderStepResume scrollableTarget={scrollableTarget} order={order} orderItems={orderItems} onChange={(order) => this.onOrderChange(order)} onError={(e) => this.props.hoc.showError(e)} />
            </Box>
          ) }
        </Box>
        <EditControllers sticky className={{root: classes.edit_ctrl}}>
          <Button disabled={loading || !this.isStepEnabled(this.getPrevStep(), order, orderItems)} className={classes.btn_prev} onClick={() => this.prevStep()} color="secondary" startIcon={ <ArrowLeftIcon /> }></Button>
          { step !== 'resume' && (
            <Button disabled={loading || !this.isStepEnabled(this.getNextStep(), order, orderItems)} className={classes.btn_next} onClick={() => this.nextStep()} color="primary" variant="contained" endIcon={ <ArrowRightIcon /> }>Suivant</Button>
          ) }
          { step === 'resume' && ordersService.isStandard(order) && (
            <Button disabled={loading} className={classes.btn_next} onClick={() => this.finalize()} color="primary" variant="contained" endIcon={ <CheckIcon /> }>Commander</Button>
          ) }
          { step === 'resume' && ordersService.isRecurrent(order) && (
            <Button disabled={loading} className={classes.btn_next} onClick={() => this.finalize()} color="primary" variant="contained" endIcon={ <CheckIcon /> }>Activer</Button>
          ) }
        </EditControllers>
      </Box>
    );
  }

  renderFinalized () {
    const { classes, onEnd, scrollableTarget } = this.props;
    const { order } = this.state;
    return (
      <Box className={classes.root}>
        <Box className={classes.step_finalized}>
          <EditNewOrderFinalized scrollableTarget={scrollableTarget} order={order} onEnd={() => onEnd && onEnd()} />
        </Box>
      </Box>
    );
  }
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0),
  },
  step: {},
  stepper: {
    margin: theme.spacing(0),
    backgroundColor: '#ffffff',
    padding: theme.spacing(1,2),
    borderBottom: '1px solid ' + theme.palette.bg.light,
    position: 'sticky',
    top: 0,
    zIndex: 10,
    overflow: 'hidden',
  },
  stepper_for_desktop: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  stepper_for_mobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  stepper_label_for_mobile: {
    textAlign: 'center',
    fontWeight: 600,
  },
  step_content: {
    minHeight: '60vh',
    backgroundColor: '#ffffff',
  },
  step_content_inner: {
    padding: theme.spacing(1),
  },
  step_content_inner_resume: {
    backgroundColor: theme.palette.bg.light,
  },
  step_finalized: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  edit_ctrl:{
    justifyContent: 'space-between',
  },
  btn_next: {},
  btn_prev: {},
});

const mapStateToProps = state => ({
  appStore: state.app,
});

EditNewOrder.propTypes = {
  order: PropTypes.object,
  onSaved: PropTypes.func,
  onDeleted: PropTypes.func,
  onSuccess: PropTypes.func,
  onQuit: PropTypes.func,
  onEnd: PropTypes.func,
  preset: PropTypes.object,
  presetOrderItems: PropTypes.array,
  onStepChange: PropTypes.func,
  scrollableTarget: PropTypes.string,
};

export default withHOCComponent(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditNewOrder)));
