import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import profilesService from './../../services/profiles';

import ProductCategoriesListItem from './ProductCategoriesListItem';
import ProductCategoriesListSearch from './ProductCategoriesListSearch';
import DialogEditProductCategory from './../dialog/DialogEditProductCategory';
import List from './../commons/List';
import { PlusIcon } from './../commons/Icons';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

class ProductCategoriesList extends React.Component {

  constructor (props) {
    super(props);
    this._isMounted = false; // isMounted React pattern to avoid memory leaks
    this.state = {
      categories: [],
      loading: false,
      reload: false,
      openDialog_edit: false,
      openDialog_edit_item: null,
    };
  }

  componentDidMount () {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const reload = JSON.stringify(this.props.query) !== JSON.stringify(prevProps.query);
    if (reload) {
      this._isMounted && this.setState({ reload: !this.state.reload });
    }
  }

  load (limit, offset, searchQuery) {
    return profilesService.getAllProductCategories(this.props.id_profile, {...this.props.query, ...searchQuery, ...{limit, offset}}).then(([items]) => items);;
  }

  onProductCategorySaved (savedProductCategory, is_new) {
    const { selectAfterCreate } = this.props;
    if (is_new) {
      this._isMounted && this.setState({categories: [...[savedProductCategory], ...this.state.categories]});
      if (selectAfterCreate) {
        this.props.onSelect(savedProductCategory);
      }
    } else {
      this._isMounted && this.setState(prevState => ({
        categories: prevState.categories.map((product) => {
          if (product.ID === savedProductCategory.ID) {
            return savedProductCategory;
          }
          return product;
        })
      }));
    }
  }

  onProductCategoryDeleted (id_deletedProductCategory) {
    this._isMounted && this.setState(prevState => ({
      categories: prevState.categories.filter((product) => {
        return product.ID !== id_deletedProductCategory;
      })
    }))
  }

  openEdit (item = null) {
    this._isMounted && this.setState({ openDialog_edit_item: item, openDialog_edit: true });
  }

  closeEdit () {
    this._isMounted && this.setState({ openDialog_edit_item: null, openDialog_edit: false });
  }

  editOnSelect = (item) => {
    this.openEdit(item);
  }

  render() {
    const { classes, id_profile, query } = this.props;
    const { categories, loading, reload } = this.state;

    const onSelect = this.props.onSelect || (this.props.canUpdate && this.editOnSelect);

    return (
      <Box className={classes.content}>

        { (this.props.canCreate || this.props.canUpdate) && (
          <React.Fragment>
            { this.state.openDialog_edit && (
              <DialogEditProductCategory
                open={ this.state.openDialog_edit }
                category={ this.state.openDialog_edit_item }
                id_profile={ id_profile }
                preset={ query }
                onSaved={ (product, is_new) => {
                  this.onProductCategorySaved(product, is_new);
                  this.closeEdit();
                } }
                onDeleted={ (id) => {
                  this.onProductCategoryDeleted(id);
                  this.closeEdit();
                } }
                onError={ this.props.onError }
                onClose={ () => this.closeEdit() }
              />
            ) }
            { this.props.canCreate && (
              <Box className={classes.actions}>
                <Button variant="outlined" color="primary" className={classes.button} startIcon={<PlusIcon />} onClick={ () => this.openEdit() }>Catégorie</Button>
              </Box>
            ) }
          </React.Fragment>
        ) }

        <List
          title={this.props.title}
          reload={reload}
          load={(limit, offset, searchQuery) => this.load(limit, offset, searchQuery)}
          loaded={(items) => this._isMounted && this.setState({categories: items})}
          searchEnabled={this.props.searchEnabled}
          renderSearch={(onInitialize, searchQuery, onQueryChange) => (
            <ProductCategoriesListSearch
              query={searchQuery}
              onQueryChange={onQueryChange}
              onInitialize={onInitialize}
              searching={loading}
              onError={this.props.onError}
              expanded={this.props.searchExpanded}
            />
          )}
          defaultSearchQuery={this.props.defaultSearchQuery}
          listOnlyOnSearch={this.props.listOnlyOnSearch}
          onSelect={onSelect}
          selectedItemsKeys={this.props.selectedItemsKeys}
          onError={this.props.onError}
          onSuccess={this.props.onSuccess}
          infiniteScrollProps={ {
            limit: this.props.limit || 25,
            scrollableTarget: this.props.scrollableTarget,
            items: categories,
            onLoading: (loading) => this._isMounted && this.setState({loading}),
            noResult: this.props.noResult,
          } }
          renderItem={ (category, selectable) => (
            <ProductCategoriesListItem
              id_profile={ id_profile }
              category={category}
              onSaved={ (category, is_new) => this.onProductCategorySaved(category, is_new) }
              onDeleted={ (id) => this.onProductCategoryDeleted(id) }
              onError={this.props.onError}
              onSuccess={this.props.onSuccess}
              right={this.props.listItemRight}
              disableExpand={selectable}
            />
          ) }
        />

      </Box>
    )
  }
}

const styles = theme => ({
  content: {
  },
  title: {
    padding: theme.spacing(1, 2),
  },
  actions: {
    padding: theme.spacing(2),
  },
  button: {
    width: '100%',
  }
});

ProductCategoriesList.propTypes = {
  id_profile: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  limit: PropTypes.number,
  title: PropTypes.string,
  onSelect: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  query: PropTypes.object,
  noResult: PropTypes.element,
  scrollableTarget: PropTypes.string,
  selectedItemsKeys: PropTypes.array,

  searchEnabled: PropTypes.bool,
  searchExpanded: PropTypes.bool,
  listOnlyOnSearch: PropTypes.bool,
  defaultSearchQuery: PropTypes.object,

  canCreate: PropTypes.bool,
  canUpdate: PropTypes.bool,
  selectAfterCreate: PropTypes.bool,

  listItemRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
};

ProductCategoriesList.defaultProps = {
  searchEnabled: true,
};

export default withStyles(styles, { withTheme: true })(ProductCategoriesList);
